import * as Types from '../../../types/generated/index';

import { gql } from '@apollo/client';
import { FolderShallowFragmentDoc } from '../../fragments/generated/folder-shallow';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateFolderMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  company_id: Types.Scalars['ID'];
  input: Types.UpdateFolderInput;
}>;


export type UpdateFolderMutation = { __typename?: 'Mutation', updateFolder: { __typename: 'Folder', id: string, company_id: string, created_at: string, fixed: boolean, parent_id?: string | null, title: string, parent?: { __typename: 'Folder', id: string, company_id: string, created_at: string, fixed: boolean, title: string } | null, user?: { __typename: 'User', id: string, first_name?: string | null, last_name?: string | null, avatar?: string | null } | null } };


export const UpdateFolderMutationDocument = gql`
    mutation UpdateFolderMutation($id: ID!, $company_id: ID!, $input: UpdateFolderInput!) {
  updateFolder(id: $id, company_id: $company_id, input: $input) {
    ...FolderShallow
  }
}
    ${FolderShallowFragmentDoc}`;
export type UpdateFolderMutationMutationFn = Apollo.MutationFunction<UpdateFolderMutation, UpdateFolderMutationVariables>;

/**
 * __useUpdateFolderMutation__
 *
 * To run a mutation, you first call `useUpdateFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFolderMutation, { data, loading, error }] = useUpdateFolderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      company_id: // value for 'company_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFolderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFolderMutation, UpdateFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFolderMutation, UpdateFolderMutationVariables>(UpdateFolderMutationDocument, options);
      }
export type UpdateFolderMutationHookResult = ReturnType<typeof useUpdateFolderMutation>;
export type UpdateFolderMutationMutationResult = Apollo.MutationResult<UpdateFolderMutation>;
export type UpdateFolderMutationMutationOptions = Apollo.BaseMutationOptions<UpdateFolderMutation, UpdateFolderMutationVariables>;