import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserTracing } from '@sentry/tracing'
import { HeadProvider } from 'react-head'
import { ApolloProvider } from '@apollo/client'
import App from './app'
import * as Sentry from '@sentry/react'
import client from './apollo/client'

// Sentry
Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
})

// Segment.io
if (process.env.SEGMENT_SOURCE_WRITE_KEY) {
  analytics.load(process.env.SEGMENT_SOURCE_WRITE_KEY)
  analytics.page('App')
}

// CSS
import './styles/index.css'

// Fonts
;(() => {
  if (sessionStorage.fontsLoaded) {
    document.documentElement.className += 'fonts-loaded'
    return
  }

  if ('fonts' in document) {
    Promise.all([document.fonts.load('700 1em GT Flexa')]).then(() => {
      document.documentElement.className += 'fonts-loaded'
      sessionStorage.fontsLoaded = true
    })
  }
})()

const container = document.getElementById('root')

if (container) {
  const root = createRoot(container)
  root.render(
    <Sentry.ErrorBoundary
      fallback={<div>An error has occurred.</div>}
      showDialog
    >
      <ApolloProvider client={client}>
        <HeadProvider>
          <App />
        </HeadProvider>
      </ApolloProvider>
    </Sentry.ErrorBoundary>
  )
}
