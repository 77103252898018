import { getSuffixForArnEndpoint } from "@aws-sdk/middleware-bucket-endpoint";
import { HttpRequest } from "@aws-sdk/protocol-http";
export const writeGetObjectResponseEndpointMiddleware = (config) => (next, context) => async (args) => {
    const { region: regionProvider, isCustomEndpoint, disableHostPrefix } = config;
    const region = await regionProvider();
    const { request, input } = args;
    if (!HttpRequest.isInstance(request))
        return next({ ...args });
    let hostname = request.hostname;
    if (hostname.endsWith("s3.amazonaws.com") || hostname.endsWith("s3-external-1.amazonaws.com")) {
        return next({ ...args });
    }
    if (!isCustomEndpoint) {
        const [, suffix] = getSuffixForArnEndpoint(request.hostname);
        hostname = `s3-object-lambda.${region}.${suffix}`;
    }
    if (!disableHostPrefix && input.RequestRoute) {
        hostname = `${input.RequestRoute}.${hostname}`;
    }
    request.hostname = hostname;
    context["signing_service"] = "s3-object-lambda";
    if (config.runtime === "node" && !request.headers["content-length"]) {
        request.headers["transfer-encoding"] = "chunked";
    }
    return next({ ...args });
};
export const writeGetObjectResponseEndpointMiddlewareOptions = {
    relation: "after",
    toMiddleware: "contentLengthMiddleware",
    tags: ["WRITE_GET_OBJECT_RESPONSE", "S3", "ENDPOINT"],
    name: "writeGetObjectResponseEndpointMiddleware",
    override: true,
};
export const getWriteGetObjectResponseEndpointPlugin = (config) => ({
    applyToStack: (clientStack) => {
        clientStack.addRelativeTo(writeGetObjectResponseEndpointMiddleware(config), writeGetObjectResponseEndpointMiddlewareOptions);
    },
});
