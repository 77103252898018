import * as Types from '../../../types/generated/index';

import { gql } from '@apollo/client';
export type AssetFragment = { __typename: 'Asset', id: string, name?: string | null, display_name?: string | null, description?: string | null, created?: string | null, updated?: string | null, url?: string | null, company_id: string, preview_url?: string | null, thumb_url?: string | null, folder_id?: string | null, user?: { __typename?: 'User', id: string, first_name?: string | null, last_name?: string | null } | null };

export const AssetFragmentDoc = gql`
    fragment AssetFragment on Asset {
  __typename
  id
  name
  display_name
  description
  created
  updated
  url
  company_id
  preview_url
  thumb_url
  folder_id
  user {
    id
    first_name
    last_name
  }
}
    `;