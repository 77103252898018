import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'
import GRAPH_COLORS from '~/src/constants/graph-colors'

interface IBarChart {
  labelFormat?: { x?: string; y?: string }
  yFormatter?: (number: number) => string
  xFormatter?: (number: number) => string
  horizontal?: boolean // Renders the bars horizontally
  distributed?: boolean // Distributes colors from GRAPH_COLORS across the bars
  data?: number[]
  labels?: string[]
  seriesName?: string
  id: string
}

const BarChart = ({
  labelFormat,
  yFormatter,
  xFormatter,
  horizontal,
  distributed,
  labels,
  data,
  seriesName,
  id,
}: IBarChart) => {
  const chart = {
    options: {
      chart: {
        toolbar: {
          show: false,
        },
        id: id,
        fontFamily: 'Manrope',
      },
      tooltip: {
        enabled: true,
        followCursor: true,
        custom: ({
          series,
          seriesIndex,
          dataPointIndex,
          w,
        }: {
          series: [ApexAxisChartSeries]
          seriesIndex: number
          dataPointIndex: number
          w: any
        }) => {
          const color = w.config.fill.colors[distributed ? dataPointIndex : 0]
          const value = series?.[seriesIndex]?.[dataPointIndex]
          const label = labels?.[dataPointIndex]
          const formattedValue = horizontal
            ? xFormatter?.(value as any)
            : yFormatter?.(value as any)
          return `<div class="graph__tooltip">
            <div class="graph__tooltip-item">
              <div class="graph__marker" style="background-color: ${color}"></div>
              <div class="graph__tooltip-label">${label}:</div> 
              <div class="graph__tooltip-value">${formattedValue}</div>
            </div>
          </div>`
        },
      },
      legend: {
        showForSingleSeries: true,
        fontSize: '12px',
        markers: {
          width: 10,
          height: 10,
          fillColors: distributed ? GRAPH_COLORS : ['#436CFF'],
          radius: 4,
        },
        labels: {
          colors: '#718096',
        },
        onItemClick: {
          toggleDataSeries: false,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        colors: distributed ? GRAPH_COLORS : ['#436CFF'],
        opacity: 1,
      },
      states: {
        hover: {
          filter: 'none',
        },
      },
      grid: {
        position: horizontal ? 'front' : 'back',
        borderColor: '#EDF2F7',
        xaxis: {
          lines: {
            show: horizontal ? true : false,
          },
        },
        yaxis: {
          lines: {
            show: horizontal ? false : true,
          },
        },
      },
      xaxis: {
        categories: labels || ['One', 'Two', 'Three', 'Four', 'Five'],
        labels: {
          style: {
            colors: '#718096',
            fontSize: '10px',
          },
          format: labelFormat?.x,
          formatter: xFormatter,
        },
        axisBorder: {
          color: '#EDF2F7',
        },
        axisTicks: {
          color: '#EDF2F7',
        },
      },
      yaxis: {
        labels: {
          show: horizontal ? false : true,
          style: {
            colors: '#718096',
            fontSize: '10px',
          },
          formatter: yFormatter,
        },
        axisBorder: {
          color: '#EDF2F7',
        },
        axisTicks: {
          color: '#EDF2F7',
        },
      },
      plotOptions: {
        bar: {
          distributed: distributed,
          horizontal: horizontal,
          borderRadius: 4,
        },
      },
    },
    series: [
      {
        name: seriesName || 'Placeholder',
        data: data || [1, 2, 3, 4, 5],
      },
    ],
    noData: {
      text: 'Loading...',
    },
  }

  return (
    <ReactApexChart
      id={id}
      options={chart.options as ApexOptions}
      series={chart.series}
      type="bar"
      width="100%"
      height={horizontal ? 350 : 250}
    />
  )
}

export default BarChart
