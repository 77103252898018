import React, { useEffect } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import useAccounts from './hooks/use-accounts'
import { RouterProvider } from 'react-router-dom'
import { router } from './routes'
import useCurrentUser from './hooks/use-current-user'
import NotificationContainer from '~/src/components/notification-container'

const App = () => {
  const { currentUserId } = useCurrentUser()

  // TODO: stick this in a cute preloading hook.
  // Preloading accounts, which we need for the dashboard.
  useAccounts()

  // Identify the user for Segment tracking.
  useEffect(() => {
    if (currentUserId) {
      analytics.identify(currentUserId)
    }
  }, [currentUserId])

  return (
    <>
      <RouterProvider router={router} />
      <NotificationContainer />
    </>
  )
}

export default App
