import React from 'react'
import { FormFields } from '~/src/components/form-fields'
import Button from '~/src/components/button'
import { useForm } from 'react-hook-form'
import { FORMS } from '~/src/constants/forms'
import './change-password.css'
import { useMutation, gql } from '@apollo/client'
import CHANGE_PASSWORD_MUTATION from '~/src/graphql/mutations/change-password.graphql'
import { notify } from '~/src/utilities/notify'
interface IChangePassword {
  onConfirm: () => void
  onForgotPwd?: () => void
  userId?: string
}

type FormData = {
  password: string
  newPassword: string
  confirmPassword: string
}

const ChangePassword = ({
  userId,
  onForgotPwd,
  onConfirm,
}: IChangePassword): JSX.Element => {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<FormData>({ mode: 'onSubmit' })

  const [changePassword, { data }] = useMutation(gql(CHANGE_PASSWORD_MUTATION))

  const onSubmit = handleSubmit(async (data: FormData) => {
    const { data: response } = await changePassword({
      variables: {
        id: userId,
        password: data.password,
        new_password: data.newPassword,
        password_confirmation: data.confirmPassword,
      },
    })

    if (!response?.changePassword?.message) {
      onConfirm()
      notify('Password successfully changed.', { type: 'success' })
    }
  })

  return (
    <form onSubmit={onSubmit} className="change-password">
      <FormFields.Password
        {...register('password', {
          required: FORMS.VALIDATION.REQUIRED_FIELD,
        })}
        label="Current Password"
        error={errors.password?.message}
      />
      <button
        type="button"
        aria-label="forgot password?"
        className="change-password__forgot"
        onClick={() => onForgotPwd?.()}
      >
        Forgot Password?
      </button>

      <FormFields.Password
        {...register('newPassword', {
          required: FORMS.VALIDATION.REQUIRED_FIELD,
          validate: {
            isNew: () =>
              getValues('password') !== getValues('newPassword')
                ? true
                : 'New password must be different from old.',
          },
        })}
        label="New Password"
        error={errors.newPassword?.message}
      />
      <FormFields.Password
        {...register('confirmPassword', {
          required: FORMS.VALIDATION.REQUIRED_FIELD,
          validate: () =>
            getValues('newPassword') === getValues('confirmPassword')
              ? true
              : 'Passwords do not match.',
        })}
        label="Confirm New Password"
        error={errors.confirmPassword?.message}
      />
      <Button
        type="submit"
        label="Change Password"
        theme="primary"
        size="medium"
        className="change-password__confirm"
      />

      <div className="change-password__form-error">
        {data?.changePassword?.message}
      </div>
    </form>
  )
}

export default ChangePassword
