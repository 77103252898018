interface IWithParentId {
  id?: string | null
  parentId?: string | null
}

export const getParents = <T extends IWithParentId>(
  child: T,
  set: T[]
): T[] => {
  const parent = child.parentId && set.find(({ id }) => child.parentId === id)
  if (parent) {
    return [parent, ...getParents(parent, set)]
  }

  return []
}

export const getChildren = <T extends IWithParentId>(
  item: T,
  set: T[]
): T[] => {
  const children = set.filter(({ parentId }) => parentId === item.id)

  if (children.length) {
    return children.reduce<T[]>(
      (acc, child) => [...acc, child, ...getChildren(child, set)],
      []
    )
  }

  return []
}

export const getFirstChildren = <T extends IWithParentId>(
  item: T,
  set: T[],
  accessor: keyof T = 'parentId'
): T[] => {
  return set.filter(eachItem => eachItem[accessor] === item.id)
}

export const getTree = <T extends IWithParentId>(
  item: T,
  set: T[],
  accessor: keyof T = 'parentId',
  mapItem?: (item: T) => any
): any => {
  const children = getFirstChildren(item, set, accessor)
  const mappedItem = mapItem ? mapItem(item) : item

  if (!children.length) {
    return { ...mappedItem, children }
  }

  return {
    ...mappedItem,
    children: children.map(child => getTree(child, set, accessor, mapItem)),
  }
}
