import React, { useState } from 'react'
import ActionItem from './action-item'
import SVGHandler from '~/src/components/svg-handler'
import { FlowIcons } from '~/src/assets/flow-icons'
import { IActionItem } from '~/src/types/action-items'

const ActionItemGroup = ({
  label,
  items,
}: {
  items: IActionItem[]
  label: string
}): JSX.Element => {
  const [collapsed, setCollapsed] = useState<boolean>(false)

  return (
    <div className="action-item-group">
      <h3
        className="action-item-group__header"
        onClick={() => setCollapsed(!collapsed)}
      >
        <span>{label}</span>{' '}
        <span className="text-grayscale-600 text-lg">({items.length})</span>
        <SVGHandler
          size="medium"
          icon={collapsed ? FlowIcons.ChevronDown : FlowIcons.ChevronUp}
          color="gray"
        />
      </h3>
      {!collapsed && (
        <div className="action-item-group__items">
          {items.map(item => (
            <ActionItem key={item.id} item={item} />
          ))}
        </div>
      )}
    </div>
  )
}

export default ActionItemGroup
