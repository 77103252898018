import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'
import GRAPH_COLORS from '~/src/constants/graph-colors'

const LineChart = ({
  id,
  labels,
  series,
  yFormatter,
}: {
  id: string
  labels?: string[]
  series: ApexAxisChartSeries
  yFormatter?: (number: number) => string
}) => {
  const chart = {
    options: {
      chart: {
        toolbar: { show: false },
        id: id,
        fontFamily: 'Manrope',
      },
      stroke: {
        width: 2,
      },
      colors: GRAPH_COLORS,
      fill: {
        colors: GRAPH_COLORS,
        opacity: 1,
      },
      tooltip: {
        enabled: true,
        shared: true,
        custom: ({
          series,
          dataPointIndex,
          w,
        }: {
          series: ApexAxisChartSeries[]
          dataPointIndex: number
          w: any
        }) => {
          const sharedTip: number[] = series.map(serie =>
            parseInt(serie[dataPointIndex] as unknown as string)
          )

          let markup = ''

          for (let i = 0; i < sharedTip.length; i++) {
            const value = yFormatter?.(sharedTip[i])

            markup += `<div class="graph__tooltip-item">
              <div class="graph__marker" style="background-color: ${
                w.config.colors[i]
              }">
              </div> 
              <div class="graph__tooltip-label">${
                w.globals.seriesNames[i]
              }:</div>
              <div class="graph__tooltip-value">${
                !isNaN(sharedTip[i]) ? value : 'N/A'
              }</div>
            </div>`
          }

          return `<div class="graph__tooltip">${markup}</div>`
        },
      },
      legend: {
        showForSingleSeries: true,
        fontSize: '12px',
        markers: {
          width: 10,
          height: 10,
          fillColors: GRAPH_COLORS,
          radius: 4,
        },
        labels: {
          colors: '#718096',
        },
        onItemClick: {
          toggleDataSeries: true,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
      },
      grid: {
        borderColor: '#EDF2F7',
      },
      xaxis: {
        categories: labels,
        type: 'category',
        labels: {
          style: {
            colors: '#718096',
            fontSize: '10px',
          },
        },
        tooltip: {
          enabled: false,
        },
        axisBorder: {
          color: '#EDF2F7',
        },
        axisTicks: {
          color: '#EDF2F7',
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: '#718096',
            fontSize: '10px',
          },
          formatter: yFormatter,
        },
        axisBorder: {
          color: '#EDF2F7',
        },
        axisTicks: {
          color: '#EDF2F7',
        },
      },
    },
    series,
  }

  return (
    <ReactApexChart
      id={id}
      options={chart.options as ApexOptions}
      series={chart.series}
      type="line"
      width="100%"
      height={250}
    />
  )
}

export default LineChart
