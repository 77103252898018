import { useMemo } from 'react'
import { sumBy, sortBy, groupBy } from 'lodash'
import { TCardSettings } from '../types/card-settings'
import { QBO_DATE_FORMAT } from '../pages/dashboard/config'
import { eachMonthOfInterval, isWithinInterval, parse, format } from 'date-fns'
import useBalances from '~/src/hooks/use-balances'
import useAccounts from '~/src/hooks/use-accounts'
import { rollup } from '../utilities/rollup'

const NO_DATA_VALUE = 0

const dateFromQBOFormat = (start: string) =>
  parse(start, QBO_DATE_FORMAT, new Date())
const monthAndYear = 'MM-yyyy'

const useCashBalances = ({ startDate, endDate, accountIds }: TCardSettings) => {
  const { balances, loading: balancesLoading } = useBalances()
  const { accounts, loading: accountsLoading } = useAccounts()

  const interval = {
    start: startDate,
    end: endDate,
  }

  const monthsInRange = eachMonthOfInterval(interval)

  const balancesInRange = balances?.filter(
    ({ start, accountId }) =>
      isWithinInterval(dateFromQBOFormat(start), interval) &&
      accountId &&
      accountIds?.includes(accountId)
  )

  // This is actually what we base our series on
  const groupedByAccount = groupBy(
    balancesInRange || [],
    ({ account }) => account
  )

  // EXPL: groupedByMonth and sumsByMonth are technically not needed for the main
  // card, but they're used by the mini card for now, and in the interest of time,
  // I will be leaving them here for now.
  const groupedByMonth = groupBy(balancesInRange || [], ({ start }) =>
    format(dateFromQBOFormat(start), monthAndYear)
  )

  const sumsByMonth = monthsInRange.map((month: Date) => {
    const balancesInMonth = groupedByMonth[format(month, monthAndYear)]

    return {
      date: month,
      sum: balancesInMonth ? sumBy(balancesInMonth, 'value') : NO_DATA_VALUE,
    }
  })

  const sortedBalances = useMemo(
    () =>
      rollup({
        accounts,
        accountIds,
        balances,
        start: startDate,
        end: endDate,
      }),
    [accounts, accountIds, balances, startDate, endDate]
  )

  return {
    sortedBalances,
    sumsByMonth: sortBy(sumsByMonth, 'date'),
    // using sortBy with no arguments just converts the object into an array
    // and preserves the sort order. i'm sure this can be done in a better way.
    groupedByAccount: sortBy(groupedByAccount),
    loading: balancesLoading || accountsLoading,
  }
}

export default useCashBalances
