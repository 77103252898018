import React from 'react'
import useCurrentUser from '~/src/hooks/use-current-user'
import CompanyForm from '../company-form'

const CompanyGeneral = (): JSX.Element => {
  const { currentCompany: company } = useCurrentUser()

  return (
    <section>
      <div className="company-page company-page--twothirds">
        {company && <CompanyForm company={company} />}
      </div>
    </section>
  )
}

export default CompanyGeneral
