import { useQuery, gql } from '@apollo/client'
import JIRA_EPICS_QUERY from '~/src/graphql/queries/jira-epics.graphql'
import useCurrentUser from './use-current-user'
import { BookStatus } from '../types/bookkeeping-banner'
import { sub, parse, endOfWeek } from 'date-fns'
import { QBO_DATE_FORMAT } from '../pages/dashboard/config'

const useJira = (
  options: { skip?: boolean } = {}
): {
  bookStatus: BookStatus | undefined
  calendarRange: Date[]
  dueDate: Date
  loading: boolean
} => {
  const { currentCompanyId } = useCurrentUser()

  const { data, loading } = useQuery(gql(JIRA_EPICS_QUERY), {
    variables: {
      company_id: currentCompanyId,
    },
    ...options,
  })

  /**
   * States!
   * It's pretty simple - we rank the states by order of precedence,
   * and if we can't get a valid state, we return undefined so the
   * component templates can react properly.
   */
  const getBookStatus = () => {
    if (data?.blockedJiraEpics?.length) return BookStatus.NEEDS_RESPONSE
    if (data?.unblockedJiraEpics?.length) return BookStatus.UNBLOCKED
    if (data?.inProgressJiraEpics?.length) return BookStatus.PROCESSING
    if (data?.closedJiraEpics?.length) return BookStatus.READY
    else return undefined
  }

  const bookStatus = getBookStatus()

  /**
   * Dates!
   * We only use the due date for in-progress status. The calendar range
   * is used to calculate what the first and last days in the dynamic
   * calendar should be. We don't actively use the end day, but it's
   * useful for confirming that the internal calendar component is
   * doing what it should.
   */

  // returns a Date from the provided date string, or a dummy Date to satisfy props.
  const getDueDate = () => {
    const date = data?.inProgressJiraEpics?.[0]?.duedate

    return date
      ? parse(
          data?.inProgressJiraEpics?.[0].duedate,
          QBO_DATE_FORMAT,
          new Date()
        )
      : new Date()
  }

  // gets our calendar range
  const getCalendarDates = (dueDate: Date): Date[] => {
    if (!dueDate) return []

    const end = endOfWeek(dueDate)
    const start = sub(end, { days: 13 })

    return [start, end]
  }

  const dueDate = getDueDate()
  const calendarRange = dueDate ? getCalendarDates(dueDate) : []

  return {
    bookStatus,
    calendarRange,
    dueDate,
    loading,
  }
}

export default useJira
