import React, { ElementType } from 'react'
import SVGHandler from '~/src/components/svg-handler'
import { FlowIcons } from '~/src/assets/flow-icons'
import FolderMenu from './folder-menu'
import { useNavigate } from 'react-router-dom'
import { Folder } from '~/src/types'

interface IFolderCard {
  as?: ElementType
  folder: Folder
}

// Uses the useNavigate hook instead of a NavLink so we don't have non-semantic markup.
const FolderCard = ({ folder }: IFolderCard) => {
  const navigate = useNavigate()
  return (
    <li
      onClick={() => navigate(`/documents/${folder.id}`)}
      className="documents__folder"
    >
      <div className="documents__folder-header">
        <SVGHandler
          size="medium"
          icon={FlowIcons.SystemFolder}
          title="FlowFi Folder"
        />

        <div
          onClick={(e: React.MouseEvent) => e.stopPropagation()}
          className="documents__folder-menu"
        >
          <FolderMenu document={folder} iconColor="gray" />
        </div>
      </div>

      <div className="documents__folder-name">{folder.title}</div>
    </li>
  )
}

export default FolderCard
