import { IMember } from '../types/member'
import Roles, { CustomerRoles } from '../types/roles'
import { IUser } from '../types/user'
import UserTypes from '../types/user-types'
export interface UserProperties {
  isDeveloper: boolean
  isInternal: boolean
  isCustomer: boolean
  isInternalOrCustomer: boolean
}
export interface MemberProperties extends UserProperties {
  customerRole?: Roles
  hasCustomerRole: boolean
}

/**
 * Most of these are sneaky three state booleans: yes, no, and we don't know (or know yet).
 * Don't depend on the inverse of these being true.
 * Choose positive permissions:
 *  e.g. if (isInternalUser) - safe
 * instead of the absence of negatives
 *  e.g. if (!isExternalUser) - does not necessarily mean the user is internal
 */

/**
 * You're employed by Flow.
 */
export const isInternal = (user?: IUser): boolean => {
  return !!user?.type && user.type !== UserTypes.customer
}

/**
 * You are an internal superuser. You aren't visible as a member of companies,
 * and you can't be assigned to customer-facing roles.
 */
export const isDeveloper = (user?: IUser): boolean => {
  return !!user?.type && user.type !== UserTypes.customer
}

/**
 * You're not employed by Flow.
 * You have a user account because a customer of Flow gave it to you.
 */
export const isCustomer = (user?: IUser): boolean => {
  // This is the only UserType that a customer can have.
  return !!user?.type && user.type === UserTypes.customer
}

export const getUserProperties = (user?: IUser): UserProperties => {
  const type = user?.type

  if (!type) {
    return {
      isDeveloper: false,
      isInternal: false,
      isCustomer: false,
      isInternalOrCustomer: false,
    }
  }

  return {
    isDeveloper: isDeveloper(user),
    isInternal: isInternal(user),
    isCustomer: isCustomer(user),
    isInternalOrCustomer: isInternal(user) || isCustomer(user),
  }
}

export const getCustomerRole = (member?: IMember) => {
  const role =
    // role property exists
    !!member?.role &&
    // and you're a customer
    isCustomer(member) &&
    // and your role is one of the roles that is meaningful for customers
    ([CustomerRoles.admin, CustomerRoles.member] as Roles[]).includes(
      member.role
    ) &&
    // so we can safely return your role.
    member.role

  return role || undefined
}

// Assert on the presence of a customer role.
export const hasCustomerRole = (member?: IMember): boolean => {
  return !!getCustomerRole(member)
}

export const getMemberProperties = (
  user?: IUser,
  member?: IMember
): MemberProperties => {
  if (!member) {
    return {
      customerRole: undefined,
      hasCustomerRole: false,
      ...getUserProperties(user),
    }
  }

  return {
    customerRole: getCustomerRole(member),
    hasCustomerRole: hasCustomerRole(member),
    ...getUserProperties(user),
  }
}

// Teams
export const getTeamPagePermissions = (user?: IUser) => {
  const { isInternal, isInternalOrCustomer } = getMemberProperties(user)
  return {
    // All internal users can edit the teams page.
    canEdit: isInternal,
    // Everyone can read the teams page.
    canView: isInternalOrCustomer,
  }
}

export const getMemberPagePermissions = (user?: IUser, member?: IMember) => {
  const { isInternal, isInternalOrCustomer, customerRole } =
    getMemberProperties(user, member)

  return {
    // Flow staff can always edit. Customers can edit if they're an admin.
    canEdit: isInternal || customerRole === CustomerRoles.admin,
    // Everyone can view the members page.
    canView: isInternalOrCustomer,
  }
}
