import { useState } from 'react'
import { QueryConfig } from '../types/generated'

const FALLBACK_PAGE_SIZE = 8
const defaultPageSize = process.env.DEFAULT_PAGE_SIZE
  ? parseInt(process.env.DEFAULT_PAGE_SIZE, 10)
  : FALLBACK_PAGE_SIZE
const INCREMENT = 100

interface UsePaginationArgs<T> {
  // Optional: items to window
  items?: Array<T>
  // Optional: the total number of items.
  count?: number
  // How many items we initially queried for.
  fetchMore?: (args: { variables: { config: QueryConfig } }) => void
}

const usePagination = <T>({
  items = [],
  count: totalCount,
  fetchMore,
}: UsePaginationArgs<T>) => {
  const [page, setPage] = useState<number>(0)
  const [fetchOffset, setFetchOffset] = useState(0)

  const perPage = defaultPageSize
  const offset = page * perPage
  const count = totalCount ?? items.length
  const pages = Math.ceil(count / perPage)

  const window = (items: T[]) =>
    items.length > perPage ? items.slice(offset, offset + perPage) : items
  const windowedItems: Array<T> = window(items)

  const handlePageClick = (event: { selected: number }) => {
    const newPage = event.selected
    setPage(newPage)

    const isMoreToFetch = (newPage + 1) * perPage < count
    const notAlreadyFetched = (newPage + 1) * perPage > items.length
    const shouldFetch = isMoreToFetch && notAlreadyFetched

    if (shouldFetch && fetchMore) {
      fetchMore({
        variables: { config: { limit: INCREMENT, offset: fetchOffset } },
      })
      setFetchOffset(fetchOffset + INCREMENT)
    }
  }

  // You can spread this as props directly into our Pagination component.
  const pagination = {
    page,
    pages,
    count,
    onPageChange: handlePageClick,
  }

  return {
    pagination,
    perPage,
    page,
    pages,
    count,
    window,
    windowedItems,
    handlePageClick,
    setPage,
  }
}

export default usePagination
