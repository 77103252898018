import React from 'react'
import PageHeader from '~/src/components/page-header'
import CreateFolder from './create-folder'
import DocumentsControls from './documents-controls'
import DocumentGrid from './document-grid'
import { FolderCardGrid } from './folder-card-grid'
import useCurrentUser from '~/src/hooks/use-current-user'
import useResponsive from '~/src/hooks/use-responsive'
import UseDesktop from '~/src/assets/images/use-desktop.svg'

const DocumentsIndex = () => {
  const { isDesktop } = useResponsive()

  const { currentCompany } = useCurrentUser()

  const folderId = currentCompany.rootFolder?.id

  return (
    <>
      <PageHeader title="Documents" />

      {isDesktop ? (
        <>
          <DocumentsControls searchPlaceholder="Search all files" />

          <FolderCardGrid rootFolderId={folderId} />
          <DocumentGrid rootFolderId={folderId} />

          <CreateFolder parentId={folderId} />
        </>
      ) : (
        <div className="flex flex-col items-center text-center m-4 mt-20">
          <img className="mb-4" src={UseDesktop} alt="WIP image" />
          <h3 className="mb-4">For full functionality, please use desktop.</h3>
          <p>
            The FlowFi App is currently optimized for desktop browsers. For the
            best experience, please use a desktop computer or a laptop. We
            apologize for any inconvenience this may cause.
          </p>
        </div>
      )}
    </>
  )
}

export default DocumentsIndex
