import {
  UseFormGetValues,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { IAccount, IAccountWithChildren } from '~/src/types/account'
import { AccountPickerFormData } from '.'
import { onlyParents } from '~/src/utilities/accounts'

type FormData = {
  [id: string]: boolean
}

const convertMapToList = (map: FormData): string[] =>
  Object.entries(map).reduce(
    (acc: string[], [key, val]) => (val ? [...acc, key] : acc),
    []
  )

interface IGetAccountPickerDefaultValuesArgs {
  // "Optional". We must prevent the hook from returning defaultValues
  // until all of these are present. Undefined means not present, empty set is real!
  accounts: IAccount[]
  accountIds: (string | undefined)[]
}

export const getAccountPickerDefaultValues = ({
  accounts,
  accountIds,
}: IGetAccountPickerDefaultValuesArgs) => {
  return accounts.reduce((acc: FormData, account: IAccount) => {
    if (!account.id) {
      return acc
    }
    return {
      ...acc,
      [account.id]: accountIds.includes(account.id),
    }
  }, {})
}

const getHelpers = (
  getValues: UseFormGetValues<AccountPickerFormData>,
  watch: UseFormWatch<AccountPickerFormData>,
  setValue: UseFormSetValue<AccountPickerFormData>,
  accountsWithChildren: IAccountWithChildren[]
) => {
  const areChildrenChecked = (parentId: string, childId: string) => {
    const children = accountsWithChildren.find(({ id }) => parentId === id)
      ?.children

    if (!children?.length) {
      return {}
    }

    const values = children.map(({ id }) =>
      id === childId ? true : getValues('accountIds')[id]
    )

    return values.every(x => x)
  }

  const setChildren = (account: IAccountWithChildren, value: boolean) => {
    // Look up the account being checked.
    const children = account?.children || []

    if (children.length) {
      children.map(({ id: childId }) =>
        setValue(`accountIds.${childId}`, value)
      )
    }
  }

  const setParents = (account: IAccountWithChildren, value: boolean) => {
    if (!account.parentIds) {
      return
    }

    account.parentIds.map(parentId => {
      // If the parent is checked, and we're unchecking its child, we should uncheck the parent.
      const accountIds = getValues('accountIds')
      const parentValue = accountIds[parentId]
      if (parentValue && !value) {
        setValue(`accountIds.${parentId}`, value)
      }

      // If the parent is unchecked, and we're checking its child, we should check it if and only if
      // this change means all its children are checked.
      if (!parentValue && value) {
        if (!areChildrenChecked(parentId, account.id)) {
          return
        }

        setValue(`accountIds.${parentId}`, true)
      }
    })
  }

  // Helper function for a checkbox to figure out if it should display the "indeterminate" state.
  const isIndeterminate = (accountId: string) => {
    const children = accountsWithChildren.find(
      account => accountId === account.id
    )?.children

    // If it doesn't have children, it can't be "indeterminate"
    if (!children?.length) {
      return false
    }

    const formValues = watch()

    const ownValue = formValues.accountIds[accountId]

    // If it's unchecked, it can't be "indeterminate"
    if (!ownValue) {
      return false
    }

    const values = children.map(({ id }) =>
      formValues.accountIds ? formValues.accountIds[id] : false
    )

    return !values.every(x => x)
  }

  const { accountIds } = watch()
  const idArray = convertMapToList(accountIds)
  const numChecked = onlyParents(
    accountsWithChildren.filter(({ id }) => idArray.includes(id))
  ).length
  const noneChecked = numChecked === 0
  const someChecked = numChecked !== 0

  return {
    numChecked,
    noneChecked,
    someChecked,
    areChildrenChecked,
    setChildren,
    setParents,
    isIndeterminate,
  }
}

export default getHelpers
