import React from 'react'
import '../documents.css'
import PageHeader from '~/src/components/page-header'
import AssetGrid from './asset-grid'
import DocumentsControls from './documents-controls'
import DocumentsContextProvider from './documents-context-provider'

const AssetsPage = (): JSX.Element => {
  return (
    <DocumentsContextProvider>
      <PageHeader title="Documents" />
      <section>
        <DocumentsControls />
        <AssetGrid />
      </section>
    </DocumentsContextProvider>
  )
}

export default AssetsPage
