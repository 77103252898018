import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { selectedCompanyId } from '~/src/cache'
import Button from '~/src/components/button'
import CompanyIcon from '~/src/components/company-icon'
import { Invitation } from '~/src/components/invitation'
import Loader from '~/src/components/loader'
import useAuth from '~/src/hooks/use-auth'
import useCompany from '~/src/hooks/use-company'
import useCurrentUser from '~/src/hooks/use-current-user'
import useInvitation from '~/src/hooks/use-invitation'
import States from '~/src/types/states'
import { Title } from 'react-head'
import { setInLocalStorage } from '~/src/utilities/local-storage'
import { InvalidInvitation } from '../invitation-invalid'

enum InvitationStatus {
  loading,
  valid,
  wrongEmail,
  invalid,
}

const InvitationPage = (): JSX.Element | null => {
  const navigate = useNavigate()
  const { currentUser, logOutCurrentUser } = useCurrentUser()
  const { invitationId } = useParams()
  const { invitation, loading: invitationLoading } = useInvitation({
    invitationId,
  })
  const { company, loading: companyLoading } = useCompany(
    invitation?.company_id
  )

  const { acceptInvitationAsExistingUser, invitationResponse } = useAuth({
    invitationId,
  })

  const { data } = invitationResponse
  const success = data?.acceptInvite?.user?.id

  const [invitationState, setInvitationStatus] = useState<InvitationStatus>()

  useEffect(() => {
    if (invitationLoading || companyLoading)
      setInvitationStatus(InvitationStatus.loading)
    else if (
      invitation?.state === States.pending &&
      currentUser?.id === invitation?.user_id
    )
      setInvitationStatus(InvitationStatus.valid)
    else if (invitation?.state === States.pending) {
      setInvitationStatus(InvitationStatus.wrongEmail)
    } else setInvitationStatus(InvitationStatus.invalid)
  }, [invitationLoading, companyLoading, invitation, currentUser])

  useEffect(() => {
    if (success && currentUser?.id) {
      setInLocalStorage({
        key: currentUser.id,
        path: 'selectedCompanyId',
        value: invitation.company_id,
      })
      selectedCompanyId(invitation.company_id)
      navigate('/', {
        replace: true,
      })
    }
  }, [success, invitation?.company_id, currentUser?.id, navigate])

  const companyName = invitation?.company_name

  switch (invitationState) {
    case InvitationStatus.loading:
      return <Loader size="lg" fullScreen />
    case InvitationStatus.valid:
      return (
        <>
          <Title>FlowFi App | Accept Invitation</Title>
          <Invitation
            companyIcon={<CompanyIcon company={company} size="3xl" />}
            message={
              <span>
                You've been invited to join{' '}
                <span className="invitation-details__company-name">
                  {companyName}
                </span>
              </span>
            }
            messageDetail={`As you accept this invitation, ${companyName} will be added to your company list.`}
            button={
              <Button
                label="Accept Invitation"
                theme="primary"
                className="w-full"
                onClick={acceptInvitationAsExistingUser}
              />
            }
          />
        </>
      )
    case InvitationStatus.wrongEmail:
      return (
        <>
          <Title>FlowFi App | Invalid Email</Title>
          <Invitation
            message="Wrong email"
            messageDetail="This is an invite to a different email address. Use the correct email address to login."
            button={
              <Button
                label="Log in to another account"
                theme="text"
                onClick={logOutCurrentUser}
              />
            }
          />
        </>
      )
    case InvitationStatus.invalid:
      return <InvalidInvitation />
    default:
      return null
  }
}

export default InvitationPage
