import * as Types from '../../../types/generated/index';

import { gql } from '@apollo/client';
import { AssetFragmentDoc } from '../../fragments/generated/asset';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AssetQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  company_id: Types.Scalars['ID'];
}>;


export type AssetQuery = { __typename?: 'Query', asset?: { __typename: 'Asset', id: string, name?: string | null, display_name?: string | null, description?: string | null, created?: string | null, updated?: string | null, url?: string | null, company_id: string, preview_url?: string | null, thumb_url?: string | null, folder_id?: string | null, user?: { __typename?: 'User', id: string, first_name?: string | null, last_name?: string | null } | null } | null };


export const AssetQueryDocument = gql`
    query AssetQuery($id: ID!, $company_id: ID!) {
  asset(id: $id, company_id: $company_id) {
    ...AssetFragment
  }
}
    ${AssetFragmentDoc}`;

/**
 * __useAssetQuery__
 *
 * To run a query within a React component, call `useAssetQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetQuery({
 *   variables: {
 *      id: // value for 'id'
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useAssetQuery(baseOptions: Apollo.QueryHookOptions<AssetQuery, AssetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssetQuery, AssetQueryVariables>(AssetQueryDocument, options);
      }
export function useAssetQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssetQuery, AssetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssetQuery, AssetQueryVariables>(AssetQueryDocument, options);
        }
export type AssetQueryHookResult = ReturnType<typeof useAssetQuery>;
export type AssetQueryLazyQueryHookResult = ReturnType<typeof useAssetQueryLazyQuery>;
export type AssetQueryQueryResult = Apollo.QueryResult<AssetQuery, AssetQueryVariables>;