import React from 'react'
import BarChart from '~/src/components/graphs/bar'
import Loader from '~/src/components/loader'
import useAccounts from '~/src/hooks/use-accounts'
import useTopExpenseAccounts from '~/src/hooks/use-top-expense-accounts'
import { formatCurrency } from '~/src/utilities/currency'

const NUM_TO_DISPLAY = 10

const TopExpenseAccounts = ({
  startDate,
  endDate,
}: {
  startDate: Date
  endDate: Date
}) => {
  const { sumsByAccount, loading } = useTopExpenseAccounts({
    startDate,
    endDate,
  })

  const { getAccount } = useAccounts()

  const topExpenseAccounts = sumsByAccount.slice(0, NUM_TO_DISPLAY)

  const labels = topExpenseAccounts.map(({ id }) => getAccount(id)?.name || '')
  const sums = topExpenseAccounts.map(({ sum }) => sum)

  return (
    <>
      {loading ? (
        <Loader size="lg" centered />
      ) : (
        <BarChart
          id="top-expense-accounts"
          seriesName="Total"
          horizontal
          distributed
          labels={labels}
          data={sums}
          xFormatter={(number: number) =>
            formatCurrency(number, { notation: 'compact' })
          }
        />
      )}
    </>
  )
}

export default TopExpenseAccounts
