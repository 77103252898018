import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import Badge from '~/src/components/badge'
import SVGHandler from '~/src/components/svg-handler'
import Classnames from 'classnames'
import ReactTooltip from 'react-tooltip'
import useResponsive from '~/src/hooks/use-responsive'

interface INavBarLink {
  collapsed: boolean
  label: string
  icon: string
  path: string
  notificationCount?: number
  onClick?: () => void
}

const NavBarLink = ({
  collapsed,
  label,
  icon,
  path,
  notificationCount,
  onClick,
}: INavBarLink): JSX.Element => {
  const handleClick = () => {
    onClick?.()
  }

  const linkClasses = Classnames('navigation-item', {
    'navigation-item--collapsed': collapsed,
  })

  const [hovering, setHovering] = useState<boolean>(false)

  const { isDesktop } = useResponsive()

  return (
    <li
      className={linkClasses}
      onMouseOver={() => setHovering(true)}
      onMouseOut={() => setHovering(false)}
    >
      <NavLink
        aria-label={label}
        data-tip={`${label} Page`}
        data-test={`navbar-link-${label.toLowerCase()}`}
        to={path}
        onClick={handleClick}
        className={({ isActive }) =>
          isActive
            ? 'navigation-item__link navigation-item__link--active'
            : 'navigation-item__link'
        }
      >
        {({ isActive }) => (
          <>
            <SVGHandler
              size="large"
              color={isActive || hovering ? 'primary' : 'black'}
              icon={icon}
              title={label}
            />
            {!collapsed && <span>{label}</span>}
            {!!notificationCount && <Badge count={notificationCount} />}
          </>
        )}
      </NavLink>
      {collapsed && isDesktop && (
        <ReactTooltip
          className="panopticon-tooltip"
          place="right"
          effect="solid"
        />
      )}
    </li>
  )
}

export default NavBarLink
