import React from 'react'
import BooksReady from './components/books-ready'
import BooksProcessing from './components/books-processing'
import BooksNeedResponse from './components/books-need-response'
import BooksResponded from './components/books-responded'
import { sub, format } from 'date-fns'
import { IBannerProps, BookStatus } from '~/src/types/bookkeeping-banner'
import './bookkeeping-banner.css'

interface IBookkeepingBanner {
  calendarRange: Date[]
  dueDate: Date
  status: BookStatus
}

const BookkeepingBanner = ({
  calendarRange,
  dueDate,
  status,
}: IBookkeepingBanner): JSX.Element | null => {
  const BannerViews: Record<
    BookStatus,
    (bannerProps: IBannerProps) => JSX.Element
  > = {
    [BookStatus.READY]: BooksReady,
    [BookStatus.PROCESSING]: BooksProcessing,
    [BookStatus.NEEDS_RESPONSE]: BooksNeedResponse,
    [BookStatus.UNBLOCKED]: BooksResponded,
  }

  const WhichBanner = BannerViews[status] ?? null

  const bookMonth = format(sub(dueDate, { months: 1 }), 'MMMM yyyy')
  const formattedDD = format(dueDate, 'MMMM d, yyyy')

  const bannerProps: IBannerProps = {
    calendarRange,
    dueDate,
    bookMonth,
    formattedDD,
  }

  return (
    <div className="bookkeeping-banner">
      <WhichBanner {...bannerProps} />
    </div>
  )
}

export default BookkeepingBanner
