import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import SVGHandler from '~/src/components/svg-handler'
import { FlowIcons } from '~/src/assets/flow-icons'
import './modal.css'
import { closeModal } from '~/src/hooks/use-modal'

interface IModal {
  open: boolean
  title?: string
  children: React.ReactNode
  onClose?: () => void | boolean
  disableClose?: boolean
}

const Modal = ({
  open,
  title,
  children,
  onClose = closeModal,
  disableClose,
}: IModal): JSX.Element => {
  return (
    <Transition show={open} as={Fragment}>
      <Dialog onClose={() => onClose()} className="modal">
        {/* Modal Backdrop */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="modal__backdrop" />
        </Transition.Child>

        {/* Modal Content */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95 -translate-y-9"
          enterTo="opacity-100 scale-100 translate-y-0"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100 translate-y-0"
          leaveTo="opacity-0 scale-95 -translate-y-9"
        >
          <div className="modal__wrap">
            <Dialog.Panel className="modal__panel">
              <button
                aria-label="close modal"
                className="modal__close"
                onClick={() => onClose()}
                disabled={disableClose}
              >
                <SVGHandler
                  size="large"
                  icon={FlowIcons.X}
                  title="Dismiss Modal"
                  color={disableClose ? 'gray' : 'black'}
                />
              </button>
              {title && (
                <Dialog.Title className="modal__title" as={'h3'}>
                  {title}
                </Dialog.Title>
              )}
              {children}
            </Dialog.Panel>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  )
}

export default Modal
