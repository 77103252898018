import React from 'react'
import ReactPaginate from 'react-paginate'
import './pagination.css'

interface IPagination {
  page: number
  count: number
  pages: number
  onPageChange: ({ selected }: { selected: number }) => void
}

const Pagination = ({
  page,
  count,
  pages,
  onPageChange,
}: IPagination): JSX.Element => {
  return (
    <div className="pagination h-8">
      <div className="pagination__count">
        {!!count && (count < 1 ? `${count} Item` : `${count} Items`)}
      </div>
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        forcePage={page}
        onPageChange={e => onPageChange(e)}
        pageRangeDisplayed={5}
        pageCount={pages}
        previousLabel="<"
        renderOnZeroPageCount={() => null}
      />
    </div>
  )
}

export default Pagination
