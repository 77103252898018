import React from 'react'

const ProfileNotifications = (): JSX.Element => {
  return (
    <section>
      <h2>Notifications</h2>
    </section>
  )
}

export default ProfileNotifications
