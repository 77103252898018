import React from 'react'
import Logo from '~/src/components/logo'
import UserMenu from '~/src/components/user-menu'
import NavBarToggle from './navbar-toggle'
import NavBarItems from './navbar-items'
import Classnames from 'classnames'
import { navCollapsed } from '~/src/cache'
import { INavBar } from '..'

const NavBarStandard = ({
  collapsed,
  actionItemCount,
}: INavBar): JSX.Element => {
  const toggleCollapse = () => {
    navCollapsed(!collapsed)
  }

  const navClasses = Classnames('app-navigation', {
    'app-navigation--collapsed': collapsed,
  })

  return (
    <nav className={navClasses}>
      <Logo collapsed={collapsed} />
      <NavBarToggle onClick={toggleCollapse} collapsed={collapsed} />
      <NavBarItems collapsed={collapsed} badgeProps={{ actionItemCount }} />
      <UserMenu collapsed={collapsed} />
    </nav>
  )
}

export default NavBarStandard
