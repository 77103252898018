import React from 'react'
import usePlaidBalances from '~/src/hooks/use-plaid-balances'
import { formatCurrency } from '~/src/utilities/currency'
import Classnames from 'classnames'
import Info from '~/src/components/info'
import { formatDistanceToNow } from 'date-fns'
import SVGHandler from '~/src/components/svg-handler'
import { FlowIcons } from '~/src/assets/flow-icons'

const MiniLiveCashBalanceCard = () => {
  const { liveBalance, lastUpdated, loading } = usePlaidBalances()

  const cardClasses = Classnames('animate-value animate-value--out', {
    'animate-value--in': !loading,
  })

  const updated =
    !loading &&
    lastUpdated &&
    formatDistanceToNow(new Date(lastUpdated), {
      addSuffix: true,
    })

  return (
    <>
      <div className={cardClasses}>
        <div className="mini-card__value">
          {liveBalance ? formatCurrency(liveBalance) : ''}
        </div>
        <div className="mt-1 mb-2 text-xs flex items-center gap-2 text-grayscale-600">
          <SVGHandler size="small" icon={FlowIcons.CheckFill} color="gray" />
          <span>Last updated {updated}</span>
        </div>
      </div>
    </>
  )
}

const MiniLiveCashBalanceHeader = () => {
  const { accounts } = usePlaidBalances()

  const accountInfo = (
    <div>
      <div>
        Live Cash Balances are retrieved from Plaid for the following bank
        accounts:
      </div>
      <ul className="mt-2 list-disc ml-2 pl-2">
        {accounts.sort().map((account, i) => (
          <li key={i}>{account}</li>
        ))}
      </ul>
    </div>
  )

  return (
    <div className="mini-card__info-line">
      <Info html={accountInfo} />
    </div>
  )
}

export default MiniLiveCashBalanceCard

export { MiniLiveCashBalanceHeader }
