import { startOfMonth, sub } from 'date-fns'
import { AccountType, IAccount } from '~/src/types/account'
import { TCardSettings, TDaysOverdue } from '~/src/types/card-settings'
import { endDateFromRange, startDateFromRange } from '~/src/utilities/dates'
import Bills from './components/cards/bills'
import BurnRateCard, { BurnRateCardHeader } from './components/cards/burn-rate'
import CashBalancesCard from './components/cards/cash-balances'
import ExpenseDistributionCard from './components/cards/expense-distribution'
import GrossMarginCard from './components/cards/gross-margin'
import Invoices from './components/cards/invoices'
import MiniBurnRateCard, {
  MiniBurnRateCardHeader,
} from './components/cards/mini-burn-rate'
import MiniLiveCashBalanceCard, {
  MiniLiveCashBalanceHeader,
} from './components/cards/mini-live-cash-balance'
import MiniCashBalancesCard, {
  MiniCashBalancesHeader,
} from './components/cards/mini-cash-balances'
import MiniRevenueCard, {
  MiniRevenueCardHeader,
} from './components/cards/mini-revenue'
import RevenueCard from './components/cards/revenue'
import Spending from './components/cards/spending'
import Subscriptions, {
  SubscriptionsHeader,
} from './components/cards/subscriptions'
import TopExpenseAccounts from './components/cards/top-expense-accounts'
import TopPaidVendors from './components/cards/top-paid-vendors'

const lastMonth = startOfMonth(sub(new Date(), { months: 1 }))
const twoMonthsAgo = startOfMonth(sub(new Date(), { months: 2 }))

const rangeDefaults = (months: number) => ({
  range: months,
  startDate: startDateFromRange(months),
  endDate: endDateFromRange(),
})

export interface IInitialDashboardSettings extends TCardSettings {
  order: number
  id: string
  title: string
  getDefaultAccountIds?: (
    accounts: IAccount[] | undefined,
    topExpenseIds?: string[]
  ) => string[] | undefined
  showDateRangePicker: boolean
  showAccountPicker: boolean
  accountTypes?: AccountType[]
  maxAccounts?: number
  headerComponent?: (props: TCardSettings) => JSX.Element
  component: (props: TCardSettings) => JSX.Element
  refKey?: string
  className?: string
  showBillsSettings?: boolean
  settingsTitle?: string
  minHeight?: number
  minBalance?: number
  minDaysOverdue?: TDaysOverdue
}

// Explicit type assertion to help Typescript understand that we're filtering.
// In a real sense, we do expect accounts to have ids, but until we can straighten out
// our types to reflect that, we should continue to humor Typescript and doublecheck.
const hasId = (id: IAccount['id']): id is string => {
  return !!id
}

const getDefaultAccountIdsForCashBalances = (
  accounts?: IAccount[]
): string[] | undefined =>
  accounts
    ?.filter(
      ({ name }) => name && !name.toLocaleLowerCase().includes('clearing')
    )
    .map(({ id }) => id)
    .filter(hasId)

// Default values for each card's settings.
export const SETTINGS: IInitialDashboardSettings[] = [
  {
    title: 'Bills (A/P)',
    order: 13,
    id: 'bills',
    ...rangeDefaults(1),
    showDateRangePicker: false,
    showAccountPicker: false,
    component: Bills,
    showBillsSettings: true,
    settingsTitle: 'Bills (A/P) Settings',
    minBalance: 0,
    minDaysOverdue: 90,
    minHeight: 250,
  },
  {
    title: 'Burn Rate',
    order: 10,
    id: 'burn',
    ...rangeDefaults(6),
    showDateRangePicker: false,
    showAccountPicker: false,
    getDefaultAccountIds: getDefaultAccountIdsForCashBalances,
    component: BurnRateCard,
    headerComponent: BurnRateCardHeader,
    refKey: 'burnRate',
    minHeight: 350,
  },
  {
    title: 'Burn Rate',
    order: 3,
    id: 'mini-burn',
    ...rangeDefaults(1),
    showDateRangePicker: false,
    showAccountPicker: false,
    getDefaultAccountIds: getDefaultAccountIdsForCashBalances,
    component: MiniBurnRateCard,
    headerComponent: MiniBurnRateCardHeader,
  },
  {
    title: 'Cash Balances',
    order: 12,
    id: 'cash-balances',
    getDefaultAccountIds: getDefaultAccountIdsForCashBalances,
    showDateRangePicker: true,
    showAccountPicker: true,
    accountTypes: [AccountType.BANK],
    component: CashBalancesCard,
    refKey: 'balances',
    minHeight: 350,
    ...rangeDefaults(6),
  },
  {
    title: 'Cash Balances',
    order: 1,
    id: 'mini-cash-balances',
    getDefaultAccountIds: getDefaultAccountIdsForCashBalances,
    showDateRangePicker: false,
    showAccountPicker: true,
    accountTypes: [AccountType.BANK],
    headerComponent: MiniCashBalancesHeader,
    component: MiniCashBalancesCard,
    ...rangeDefaults(1),
  },
  {
    title: 'Cash Balances', // Live Cash Balances
    order: 0,
    id: 'mini-live-cash-balance',
    getDefaultAccountIds: getDefaultAccountIdsForCashBalances,
    showDateRangePicker: false,
    showAccountPicker: false,
    headerComponent: MiniLiveCashBalanceHeader,
    component: MiniLiveCashBalanceCard,
    ...rangeDefaults(1),
  },
  {
    title: 'Expense Distribution',
    order: 5,
    id: 'expense-distribution',
    showDateRangePicker: true,
    showAccountPicker: false,
    ...rangeDefaults(6),
    component: ExpenseDistributionCard,
    refKey: 'expenseDistribution',
    minHeight: 250,
  },
  {
    title: 'Gross Margin',
    order: 9,
    id: 'gross-margin',
    showDateRangePicker: true,
    ...rangeDefaults(6),
    showAccountPicker: false,
    component: GrossMarginCard,
    refKey: 'grossMargin',
    minHeight: 250,
  },
  {
    title: 'Invoices (A/R)',
    order: 14,
    id: 'invoices',
    ...rangeDefaults(1),
    showDateRangePicker: false,
    showAccountPicker: false,
    component: Invoices,
    showBillsSettings: true,
    settingsTitle: 'Invoices (A/R) Settings',
    minBalance: 0,
    minDaysOverdue: 90,
    minHeight: 250,
  },
  {
    title: 'Revenue',
    order: 2,
    id: 'mini-revenue',
    showDateRangePicker: false,
    showAccountPicker: true,
    getDefaultAccountIds: (accounts?: IAccount[]) =>
      accounts?.map(({ id }) => id).filter(hasId),
    accountTypes: [AccountType.INCOME],
    ...rangeDefaults(6),
    component: MiniRevenueCard,
    headerComponent: MiniRevenueCardHeader,
  },
  {
    title: 'Top Expense Accounts',
    order: 7,
    id: 'top-expenses',
    showDateRangePicker: true,
    showAccountPicker: false,
    ...rangeDefaults(6),
    component: TopExpenseAccounts,
    className: 'col-span-2',
    refKey: 'topExpenses',
    minHeight: 350,
  },
  {
    title: 'Top Paid Vendors',
    order: 6,
    id: 'top-paid-vendors',
    showDateRangePicker: true,
    showAccountPicker: false,
    ...rangeDefaults(6),
    component: TopPaidVendors,
    minHeight: 350,
    refKey: 'topPaidVendors',
  },
  {
    title: 'Revenue',
    order: 8,
    id: 'revenue',
    showDateRangePicker: true,
    showAccountPicker: true,
    getDefaultAccountIds: (accounts?: IAccount[]) =>
      accounts?.map(({ id }) => id).filter(hasId),
    accountTypes: [AccountType.INCOME],
    ...rangeDefaults(6),
    component: RevenueCard,
    className: 'col-span-2',
    refKey: 'revenue',
    minHeight: 250,
  },
  {
    title: 'Spending',
    order: 4,
    id: 'spending',
    showDateRangePicker: true,
    getDefaultAccountIds: (
      accounts: IAccount[] | undefined,
      topExpenseIds?: string[]
    ) => topExpenseIds?.slice(0, 10),
    showAccountPicker: true,
    maxAccounts: 10,
    accountTypes: [AccountType.EXPENSE],
    component: Spending,
    className: 'col-span-2',
    minHeight: 250,
    ...rangeDefaults(6),
  },
  {
    title: 'Subscriptions',
    order: 11,
    id: 'subscriptions',
    endDate: lastMonth,
    startDate: twoMonthsAgo,
    showDateRangePicker: false,
    showAccountPicker: false,
    component: Subscriptions,
    headerComponent: SubscriptionsHeader,
    className: 'col-span-2',
    minHeight: 250,
  },
]

// The date token to change dates into or parse them out of the QBO date format.
export const QBO_DATE_FORMAT = 'yyyy-MM-dd'
export const GOOGLE_DATE_FORMAT = 'yyyyMMdd'
export const GRAPH_DATE_FORMAT = "MMM ''yy"
export const SHORT_DATE_FORMAT = 'MMM yyyy'
