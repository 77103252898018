# import UserFragment from "../fragments/user.graphql"
# import CompanyFragment from "../fragments/company.graphql"
# import MemberFragment from "../fragments/member.graphql"
# import FolderShallow from "../fragments/folder-shallow.graphql"

query CurrentUserQuery($user_id: ID!, $company_id: ID) {
  user(id: $user_id) {
    ...UserFragment
  }
  member(user_id: $user_id, company_id: $company_id) {
    ...MemberFragment
  }
  company(id: $company_id) {
    ...CompanyFragment
    rootFolder {
      ...FolderShallow
    }
  }
}
