type PaginatedData<T> = { items: T[]; count: number }
interface PaginatedArgs {
  args: Record<string, any> | null
}

export const merge = <T>(
  existing: PaginatedData<T> | undefined,
  incoming: PaginatedData<T>,
  { args }: PaginatedArgs
) => {
  if (!existing) {
    return incoming
  }
  const offset = args?.config?.offset || 0
  // Slicing is necessary because the existing data is
  // immutable, and frozen in development.
  const merged = existing?.items ? existing.items.slice(0) : []
  for (let i = 0; i < incoming.items.length; ++i) {
    merged[offset + i] = incoming.items[i]
  }
  return { ...existing, items: merged }
}
