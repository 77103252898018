import React from 'react'
import SortableColumn, {
  TSortableColumn,
} from '~/src/components/sortable/components/sortable-column'
import { SortOrder } from '~/src/types/tables'
import './sortable.css'

// TODO: "any" alert. This is because our client-side sorting function
// ain't typesafe
export type TSortableDatum = Record<string, any>

interface ISortableGrid<T extends TSortableDatum, K = keyof T> {
  className?: string
  columns: TSortableColumn<T, K>[]
  sortField: K
  sortOrder: SortOrder
  handleSortingChange: (accessor: K) => void
}

const SortableGrid = <T extends TSortableDatum, K = keyof T>({
  columns,
  children,
  sortField,
  sortOrder,
  className,
  handleSortingChange,
}: ISortableGrid<T, K> &
  React.HTMLAttributes<HTMLTableElement>): JSX.Element => {
  return (
    <div className={`sortable-grid ${className}`}>
      <div className="sortable-grid__header">
        {columns.map((column, index) => {
          return (
            <SortableColumn<T, K>
              key={`column-${index}`}
              sortable={column.sortable}
              onSort={column.sortable ? handleSortingChange : undefined}
              accessor={column.accessor}
              label={column.label}
              isActive={sortField === column.accessor}
              order={sortOrder}
            />
          )
        })}
      </div>
      <div className="sortable-grid__rows">{children}</div>
    </div>
  )
}

export default SortableGrid
