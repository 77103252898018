import { useCallback, useMemo } from 'react'
import { gql, useQuery, useReactiveVar } from '@apollo/client'
import { IUser } from '~/src/types/user'
import CURRENT_USER_QUERY from '~/src/graphql/queries/current-user.graphql'
import { loggedInUserId, selectedCompanyId } from '~/src/cache'
import { IMember } from '~/src/types/member'
import { getMemberProperties } from '~/src/utilities/permissions'
import {
  getFromLocalStorage,
  setInLocalStorage,
} from '~/src/utilities/local-storage'
import * as Sentry from '@sentry/react'
import { useUserCompaniesQuery } from '../graphql/queries/generated/user-companies'

interface IClientLoginArgs {
  userId: IUser['id']
}

const useCurrentUser = () => {
  const currentUserId = useReactiveVar(loggedInUserId)
  const currentCompanyId = useReactiveVar(selectedCompanyId)

  const { client, data, loading } = useQuery(gql(CURRENT_USER_QUERY), {
    variables: {
      user_id: currentUserId,
      company_id: currentCompanyId,
    },
    skip: !currentUserId,
  })

  const currentUser: IUser = data?.user
  const currentMember: IMember = data?.member
  const currentCompany = data?.company

  const { data: companyData, loading: companyDataLoading } =
    useUserCompaniesQuery({
      variables: {
        user_id: currentUserId,
        // TODO: Remove this when the server-side config clause is actually optional,
        // and not an invisible default.
        // This is currently hardcoded to override a server default of 100 if none is specified.
        config: { limit: 1000, offset: 0 },
      },
      skip: !currentUserId,
    })

  const companies = useMemo(() => companyData?.companies || [], [companyData])

  const login = useCallback(({ userId }: IClientLoginArgs) => {
    localStorage.setItem('loggedInUserId', userId)
    const savedCompanyId = getFromLocalStorage({
      key: userId,
      path: 'selectedCompanyId',
    })
    selectedCompanyId(savedCompanyId ?? undefined)
    loggedInUserId(userId)

    analytics.identify(userId)
    analytics.track('login', {
      userId,
      companyId: savedCompanyId,
    })
  }, [])

  const logOutCurrentUser = useCallback(() => {
    localStorage.removeItem('loggedInUserId')
    loggedInUserId(undefined)
    Sentry.setUser(null)

    analytics.track('logout')
    analytics.reset()
    client.resetStore()
  }, [client])

  const setCompany = useCallback(
    (companyId?: string) => {
      if (!currentUserId) return

      setInLocalStorage({
        key: currentUserId,
        path: 'selectedCompanyId',
        value: companyId,
      })

      selectedCompanyId(companyId)
    },
    [currentUserId]
  )

  return {
    loggedIn: !!currentUserId,
    companies,
    currentUser,
    currentUserId,
    currentCompanyId,
    currentCompany,
    currentMember,
    loading,
    companiesDataLoading: companyDataLoading,
    login,
    logOutCurrentUser,
    setCompany,
    ...getMemberProperties(currentUser, currentMember),
  }
}

export default useCurrentUser
