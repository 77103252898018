import { TSortableDatum } from '../components/sortable'

// Can search values that are strings, or arrays of strings.
export const searchFilter = <
  T extends TSortableDatum,
  K extends keyof T = keyof T,
>(
  searchTerm: string,
  filterFields: K[],
  item: T
) => {
  const caseInsensitiveSearchTerm = searchTerm.toLocaleLowerCase()
  return filterFields.some(field => {
    // Match on string value.
    if ('string' === typeof item[field]) {
      return item[field].toLocaleLowerCase().includes(caseInsensitiveSearchTerm)
    }

    // Match on an array of string values.
    if (
      item &&
      item[field] &&
      0 in item[field] &&
      typeof item[field][0] === 'string'
    ) {
      return item[field].some((item: string) =>
        item.toLocaleLowerCase().includes(caseInsensitiveSearchTerm)
      )
    }
  })
}
