import React from 'react'
import StackedBarChart from '~/src/components/graphs/stacked-bar'
import useCashBalances from '~/src/hooks/use-cash-balances'
import { formatCurrency } from '~/src/utilities/currency'
import Loader from '~/src/components/loader'
import { GRAPH_DATE_FORMAT } from '../../config'
import { format } from 'date-fns'
import { TCardSettings } from '~/src/types/card-settings'

const CashBalances = ({ startDate, endDate, accountIds }: TCardSettings) => {
  const { sumsByMonth, sortedBalances, loading } = useCashBalances({
    startDate,
    endDate,
    accountIds,
  })

  const xLabels = sumsByMonth.map(({ date }) => format(date, GRAPH_DATE_FORMAT))

  const series = sortedBalances.map(({ name, data }) => ({
    name: name || '',
    data: data.map(({ value }) => value),
  }))

  return loading ? (
    <Loader size="lg" centered />
  ) : (
    <StackedBarChart
      id="cash-balances"
      labels={xLabels}
      series={series}
      yFormatter={(number: number) =>
        formatCurrency(number, {
          notation: 'compact',
        })
      }
    />
  )
}

export default CashBalances
