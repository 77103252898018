import React, { useRef } from 'react'
import useIntersection from '~/src/hooks/use-intersection'
import Classnames from 'classnames'
import './scroll-container.css'

const ScrollContainer = ({
  className,
  children,
}: React.HTMLAttributes<HTMLDivElement>): JSX.Element => {
  const itemList = useRef<HTMLDivElement>(null)
  const lastItem = useRef<HTMLDivElement>(null)
  const onScreen = useIntersection(itemList, lastItem)

  const shadowClasses = Classnames('scroll-shadow', {
    'scroll-shadow--visible': !onScreen,
  })

  return (
    <div className="scroll-container">
      <div ref={itemList} className={className}>
        {children}
        <div className="relative -top-1" ref={lastItem} />
      </div>
      <div className={shadowClasses}></div>
    </div>
  )
}

export default ScrollContainer
