import { gql, useMutation } from '@apollo/client'
import { CredentialResponse } from 'google-one-tap'
import { useCallback } from 'react'
import ACCEPT_INVITE_MUTATION from '~/src/graphql/mutations/accept-invite.graphql'
import LOGIN_WITH_GOOGLE_MUTATION from '~/src/graphql/mutations/login-with-google.graphql'
import LOGIN_WITH_PASSWORD_MUTATION from '~/src/graphql/mutations/login-with-password.graphql'
import { AuthResponse } from '~/src/types/auth'
import useCurrentUser from './use-current-user'

interface IUseAuthArgs {
  invitationId?: string
}

export interface IOnLoginSubmitArgs {
  email: string
  password: string
  confirmPassword?: string
}

const useAuth = (args?: IUseAuthArgs) => {
  const { login, currentUserId } = useCurrentUser()
  const { invitationId } = args || {}

  const [validateGoogleUser, googleResponse] = useMutation(
    gql(LOGIN_WITH_GOOGLE_MUTATION)
  )

  const [validatePasswordUser, passwordResponse] = useMutation(
    gql(LOGIN_WITH_PASSWORD_MUTATION)
  )

  const [acceptInvitation, invitationResponse] = useMutation(
    gql(ACCEPT_INVITE_MUTATION)
  )

  const acceptInvitationAsExistingUser = () =>
    acceptInvitation({
      variables: { invitation_id: invitationId, user_id: currentUserId },
    })

  // Register as a new user. You can only do this by accepting an invitation.
  const acceptInvitationWithGoogleAuth = (args: CredentialResponse) => {
    if (invitationId) {
      return acceptInvitation({
        variables: {
          invitation_id: invitationId,
          credential: args.credential,
        },
        onCompleted: (data: { acceptInvite: AuthResponse }) => {
          const userId = data.acceptInvite.user?.id
          if (userId) {
            login({
              userId,
            })
          }
        },
      })
    }
  }

  // Register as a new user. Same as Google auth, but with username and password.
  const acceptInvitationWithPassword = (args: IOnLoginSubmitArgs) => {
    const { email, password, confirmPassword } = args
    return acceptInvitation({
      variables: {
        invitation_id: invitationId,
        username: email,
        password,
        password_confirmation: confirmPassword,
      },
      onCompleted: (data: { acceptInvite: AuthResponse }) => {
        const userId = data.acceptInvite.user?.id
        if (userId) {
          login({
            userId,
          })
        }
      },
    })
  }

  // Log in with your username and password. This is two parts:
  // create or validate the user serverside, then start the client session.
  const loginWithPassword = (args: IOnLoginSubmitArgs) => {
    const { email, password } = args

    return validatePasswordUser({
      variables: {
        username: email,
        password,
      },
      onCompleted: (data: { loginWithPassword: AuthResponse }) => {
        const userId = data.loginWithPassword.user?.id
        if (userId) {
          login({
            userId,
          })
        }
      },
    })
  }

  // Google auth is three parts! Call to Google, call to our server
  // with Google credentials instead of a username/password,
  // and then start the client session.
  const loginWithGoogleAuth = useCallback(
    (args: CredentialResponse) => {
      // Call the server with the Google token, and get
      // a user back.
      return validateGoogleUser({
        variables: {
          credential: args.credential,
        },
        onCompleted: (data: { loginWithGoogle: AuthResponse }) => {
          const userId = data.loginWithGoogle.user?.id
          if (userId) {
            login({
              userId,
            })
          }
        },
      })
    },
    [login, validateGoogleUser]
  )

  return {
    acceptInvitationAsExistingUser,
    acceptInvitationWithPassword,
    acceptInvitationWithGoogleAuth,
    invitationResponse,
    loginWithGoogleAuth,
    loginWithPassword,
    passwordResponse,
    googleResponse,
  }
}

export default useAuth
