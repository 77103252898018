import React from 'react'
import {
  AssetFragment,
  AssetFragmentDoc,
} from '~/src/graphql/fragments/generated/asset'
import { useDeleteAssetMutation } from '~/src/graphql/mutations/generated/delete-asset'
import { FlowIcons } from '~/src/assets/flow-icons'
import DeleteDocument from './delete-document'
import { useFeatureFlag } from '~/src/hooks/use-feature-flag'
import DeleteForm from '~/src/components/delete-form'
import { FetchResult } from '@apollo/client'

const DeleteAsset = () => {
  const [deleteMutation, { loading }] = useDeleteAssetMutation()
  const showFolders = useFeatureFlag('showFolders')

  const isSuccess = (response: FetchResult) => response.data?.deleteAsset?.id
  const onError = 'Could not delete file.'
  const onSuccess = 'Your file has been deleted.'

  const contents = ({ display_name }: AssetFragment) => (
    <DeleteDocument icon={FlowIcons.FileText} title={display_name} />
  )

  const deleteItem = ({ id, company_id }: AssetFragment) =>
    deleteMutation({
      variables: { id, company_id },
      refetchQueries: showFolders
        ? ['DocumentsQuery']
        : ['PaginatedAssetsQuery'],
    })

  return (
    <DeleteForm<AssetFragment>
      type="Asset"
      fragment={AssetFragmentDoc}
      isSuccess={isSuccess}
      onSuccess={onSuccess}
      onError={onError}
      deleteItem={deleteItem}
      contents={contents}
      loading={loading}
    />
  )
}

export default DeleteAsset
