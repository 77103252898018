import useRevenue from './use-revenue'
import { groupBy, sumBy, sortBy } from 'lodash'
import { eachMonthOfInterval, parse } from 'date-fns'
import { isDateWithinMonth } from '../utilities/dates'
import { QBO_DATE_FORMAT } from '../pages/dashboard/config'
import { Balance } from '../types/balance'

const getBalancesForMonth = (balances: Balance[], month: Date) =>
  balances?.filter(({ start }) =>
    isDateWithinMonth(parse(start, QBO_DATE_FORMAT, new Date()), month)
  ) ?? []

// Gross margin = income, minus the cost of goods sold.
// The revenue query includes both types of balances.
const useGrossMargin = ({
  startDate,
  endDate,
}: {
  startDate: Date
  endDate: Date
}) => {
  const { balances, loading } = useRevenue({
    startDate,
    endDate,
  })

  const { income, cost_of_goods_sold: cogs } = groupBy(balances, 'type')

  const monthsInRange = eachMonthOfInterval({ start: startDate, end: endDate })

  // Sort (into income and COGS), sum, and subtract.

  // Gross margin is a percentage: ((sum - cogs) / sum) * 100
  const grossMarginByMonth = monthsInRange.map(month => {
    const [incomeInMonth, cogsInMonth] = [income, cogs].map(group =>
      getBalancesForMonth(group, month)
    )

    const sum = sumBy(incomeInMonth, 'value')
    const cogsSum = sumBy(cogsInMonth, 'value')

    const grossMargin = ((sum - cogsSum) / sum) * 100
    return {
      date: month,
      value: isFinite(grossMargin) ? parseInt(grossMargin.toFixed(1)) : 0,
    }
  })

  return {
    grossMarginByMonth: sortBy(grossMarginByMonth, 'date'),
    loading,
  }
}

export default useGrossMargin
