import React, { useRef } from 'react'
import { Popover } from '@headlessui/react'
import useIntersection from '~/src/hooks/use-intersection'
import CompanyIcon from '~/src/components/company-icon'
import { ICompany } from '~/src/types/company'
import Classnames from 'classnames'

interface ICompanyList {
  companies: ICompany[]
  onClick: (value: string) => void
}

const CompanyList = ({ onClick, companies }: ICompanyList): JSX.Element => {
  const handleClick = (value: string) => {
    onClick(value)
  }

  const itemList = useRef<HTMLDivElement>(null)
  const lastItem = useRef<HTMLDivElement>(null)
  const onScreen = useIntersection(itemList, lastItem)

  const companyListClasses = Classnames('workspace__companies', {
    'workspace__companies--scrollable': companies.length > 5,
  })

  const shadowClasses = Classnames('workspace__shadow', {
    'workspace__shadow--visible': !onScreen,
  })

  return (
    <>
      <div ref={itemList} className={companyListClasses}>
        {companies.map(company => (
          <Popover.Button
            onClick={() => handleClick(company.id)}
            key={company.id}
            className="workspace__link"
          >
            <CompanyIcon size="sm" company={company} />
            <span>{company.name}</span>
          </Popover.Button>
        ))}
        <div ref={lastItem} />
      </div>
      {companies.length > 5 ? <div className={shadowClasses}></div> : null}
    </>
  )
}

export default CompanyList
