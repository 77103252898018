import React from 'react'
import Logo from '~/src/components/logo'
import UserMenu from '~/src/components/user-menu'
import SVGHandler from '~/src/components/svg-handler'
import NavBarItems from './navbar-items'
import Classnames from 'classnames'
import { INavBar } from '..'
import { FlowIcons } from '~/src/assets/flow-icons'
import useRoute from '~/src/hooks/use-route'
import { useNavBarContext } from '../navbar.context'
import NavBarControls from './navbar-controls'

const NavBarMobile = ({ actionItemCount }: INavBar): JSX.Element => {
  const { isMenuOpen, setMenuOpen } = useNavBarContext()
  const { currentRoute } = useRoute()

  const navClasses = Classnames('app-navigation app-navigation--mobile')

  const drawerClasses = Classnames('app-navigation__drawer', {
    'app-navigation__drawer--open': isMenuOpen,
  })

  const openMenu = () => setMenuOpen?.(true)
  const closeMenu = () => setMenuOpen?.(false)

  return (
    <nav className={navClasses}>
      <header className="app-navigation__header">
        <button
          className="flex items-center"
          type="button"
          onClick={() => requestAnimationFrame(openMenu)}
        >
          <SVGHandler
            color="black"
            size="smaller"
            icon={FlowIcons.BurgerMenu}
          />
        </button>
        <span className="font-bold">{currentRoute?.name}</span>
        <NavBarControls />
      </header>
      <div className={drawerClasses}>
        <header className="app-navigation__drawer-header">
          <button
            className="app-navigation__drawer-dismiss"
            type="button"
            onClick={() => requestAnimationFrame(closeMenu)}
          >
            <SVGHandler color="black" size="large" icon={FlowIcons.X} />
          </button>
          <Logo className="m-auto" collapsed={false} />
        </header>
        <div className="navigation-items__wrap">
          <NavBarItems
            collapsed={false}
            badgeProps={{ actionItemCount }}
            onClick={() => setMenuOpen?.(false)}
          />
          <UserMenu collapsed={false} />
        </div>
      </div>
    </nav>
  )
}

export default NavBarMobile
