import React from 'react'
import ReactDomServer from 'react-dom/server'
import SVGHandler from '~/src/components/svg-handler'
import { FlowIcons } from '~/src/assets/flow-icons'
import ReactTooltip from 'react-tooltip'
import { v4 } from 'uuid'

interface IInfo {
  message?: string
  html?: React.ReactElement
}

const Info = ({
  message,
  html,
  className,
}: IInfo & React.HTMLAttributes<HTMLElement>) => {
  const tipId = v4()

  // If you want to display HTML, the entire contents must be passed as HTML. Otherwise,
  // pass a string for a normal message.
  const contents = html ? ReactDomServer.renderToString(html) : message

  return (
    <div
      className={`flex flex-col items-center justify-center ${className}`}
      data-html={!!html}
      data-tip={contents}
      data-for={tipId}
    >
      <SVGHandler
        size="large"
        color="gray"
        icon={FlowIcons.QuestionOutline}
        title={''}
      />
      <ReactTooltip
        className="panopticon-tooltip"
        id={tipId}
        place="left"
        effect="solid"
      />
    </div>
  )
}

export default Info
