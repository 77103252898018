import { FormEvent } from 'react'

// Should this modal only be shown when it has an associated item?
export const MODAL_CONFIG = {
  editFolder: { idRequired: true },
  editAsset: { idRequired: true },
  moveAsset: { idRequired: true },
  moveFolder: { idRequired: true },
  createFolder: { idRequired: false },
  deleteFolder: { idRequired: true },
  deleteAsset: { idRequired: true },
}

/**
 * The names of modals we know how to handle in this hook.
 * Eventually this should be all of them
 */
export type TModalNames = keyof typeof MODAL_CONFIG

/**
 * Fancy type just to say: require an id in the setter args if it's required,
 * forbid it if it's not
 */
export type TModalSetterArgs<
  T extends TModalNames = TModalNames,
  K = (typeof MODAL_CONFIG)[T]['idRequired'],
> = K extends true ? { id: string } : never

/**
 * The kinds of events our onClose function expects to receive.
 * I'm honestly not sure this is correct. We don't do anything with these,
 * it's just to make Typescript happy.
 */
export type TModalCloseEvents = FormEvent<HTMLFormElement> | MouseEvent
