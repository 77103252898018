import React from 'react'
import { useFoldersQuery } from '~/src/graphql/queries/generated/folders'
import useCurrentUser from '~/src/hooks/use-current-user'
import { useDocumentsContext } from '../documents.context'
import FolderCard from './folder-card'
import { FolderShallowFragment } from '~/src/graphql/fragments/generated/folder-shallow'
import Classnames from 'classnames'

interface IFolderCardGrid {
  rootFolderId: string
  className?: string
}

export const FolderCardGrid = ({
  rootFolderId,
  className,
}: IFolderCardGrid) => {
  const { currentCompanyId } = useCurrentUser()
  const { searchTerm } = useDocumentsContext()

  const { data: foldersData } = useFoldersQuery({
    variables: {
      fixed: true,
      search: searchTerm,
      company_id: currentCompanyId || '',
      parent_id: rootFolderId,
    },
    skip: !rootFolderId,
  })

  const fixedFolders = foldersData?.folders?.items

  const classnames = Classnames('documents__folder-container', className)

  return fixedFolders?.length ? (
    <ul className={classnames}>
      {fixedFolders.map((folder: FolderShallowFragment) => (
        <FolderCard key={folder.id} folder={folder} />
      ))}
    </ul>
  ) : null
}
