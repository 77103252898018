import { IAsset } from '~/src/types/asset'
import { Upload } from '@aws-sdk/lib-storage'
import { S3Client, GetObjectCommand } from '@aws-sdk/client-s3'

export interface IS3OptionalParams {
  ACL?: string
}

const BUCKET_NAME = process.env.AWS_ASSETS_BUCKET || ''
const REGION = 'us-west-2'

const s3ClientConfig = {
  credentials: {
    accessKeyId: process.env.AWS_ACCESS_KEY_ID || '',
    secretAccessKey: process.env.AWS_SECRET_ACCESS_KEY || '',
  },
  region: REGION,
}

const s3Client = new S3Client(s3ClientConfig)

const s3Params = {
  Bucket: BUCKET_NAME,
}

const key = (asset: IAsset) => {
  return `${asset.company_id}/${asset.id}/${asset.name}`
}

const upload = async (
  key: string,
  file: File,
  options: IS3OptionalParams = {}
) => {
  const fileUpload = new Upload({
    client: new S3Client({
      ...s3ClientConfig,
    }),
    params: {
      ...options,
      ...s3Params,
      Key: key,
      Body: file,
    },
  })

  await fileUpload.done()
  return `https://${BUCKET_NAME}.s3.${REGION}.amazonaws.com/${key}`
}

const get = async (key: string) => {
  const command = new GetObjectCommand({
    ...s3Params,
    Key: key,
  })
  const response = await s3Client.send(command)

  return response.Body
}

const getPreviewDocument = async (
  asset: IAsset | undefined
): Promise<Uint8Array | undefined> => {
  const key = `${asset?.company_id}/${asset?.id}/preview.pdf`
  let file

  try {
    const stream = await get(key)
    file = await stream?.transformToByteArray()
  } catch (err) {
    file = undefined
  }

  return file
}

const download = async (asset: IAsset): Promise<void> => {
  const assetKey = key(asset)
  const stream = await get(assetKey)
  const encoded = await stream?.transformToString('base64')
  const a = document.createElement('a')
  a.href = `data:application/octet-stream;base64,${encoded}`
  a.download = asset.name || 'download'
  a.click()
}

export { upload, download, getPreviewDocument }
