export type TRouteDefinition = {
  path: string
  name: string
  subroutes?: {
    [key: string]: TRouteDefinition
  }
}

export type IRouteDefinitions = typeof ROUTES

const ROUTES = {
  actionItems: {
    name: 'Action Items',
    path: '/action-items',
  },
  dashboard: {
    name: 'Dashboard',
    path: '/',
  },
  documents: {
    name: 'Documents',
    path: '/documents',
    subroutes: {
      folder: { name: 'Documents', path: '/documents/:folderId' },
      file: { name: 'File', path: '/documents/file/:assetId' },
    },
  },
  company: {
    name: 'Company',
    path: '/company',
    subroutes: {
      general: { name: 'General', path: '/company/general' },
      users: { name: 'Users', path: '/company/users' },
      connections: { name: 'Connections', path: '/company/connections' },
    },
  },
  team: {
    name: 'Team',
    path: '/team',
  },
  profile: {
    name: 'My Profile',
    path: '/profile',
    subroutes: {
      general: { name: 'General', path: '/profile/general' },
      preferences: { name: 'Preferences', path: '/profile/preferences' },
      notifications: { name: 'Notifications', path: '/profile/notifications' },
    },
  },
  login: {
    name: 'Log In',
    path: '/login',
    subroutes: {
      forgotPassword: {
        name: 'Forgot Password',
        path: '/login/forgot-password/',
        subroutes: {
          reset: {
            name: 'Reset Password',
            path: '/login/forgot-password/reset/:user_jwt',
          },
        },
      },
    },
  },
  loginWithInvite: {
    name: 'Accept Invitation',
    path: '/login/:invitationId',
  },
  register: {
    name: 'Register',
    path: '/register/:invitationId',
  },
  companyPicker: {
    name: 'Select a Company',
    path: '/select-company',
    subroutes: {
      direct: {
        name: 'Direct',
        path: '/select-company/:companyId/*',
      },
    },
  },
  resetPassword: {
    name: 'Reset Password',
    path: '/reset-password',
  },
  invitation: {
    name: 'Joining Company',
    path: '/invitation/:invitationId',
  },
  invalidInvitation: {
    name: 'Invalid Invitation',
    path: '/invalid-invitation',
  },
}

export default ROUTES
