import React from 'react'
import useGrossMargin from '~/src/hooks/use-gross-margin'
import LineChart from '~/src/components/graphs/line'
import Loader from '~/src/components/loader'
import { GRAPH_DATE_FORMAT } from '../../config'
import { format } from 'date-fns'

const GrossMarginCard = ({
  startDate,
  endDate,
}: {
  startDate: Date
  endDate: Date
}) => {
  const { grossMarginByMonth, loading } = useGrossMargin({
    startDate,
    endDate,
  })

  const values = grossMarginByMonth.map(({ value }) => value)
  const labels = grossMarginByMonth.map(({ date }) =>
    format(date, GRAPH_DATE_FORMAT)
  )

  return loading ? (
    <Loader size="lg" centered />
  ) : (
    <LineChart
      id="gross-margin"
      labels={labels}
      series={[{ name: 'Gross Margin', data: values }]}
      yFormatter={(number: number) => `${number.toFixed(1)}%`}
    />
  )
}

export default GrossMarginCard
