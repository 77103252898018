import React from 'react'
import { Menu, Transition } from '@headlessui/react'
import { TIconColors } from '~/src/components/svg-handler'
import Button from '~/src/components/button'
import { FlowIcons } from '~/src/assets/flow-icons'
import './inline-menu.css'

interface IInlineMenu {
  children?: React.ReactNode[]
  iconColor: TIconColors
  borderless?: boolean
  testId?: string
}

const InlineMenu = ({
  children,
  iconColor = 'gray',
  borderless,
  testId,
}: IInlineMenu): JSX.Element => {
  return (
    <Menu as={'div'} className="menu">
      {({ open }) => (
        <>
          <Menu.Button as={'div'}>
            <Button
              theme="text"
              testId={testId || 'inline-menu-button'}
              className="menu__trigger"
              icon={FlowIcons.Dots}
              iconPosition="left"
              label="toggle menu"
              iconColor={open ? 'primary' : iconColor}
              iconOnly
              size="medium"
              borderless={borderless}
            />
          </Menu.Button>
          <Transition
            className="relative z-50"
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Menu.Items className="menu__items">
              {children?.map((child, i) => (
                <Menu.Item key={`menu-item-${i}`}>{child}</Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  )
}

export default InlineMenu
