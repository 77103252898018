import React, { useState } from 'react'
import SortableGrid from '~/src/components/sortable'
import SVGHandler from '~/src/components/svg-handler'
import { FlowIcons } from '~/src/assets/flow-icons'
import { IInvoice } from '~/src/types/invoices'
import { formatCurrency } from '~/src/utilities/currency'
import useSortableGrid, { defaultOnSort } from '~/src/hooks/use-sortable-grid'
import useInvoices from '~/src/hooks/use-invoices'
import Loader from '~/src/components/loader'
import ScrollContainer from '~/src/components/scroll-container'
import BillsPaid from '~/src/assets/images/bills-paid.svg'
import { TCardSettings } from '~/src/types/card-settings'
import { format } from 'date-fns'
import { QBO_DATE_FORMAT } from '../../config'
import { TSortableColumn } from '~/src/components/sortable/components/sortable-column'
import useResponsive from '~/src/hooks/use-responsive'

type SortableInvoice = IInvoice & { line_item_descriptions: string[] }
// Columns for sortable table
const columns: TSortableColumn<SortableInvoice>[] = [
  {
    label: 'Customer',
    accessor: 'customer_name',
    sortable: true,
    default: true,
  },
  {
    label: 'Balance',
    accessor: 'remaining_balance',
    sortable: true,
  },
]

const Invoices = ({
  minDaysOverdue,
  minBalance,
}: TCardSettings): JSX.Element => {
  const { isDesktop } = useResponsive()
  const { invoices: unsortedInvoices, loading } = useInvoices({
    minBalance: minBalance || 0,
    minDaysOverdue: minDaysOverdue || 90,
  })

  const invoicesWithLineItemDescriptions = unsortedInvoices.map(invoice => ({
    ...invoice,
    line_item_descriptions: invoice.line_items.map(
      lineItem => lineItem.description
    ),
  }))
  const [filter, setFilter] = useState<string>('')

  const {
    handleSortingChange,
    sortOrder,
    sortField,
    data: invoices,
  } = useSortableGrid<SortableInvoice>({
    columns,
    data: invoicesWithLineItemDescriptions,
    onSort: defaultOnSort,
  })

  const showTable = !!invoices?.length

  const totalInRange = (invoices: IInvoice[]) => {
    return invoices.reduce((acc, curr) => acc + curr.remaining_balance, 0)
  }

  const mailToUrl = (invoice: IInvoice) => {
    const url = encodeURI(
      `mailto:${invoice.customer_email || ''}` +
        `?subject=Reminder about invoice ${invoice.doc_number} ` +
        (invoice.due_date
          ? `due ${format(new Date(invoice.due_date), QBO_DATE_FORMAT)}`
          : '')
    )

    return url
  }

  const overdueMsg =
    minDaysOverdue && minDaysOverdue > 0
      ? `${minDaysOverdue}+ days overdue`
      : 'Overdue'

  return loading ? (
    <Loader size="lg" centered />
  ) : (
    <div className="h-full flex flex-col">
      <div className="my-4 relative">
        <SVGHandler
          className="absolute left-2 top-3"
          size="small"
          icon={FlowIcons.Search}
          color="gray"
        />
        <input
          className="!pl-8"
          placeholder="Search"
          type="text"
          onChange={e => setFilter(e.target.value)}
        />
      </div>
      {showTable && (
        <>
          <ScrollContainer className="invoices-table-wrap">
            <SortableGrid
              className="bills-invoices-table"
              columns={columns}
              handleSortingChange={handleSortingChange}
              sortOrder={sortOrder}
              sortField={sortField}
            >
              {invoices?.map(invoice => {
                return (
                  <div className="sortable-grid__row" key={invoice.id}>
                    <div>
                      <a
                        className="text-grayscale-900 hover:text-primary"
                        aria-label="view this invoice on QBO"
                        href={isDesktop ? invoice.deep_link : '#'}
                        target={isDesktop ? '_blank' : '_top'}
                      >
                        <div className="flex items-center h-full">
                          <div className="w-full">
                            <div className="overflow-x-hidden text-ellipsis whitespace-nowrap">
                              {invoice.customer_name}
                            </div>
                            {!!invoice.days_overdue &&
                              invoice.days_overdue > 0 && (
                                <div className="text-xs text-grayscale-600">
                                  {invoice.days_overdue}{' '}
                                  {invoice.days_overdue > 1 ? 'days' : 'day'}{' '}
                                  overdue
                                </div>
                              )}
                          </div>
                        </div>
                      </a>
                    </div>
                    <div>
                      <div className="h-full w-full flex items-center justify-between">
                        {formatCurrency(invoice.remaining_balance)}
                        <a
                          className="email-icon"
                          aria-label="send email reminder"
                          href={mailToUrl(invoice)}
                        >
                          <SVGHandler
                            color="gray"
                            size="smallest"
                            icon={FlowIcons.Email}
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                )
              })}
            </SortableGrid>
            {filter && !invoices.length && (
              <div className="my-4 text-center">No matching results.</div>
            )}
          </ScrollContainer>

          {!!invoices.length && (
            <div className="mt-auto pt-4">
              <div className="mb-2 text-xs flex items-center gap-2 text-grayscale-600">
                <SVGHandler
                  size="small"
                  icon={FlowIcons.FileInvoice}
                  color="gray"
                />
                <span>Unpaid Invoices</span>
              </div>
              <div>
                <div>
                  {formatCurrency(totalInRange(invoices))} from{' '}
                  {invoices.length}{' '}
                  {invoices.length > 1 ? 'invoices' : 'invoice'}.
                </div>
                <span className="italic text-xs text-grayscale-600">
                  ({overdueMsg}, {formatCurrency(minBalance as number)} min
                  remaining balance. )
                </span>
              </div>
            </div>
          )}
        </>
      )}
      {/* If there are no bills at all to show, show a filler image. */}
      {!showTable && (
        <div className="w-full h-full flex flex-col items-center justify-center">
          <img
            src={BillsPaid}
            alt="A green checkmark shown over a representation of a bill."
          />
          <div className="mt-2 text-sm">All of your invoices are paid.</div>
        </div>
      )}
    </div>
  )
}

export default Invoices
