import React, { useState } from 'react'
import { Switch } from '@headlessui/react'
import Classnames from 'classnames'
import { useController, FieldValues } from 'react-hook-form'
import { ToggleProps } from '~/src/types/forms'

const Toggle = <T extends FieldValues>(props: ToggleProps<T>) => {
  const {
    field: { value, onChange },
  } = useController(props)

  const [enabled, setEnabled] = useState<boolean>(value)

  const switchClasses = Classnames('toggle', {
    'toggle--active': enabled,
  })

  const indicatorClasses = Classnames('toggle-indicator', {
    'toggle-indicator--active': enabled,
  })

  return (
    <Switch
      onChange={() => {
        onChange()
        setEnabled(!enabled)
      }}
      checked={enabled}
      className={switchClasses}
    >
      <span className="sr-only">Enable notifications</span>
      <span className={indicatorClasses} />
    </Switch>
  )
}

export default Toggle
