import React from 'react'
import Button from '~/src/components/button'
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form'
import { FormFields } from '~/src/components/form-fields'

import { capitalizeFirstLetter } from '~/src/utilities/string'

interface IFieldConfig {
  required?: string | ((field: string) => boolean)
  label?: string
}

type IFields<T> = Record<keyof T, IFieldConfig>
interface IEditDocument<T extends FieldValues> {
  defaultValues: T
  title: string
  fields: IFields<T>
  onSubmit: SubmitHandler<T>
}

const EditForm = <T extends FieldValues>(
  props: Omit<React.HTMLAttributes<HTMLDivElement>, 'onSubmit'> &
    IEditDocument<T>
) => {
  const { className, defaultValues, onSubmit, title } = props

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'onSubmit',
    defaultValues,
  })

  return (
    <>
      <h3 className="modal__title">{title}</h3>

      <div className={className}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <section className="flex flex-col gap-4 mb-5">
            {Object.entries(props.fields).map(([key, value]) => {
              return (
                <FormFields.Text
                  key={key}
                  {...register(key, {
                    required: value.required,
                  })}
                  testId={`edit-form-${key}-field`}
                  label={value.label || capitalizeFirstLetter(key)}
                  error={errors[key]?.message}
                />
              )
            })}
          </section>
          <Button
            className="w-full"
            type="submit"
            label="Confirm"
            testId="edit-form-submit-button"
            disabled={isSubmitting}
          />
        </form>
      </div>
    </>
  )
}

export default EditForm
