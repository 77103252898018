import React from 'react'
import Workspace from '~/src/components/workspace'
import SVGHandler from '~/src/components/svg-handler'
import { snapshotModalOpen } from '~/src/cache'
import { FlowIcons } from '~/src/assets/flow-icons'
import useRoute from '~/src/hooks/use-route'

const NavBarControls = () => {
  const { currentRoute } = useRoute()

  return (
    <div className="navigation-controls--mobile">
      {currentRoute?.name.toLowerCase() === 'dashboard' && (
        <button
          aria-label="snapshot"
          className="page-header__button"
          type="button"
          onClick={() => snapshotModalOpen(true)}
        >
          <SVGHandler size="xlarge" icon={FlowIcons.Snapshot} color="gray" />
        </button>
      )}
      <button
        aria-label="notifications"
        className="page-header__button"
        type="button"
        disabled
      >
        <SVGHandler size="xlarge" icon={FlowIcons.Bell} color="gray" />
      </button>
      <Workspace compact />
    </div>
  )
}

export default NavBarControls
