export const endpointConfig = [
  { name: 'root', baseUrl: process.env.YGGDRASIL_URL, path: '/' },
  // {
  //   name: "connections",
  //   baseUrl: process.env.GALACTUS_API,
  //   path: "",
  //   headers: { "X-API-Key": process.env.GALACTUS_API_KEY },
  // },
]

interface IEndpoints {
  [key: string]: {
    uri: string
    headers?: Record<string, string>
  }
}

const ENDPOINTS: IEndpoints = endpointConfig.reduce(
  (acc, { name, baseUrl, path, headers }) => {
    return { ...acc, [name]: { uri: `${baseUrl}${path}`, headers } }
  },
  {}
)

export default ENDPOINTS
