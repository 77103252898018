import * as Types from '../../../types/generated/index';

import { gql } from '@apollo/client';
export type AccountFragment = { __typename?: 'Account', accountNumber?: string | null, accountSubTypeEnum?: string | null, accountType?: string | null, active?: boolean | null, category?: string | null, companyId?: string | null, id?: string | null, last4?: string | null, name?: string | null, number?: string | null, parentId?: string | null, s3Key?: string | null, type?: string | null };

export const AccountFragmentDoc = gql`
    fragment AccountFragment on Account {
  accountNumber
  accountSubTypeEnum
  accountType
  active
  category
  companyId
  id
  last4
  name
  number
  parentId
  s3Key
  type
}
    `;