import React from 'react'
import { IFormFieldRadioProps } from '~/src/types/forms'
import Classnames from 'classnames'

const Radio = React.forwardRef<HTMLInputElement, IFormFieldRadioProps>(
  (props, ref) => {
    const labelClasses = Classnames('form-field__label', {
      'cursor-pointer': !props.disabled,
      'cursor-not-allowed': props.disabled,
    })

    const { labelRight, ...rest } = props

    return (
      <div className="form-field form-field--radio">
        <label htmlFor={props.id} className={labelClasses}>
          {!labelRight && props.label}
          <div className="radio">
            <input
              className="form-field__input"
              type="radio"
              ref={ref}
              {...rest}
            />
            <div className="radio-indicator"></div>
          </div>
          {labelRight && props.label}
        </label>
      </div>
    )
  }
)

export default Radio
