import React, { HTMLAttributes } from 'react'
import Classnames from 'classnames'
import SVGHandler, { TIconColors } from '~/src/components/svg-handler'
import './button.css'

export type TButtonSizes = 'small' | 'medium' | 'large'

export type TButtonThemes =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'text'
  | 'success'
  | 'error'

type TButtonTypes = 'submit' | 'button'

type TIconPosition = 'left' | 'right'

interface IButtonProps {
  size?: TButtonSizes
  fullWidth?: boolean
  theme?: TButtonThemes
  icon?: string
  iconPosition?: TIconPosition
  iconColor?: TIconColors
  iconOnly?: boolean
  label?: string
  disabled?: boolean
  onClick?: (e: React.MouseEvent) => void
  className?: string
  type?: TButtonTypes
  noWrap?: boolean
  textOnly?: boolean
  borderless?: boolean
  testId?: string
  textLeft?: boolean
}

const Button = ({
  size = 'medium',
  theme = 'primary',
  icon,
  label,
  disabled,
  onClick,
  children,
  fullWidth,
  className,
  type = 'button',
  noWrap,
  iconPosition = 'left',
  iconColor = 'primary',
  iconOnly,
  borderless,
  testId,
  textLeft,
}: IButtonProps & HTMLAttributes<HTMLButtonElement>) => {
  const buttonClasses = Classnames('button', {
    'button--sm': size === 'small',
    'button--md': size === 'medium',
    'button--lg': size === 'large',
    'button--primary': theme === 'primary',
    'button--secondary': theme === 'secondary',
    'button--tertiary': theme === 'tertiary',
    'button--text': theme === 'text',
    'button--success': theme === 'success',
    'button--error': theme === 'error',
    'button--with-icon': icon && label,
    'button--icon-only': icon && !label,
    'button--disabled': disabled,
    'button--full-width': fullWidth,
    'button--no-wrap': noWrap,
    'button--borderless': borderless,
    ...(className ? { [className]: className } : {}),
  })

  const handleClick = (e: React.MouseEvent) => {
    if (!onClick) return
    onClick(e)
  }

  const iconLeft = !!icon && iconPosition === 'left'
  const iconRight = !!icon && !iconLeft

  return (
    <button
      type={type}
      onClick={handleClick}
      className={buttonClasses}
      disabled={disabled}
      data-test={testId ?? type}
      aria-label={label}
    >
      {iconLeft && <SVGHandler icon={icon} size={size} color={iconColor} />}
      {!iconOnly && label && (
        <div className={!textLeft ? 'text-center' : 'text-left'}>{label}</div>
      )}
      {children}
      {iconRight && <SVGHandler icon={icon} size={size} color={iconColor} />}
    </button>
  )
}

export default Button
