import * as Types from '../../../types/generated/index';

import { gql } from '@apollo/client';
export type InvitationFragment = { __typename: 'Invitation', id: string, email_address?: string | null, company_id: string, duration?: number | null, state?: Types.States | null, created?: string | null, first_name?: string | null, last_name?: string | null, role?: string | null, user_id?: string | null };

export const InvitationFragmentDoc = gql`
    fragment InvitationFragment on Invitation {
  __typename
  id
  email_address
  company_id
  duration
  state
  created
  first_name
  last_name
  role
  user_id
}
    `;