import { useCreateAssetMutation } from '~/src/graphql/mutations/generated/create-asset'
import { useUpdateAssetMutation } from '~/src/graphql/mutations/generated/update-asset'
import useCurrentUser from './use-current-user'
import { upload } from '~/src/utilities/s3'
import { useFeatureFlag } from './use-feature-flag'

export interface ICreateAssetHook {
  file: File
  asset: { title: string; description?: string; folder_id?: string }
}

const useCreateAsset = () => {
  const showFolders = useFeatureFlag('showFolders')
  const { currentCompanyId, currentUser } = useCurrentUser()
  const [updateAsset] = useUpdateAssetMutation()
  const [createAssetTrigger] = useCreateAssetMutation()

  const createAsset = async ({ file, asset }: ICreateAssetHook) => {
    if (!currentCompanyId) {
      throw new Error("Can't create asset without company id")
    }

    const name = file.name.replace(/\s+/g, '-')

    const { data } = await createAssetTrigger({
      variables: {
        input: {
          folder_id: asset.folder_id,
          description: asset.description,
          company_id: currentCompanyId,
          name,
          display_name: asset.title,
          user_id: currentUser?.id,
        },
      },
    })

    const id = data?.createAsset?.id

    if (id) {
      const key = `${currentCompanyId}/${id}/${name}`
      const url = await upload(key, file)

      await updateAsset({
        variables: { id, company_id: currentCompanyId, input: { url } },
        refetchQueries: showFolders
          ? ['DocumentsQuery']
          : ['PaginatedAssetQuery'],
      })
    }
  }

  const createAssets = (items: ICreateAssetHook[]) =>
    Promise.all(items.map(createAsset))

  return { createAssets }
}

export default useCreateAsset
