import React, { useState } from 'react'
import useCurrentUser from '~/src/hooks/use-current-user'
import CompanyIcon from '~/src/components/company-icon'
import CompanyList from './components/company-list'
import SVGHandler from '~/src/components/svg-handler'
import { Popover } from '@headlessui/react'
import { FlowIcons } from '~/src/assets/flow-icons'
import Classnames from 'classnames'
import './workspace.css'

interface IWorkspace {
  compact?: boolean
}

const Workspace = ({
  compact,
  className,
}: IWorkspace & React.HTMLAttributes<HTMLElement>): JSX.Element => {
  const { currentCompany, setCompany, companies } = useCurrentUser()

  const [filter, setFilter] = useState<string>('')

  const handleClick = (value: string) => {
    if (value) {
      setCompany(value)
      setFilter('')
    }
  }

  const workspaceClasses = Classnames('workspace', {
    'workspace--compact': compact,
    ...(className ? { [className]: className } : {}),
  })

  return (
    <div className={workspaceClasses}>
      <Popover>
        {({ open }) => (
          <>
            <Popover.Button
              className={`workspace__button ${
                open ? 'workspace__button--open' : ''
              }`}
            >
              {currentCompany && (
                <>
                  <CompanyIcon size="sm" company={currentCompany} />
                  {!compact ? <span>{currentCompany.name}</span> : null}
                </>
              )}
              <SVGHandler
                size="small"
                icon={open ? FlowIcons.ChevronUp : FlowIcons.ChevronDown}
                color={open ? 'black' : 'gray'}
              />
            </Popover.Button>

            <Popover.Panel className="workspace__popover">
              <div className="workspace__filter-wrap">
                <SVGHandler
                  className="workspace__filter-icon"
                  size="small"
                  icon={FlowIcons.Search}
                  color={'gray'}
                />
                <input
                  placeholder="Search"
                  type="text"
                  className="workspace__filter"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFilter(e.target.value)
                  }
                />
              </div>
              <div className="relative">
                <CompanyList
                  companies={companies?.filter(
                    company =>
                      company.name
                        ?.toLocaleLowerCase()
                        .includes(filter.toLocaleLowerCase())
                  )}
                  onClick={handleClick}
                />
              </div>
            </Popover.Panel>
          </>
        )}
      </Popover>
    </div>
  )
}

export default Workspace
