import React from 'react'
import EntryForm from '~/src/components/entry-form'
import useAuth from '~/src/hooks/use-auth'
import { Title } from 'react-head'

const Login = () => {
  const {
    loginWithGoogleAuth,
    googleResponse,
    passwordResponse,
    loginWithPassword,
  } = useAuth()
  return (
    <>
      <Title>FlowFi App | Log In</Title>
      <EntryForm
        submitText="Log In"
        showForgotPassword={true}
        onGoogleAuthSuccess={loginWithGoogleAuth}
        onSubmit={loginWithPassword}
        error={
          passwordResponse.data?.loginWithPassword?.error ||
          googleResponse.data?.loginWithGoogle?.error
        }
      />
    </>
  )
}

export default Login
