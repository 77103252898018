import React from 'react'

const ProfilePreferences = (): JSX.Element => {
  return (
    <section>
      <h2>Preferences</h2>
    </section>
  )
}

export default ProfilePreferences
