import React from 'react'
import { CheckboxProps } from '~/src/types/forms'
import Classnames from 'classnames'

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (ownProps, ref) => {
    const { labelRight, indeterminate, ...props } = ownProps

    const labelClasses = Classnames('form-field__label', {
      'cursor-pointer': !props.disabled,
      'cursor-not-allowed': props.disabled,
      ...(props.className ? { [props.className]: props.className } : {}),
    })

    const wrapperClasses = Classnames('form-field', 'form-field--checkbox', {
      'form-field--invalid': !!props.error,
    })

    const checkboxClasses = Classnames('checkbox-indicator', {
      'checkbox-indicator--indeterminate': indeterminate,
    })

    return (
      <div className={wrapperClasses}>
        <label htmlFor={props.id} className={labelClasses}>
          {!labelRight && <span>{props.label}</span>}
          <div className="checkbox">
            <input
              className="form-field__input peer"
              type="checkbox"
              ref={ref}
              {...props}
            />
            <div className={checkboxClasses}></div>
          </div>
          {labelRight && <span>{props.label}</span>}
        </label>
      </div>
    )
  }
)

export default Checkbox
