import React from 'react'
import SVGHandler from '~/src/components/svg-handler'
import { FlowIcons } from '~/src/assets/flow-icons'
import { SortOrder } from '~/src/types/tables'
import Classnames from 'classnames'
import { TSortableDatum } from '../index'

export type TSortableColumn<T, K = keyof T> = {
  label: string
  accessor?: K
  initialSortOrder?: SortOrder
  sortable?: boolean
  isActive?: boolean
  order?: SortOrder
  onSort?: (accessor: K) => void
  className?: string
  default?: boolean
  priority?: number
}

const SortableColumn = <T extends TSortableDatum, K = keyof T>({
  label,
  accessor,
  sortable,
  isActive,
  order,
  onSort,
  className,
}: Omit<TSortableColumn<T, K>, 'initialSortOrder'>): JSX.Element => {
  const headerClasses = Classnames('sortable-grid__column', {
    'sortable-grid__column--sortable': sortable,
    ...(className ? { [className]: className } : {}),
  })

  return (
    <div
      onClick={() => (sortable && onSort && accessor ? onSort(accessor) : null)}
      className={headerClasses}
    >
      {label}
      {sortable && isActive && (
        <SVGHandler
          color="gray"
          size="small"
          icon={order === 'asc' ? FlowIcons.ChevronDown : FlowIcons.ChevronUp}
        />
      )}
    </div>
  )
}

export default SortableColumn
