import { ISelectOption } from '../types/forms'
import { CustomerRoles, StaffRoles } from '../types/roles'
import { capitalizeFirstLetter } from './string'

export const customerRoleOptions = [
  { id: CustomerRoles.admin, name: capitalizeFirstLetter(CustomerRoles.admin) },
  {
    id: CustomerRoles.member,
    name: capitalizeFirstLetter(CustomerRoles.member),
  },
]

export const staffRoleOptions = [
  { id: StaffRoles.cfo, name: StaffRoles.cfo.toUpperCase() },
  {
    id: StaffRoles.bookkeeper,
    name: capitalizeFirstLetter(StaffRoles.bookkeeper),
  },
  {
    id: StaffRoles.sales,
    name: capitalizeFirstLetter(StaffRoles.sales),
  },
]

export const monthOptions: ISelectOption<number>[] = [
  { id: 1, name: '1 month' },
  { id: 3, name: '3 months' },
  { id: 6, name: '6 months' },
  { id: 12, name: '1 year' },
  { id: 0, name: 'Custom' },
]
