import React from 'react'
import '../documents.css'
import { useParams, redirect, LoaderFunction } from 'react-router-dom'
import BreadcrumbTrailHeader from '~/src/components/breadcrumb-trail-header'
import DocumentGrid from './document-grid'
import DocumentsControls from './documents-controls'
import CreateFolder from './create-folder'
import {
  useFolderQuery,
  FolderDocument,
} from '~/src/graphql/queries/generated/folder'
import { FolderBaseFragment } from '~/src/graphql/fragments/generated/folder-base'
import useCurrentUser from '~/src/hooks/use-current-user'
import { FolderCardGrid } from './folder-card-grid'
import { selectedCompanyId } from '~/src/cache'
import ROUTES from '~/src/constants/routes'
import client from '~/src/apollo/client'

const getBreadcrumbs = (
  rootFolder?: { parents: FolderBaseFragment[] } | null
) => {
  const { parents } = rootFolder || {}

  const beginning = {
    to: '/documents',
    name: 'Documents',
  }

  if (!parents) return [beginning]

  const sorted = [...parents].sort((a, b) => (a.id === b.parent_id ? -1 : 1))

  return [
    beginning,
    ...sorted.slice(1).map(({ id, title }) => ({
      to: `/documents/${id}`,
      name: title,
    })),
  ]
}

export const folderLoader: LoaderFunction = async ({ params }) => {
  const { folderId } = params
  const currentCompanyId = selectedCompanyId()

  if (!folderId && currentCompanyId) {
    throw redirect(ROUTES.documents.path)
  }

  const { data } = await client.query({
    query: FolderDocument,
    variables: { id: folderId, company_id: currentCompanyId },
  })

  if (!data?.folder) {
    throw redirect(ROUTES.documents.path)
  }

  return data.folder
}

const Document = (): JSX.Element => {
  const { folderId } = useParams()
  const { currentCompanyId } = useCurrentUser()

  const { data } = useFolderQuery({
    variables: { id: folderId, company_id: currentCompanyId },
    skip: !folderId || !currentCompanyId,
  })

  const rootFolder = data?.folder

  const breadcrumbs = getBreadcrumbs(rootFolder)

  return (
    <>
      <BreadcrumbTrailHeader
        pageTitle="Documents"
        title={rootFolder?.title || ''}
        breadcrumbs={breadcrumbs}
      />

      <DocumentsControls searchPlaceholder="Search in this folder" />

      {rootFolder?.fixed && <FolderCardGrid rootFolderId={rootFolder.id} />}

      <DocumentGrid rootFolderId={folderId} />

      <CreateFolder parentId={folderId} />
    </>
  )
}

export default Document
