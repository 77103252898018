import React from 'react'
import PageHeader from '~/src/components/page-header'
import { NavLink } from 'react-router-dom'
import './breadcrumb-trail-header.css'

interface IBreadcrumbTrailHeader {
  // What we show in the browser bar
  pageTitle: string
  // What we show in the h2 on the page
  title: string
  breadcrumbs: { to: string; name: string }[]
}

const BreadcrumbTrailHeader = ({
  pageTitle,
  title,
  breadcrumbs,
}: IBreadcrumbTrailHeader) => {
  return (
    <PageHeader title={pageTitle}>
      <div className="breadcrumb-trail-wrap">
        <nav>
          <ul className="breadcrumb-trail">
            {breadcrumbs.map(item => (
              <li className="breadcrumb-trail__segment" key={item.to}>
                <NavLink className="breadcrumb-trail__link" to={item.to}>
                  {item.name}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
        <h2>{title}</h2>
      </div>
    </PageHeader>
  )
}

export default BreadcrumbTrailHeader
