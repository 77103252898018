import React, { useState } from 'react'
import { useLocation, matchPath } from 'react-router-dom'
import UserIcon from '~/src/components/user-icon'
import { NavLink } from 'react-router-dom'
import { Popover } from '@headlessui/react'
import SVGHandler from '~/src/components/svg-handler'
import { FlowIcons } from '~/src/assets/flow-icons'
import useCurrentUser from '~/src/hooks/use-current-user'
import './user-menu.css'
import UserName from '~/src/components/user-name'
import useResponsive from '~/src/hooks/use-responsive'
import Classnames from 'classnames'
import { useNavBarContext } from '~/src/components/navbar/navbar.context'

interface IUserMenu {
  collapsed: boolean
}

const UserMenu = ({ collapsed }: IUserMenu): JSX.Element => {
  const { setMenuOpen } = useNavBarContext()
  const { isTablet } = useResponsive()
  const [hovering, setHovering] = useState<boolean>(false)
  const location = useLocation()
  const { currentUser, logOutCurrentUser } = useCurrentUser()

  const popoverClasses = Classnames('user-menu__popover', {
    'user-menu__popover--mobile': !isTablet,
  })

  const activeIcon = isTablet ? FlowIcons.ChevronRight : FlowIcons.ChevronDown

  // Since the popover button itself isn't a navigation item, we use this to set
  // an 'active' class on it if the user is on a profile route/subroute
  const linkActive = (): boolean => {
    return !!matchPath(`/profile/:any`, location.pathname)
  }

  return (
    <div className="user-menu">
      <Popover>
        {({ open }) => (
          <>
            {/* We don't use Classnames on this component since the HeadlessUI docs prefer this method */}
            <Popover.Button
              className={`user-menu__button ${
                open ? 'user-menu__button--open' : ''
              } ${linkActive() ? 'user-menu__button--route-active' : ''}`}
              onMouseOver={() => setHovering(true)}
              onMouseOut={() => setHovering(false)}
            >
              <UserIcon user={currentUser} size="sm" />
              {!collapsed && (
                <>
                  <UserName user={currentUser} />
                  <SVGHandler
                    size="small"
                    icon={open || hovering ? activeIcon : FlowIcons.ChevronDown}
                    color={open || hovering ? 'primary' : 'gray'}
                  />
                </>
              )}
            </Popover.Button>

            <Popover.Panel className={popoverClasses}>
              {/* "as" prop will swap in a named component and treat it like a button */}
              <Popover.Button
                className="user-menu__link"
                as={NavLink}
                to={'/profile/general'}
                onClick={() => setMenuOpen?.(false)}
              >
                My Profile
              </Popover.Button>
              <Popover.Button
                className="user-menu__logout"
                onClick={logOutCurrentUser}
                data-test="log-out"
              >
                Log Out
              </Popover.Button>
            </Popover.Panel>
          </>
        )}
      </Popover>
    </div>
  )
}

export default UserMenu
