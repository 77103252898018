# import UserFragment from "../fragments/user.graphql"

mutation ResetPasswordMutation(
  $user_jwt: String!
  $new_password: String!
  $password_confirmation: String!
) {
  resetPassword(
    user_jwt: $user_jwt
    new_password: $new_password
    password_confirmation: $password_confirmation
  ) {
    ... on User {
      ...UserFragment
    }
    ... on PasswordIncorrect {
      message
    }
    ... on PasswordNotUnique {
      message
    }
    ... on PasswordUnconfirmed {
      message
    }
    ... on PasswordResetInvalid {
      message
    }
    ... on PasswordResetExpired {
      message
    }
  }
}
