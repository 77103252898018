import * as Types from '../../../types/generated/index';

import { gql } from '@apollo/client';
import { AssetFragmentDoc } from '../../fragments/generated/asset';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateAssetMutationVariables = Types.Exact<{
  input: Types.CreateAssetInput;
}>;


export type CreateAssetMutation = { __typename?: 'Mutation', createAsset: { __typename: 'Asset', id: string, name?: string | null, display_name?: string | null, description?: string | null, created?: string | null, updated?: string | null, url?: string | null, company_id: string, preview_url?: string | null, thumb_url?: string | null, folder_id?: string | null, user?: { __typename?: 'User', id: string, first_name?: string | null, last_name?: string | null } | null } };


export const CreateAssetDocument = gql`
    mutation CreateAsset($input: CreateAssetInput!) {
  createAsset(input: $input) {
    ...AssetFragment
  }
}
    ${AssetFragmentDoc}`;
export type CreateAssetMutationFn = Apollo.MutationFunction<CreateAssetMutation, CreateAssetMutationVariables>;

/**
 * __useCreateAssetMutation__
 *
 * To run a mutation, you first call `useCreateAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssetMutation, { data, loading, error }] = useCreateAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAssetMutation(baseOptions?: Apollo.MutationHookOptions<CreateAssetMutation, CreateAssetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAssetMutation, CreateAssetMutationVariables>(CreateAssetDocument, options);
      }
export type CreateAssetMutationHookResult = ReturnType<typeof useCreateAssetMutation>;
export type CreateAssetMutationResult = Apollo.MutationResult<CreateAssetMutation>;
export type CreateAssetMutationOptions = Apollo.BaseMutationOptions<CreateAssetMutation, CreateAssetMutationVariables>;