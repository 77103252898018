import { IAccount, IAccountWithChildren } from '../types/account'
import {
  getAccountChildren,
  getAccountParents,
  onlyParents,
} from '~/src/utilities/accounts'
import { groupBy, flatten, pick, sumBy, sortBy } from 'lodash'
import { Balance } from '~/src/types/balance'
import { isDateWithinMonth } from '../utilities/dates'
import { eachMonthOfInterval, parse } from 'date-fns'
import { QBO_DATE_FORMAT } from '../pages/dashboard/config'

interface IRollupArgs {
  accounts?: IAccount[]
  accountIds?: string[]
  balances?: Balance[]
  start: Date
  end: Date
}

const getExpensesInCalendarMonth = (expenses: Balance[], month: Date) =>
  expenses.filter(({ start: date }) => {
    return isDateWithinMonth(parse(date, QBO_DATE_FORMAT, new Date()), month)
  })

// A utility function to aggregate balances (or expenses, etc) monthly for nested accounts over a period of time.

// This works for any collection of values over time that are associated with accounts -- monthly sums, balances, or individual expenses/payments -- as long as they are in the format of a Balance.
export const rollup = ({
  accounts: accountData,
  accountIds: pickedAccountIds,
  balances,
  start,
  end,
}: IRollupArgs) => {
  const monthsInRange = eachMonthOfInterval({ start, end })
  const accounts = accountData || []

  // Time to sort! We want to display as line items only parents: e.g., picked accounts that do not have
  // parents in the list. So let's start by filtering the account list:
  const pickedAccounts =
    accounts.filter(({ id }) => id && (pickedAccountIds || []).includes(id)) ||
    []

  // Give each account some info about its parents and children.
  // This is stolen from the account picker which does something very similar.
  const accountsWithChildren = pickedAccounts.map(account => ({
    ...account,
    children: getAccountChildren(account, accounts),
    parentIds: getAccountParents(account, accounts).map(({ id }) => id),
  })) as unknown as IAccountWithChildren[]

  // Now find the parents.
  // Parents are accounts that aren't the children of other accounts in the set.
  const parents = onlyParents(accountsWithChildren)

  const expenses = balances || []

  const expensesByAccountId = groupBy(expenses, 'accountId')

  // Go through each parent account and gather its expenses and that of its selected children. Find the expenses that are in that month and sum them.
  const expensesByAccountIdAndMonth = parents.map(({ children, ...parent }) => {
    const childIds = children?.map(child => child.id) || []
    const expenses = flatten(
      Object.values(pick(expensesByAccountId, [parent.id, ...childIds]))
    )

    // We want to end up with a value for each month in the range, whether or not there's data.
    const byMonth = monthsInRange.map(month => {
      const expensesInMonth = getExpensesInCalendarMonth(expenses, month)

      // Sum just that month's data.
      const sum = expensesInMonth.length ? sumBy(expensesInMonth, 'value') : 0

      return {
        date: month,
        value: sum,
      }
    })

    return {
      id: parent.id,
      name: parent.name,
      data: sortBy(byMonth, 'date'),
    }
  })

  return expensesByAccountIdAndMonth
}
