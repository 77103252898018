import { useEffect, useState, useRef, RefObject } from 'react'

const useIntersection = (
  root: RefObject<HTMLElement>, // the scrollable container
  target: RefObject<HTMLElement> // the item we're measuring
) => {
  const observerRef = useRef<IntersectionObserver | null>(null)
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    if (!root?.current) {
      return
    }

    observerRef.current = new IntersectionObserver(
      ([entry]) => setIsVisible(entry.isIntersecting),
      {
        root: root.current,
        rootMargin: '0px',
        threshold: 1,
      }
    )
  }, [root])

  useEffect(() => {
    if (!observerRef.current) {
      return
    }

    observerRef?.current?.observe(target?.current as HTMLElement)

    return () => {
      observerRef?.current?.disconnect()
    }
  }, [target])

  return isVisible
}

export default useIntersection
