import React from 'react'
import SVGHandler from '~/src/components/svg-handler'
import DeleteDocumentIcon from '~/src/assets/images/delete-document.svg'

interface IDeleteDocument {
  title?: string | null
  icon: string
}

const DeleteDocument = ({ icon, title }: IDeleteDocument) => {
  const svg = (
    <SVGHandler
      className="relative top-1"
      size="medium"
      icon={icon}
      color="gray"
    />
  )

  return (
    <div className="flex flex-col items-center justify-center mx-10">
      <object type="image/svg+xml" data={DeleteDocumentIcon}>
        Document placed in a trash can
      </object>
      <p className="text-center text-grayscale-900 mb-4 font-bold">
        {title ? (
          <>
            Are you sure you want to delete <br />
            <span className="text-primary">
              {svg}
              {title}
            </span>{' '}
            from your files?
          </>
        ) : (
          <>Are you sure you want to delete this file?</>
        )}
      </p>
    </div>
  )
}

export default DeleteDocument
