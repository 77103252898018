import React from 'react'
import Success from '~/src/assets/images/success.svg'
import './email-sent.css'

const EmailSent = (): JSX.Element => {
  return (
    <div className="email-sent">
      <object type="image/svg+xml" data={Success}>
        Letter with green check icon
      </object>
      <h3>Security link has been sent to your inbox</h3>
      <p>Hurry up! This link is valid only for 30 minutes.</p>
    </div>
  )
}

export default EmailSent
