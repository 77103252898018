import React from 'react'
import { Link, LinkProps } from 'react-router-dom'
import Classnames from 'classnames'

// This is a placeholder component so if we want to add custom link behavior
// in the future, we won't have to go track down every internal link.
export default ({ className, children, ...props }: LinkProps) => {
  const linkClasses = Classnames('link', className)

  return (
    <Link className={linkClasses} {...props}>
      {children}
    </Link>
  )
}
