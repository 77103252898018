import React from 'react'
import useCurrentUser from '~/src/hooks/use-current-user'
import {
  FolderShallowFragment,
  FolderShallowFragmentDoc,
} from '~/src/graphql/fragments/generated/folder-shallow'
import { useUpdateFolderMutation } from '~/src/graphql/mutations/generated/update-folder'
import useFragment from '~/src/hooks/use-fragment'
import Modal from '~/src/components/modal'
import useModal from '~/src/hooks/use-modal'
import MoveDocumentForm from '~/src/pages/documents/components/move-document-form'
import { notify } from '~/src/utilities/notify'

interface FormData {
  folder_id: string
}

export const MoveFolder = ({
  className,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const fragment = FolderShallowFragmentDoc
  const {
    show,
    modalId: id,
    closeModal: onClose,
  } = useModal({
    name: 'moveFolder',
  })

  const item = useFragment<FolderShallowFragment>({
    fragment,
    id: `Folder:${id}`,
  })

  const { currentCompanyId } = useCurrentUser()

  const defaultValues = {
    title: item?.title,
    existing_folder_id: item?.parent?.id || '',
    folder_id: '',
  }

  const [updateFolder] = useUpdateFolderMutation()

  const onSubmit = async ({ folder_id }: FormData) => {
    if (!currentCompanyId || !item) {
      return
    }

    try {
      await updateFolder({
        variables: {
          id: item.id,
          company_id: currentCompanyId,
          input: {
            parent_id: folder_id,
          },
        },
        refetchQueries: ['DocumentsQuery'],
      })
    } catch (e) {
      console.log(e); // eslint-disable-line
      notify('An error occurred', { type: 'error' })
    }

    onClose()
  }

  return (
    <Modal open={show} onClose={onClose}>
      {item && defaultValues && (
        <MoveDocumentForm
          onSubmit={onSubmit}
          className={className}
          defaultValues={defaultValues}
          id={id}
        />
      )}
    </Modal>
  )
}
