import {
  add,
  eachMonthOfInterval,
  format,
  formatISO,
  parse,
  startOfMonth,
  sub,
} from 'date-fns'
import useRevenue from '~/src/hooks/use-revenue'
import useSpending from '~/src/hooks/use-spending'
import { Balance } from '~/src/types/balance'
import { TCardSettings } from '~/src/types/card-settings'
import { QBO_DATE_FORMAT, SHORT_DATE_FORMAT } from '../pages/dashboard/config'
import { AccountType } from '../types/account'
import { isDateWithinMonth } from '../utilities/dates'
import useAccounts from './use-accounts'
import useCashBalances from './use-cash-balances'

const NO_DATA_VALUE = 0

const lastMonth = sub(new Date(), { months: 1 })

export const getBurnRate = ({
  startDate,
  endDate,
  balances: _balances,
  lastMonthBalance,
}: {
  startDate: Date
  endDate: Date
  balances?: Balance[]
  lastMonthBalance: number
}) => {
  const balances = _balances || []

  const monthsInRange = eachMonthOfInterval({
    start: startDate,
    end: endDate,
  })

  // Get the monthly burn rates for the big burn card.
  // There's only one "net burn" entry per month
  const netBurnByMonth = monthsInRange.map(month => {
    const monthlyBurn = balances.find(
      ({ start, type }) =>
        type === 'burn' &&
        isDateWithinMonth(parse(start, QBO_DATE_FORMAT, new Date()), month)
    )

    return {
      date: month,
      sum: monthlyBurn?.value ?? NO_DATA_VALUE,
    }
  })

  // Last month's burn rate to calculate the zero cash date.
  const burnRate =
    balances.find(
      b =>
        b.type == 'burn' &&
        b.start ===
          formatISO(startOfMonth(lastMonth), {
            representation: 'date',
          })
    )?.value || NO_DATA_VALUE

  const { zeroCashMonth, nearZero } = getZeroCashMonth({
    burnRate,
    lastMonthBalance,
  })

  return {
    zeroCashMonth,
    nearZero,
    burnRate,
    netBurnByMonth,
  }
}

const getZeroCashMonth = ({
  burnRate,
  lastMonthBalance,
}: {
  burnRate: number
  lastMonthBalance: number
}) => {
  // if burnRate is a positive number, they're gaining money and we can't calculate zero cash.
  // If it's 0, data probably isn't loaded upstream.
  if (burnRate <= 0)
    return {
      zeroCashMonth: 'Profitable 🎉💪',
      nearZero: false,
    }

  // Returns a message if they're at or under zero cash
  if (lastMonthBalance <= 0) {
    return {
      zeroCashMonth: 'Now',
      nearZero: true,
    }
  }

  // number of months remaining until zero cash
  const monthsRemaining = Math.abs(Math.floor(lastMonthBalance / burnRate))

  const month = format(
    add(new Date(), {
      months: monthsRemaining,
    }),
    SHORT_DATE_FORMAT
  )

  return {
    zeroCashMonth: month,
    nearZero: monthsRemaining <= 3,
  }
}

const getBurnForMonths = (
  revenue: { date: Date; sum: number }[],
  expenses: { date: Date; sum: number }[]
): { date: Date; sum: number }[] => {
  return revenue.reduce(
    (acc: { date: Date; sum: number }[], curr: { date: Date; sum: number }) => {
      const rev = curr.sum || 0
      const expense = Math.abs(
        expenses.filter(
          e => e.date.toISOString() === curr.date.toISOString()
        )[0]?.sum || 0
      )
      const burn = rev - expense

      return [
        ...acc,
        { date: curr.date, sum: parseFloat((burn * -1).toFixed(2)) },
      ]
    },
    []
  )
}

const useBurn = ({ startDate, endDate }: TCardSettings) => {
  const { accounts: bankAccounts } = useAccounts({
    accountTypes: [AccountType.BANK],
  })
  const { accounts: incomeAccounts } = useAccounts({
    accountTypes: [AccountType.INCOME],
  })
  const { accounts: expenseAccounts } = useAccounts({
    accountTypes: [AccountType.EXPENSE],
  })
  const bankAccountIds = (bankAccounts?.map(({ id }) => id) || []) as string[]
  const incomeAccountIds = (incomeAccounts?.map(({ id }) => id) ||
    []) as string[]
  const expenseAccountIds = (expenseAccounts?.map(({ id }) => id) ||
    []) as string[]

  const { netSumsByMonth: revenueSumsByMonth, loading: revenueLoading } =
    useRevenue({
      startDate,
      endDate,
      accountIds: incomeAccountIds,
    })

  const { sumsByMonth: expenseSumsByMonth, loading: expensesLoading } =
    useSpending({
      startDate,
      endDate,
      accountIds: expenseAccountIds,
    })

  const { sumsByMonth: cashBalancesByMonth, loading: cashBalancesLoading } =
    useCashBalances({
      startDate,
      endDate,
      accountIds: bankAccountIds,
    })

  const burnData = getBurnForMonths(revenueSumsByMonth, expenseSumsByMonth)
  const currentBurn = [...burnData].reverse()[0].sum || 0
  const currentBalance = [...cashBalancesByMonth].reverse()[0].sum || 0
  const zeroCashData = getZeroCashMonth({
    burnRate: currentBurn,
    lastMonthBalance: currentBalance,
  })

  return {
    burnRate: currentBurn,
    nearZero: zeroCashData.nearZero,
    zeroCashMonth: zeroCashData.zeroCashMonth,
    lastMonthFormatted: format(lastMonth, SHORT_DATE_FORMAT),
    netBurnByMonth: burnData,
    cashBalancesByMonth: cashBalancesByMonth,
    loading: expensesLoading || revenueLoading || cashBalancesLoading,
  }
}

export default useBurn
