import React, { useEffect, useState } from 'react'
import Modal from '~/src/components/modal'
import { useForm, Controller } from 'react-hook-form'
import Button from '~/src/components/button'
import { FormFields } from '~/src/components/form-fields'
import { monthOptions } from '~/src/utilities/select'
import { isBefore, format } from 'date-fns'
import { startDateFromRange, endDateFromRange } from '~/src/utilities/dates'
import { TCardSettings } from '~/src/types/card-settings'
import { ISelectOption } from '~/src/types/forms'

const CUSTOM_RANGE_OPTION: ISelectOption<number> = { id: -1, name: '' }
const monthOptionsWithCustom = [...monthOptions, CUSTOM_RANGE_OPTION]

export const useDateRangeModal = () => {
  const [isDateRangeModalOpen, setDateRangeModalOpen] = useState(false)

  return { isDateRangeModalOpen, setDateRangeModalOpen }
}

interface IDateRangePickerProps {
  cardSettings: TCardSettings
  setCardSettings: (arg: TCardSettings) => void
  isDateRangeModalOpen: boolean
  setDateRangeModalOpen: (arg: boolean) => void
}

interface IDateRangeForm extends TCardSettings {
  rangeOption: ISelectOption
}

const DateRangePicker = ({
  cardSettings,
  setCardSettings,
  isDateRangeModalOpen: isModalOpen,
  setDateRangeModalOpen: setModalOpen,
}: IDateRangePickerProps): JSX.Element => {
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    resetField,
    formState: { errors },
  } = useForm<IDateRangeForm>({
    defaultValues: {
      range: cardSettings.range,
      rangeOption: monthOptionsWithCustom.find(
        option => option.id === cardSettings.range
      ),
      startDate: cardSettings.startDate,
      endDate: cardSettings.endDate,
    },
  })

  const { range, rangeOption, startDate, endDate } = watch()

  // when the custom date range modal is cancelled, the inline picker can be set back to the last chosen range
  const [lastRange, setLastRange] = useState(cardSettings.range)

  const cancelCustomDateRange = () => {
    setValue('range', lastRange)
    const lastRangeOption = monthOptions.find(option => option.id === lastRange)
    if (lastRangeOption) {
      setValue('rangeOption', lastRangeOption)
    }
    setModalOpen?.(false)
  }

  const saveFormSettings = handleSubmit(data => {
    const { startDate, endDate } = data
    if (data.range) setLastRange(data.range)

    // the cardSettings range value should never actually be set to 0, or it would just show "custom" and not the custom range chosen
    const range = data.range ? data.range : CUSTOM_RANGE_OPTION.id

    setCardSettings?.({ startDate, endDate, range })
  })

  const saveFormSettingsAndClose = handleSubmit(data => {
    const { startDate, endDate, range } = data

    // if the range is 0 (custom), we need set the range and rangeOption to the custom values and the
    // graph dates are determined by the dates chosen, otherwise the graph dates are determined by the range value
    if (!range) {
      setValue('rangeOption', CUSTOM_RANGE_OPTION)
      setValue('startDate', startDate)
      setValue('endDate', endDate)
      setValue('range', CUSTOM_RANGE_OPTION.id)
    } else {
      setValue('rangeOption', rangeOption)
      setFormDatesFromRange(range)
    }
    saveFormSettings()

    setModalOpen?.(false)
  })

  const setFormDatesFromRange = (range: number) => {
    setValue('startDate', startDateFromRange(range))
    setValue('endDate', endDateFromRange())
  }

  const onDateRangeChange = (range: number) => {
    setValue('range', range)
  }

  const onInlineDateRangeChange = (range: number) => {
    onDateRangeChange(range)

    // graph dates can only be determined from range if range is greater than 0
    if (!range) {
      setModalOpen?.(true)
      return
    }

    setFormDatesFromRange(range)
    saveFormSettings()
  }

  // to update the options shown in a select (per a discussion on the react-hook-form github repo),
  // you must do so in a useEffect and reset the form/field
  useEffect(() => {
    if (range !== -1) return

    const customRangeLabel = [startDate, endDate]
      .map(date => [format(date, 'MMM'), format(date, 'yy')].join(" '"))
      .join(' - ')
    monthOptionsWithCustom[monthOptionsWithCustom.length - 1].name =
      customRangeLabel

    resetField('rangeOption', {
      defaultValue: { ...CUSTOM_RANGE_OPTION, name: customRangeLabel },
    })
  }, [range, endDate, startDate, resetField])

  return (
    <form className="ml-auto" onSubmit={saveFormSettingsAndClose}>
      {!isModalOpen ? (
        <FormFields.Select
          {...register('rangeOption', {
            onChange: e => onInlineDateRangeChange(e.target.value.id),
          })}
          label=""
          name="rangeOption"
          options={monthOptionsWithCustom.filter(option =>
            range === CUSTOM_RANGE_OPTION.id
              ? true
              : option.id !== CUSTOM_RANGE_OPTION.id
          )}
          control={control}
          ref={null}
        />
      ) : (
        <Modal
          title="Date Range"
          open={isModalOpen}
          onClose={cancelCustomDateRange}
        >
          <div className="mb-4">
            <FormFields.Select
              {...register('rangeOption', {
                onChange: e => onDateRangeChange(e.target.value.id),
              })}
              label="Date Range"
              name="rangeOption"
              options={monthOptions}
              control={control}
              ref={null}
            />
          </div>
          <div
            className={`mb-4 grid-cols-2 gap-2 ${
              rangeOption.id === 0 ? 'grid' : 'hidden'
            }`}
          >
            {/* Only display the message on the first date field, since it'd be redundant otherwise. */}
            <Controller
              render={({ field: { onChange, value } }) => (
                <FormFields.DatePicker
                  label="From"
                  selected={value}
                  onChange={onChange}
                  placement="bottom-start"
                  showMonthYearPicker
                  dateFormat="MMMM yyyy"
                  noFuture
                  error={
                    errors.endDate &&
                    errors.endDate.type === 'startDateAfterEndDate' &&
                    'Start date cannot be after end date.'
                  }
                />
              )}
              control={control}
              rules={{
                validate: {
                  startDateAfterEndDate: () =>
                    isBefore(getValues('startDate'), getValues('endDate')),
                },
              }}
              name="startDate"
            />
            <Controller
              render={({ field: { onChange, value } }) => (
                <FormFields.DatePicker
                  label="To"
                  selected={value}
                  onChange={onChange}
                  placement="bottom-start"
                  showMonthYearPicker
                  dateFormat="MMMM yyyy"
                  noFuture
                  error={
                    errors.endDate &&
                    errors.endDate.type === 'startDateAfterEndDate'
                  }
                />
              )}
              control={control}
              rules={{
                validate: {
                  startDateAfterEndDate: () =>
                    isBefore(getValues('startDate'), getValues('endDate')),
                },
              }}
              name="endDate"
            />
          </div>

          <p>{errors.range?.message}</p>

          <Button
            type="button"
            label="Cancel"
            size="medium"
            theme="secondary"
            onClick={() => setModalOpen?.(false)}
          />
          <Button
            type="submit"
            label="Submit"
            size="medium"
            theme="primary"
            onClick={saveFormSettingsAndClose}
          />
        </Modal>
      )}
    </form>
  )
}

export default DateRangePicker
