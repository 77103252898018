import React, { useState } from 'react'
import NavBarStandard from './components/navbar-standard'
import NavBarMobile from './components/navbar-mobile'
import { navCollapsed } from '~/src/cache'
import { useReactiveVar } from '@apollo/client'
import useActionItems from '~/src/hooks/use-action-items'
import useResponsive from '~/src/hooks/use-responsive'
import { NavBarContext } from './navbar.context'
import './navbar.css'

export interface INavBar {
  collapsed: boolean
  actionItemCount: number
}

const NavBar = (): JSX.Element => {
  const { isTablet, isDesktop } = useResponsive()

  const collapsed = useReactiveVar(navCollapsed)
  const {
    actionItems: { loading, todo, late },
  } = useActionItems()

  const actionItemCount = [...todo, ...late].length

  const navBarProps: INavBar = {
    collapsed: isDesktop ? collapsed : true,
    actionItemCount: loading ? 0 : actionItemCount,
  }

  const [isMenuOpen, setMenuOpen] = useState(false)

  return (
    <NavBarContext.Provider value={{ isMenuOpen, setMenuOpen }}>
      {isTablet || isDesktop ? (
        <NavBarStandard {...navBarProps} />
      ) : (
        <NavBarMobile {...navBarProps} />
      )}
    </NavBarContext.Provider>
  )
}

export default NavBar
