import {
  ApolloLink,
  NextLink,
  Operation,
  RequestHandler,
  HttpLink,
} from '@apollo/client'

import ENDPOINTS from '../graphql/endpoints'

// If we don't specify a uri in the context, send it to Yggdrasil.
const defaultURI = ENDPOINTS.root.uri

class MultiAPILink extends ApolloLink {
  httpLink: ApolloLink
  constructor(request?: RequestHandler) {
    super(request)
    this.httpLink = new HttpLink()
  }

  public request(operation: Operation, forward: NextLink) {
    const { uri: provided, ...rest } = operation.getContext()
    const uri = provided || defaultURI

    operation.setContext({ ...rest, uri })

    // we return the request
    return this.httpLink.request(operation, forward)
  }
}

export default MultiAPILink
