import React, { useMemo } from 'react'
import ReactQuill from '@flowfinance/react-quill'
import Classnames from 'classnames'
import '@flowfinance/react-quill/dist/quill.snow.css'
import { v4 } from 'uuid'

interface IRichText {
  value: string
  onChange: (editorState: string) => void
  error?: React.ReactNode
  label: string
  id: string
  disabled?: boolean
}

interface IToolBar {
  id: string
  disabled?: boolean
}

const ToolBar = ({ id, disabled }: IToolBar): JSX.Element => {
  const toolbarClasses = Classnames('richtext-toolbar', {
    'richtext-toolbar--disabled': disabled,
  })

  return (
    <div id={id} className={toolbarClasses}>
      <button aria-label="bold" className="ql-bold" />
      <button aria-label="italic" className="ql-italic" />
      <button aria-label="strikethrough" className="ql-strike" />
      <div className="w-px h-6 mx-1 bg-grayscale-300"></div>
      <button aria-label="add/remove hyperlink" className="ql-link" />
      <div className="w-px h-6 mx-1 bg-grayscale-300"></div>
      <button aria-label="ordered list" className="ql-list" value="ordered" />
      <button aria-label="unordered list" className="ql-list" value="bullet" />
    </div>
  )
}

const RichText = React.forwardRef<ReactQuill, IRichText>(
  (props, ref): JSX.Element => {
    const toolbarId = useMemo(() => `${props.id}-${v4()}`, [props.id])

    const modules = {
      toolbar: !props.disabled ? `#${toolbarId}` : '',
    }

    const formats = [
      'header',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'list',
      'bullet',
      'indent',
      'link',
      'image',
    ]

    const inputClasses = Classnames('form-field', {
      'form-field--invalid': props.error,
      'form-field--disabled': props.disabled,
    })

    const editorClasses = Classnames('editor', {
      'editor--disabled': props.disabled,
    })

    const handleChange = (editorState: string) => {
      props.onChange(editorState)
    }

    return (
      <div className={inputClasses}>
        <label htmlFor={props.id} className="form-field__label">
          {props.label}
        </label>
        {!props.disabled && (
          <ToolBar id={toolbarId} disabled={props.disabled} />
        )}
        <ReactQuill
          className={editorClasses}
          ref={ref}
          readOnly={props.disabled}
          theme={'snow'}
          value={props.value}
          onChange={handleChange}
          modules={modules}
          formats={formats}
        />
        <p className="form-field__error">{props.error}</p>
      </div>
    )
  }
)

export default RichText
