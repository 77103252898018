# import MemberFragment from "../fragments/member.graphql"

query TeamMemberQuery(
  $company_id: ID!
  $substring: String
  $config: QueryConfig
) {
  teamMembers(company_id: $company_id, substring: $substring, config: $config) {
    count
    items {
      ...MemberFragment
    }
  }
}
