import * as Types from '../../../types/generated/index';

import { gql } from '@apollo/client';
export type FolderBaseFragment = { __typename?: 'Folder', id: string, company_id: string, parent_id?: string | null, created_at: string, fixed: boolean, title: string };

export const FolderBaseFragmentDoc = gql`
    fragment FolderBase on Folder {
  id
  title: name
  company_id
  parent_id
  created_at
  fixed
}
    `;