import React from 'react'
import { IUser } from '~/src/types/user'
import { getUserProperties } from '~/src/utilities/permissions'
import './user-name.css'

const UserName = ({ user }: { user?: IUser }) => {
  const { isInternal } = getUserProperties(user)
  return (
    <div className="flex items-center gap-1">
      <div className="user-name" data-test="user-name">
        {user?.first_name} {user?.last_name}
      </div>
      <div>{isInternal && ' ✨'}</div>
    </div>
  )
}

export default UserName
