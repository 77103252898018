import React from 'react'
import Modal from '~/src/components/modal'
import Button, { TButtonThemes } from '~/src/components/button'

interface IConfirm {
  label: string
  buttonDisabled?: boolean
  confirmAction: VoidFunction
  open: boolean
  onClose: VoidFunction
  confirmText: string
  cancelText?: string
  buttonTheme?: TButtonThemes
  testId?: string
}

export const Confirm = ({
  label,
  confirmAction,
  open,
  onClose,
  children,
  confirmText,
  cancelText,
  buttonTheme,
  buttonDisabled,
  testId = 'confirm-button',
}: IConfirm & React.HTMLAttributes<HTMLElement>) => {
  const sharedProps = {
    fullWidth: true,
  }

  return (
    <Modal open={open} title={label} onClose={onClose}>
      {children}
      <Button
        {...sharedProps}
        disabled={buttonDisabled}
        label={confirmText || 'Confirm'}
        theme={buttonTheme || 'primary'}
        testId={testId}
        onClick={() => confirmAction()}
      />
      {cancelText && (
        <Button
          {...sharedProps}
          label={cancelText || 'Cancel'}
          theme="secondary"
          onClick={() => onClose()}
        />
      )}
    </Modal>
  )
}

export default Confirm
