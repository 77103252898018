import * as Types from '../../../types/generated/index';

import { gql } from '@apollo/client';
import { AssetFragmentDoc } from '../../fragments/generated/asset';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateAssetMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  company_id: Types.Scalars['ID'];
  input: Types.UpdateAssetInput;
}>;


export type UpdateAssetMutation = { __typename?: 'Mutation', updateAsset: { __typename: 'Asset', id: string, name?: string | null, display_name?: string | null, description?: string | null, created?: string | null, updated?: string | null, url?: string | null, company_id: string, preview_url?: string | null, thumb_url?: string | null, folder_id?: string | null, user?: { __typename?: 'User', id: string, first_name?: string | null, last_name?: string | null } | null } };


export const UpdateAssetDocument = gql`
    mutation UpdateAsset($id: ID!, $company_id: ID!, $input: UpdateAssetInput!) {
  updateAsset(id: $id, company_id: $company_id, input: $input) {
    ...AssetFragment
  }
}
    ${AssetFragmentDoc}`;
export type UpdateAssetMutationFn = Apollo.MutationFunction<UpdateAssetMutation, UpdateAssetMutationVariables>;

/**
 * __useUpdateAssetMutation__
 *
 * To run a mutation, you first call `useUpdateAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssetMutation, { data, loading, error }] = useUpdateAssetMutation({
 *   variables: {
 *      id: // value for 'id'
 *      company_id: // value for 'company_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAssetMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAssetMutation, UpdateAssetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAssetMutation, UpdateAssetMutationVariables>(UpdateAssetDocument, options);
      }
export type UpdateAssetMutationHookResult = ReturnType<typeof useUpdateAssetMutation>;
export type UpdateAssetMutationResult = Apollo.MutationResult<UpdateAssetMutation>;
export type UpdateAssetMutationOptions = Apollo.BaseMutationOptions<UpdateAssetMutation, UpdateAssetMutationVariables>;