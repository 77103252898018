import { useMutation, gql } from '@apollo/client'
import CREATE_MISC_RESPONSE_MUTATION from '~/src/graphql/mutations/create-misc-action-item-response.graphql'
import CREATE_CATEGORIZATION_RESPONSE_MUTATION from '~/src/graphql/mutations/create-categorization-action-item-response.graphql'
import CREATE_AMORTIZATION_RESPONSE_MUTATION from '~/src/graphql/mutations/create-amortization-action-item-response.graphql'
import UPDATE_ACTION_ITEM_STATE_MUTATION from '~/src/graphql/mutations/update-action-item-state.graphql'
import {
  AmortizationActionItemResponseInput,
  MiscellaneousActionItemResponseInput,
  CategorizationActionItemResponseInput,
} from '~/src/types/generated'
import { ActionItemCategory, ActionItemState } from '~/src/types/action-items'
import { notify } from '~/src/utilities/notify'

/**
 * A simplified partial union of all the variables from our
 * different action item reply types.
 */
export interface IActionItemReplyVars {
  notes: string
  is_amortization: boolean
  start_date: Date
  service_period_months: number
  merchant_name: string
  account_name: string
  account_id: string
  merchant_id: string
}

const useCreateActionItemReply = (initialArgs: {
  actionItemId: string
  actionItemType: ActionItemCategory
  authorId: string
}) => {
  const { actionItemId, actionItemType, authorId } = initialArgs

  // All the reply inputs share the id of their parent, and an author id.
  const initialVars = {
    action_item_id: actionItemId,
    author_id: authorId,
  }
  const options = {
    variables: {
      data: initialVars,
    },
    refetchQueries: ['GetActionItems'],
  }
  const [createCategorizationActionItemResponse] = useMutation(
    gql(CREATE_CATEGORIZATION_RESPONSE_MUTATION),
    options
  )
  const [createAmortizationActionItemResponse] = useMutation(
    gql(CREATE_AMORTIZATION_RESPONSE_MUTATION),
    options
  )
  const [createMiscActionItemResponse] = useMutation(
    gql(CREATE_MISC_RESPONSE_MUTATION),
    options
  )

  const actionItemUpdateVariables = {
    action_item_id: actionItemId,
    state: ActionItemState.ready_for_action, // "Pending"
  }

  const [updateActionItemState] = useMutation(
    gql(UPDATE_ACTION_ITEM_STATE_MUTATION),
    {
      variables: {
        data: actionItemUpdateVariables,
      },
      refetchQueries: ['GetActionItems'],
    }
  )

  const createReply = async (callTimeVars: IActionItemReplyVars) => {
    const {
      notes,
      is_amortization,
      start_date,
      service_period_months,
      merchant_name,
      account_name,
      account_id,
      merchant_id,
    } = callTimeVars

    if (actionItemType === ActionItemCategory.categorization) {
      const categorizationVars: CategorizationActionItemResponseInput = {
        merchant_id,
        name: merchant_name,
        account_id,
        account: account_name,
        additional_notes: notes || undefined,
      }
      await createCategorizationActionItemResponse({
        variables: {
          data: {
            ...categorizationVars,
            ...initialVars,
          },
        },
      }).then(data => {
        if (data.errors) {
          notify('There was a problem submitting your response.', {
            type: 'error',
          })
        } else {
          notify('Your response has been submitted.', { type: 'success' })
        }
      })
      await updateActionItemState()
      return
    }

    if (actionItemType === ActionItemCategory.amortization) {
      const amortizationVars: AmortizationActionItemResponseInput = {
        is_amortization,
        service_period_months:
          (is_amortization && service_period_months) || undefined,
        start_date:
          is_amortization && start_date
            ? (start_date as Date).getTime().toString()
            : undefined,
        additional_notes: notes || undefined,
      }

      await createAmortizationActionItemResponse({
        variables: {
          data: {
            ...amortizationVars,
            ...initialVars,
          },
        },
      }).then(data => {
        if (data.errors) {
          notify('There was a problem submitting your response.', {
            type: 'error',
          })
        } else {
          notify('Your response has been submitted.', { type: 'success' })
        }
      })
      await updateActionItemState()
      return
    }

    if (actionItemType === ActionItemCategory.miscellaneous) {
      const miscellaneousVars: MiscellaneousActionItemResponseInput = {
        long_answer: notes,
      }
      await createMiscActionItemResponse({
        variables: {
          data: {
            ...miscellaneousVars,
            ...initialVars,
          },
        },
      }).then(data => {
        if (data.errors) {
          notify('There was a problem submitting your response.', {
            type: 'error',
          })
        } else {
          notify('Your response has been submitted.', { type: 'success' })
        }
      })
      await updateActionItemState()
      return
    }

    throw new Error(
      'Must provide action item category to create an action item response'
    )
  }

  return createReply
}

export default useCreateActionItemReply
