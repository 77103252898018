import React from 'react'
import { gql, useMutation } from '@apollo/client'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import Button from '~/src/components/button'
import { FormFields } from '~/src/components/form-fields'
import Logo from '~/src/components/logo'
import { FORMS } from '~/src/constants/forms'
import ROUTES from '~/src/constants/routes'
import CONFIRM_RESET_PASSWORD_MUTATION from '~/src/graphql/mutations/confirm-reset-password.graphql'
import { notify } from '~/src/utilities/notify'

type FormData = {
  newPassword: string
  confirmPassword: string
}
export const ResetPasswordPage = (): JSX.Element => {
  const { user_jwt } = useParams()
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<FormData>({ mode: 'onSubmit' })

  const [resetPassword, { data }] = useMutation(
    gql(CONFIRM_RESET_PASSWORD_MUTATION)
  )

  const onSubmit = handleSubmit(async (data: FormData) => {
    const { data: response } = await resetPassword({
      variables: {
        user_jwt,
        new_password: data.newPassword,
        password_confirmation: data.confirmPassword,
      },
    })

    if (!response?.resetPassword?.message) {
      notify('Password successfully reset. Redirecting to login...', {
        type: 'success',
      })
      navigate(ROUTES.login.path)
      return
    }

    notify(response.resetPassword.message, { type: 'error' })
    const errorType: string = response?.resetPassword.__typename
    if (errorType.toLocaleLowerCase().includes('reset')) {
      navigate(ROUTES.login.path)
    }
  })

  return (
    <section className="entry-form__section">
      <Logo collapsed={false} />
      <h2 className="entry-form__header">Reset Password</h2>
      <p className="entry-form__description">Please choose your new password</p>
      <form onSubmit={onSubmit} className="entry-form">
        <FormFields.Password
          {...register('newPassword', {
            required: FORMS.VALIDATION.REQUIRED_FIELD,
          })}
          label="New Password"
          error={errors.newPassword?.message}
        />
        <FormFields.Password
          {...register('confirmPassword', {
            required: FORMS.VALIDATION.REQUIRED_FIELD,
            validate: () =>
              getValues('newPassword') === getValues('confirmPassword')
                ? true
                : 'Passwords do not match.',
          })}
          label="Confirm New Password"
          error={errors.confirmPassword?.message}
        />
        <Button
          type="submit"
          label="Reset Password"
          theme="primary"
          size="medium"
          className="change-password__confirm"
        />

        <div className="change-password__form-error">
          {data?.changePassword?.message}
        </div>
      </form>
    </section>
  )
}
