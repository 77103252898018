import React from 'react'
import InvalidInvitationImage from '~/src/assets/images/invitation-invalid.svg'
import Logo from '~/src/assets/images/logo.svg'

import './invitation.css'

interface IInvitationProps {
  message: string | JSX.Element
  messageDetail: string
  button?: JSX.Element
  companyIcon?: JSX.Element
}

export const Invitation = ({
  message,
  messageDetail,
  button,
  companyIcon,
}: IInvitationProps): JSX.Element => {
  return (
    <div className="invitation">
      <header className="invitation__header">
        <object type="image/svg+xml" data={Logo}>
          flowfi type logo
        </object>
      </header>
      <div className="invitation-details">
        <div className="invitation-details__image">
          {companyIcon ? (
            companyIcon
          ) : (
            <object type="image/svg+xml" data={InvalidInvitationImage}>
              Empty mailbox
            </object>
          )}
        </div>
        <p className="invitation-details__message">{message}</p>
        <p>{messageDetail}</p>
        <div className="invitation-details__button-wrapper">{button}</div>
      </div>
    </div>
  )
}
