import React, { ReactNode, useState } from 'react'
import { useForm } from 'react-hook-form'
import Button from '~/src/components/button'
import ChangePassword from '~/src/components/change-password'
import EmailSent from '~/src/components/email-sent'
import { FormFields } from '~/src/components/form-fields'
import Modal from '~/src/components/modal'
import ResetPassword from '~/src/components/reset-password'
import { ISelectOption } from '~/src/components/select'
import { FORMS } from '~/src/constants/forms'
import { useUpdateUserMutation } from '~/src/graphql/mutations/generated/update-user'
import { notify } from '~/src/utilities/notify'
import { customerRoleOptions, staffRoleOptions } from '~/src/utilities/select'
import { useProfileContext } from '../..'
import './general.css'

type FormData = {
  first_name: string
  last_name: string
  email_address?: string
  role?: ISelectOption
}

const ProfileGeneral = (): JSX.Element => {
  // It isn't meaningful to render this component if the user/member isn't loaded; we'll prematurely set default
  // values, and it will result in a blank form. So we're letting the parent component fetch the member
  // for us through OutletContext, conditionally render its child (this component), and pass the value to us
  // with a custom hook.
  const { currentUser, currentMember } = useProfileContext()

  const [updateUser, { data, loading }] = useUpdateUserMutation({
    refetchQueries: ['UserQuery', 'MemberQuery'],
  })

  type ModalTypes =
    | 'changePassword'
    | 'forgotPassword'
    | 'emailSent'
    | undefined
  const [modalOpen, setModalOpen] = useState<ModalTypes>()

  const initialRoleOption = [...customerRoleOptions, ...staffRoleOptions].find(
    ({ id }) => currentMember?.role === id
  )

  const initialValues: FormData = {
    first_name: currentUser.first_name,
    last_name: currentUser.last_name,
    email_address: currentMember?.email_address || '',
    role: initialRoleOption,
  }

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors, isDirty },
  } = useForm<FormData>({
    mode: 'onSubmit',
    defaultValues: initialValues,
  })

  const onSubmit = async (formData: FormData) => {
    const { first_name, last_name, email_address } = formData

    try {
      await updateUser({
        variables: {
          id: currentUser.id,
          input: {
            first_name,
            last_name,
            email:
              email_address && currentMember?.email_id
                ? {
                    id: currentMember.email_id,
                    address: email_address,
                  }
                : null,
          },
        },
      })

      if (data?.updateUser?.id) {
        notify('User profile updated.', {
          type: 'success',
        })

        reset({
          first_name,
          last_name,
          email_address,
        })
      }
    } catch {
      notify('Unable to update user profile', {
        type: 'error',
      })
    }
  }

  const onSubmitWithValidation = handleSubmit(onSubmit)

  const submitDisabled = !isDirty || loading

  return (
    <section>
      <div className="general-settings">
        <form
          className="general-settings__form"
          onSubmit={onSubmitWithValidation}
        >
          <FormFields.Text
            {...register('first_name', {
              required: FORMS.VALIDATION.REQUIRED_FIELD,
            })}
            label="First Name"
            placeholder="First Name"
            id="first"
            error={errors?.first_name?.message}
          />
          <FormFields.Text
            {...register('last_name', {
              required: FORMS.VALIDATION.REQUIRED_FIELD,
            })}
            label="Last Name"
            placeholder="Last Name"
            id="last"
            error={errors?.last_name?.message}
          />
          {currentMember && (
            <>
              <FormFields.Text
                {...register('email_address', {
                  required: FORMS.VALIDATION.REQUIRED_FIELD,
                })}
                label="Work Email"
                placeholder="Work Email"
                id="email"
                error={errors?.email_address?.message}
              />
              <FormFields.Select
                disabled={true}
                label="Role"
                name="role"
                control={control}
                options={customerRoleOptions}
                error={errors?.role?.message as ReactNode}
              />
            </>
          )}
          <Button
            type="submit"
            size="medium"
            theme="secondary"
            disabled={submitDisabled}
            label="Save Changes"
          />
        </form>
      </div>

      <div className="grid grid-cols-1 tablet:grid-cols-flowfi-twothirds gap-4 mt-10">
        <div>
          <h4>Password</h4>
          <p>Set a unique password to protect your personal FlowFi account.</p>
        </div>
        <div className="flex">
          <Button
            size="medium"
            theme="text"
            className="pl-0 tablet:ml-auto"
            onClick={() => setModalOpen('changePassword')}
            label="Change Password"
          />
        </div>
      </div>

      <Modal
        title="Change Password"
        open={modalOpen === 'changePassword'}
        onClose={() => setModalOpen(undefined)}
      >
        <ChangePassword
          userId={currentUser?.id}
          onForgotPwd={() => {
            setModalOpen(undefined)
            setTimeout(() => {
              setModalOpen('forgotPassword')
            }, 300)
          }}
          onConfirm={() => setModalOpen(undefined)}
        />
      </Modal>
      <Modal
        title="Reset Password"
        open={'forgotPassword' === modalOpen}
        onClose={() => setModalOpen(undefined)}
      >
        <ResetPassword
          onConfirm={() => {
            setModalOpen(undefined)
            setTimeout(() => {
              setModalOpen('emailSent')
            }, 300)
          }}
        />
      </Modal>
      <Modal
        title=""
        open={'emailSent' === modalOpen}
        onClose={() => setModalOpen(undefined)}
      >
        <EmailSent />
      </Modal>
    </section>
  )
}

export default ProfileGeneral
