import React, { useState, useEffect } from 'react'
import * as htmlToImage from 'html-to-image'
import Button from '~/src/components/button'
import { FormFields } from '~/src/components/form-fields'
import { IChartRefs, IChartImage } from '~/src/types/graphs'
import { useForm } from 'react-hook-form'
import { notify } from '~/src/utilities/notify'
import { exportImages } from '~/src/resources/exports'
import useCurrentUser from '~/src/hooks/use-current-user'

interface IDashboardReports {
  graphRefs: IChartRefs
}

type FormData = {
  graphs: string[]
  selectAll: boolean
}

type TStringMap = {
  [key: string]: string
}

const GRAPH_NAMES: TStringMap = {
  topPaidVendors: 'Top Paid Vendors',
  topExpenses: 'Top Expense Accounts',
  revenue: 'Revenue',
  grossMargin: 'Gross Margin',
  expenseDistribution: 'Expense Distribution',
  burnRate: 'Burn Rate',
  balances: 'Cash Balances',
}

const DEFAULT_GRAPHS_STATE = [
  'topPaidVendors',
  'topExpenses',
  'revenue',
  'grossMargin',
  'expenseDistribution',
  'burnRate',
  'balances',
]

const defaultValues = {
  graphs: DEFAULT_GRAPHS_STATE,
  selectAll: true,
}

const DashboardReports = ({ graphRefs }: IDashboardReports): JSX.Element => {
  const [capturing, setCapturing] = useState<boolean>(false)
  const [indeterminate, setIndeterminate] = useState<boolean>(false)
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues,
  })
  const { currentUser, currentCompany, currentCompanyId } = useCurrentUser()
  const dateStamp = new Date().toDateString().toLowerCase().split(' ').join('-')

  const allGraphs = Object.entries(graphRefs).map(graph => graph[0])

  // Take the screenshots & return them in an array.
  const captureScreenshots = async (data: FormData) => {
    const checkedCards = Object.entries(graphRefs).filter(
      graph => !!data.graphs.includes(graph[0])
    )

    const images = []

    for (const card of checkedCards) {
      const png = await htmlToImage.toPng(card[1].current as HTMLElement)
      images.push({
        fileName: `${card[0]}-export-${dateStamp}.png`,
        image: png,
      })
    }

    return images
  }

  // Handle the export
  const handleExport = async (data: FormData) => {
    if (!data.graphs.length) {
      setError('graphs', {
        type: 'custom',
        message: 'You must select at least one graph.',
      })
      return
    }

    setCapturing(true)

    try {
      const cardImages: IChartImage[] = await captureScreenshots(data)

      if (cardImages) {
        const zipUrl = await exportImages(
          currentUser?.id || '',
          currentCompanyId || '',
          currentCompany?.name || '',
          dateStamp,
          cardImages
        )

        const a = document.createElement(`a`)
        a.href = zipUrl
        a.click()
        a.remove()
      }
    } catch (e) {
      notify('There was an error exporting your images.', {
        type: 'error',
      })
    } finally {
      setCapturing(false)
    }
  }

  // Handle "select all" logic
  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      setValue('graphs', DEFAULT_GRAPHS_STATE)
    } else {
      setValue('graphs', [])
    }
  }

  const graphs = watch('graphs')

  useEffect(() => {
    if (!graphs.length) {
      setValue('selectAll', false)
      setIndeterminate(false)
    } else if (graphs.length === DEFAULT_GRAPHS_STATE.length) {
      setValue('selectAll', true)
      setIndeterminate(false)
    } else {
      setIndeterminate(true)
    }
  }, [graphs, setValue])

  // TODO: we have some stale/undefined state when this component first mounts
  // that causes interacting with checkboxes to unselect a random set. This is
  // preventing that, but we should solve that problem
  useEffect(() => {
    reset(defaultValues)
  }, [reset])

  return (
    <>
      <p className="snapshot__description">
        Export Dashboard cards as PNGs for decks, emails, and more!
      </p>

      <form className="snapshot__form" onSubmit={handleSubmit(handleExport)}>
        <div className="mt-4">
          <FormFields.Checkbox
            indeterminate={indeterminate}
            {...register('selectAll')}
            label={'Select All'}
            onChange={e => handleSelectAll(e.target.checked)}
            labelRight
          />
        </div>

        <div className="snapshot__form-divider" />

        <ul>
          {allGraphs.map(graph => {
            return (
              <li key={graph}>
                <FormFields.Checkbox
                  {...register('graphs')}
                  label={GRAPH_NAMES[graph]}
                  labelRight={true}
                  value={graph}
                  className="text-base my-1"
                />
              </li>
            )
          })}
        </ul>

        <p className="text-error mt-2">{errors.graphs?.message}</p>

        <Button
          className="w-full mt-4"
          type="submit"
          size="medium"
          theme="success"
          disabled={capturing}
          label={capturing ? 'Loading...' : 'Export as PNG'}
        />
      </form>
    </>
  )
}

export default DashboardReports
