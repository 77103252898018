import { ApolloClient, InMemoryCache, from } from '@apollo/client'
import { cacheOptions } from '../cache'
import errorLink from './error-link'
import MultiAPILink from './multi-http-link'

const httpLink = new MultiAPILink()

// Apollo config
const client = new ApolloClient({
  link: from([errorLink, httpLink]),
  cache: new InMemoryCache(cacheOptions),
  connectToDevTools: process.env.NODE_ENV === 'development',
})

export default client
