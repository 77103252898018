import React, { useEffect } from 'react'
import useBurn from '~/src/hooks/use-burn'
import { formatCurrency } from '~/src/utilities/currency'
import Classnames from 'classnames'
import SVGHandler from '~/src/components/svg-handler'
import { FlowIcons } from '~/src/assets/flow-icons'
import Info from '~/src/components/info'
import ReactTooltip from 'react-tooltip'
import { TCardSettings } from '~/src/types/card-settings'
import { v4 } from 'uuid'

const MiniBurnRateCard = ({ startDate, endDate }: TCardSettings) => {
  const { burnRate, nearZero, zeroCashMonth, loading } = useBurn({
    startDate,
    endDate,
  })

  // console.log(startDate, endDate)
  // CSS triggers for loading effect
  const cardClasses = Classnames('animate-value animate-value--out', {
    'animate-value--in': !loading,
  })

  // Tooltip. The useEffect is necessary to attach the tooltip since its
  // parent is conditionally rendered
  const tipId = v4()
  const tipMsg = 'You are running out of cash in less than three months.'

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [nearZero])

  return (
    <>
      <div className={cardClasses}>
        <div className="mini-card__value">
          {burnRate !== 0 ? formatCurrency(burnRate) : formatCurrency(burnRate)}{' '}
          {nearZero ? (
            <div data-tip={tipMsg} data-for={tipId}>
              <SVGHandler
                size="xlarge"
                icon={FlowIcons.AlertFill}
                color="warning"
              />
            </div>
          ) : null}
        </div>
        <div className="mini-card__zero-cash-line">
          <SVGHandler size="small" icon={FlowIcons.ZeroCash} color="gray" />
          <span>{`Zero Cash Date: ${zeroCashMonth}`}</span>
        </div>
      </div>
      <ReactTooltip
        className="panopticon-tooltip"
        id={tipId}
        place="bottom"
        effect="solid"
      />
    </>
  )
}

export const MiniBurnRateCardHeader = ({
  startDate,
  endDate,
}: TCardSettings) => {
  const { lastMonthFormatted } = useBurn({
    startDate,
    endDate,
  })

  return (
    <div className="mini-card__info-line">
      <Info message="Burn Rate is calculated by subtracting your total monthly expenses from your total monthly income, found on your Profit and Loss." />{' '}
      <span>as of {lastMonthFormatted}</span>
    </div>
  )
}

export default MiniBurnRateCard
