import React, { useState } from 'react'
import { DocumentsContext } from '../documents.context'
import { IAsset } from '~/src/types/asset'

const DocumentsContextProvider = ({
  children,
}: React.HTMLAttributes<HTMLElement>) => {
  const [searchTerm, setSearchTerm] = useState<string>('')

  // State management for uploading documents.
  const [document, setDocument] = useState<IAsset | undefined>()
  const [file, setFile] = useState<Uint8Array | undefined>()
  const [isUploadModalOpen, setUploadModalOpen] = useState<boolean>(false)

  const [uploading, setUploading] = useState(false)

  // Document viewer modal
  const [isModalOpen, setModalOpen] = useState(false)

  const [page, setPage] = useState(0)

  const handlePageClick = (event: { selected: number }) => {
    setPage?.(event.selected)
  }

  return (
    <DocumentsContext.Provider
      value={{
        uploading,
        setUploading,
        setUploadModalOpen,
        isUploadModalOpen,
        searchTerm,
        setSearchTerm,
        page,
        setPage,
        handlePageClick,
        document,
        setDocument,
        file,
        setFile,
        isModalOpen,
        setModalOpen,
      }}
    >
      {children}
    </DocumentsContext.Provider>
  )
}

export default DocumentsContextProvider
