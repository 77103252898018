import React from 'react'
import Button from '~/src/components/button'
import { FlowIcons } from '~/src/assets/flow-icons'

const BillsInvoicesSettingsButton = ({
  setBillsInvoicesSettingsOpen,
}: {
  setBillsInvoicesSettingsOpen: (arg: boolean) => void
}) => {
  return (
    <Button
      label="show settings"
      className="ml-auto relative -right-4"
      theme="text"
      icon={FlowIcons.Dots}
      iconPosition="left"
      iconColor="gray"
      iconOnly
      size="medium"
      onClick={() => setBillsInvoicesSettingsOpen(true)}
    />
  )
}

export default BillsInvoicesSettingsButton
