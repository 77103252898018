import { toast, ToastOptions } from 'react-toastify'
import Classnames from 'classnames'

export interface NotifyOptions {
  type: 'success' | 'warning' | 'error' | 'info'
  className?: ToastOptions['className']
}

export const notify = (message: string, options: NotifyOptions) => {
  const ToastifyOptions: ToastOptions = {
    type: options.type,
    theme: 'colored',
    className: Classnames(
      'toast',
      options.className
        ? {
            [options.className as string]: !!options.className,
          }
        : {}
    ),
  }

  return toast(message, ToastifyOptions)
}
