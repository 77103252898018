import * as Types from '../../../types/generated/index';

import { gql } from '@apollo/client';
export type UserFragment = { __typename?: 'User', avatar?: string | null, first_name?: string | null, id: string, last_name?: string | null, state?: Types.States | null, type?: string | null };

export const UserFragmentDoc = gql`
    fragment UserFragment on User {
  avatar
  first_name
  id
  last_name
  state
  type
}
    `;