import React from 'react'
import ZeroStateImg from '~/src/assets/images/action-items-zero-state.svg'
import './action-items-zero-state.css'

export const ActionItemsZeroState = (): JSX.Element => {
  return (
    <div className="action-items-zero-state">
      <object type="image/svg+xml" data={ZeroStateImg}>
        Empty inbox
      </object>
      <p className="text-black text-lg font-bold">
        There are no action items for you to review.
      </p>
      <p className="text-black text-lg font-bold">
        Everything is under control!
      </p>
    </div>
  )
}
