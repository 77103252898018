import { SignatureV4MultiRegion } from "@aws-sdk/signature-v4-multi-region";
import { parseUrl } from "@aws-sdk/url-parser";
import { defaultRegionInfoProvider } from "./endpoints";
export const getRuntimeConfig = (config) => ({
    apiVersion: "2006-03-01",
    disableHostPrefix: config?.disableHostPrefix ?? false,
    logger: config?.logger ?? {},
    regionInfoProvider: config?.regionInfoProvider ?? defaultRegionInfoProvider,
    serviceId: config?.serviceId ?? "S3",
    signerConstructor: config?.signerConstructor ?? SignatureV4MultiRegion,
    signingEscapePath: config?.signingEscapePath ?? false,
    urlParser: config?.urlParser ?? parseUrl,
    useArnRegion: config?.useArnRegion ?? false,
});
