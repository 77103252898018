import Classnames from 'classnames'
import { formatDistanceToNow, isFuture } from 'date-fns'
import React, { useState } from 'react'
import { FlowIcons } from '~/src/assets/flow-icons'
import Button from '~/src/components/button'
import SVGHandler from '~/src/components/svg-handler'
import useCurrentUser from '~/src/hooks/use-current-user'
import {
  ActionItemCategory,
  ActionItemState,
  IActionItem,
} from '~/src/types/action-items'
import ActionItemForm from './action-item-form'
import ActionItemNotification from './action-item-notification'
import useMerchantOptions from '~/src/hooks/use-merchant-options'
import useAccountOptions from '~/src/hooks/use-account-options'

interface IActionItemProps {
  item: IActionItem
}

const getReply = (actionItem: IActionItem) => {
  if (actionItem.category === ActionItemCategory.amortization) {
    return actionItem.amortization_responses?.[0]
  }
  if (actionItem.category === ActionItemCategory.categorization) {
    return actionItem.categorization_responses?.[0]
  }
  if (actionItem.category === ActionItemCategory.miscellaneous) {
    return actionItem.miscellaneous_responses?.[0]
  }
}

const ActionItem = ({ item }: IActionItemProps): JSX.Element => {
  const { currentUserId } = useCurrentUser()
  const [showForm, setShowForm] = useState<boolean>(false)

  const {
    options: merchantOptions,
    merchants,
    loading: merchantsLoading,
  } = useMerchantOptions()
  const {
    options: accountOptions,
    accounts,
    loading: accountsLoading,
  } = useAccountOptions()
  const formData = { merchantOptions, accountOptions, merchants, accounts }

  // If we try to render the form without this information,
  // we can't correctly set the values of the merchant or account dropdowns
  // and existing categorization replies will appear blank.
  const formDataLoading = merchantsLoading || accountsLoading || !currentUserId

  const markup = () => {
    return {
      __html: item.question,
    }
  }

  const reply = getReply(item)

  const dueDate = item.due_date && new Date(parseInt(item.due_date))
  const timeUntilDue =
    dueDate &&
    formatDistanceToNow(dueDate, {
      addSuffix: true,
    })
  const overdue = dueDate && !isFuture(dueDate)

  const toDo = item.state === ActionItemState.awaiting_reply
  const done = item.state === ActionItemState.ready_for_action

  const replyClasses = Classnames('action-item__reply', {
    'action-item__reply--form-visible': showForm || done,
  })

  const displayCategory =
    item.category === 'amortization' ? 'service period' : item.category

  return (
    <div className="action-item">
      <div className="p-4">
        <div className="action-item__category">
          <SVGHandler
            icon={FlowIcons.Categorization}
            size="small"
            color="primary"
          />
          <span className="capitalize font-semibold">{displayCategory}</span>
        </div>
        <div
          className="action-item__question"
          dangerouslySetInnerHTML={markup()}
        ></div>
      </div>

      <div className={replyClasses}>
        {!showForm && (
          <>
            {done && !formDataLoading && (
              <ActionItemForm
                currentUserId={currentUserId}
                readOnly={true}
                actionItem={item}
                reply={reply}
                {...formData}
              />
            )}

            <div className="action-item__status">
              {toDo && overdue && <ActionItemNotification type="overdue" />}
              {toDo && !overdue && (
                <div className="action-item__due-date">Due {timeUntilDue}</div>
              )}
              {done && <ActionItemNotification type="submitted" />}
            </div>

            {!done && (
              <Button
                className="ml-auto pr-0"
                theme="text"
                icon={FlowIcons.Reply}
                iconPosition="left"
                iconColor="primary"
                label="Reply"
                onClick={() => setShowForm(!showForm)}
                size="medium"
              />
            )}
          </>
        )}
        {showForm && !formDataLoading && (
          <ActionItemForm
            currentUserId={currentUserId}
            actionItem={item}
            reply={reply}
            onClose={() => setShowForm(false)}
            {...formData}
          />
        )}
      </div>
    </div>
  )
}

export default ActionItem
