import React from 'react'
import {
  isAfter,
  isBefore,
  startOfWeek,
  endOfWeek,
  isFirstDayOfMonth,
  startOfMonth,
  isSameDay,
  isSameMonth,
  eachDayOfInterval,
} from 'date-fns'
import Classnames from 'classnames'

// display two weeks.
// whenever a banner is showing, the two week period does not change.
// first day of month is highlighted.
// days from 1st until due date are highlighted.

interface ICalendar {
  startDate: Date
  dueDate: Date
}

const Calendar = ({ startDate, dueDate }: ICalendar): JSX.Element => {
  const weekdays = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
  const today = new Date()
  const start = startOfWeek(startDate)
  const end = endOfWeek(dueDate)
  const days = eachDayOfInterval({ start, end })

  const createCells = (days: Date[]) => {
    return days.map((day, i) => {
      const dayClasses = Classnames('day', {
        'day--first': isFirstDayOfMonth(day) && isBefore(day, dueDate),
        'day--range':
          isAfter(day, startOfMonth(dueDate)) && isBefore(day, dueDate),
        'day--last': isSameDay(day, dueDate),
        'day--today': isSameDay(day, today),
        'day--last-month': !isSameMonth(day, dueDate),
      })

      return (
        <div className={dayClasses} key={i}>
          <div className="day__inner">{day.getDate()}</div>
        </div>
      )
    })
  }

  return (
    <div className="bookkeeping-calendar">
      <div className="bookkeeping-calendar__grid">
        {weekdays.map(day => (
          <div className="day opacity-60 font-semibold" key={day}>
            <div className="day__inner">{day}</div>
          </div>
        ))}
        {createCells(days)}
      </div>
    </div>
  )
}

export default Calendar
