import React from 'react'
import InlineMenu from '~/src/components/inline-menu'
import { TIconColors } from '~/src/components/svg-handler'
import { IAsset } from '~/src/types/asset'
import { download } from '~/src/utilities/s3'
import useModal from '~/src/hooks/use-modal'
import { useNavigate } from 'react-router'
import { notify } from '~/src/utilities/notify'

interface IAssetMenu {
  document: IAsset
  iconColor: TIconColors
  borderless?: boolean
}

const AssetMenu = ({ iconColor, borderless, document }: IAssetMenu) => {
  const navigate = useNavigate()
  const id = document.id

  const { setModalOpen } = useModal()

  return (
    <InlineMenu
      iconColor={iconColor}
      borderless={borderless}
      testId="file-inline-menu-button"
    >
      <button
        aria-label="view file"
        key="view"
        onClick={() => navigate(`/documents/file/${id}`)}
        className="menu__item"
      >
        View File
      </button>

      <button
        aria-label="edit file"
        key="edit"
        onClick={() => setModalOpen({ name: 'editAsset', id })}
        className="menu__item"
        data-test="edit-file-button"
      >
        Edit File
      </button>

      <button
        aria-label="download file"
        key="download"
        onClick={() => download(document)}
        className="menu__item"
      >
        Download
      </button>

      <button
        aria-label="share"
        key="share"
        onClick={() => {
          navigator.clipboard.writeText(
            `${window.location.origin}/#/documents/file/${id}`
          )
          notify('Copied share URL!', { type: 'success' })
        }}
        className="menu__item"
      >
        Share
      </button>

      <button
        aria-label="move file"
        key="move"
        onClick={() => setModalOpen({ name: 'moveAsset', id })}
        className="menu__item"
      >
        Move to ...
      </button>

      <button
        aria-label="delete file"
        key="delete"
        onClick={() => setModalOpen({ name: 'deleteAsset', id })}
        className="menu__item"
        data-test="delete-file-button"
      >
        Delete File
      </button>
    </InlineMenu>
  )
}

export default AssetMenu
