import { ISelectOption } from '../types/forms'
import { validateEmail } from '~/src/utilities/string'

// const FILE_SIZE_LIMIT = 5;
const ACCEPTED_FILE_TYPES = ['image/png', 'image/jpg', 'image/jpeg']

const validateAvatarSize = () =>
  // data: File
  {
    // console.log(data.size);
    // if (data.size / 1000 > FILE_SIZE_LIMIT) {
    //   return "File is larger than 5MB. Please resize your file and try again.";
    // }
    return true
  }

const validateAvatarType = (data: File) => {
  if (!ACCEPTED_FILE_TYPES.includes(data.type)) {
    return "This file type isn't valid."
  }
  return true
}

const requiredSelectOption = (option: ISelectOption): string | true =>
  !!option?.id || 'Please select an option'

export const FORMS = {
  VALIDATION: {
    REQUIRED_FIELD: 'This field is required',
    MIN_LENGTH: {
      value: 2,
      message: 'Min 2 characters.',
    },
    MAX_LENGTH: {
      value: 50,
      message: 'Max 50 characters.',
    },
    AVATAR: {
      validate: { type: validateAvatarType, size: validateAvatarSize },
    },
    // While the default "required field" validation does *seem* to work for our selects, it's
    // recommended to explicitly use a custom validator for objects and arrays, so we are!
    REQUIRED_SELECT: {
      validate: requiredSelectOption,
    },
    EMAIL: {
      validate: (email: string) => validateEmail(email) || 'Not a valid email',
    },
  },
}
