import { IAccount } from '~/src/types/account'
import { sumBy } from 'lodash'
import { useMemo } from 'react'
import { sumAccounts, getAccountChildren } from '~/src/utilities/accounts'

const excludeParents = (list: IExpenseAccountSum[]) =>
  list.filter(
    account => !list.find(({ id }) => id && account.childIds.includes(id))
  )

interface IExpenseAccountSum {
  childIds: string[]
  displayName: string
  name: string
  id: string
  parentId?: string
  value: number
}

export const getExpenseDistribution = ({
  accounts,
  sumsByAccount,
}: {
  accounts: IAccount[]
  sumsByAccount: { id: string; sum: number }[]
}) => {
  // The total is all expenses, for all accounts, for the given period.
  const totalForPeriod = sumBy(sumsByAccount, 'sum')
  // We want to show expenses that meet or exceed the cutoff of 10%.
  const cutoff = totalForPeriod / 10

  const sumAccum: IExpenseAccountSum[] = []

  // For each account, see if that meets the cutoff.
  const qualifyingAccounts: IExpenseAccountSum[] = accounts.reduce(
    (acc: typeof sumAccum, parent: IAccount) => {
      // A flattened list of the account and its children.
      const children = getAccountChildren(parent, accounts)

      // Match the accounts to the dictionary of total balances.
      const bigSum = sumAccounts([parent, ...children], sumsByAccount)

      if (bigSum < cutoff) {
        return acc
      }

      return [
        ...acc,
        {
          childIds: children.map(({ id }) => id),
          name: parent.name,
          displayName: children.length ? `Total ${parent.name}` : parent.name,
          id: parent.id,
          parentId: parent.parentId,
          value: bigSum,
        },
      ] as IExpenseAccountSum[]
    },
    sumAccum
  )

  // Now, filter the accounts to exclude any parents of other qualifying accounts.
  const accountsExcludingParents = excludeParents(qualifyingAccounts)

  const totalOther = totalForPeriod - sumBy(accountsExcludingParents, 'value')

  const donutGraphData = [
    ...accountsExcludingParents.map(({ displayName: name, value }) => ({
      name,
      value,
    })),
    {
      name: 'Other',
      value: totalOther,
    },
  ]

  return {
    accountsWithSums: accountsExcludingParents,
    sumTotal: totalForPeriod,
    donutGraphData,
  }
}

interface IUseExpenseDistributionArgs {
  accounts?: IAccount[]
  sumsByAccount: { id: string; sum: number }[]
}

const useExpenseDistribution = ({
  accounts: _accounts,
  sumsByAccount,
}: IUseExpenseDistributionArgs) => {
  const memoized = useMemo(
    () => getExpenseDistribution({ accounts: _accounts || [], sumsByAccount }),
    [_accounts, sumsByAccount]
  )

  return memoized
}

export default useExpenseDistribution
