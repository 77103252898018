import React from 'react'
import Modal from '~/src/components/modal'
import SnapShot from '~/src/components/snapshot'
import { IChartRefs } from '~/src/types/graphs'
import { snapshotModalOpen } from '~/src/cache'
import { useReactiveVar } from '@apollo/client'

export const GRAPH_REFS = {
  topPaidVendors: React.createRef<HTMLDivElement>(),
  topExpenses: React.createRef<HTMLDivElement>(),
  revenue: React.createRef<HTMLDivElement>(),
  expenseDistribution: React.createRef<HTMLDivElement>(),
  grossMargin: React.createRef<HTMLDivElement>(),
  burnRate: React.createRef<HTMLDivElement>(),
  balances: React.createRef<HTMLDivElement>(),
}

const SnapshotModal = ({ graphRefs }: { graphRefs: IChartRefs }) => {
  const modalOpen = useReactiveVar(snapshotModalOpen)
  return (
    <Modal
      open={modalOpen}
      onClose={() => snapshotModalOpen(false)}
      title="Snapshots"
    >
      <SnapShot graphRefs={graphRefs} />
    </Modal>
  )
}

export default SnapshotModal
