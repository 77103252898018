import Classnames from 'classnames'
import React from 'react'
import { ICompany } from '~/src/types/company'
import './company-icon.css'

type CompanyIconSizes = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl'

interface ICompanyIcon {
  size: CompanyIconSizes
  company?: ICompany
}

const getInitials = (company?: ICompany) => {
  if (!company?.name) {
    return
  }

  const initials: string[] = []

  company.name?.split(' ').map(word => {
    initials.push(word.charAt(0))
  })

  return initials.slice(0, 2).join('')
}

const CompanyIcon = ({ company, size }: ICompanyIcon): JSX.Element => {
  const companyInitials = getInitials(company)

  const companyClasses = Classnames('company-icon', {
    'company-icon--xs': size === 'xs',
    'company-icon--sm': size === 'sm',
    'company-icon--md': size === 'md',
    'company-icon--lg': size === 'lg',
    'company-icon--xl': size === 'xl',
    'company-icon--2xl': size === '2xl',
    'company-icon--3xl': size === '3xl',
  })

  return (
    <div className={companyClasses}>
      {company?.avatar ? (
        <img src={company.avatar} alt="company avatar" />
      ) : (
        <span>{companyInitials}</span>
      )}
    </div>
  )
}

export default CompanyIcon
