import React from 'react'
import PieChart from '~/src/components/graphs/pie'
import useExpenseDistribution from '~/src/hooks/use-expense-distribution'
import useTopExpenseAccounts from '~/src/hooks/use-top-expense-accounts'
import Loader from '~/src/components/loader'
import useAccounts from '~/src/hooks/use-accounts'
import { AccountType } from '~/src/types/account'

const ExpenseDistributionCard = ({
  startDate,
  endDate,
}: {
  startDate: Date
  endDate: Date
}) => {
  const { accounts } = useAccounts({ accountTypes: [AccountType.EXPENSE] })

  const { sumsByAccount, loading } = useTopExpenseAccounts({
    startDate,
    endDate,
  })

  const { donutGraphData } = useExpenseDistribution({
    sumsByAccount,
    accounts,
  })

  return (
    <>
      {loading ? (
        <Loader size="lg" centered />
      ) : (
        <PieChart id="expense-distribution" data={donutGraphData} />
      )}
    </>
  )
}

export default ExpenseDistributionCard
