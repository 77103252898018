import { useQuery, gql } from '@apollo/client'
import GET_ACTION_ITEMS_QUERY from '~/src/graphql/queries/action-items.graphql'
import { IActionItem, ActionItemState } from '~/src/types/action-items'
import useCurrentUser from '~/src/hooks/use-current-user'
import { isFuture } from 'date-fns'

const isOverdue = (item: IActionItem) => {
  if (!item.due_date) {
    return false
  }
  const dueDate = new Date(parseInt(item.due_date))

  return !isFuture(dueDate)
}

const useActionItems = () => {
  const { currentCompanyId } = useCurrentUser()

  const { data, loading } = useQuery(gql(GET_ACTION_ITEMS_QUERY), {
    variables: {
      company_id: currentCompanyId,
      states: [
        ActionItemState.awaiting_reply,
        ActionItemState.ready_for_action,
      ],
    },
  })

  const actionItems: IActionItem[] = data?.actionItems || []

  // The customer replied to this action item. Now a bookkeeper will take the appropriate action and close it.
  const completed = actionItems.filter(
    ai => ai.state === ActionItemState.ready_for_action
  )
  // These action items are awaiting our input: a "reply".
  const awaitingReply = actionItems.filter(
    ai => ai.state === ActionItemState.awaiting_reply
  )

  // Of the action items awaiting our input, filter by due date.
  const late = awaitingReply.filter(ai => isOverdue(ai))
  const todo = awaitingReply.filter(ai => !isOverdue(ai))

  return { actionItems: { all: actionItems, completed, todo, late, loading } }
}

export default useActionItems
