// regex matches space, -, _, tab, new line, and . characters
const splitSting = (string: string) =>
  string.split(/[ \-_\t\n\r.]/g).filter(word => !!word)

export const capitalizeFirstLetter = (string: string): string => {
  if (!string) return ''
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const convertToPascalCase = (string: string): string => {
  if (!string) return ''
  return splitSting(string).reduce(
    (accum, word) => accum + capitalizeFirstLetter(word.toLowerCase()),
    ''
  )
}

export const convertToCamelCase = (string: string): string => {
  if (!string) return ''
  return splitSting(string).reduce(
    (accum, word, idx) =>
      accum +
      (idx ? capitalizeFirstLetter(word.toLowerCase()) : word.toLowerCase()),
    ''
  )
}

export const convertToKebabCase = (string: string): string => {
  if (!string) return ''
  return splitSting(string).reduce(
    (accum, word, idx) => accum + (idx ? '-' : '') + word.toLowerCase(),
    ''
  )
}

export const convertToTitleCase = (string: string): string => {
  if (!string) return ''
  return splitSting(string).reduce(
    (accum, word, idx) =>
      accum + (idx ? ' ' : '') + capitalizeFirstLetter(word.toLowerCase()),
    ''
  )
}

export const validateEmail = (string: string): boolean => {
  // TODO: Refine this regex.
  const regex = new RegExp(
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  )

  return regex.test(string)
}
