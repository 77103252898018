import React from 'react'
import { NavLink } from 'react-router-dom'
import { TRouteDefinition } from '~/src/constants/routes'
import './tabset.css'

interface ITabSet {
  routes: { [key: string]: TRouteDefinition }
}

const TabSet = ({ routes }: ITabSet): JSX.Element => {
  const tabRoutes = Object.entries(routes)

  return (
    <ul className="tabset">
      {tabRoutes.map((route, index) => {
        return (
          <li className="tabset__item" key={`route-${index}`}>
            <NavLink
              aria-label={route[1].name}
              data-test={`tabset-link-${route[1].name.toLowerCase()}`}
              to={route[1].path}
              className={({ isActive }) =>
                isActive ? 'tabset__link tabset__link--active' : 'tabset__link'
              }
            >
              {route[1].name}
            </NavLink>
          </li>
        )
      })}
    </ul>
  )
}

export default TabSet
