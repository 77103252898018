import React from 'react'
import { ToastContainer, ToastPosition } from 'react-toastify'
import useResponsive from '~/src/hooks/use-responsive'

const NotificationContainer = () => {
  const { isDesktop } = useResponsive()

  const config = {
    bodyClassName: 'toast__body',
    hideProgressBar: false,
    pauseOnHover: true,
    autoClose: 3500,
    closeButton: true,
    draggable: false,
    closeOnClick: true,
    position: isDesktop
      ? ('top-center' as ToastPosition)
      : ('bottom-center' as ToastPosition),
  }

  return <ToastContainer {...config} />
}

export default NotificationContainer
