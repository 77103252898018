export const getFromLocalStorage = ({
  key,
  path,
}: {
  key: string
  path?: string
}) => {
  const storedValues = localStorage.getItem(key) ?? undefined
  if (!storedValues) {
    return
  }

  const parsed = JSON.parse(storedValues)

  return path ? parsed[path] : parsed
}

export const setInLocalStorage = ({
  key,
  path,
  value,
}: {
  key: string
  path: string
  value: any
}) => {
  const existing = getFromLocalStorage({ key }) || {}
  const newValue = { ...existing, [path]: value }

  localStorage.setItem(key, JSON.stringify(newValue))
}
