import React from 'react'
import EditForm from '../../../components/edit-form'
import { useUpdateFolderMutation } from '~/src/graphql/mutations/generated/update-folder'
import useCurrentUser from '~/src/hooks/use-current-user'
import { FORMS } from '~/src/constants/forms'
import {
  FolderShallowFragment,
  FolderShallowFragmentDoc,
} from '~/src/graphql/fragments/generated/folder-shallow'
import useFragment from '~/src/hooks/use-fragment'
import Modal from '~/src/components/modal'
import useModal from '~/src/hooks/use-modal'
import { notify } from '~/src/utilities/notify'

type FormData = {
  title: string
}

const fields = {
  title: {
    required: FORMS.VALIDATION.REQUIRED_FIELD,
  },
}

export const EditFolder = ({
  className,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    closeModal: onClose,
    show,
    modalId: id,
  } = useModal({
    name: 'editFolder',
  })

  const fragment = FolderShallowFragmentDoc

  const item = useFragment<FolderShallowFragment>({
    fragment,
    id: `Folder:${id}`,
  })

  const { currentCompanyId } = useCurrentUser()

  const defaultValues = item
    ? {
        title: item.title,
      }
    : null

  const [updateFolder] = useUpdateFolderMutation()

  const onSubmit = async ({ title }: FormData) => {
    if (!currentCompanyId || !item) {
      return
    }

    try {
      await updateFolder({
        variables: {
          id: item.id,
          company_id: currentCompanyId,
          input: {
            name: title,
          },
        },
      })
    } catch (e) {
      console.log("Error in EditFolder", e); /* eslint-disable-line */
      notify('An error occurred.', { type: 'error' })
    }

    onClose()
  }

  return (
    <Modal open={show} onClose={onClose}>
      {item && defaultValues && (
        <EditForm
          title={`Edit "${item?.title}"`}
          onSubmit={onSubmit}
          className={className}
          defaultValues={defaultValues}
          fields={fields}
        />
      )}
    </Modal>
  )
}

export default EditFolder
