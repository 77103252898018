import * as Types from '../../../types/generated/index';

import { gql } from '@apollo/client';
import { AccountFragmentDoc } from '../../fragments/generated/account';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountsQueryVariables = Types.Exact<{
  company_id: Types.Scalars['ID'];
  account_type?: Types.InputMaybe<Array<Types.AccountType> | Types.AccountType>;
}>;


export type AccountsQuery = { __typename?: 'Query', accounts: Array<{ __typename?: 'Account', accountNumber?: string | null, accountSubTypeEnum?: string | null, accountType?: string | null, active?: boolean | null, category?: string | null, companyId?: string | null, id?: string | null, last4?: string | null, name?: string | null, number?: string | null, parentId?: string | null, s3Key?: string | null, type?: string | null }> };


export const AccountsQueryDocument = gql`
    query AccountsQuery($company_id: ID!, $account_type: [AccountType!]) {
  accounts(company_id: $company_id, account_type: $account_type) {
    ...AccountFragment
  }
}
    ${AccountFragmentDoc}`;

/**
 * __useAccountsQuery__
 *
 * To run a query within a React component, call `useAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountsQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *      account_type: // value for 'account_type'
 *   },
 * });
 */
export function useAccountsQuery(baseOptions: Apollo.QueryHookOptions<AccountsQuery, AccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountsQuery, AccountsQueryVariables>(AccountsQueryDocument, options);
      }
export function useAccountsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountsQuery, AccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountsQuery, AccountsQueryVariables>(AccountsQueryDocument, options);
        }
export type AccountsQueryHookResult = ReturnType<typeof useAccountsQuery>;
export type AccountsQueryLazyQueryHookResult = ReturnType<typeof useAccountsQueryLazyQuery>;
export type AccountsQueryQueryResult = Apollo.QueryResult<AccountsQuery, AccountsQueryVariables>;