import React, { useState } from 'react'
import SVGHandler from '~/src/components/svg-handler'
import Classnames from 'classnames'
import { FlowIcons } from '~/src/assets/flow-icons'
import { InputProps } from '~/src/types/forms'

const Password = React.forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    const inputClasses = Classnames('form-field', {
      'form-field--invalid': props.error,
      'form-field--disabled': props.disabled,
    })

    const { testId, ...rest } = props

    const [showPassword, setShowPassword] = useState<boolean>(false)

    return (
      <div className={inputClasses}>
        <label htmlFor={props.name} className="form-field__label">
          {props.label}
        </label>
        <div className="relative">
          <input
            className="form-field__input"
            type={showPassword ? 'text' : 'password'}
            ref={ref}
            data-test={testId}
            {...rest}
          />

          <div className="form-field__password-toggle">
            <SVGHandler
              size="medium"
              icon={showPassword ? FlowIcons.Eye : FlowIcons.EyeSlash}
              color={showPassword ? 'primary' : 'gray'}
              title={showPassword ? 'Hide Password' : 'Show Password'}
            />

            <input
              type="checkbox"
              checked={showPassword}
              onChange={e => setShowPassword(e.target.checked)}
            />
          </div>
        </div>
        <p className="form-field__error">{props.error}</p>
      </div>
    )
  }
)

export default Password
