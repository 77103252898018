import React from 'react'
import Button from '~/src/components/button'
import { validateEmail } from '~/src/utilities/string'
import SVGHandler from '~/src/components/svg-handler'
import { FlowIcons } from '~/src/assets/flow-icons'
import './reset-password.css'
import { useMutation, gql } from '@apollo/client'
import CREATE_PASSWORD_RESET_MUTATION from '~/src/graphql/mutations/create-password-reset.graphql'
import { useForm } from 'react-hook-form'
import { FORMS } from '~/src/constants/forms'
import { FormFields } from '../form-fields'

interface IResetPassword {
  onConfirm?: () => void
}

type FormData = {
  email: string
}

const ResetPassword = ({ onConfirm }: IResetPassword): JSX.Element => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    mode: 'onSubmit',
  })

  const [createPasswordReset] = useMutation(gql(CREATE_PASSWORD_RESET_MUTATION))

  const onSubmit = async (data: FormData) => {
    const response = await createPasswordReset({
      variables: {
        address: data.email,
        baseUrl: window.location.origin,
      },
    })

    if (response.data && !response.data.error) {
      onConfirm?.()
    }
  }

  const { email } = watch()

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="reset-password">
      <p>
        We will send a link to your email to reset the password to your account.
      </p>
      <div className="relative">
        <FormFields.Text
          label="Email Address"
          type="email"
          {...register('email', {
            required: FORMS.VALIDATION.REQUIRED_FIELD,
          })}
          error={errors.email?.message}
        />

        {validateEmail(email) && (
          <SVGHandler
            className="email-valid"
            size="medium"
            icon={FlowIcons.Check}
            color="success"
          />
        )}
      </div>
      <Button
        type="submit"
        label="Reset Password"
        theme="primary"
        size="medium"
        className="reset-password__confirm"
      />
    </form>
  )
}

export default ResetPassword
