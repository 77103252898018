import { ICompany } from '../types/company'
import useCurrentUser from './use-current-user'

// The names of features that work with this hook. Add yours to this list.
type FEATURES = 'showFolders'

// Stuff we need to pass to the function
interface FeatureFlagDeps {
  currentCompany: ICompany & { rootFolder: { id: string } }
}

// The function that defines our conditions (probably mostly "if process.env.MY_FEATURE === " ...)
const getConditions = ({ currentCompany }: FeatureFlagDeps) => ({
  showFolders: !!currentCompany?.rootFolder?.id,
})

// Got an exciting new way to gate a feature, like url params? Stick it in here! This is just
// an attempt to stop writing "if (process.env.MY_FEATURE_NAME)" a million times and thus inevitably
// mis-typing it.
export const useFeatureFlag = (myFeatureName: FEATURES) => {
  const { currentCompany } = useCurrentUser()

  const conditions: Record<FEATURES, boolean> = getConditions({
    currentCompany,
  })

  return conditions[myFeatureName]
}
