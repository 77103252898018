const ACCENT_COLORS = [
  '#436CFF',
  '#2FD9BA',
  '#FACC15',
  '#FF457D',
  '#718096',
  '#A0AEC0',
  '#CBD5E0',
  '#95ADFF',
  '#BFCDFF',
  '#E8EDFF',
]

export default ACCENT_COLORS
