/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'
import GRAPH_COLORS from '~/src/constants/graph-colors'
import { formatCurrency } from '~/src/utilities/currency'

interface IPieChart {
  data: any
  id: string
}

const PieChart = ({ data, id }: IPieChart) => {
  const { series, labels } = data.reduce(
    // TODO: fix typing here
    (acc: any, datum: any) => {
      return {
        series: [...acc.series, datum.value],
        labels: [...acc.labels, datum.name],
      }
    },
    { series: [], labels: [] }
  )
  const options: ApexOptions = {
    chart: {
      type: 'donut',
      fontFamily: 'Manrope',
    },
    legend: {
      showForSingleSeries: true,
      position: 'bottom',
      fontSize: '12px',
      markers: {
        width: 10,
        height: 10,
        fillColors: GRAPH_COLORS,
        radius: 4,
      },
      labels: {
        colors: '#718096',
      },
      onItemClick: {
        toggleDataSeries: false,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      custom: ({
        series,
        seriesIndex,
        w,
      }: {
        series: ApexNonAxisChartSeries
        seriesIndex: number
        w: any
      }) => {
        const color = w.config.fill.colors[seriesIndex]
        const total: number = series.reduce((a: number, b: number) => a + b, 0)
        const label = labels?.[seriesIndex]
        const selected = series[seriesIndex]
        return `<div class="graph__tooltip">
          <div class="graph__tooltip-item">
            <div class="graph__marker" style="background-color: ${color}"></div>
            <div class="graph__tooltip-label">${label}:</div> 
            <div class="graph__tooltip-value">
              ${((selected / total) * 100).toFixed(2)}%
            </div>
          </div>
        </div>`
      },
    },
    fill: {
      colors: GRAPH_COLORS,
      opacity: 1,
    },
    stroke: {
      colors: ['#FFFFFF'],
      width: 8,
    },
    states: {
      hover: {
        filter: { type: 'none' },
      },
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: '75%',
          background: 'transparent',
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: '12px',
              color: '#718096',
              offsetY: 20,
              formatter: function (val: string) {
                return val.length > 20 ? `${val.substring(0, 17)}...` : val
              },
            },
            value: {
              show: true,
              fontSize: '22px',
              fontWeight: 600,
              color: '#1A202C',
              offsetY: -15,
              formatter: function (val: string) {
                return `${formatCurrency(val, { notation: 'compact' })}`
              },
            },
            total: {
              show: true,
              showAlways: false,
              label: 'Total Expenses',
              fontSize: '12px',
              color: '#718096',
              formatter: function (w: { config: ApexCharts; globals: any }) {
                const total = w.globals.seriesTotals.reduce(
                  (a: number, b: number) => {
                    return a + b
                  },
                  0
                )

                return `${formatCurrency(total, { notation: 'compact' })}`
              },
            },
          },
        },
      },
    },
    labels,
  }

  return (
    <ReactApexChart
      id={id}
      options={options}
      series={series}
      type="donut"
      width="100%"
      height={300}
    />
  )
}

export default PieChart
