import { useQuery, gql } from '@apollo/client'
import GET_BILLS_QUERY from '~/src/graphql/queries/invoices.graphql'
import useCurrentUser from './use-current-user'
import { IInvoice } from '../types/invoices'
import { differenceInDays, parse } from 'date-fns'
import { QBO_DATE_FORMAT } from '~/src/pages/dashboard/config'

interface IUseInvoicesResponse {
  invoices: IInvoice[]
  loading: boolean
}

const useInvoices = ({
  minBalance,
  minDaysOverdue,
}: {
  minBalance: number
  minDaysOverdue: number
}): IUseInvoicesResponse => {
  const { currentCompanyId } = useCurrentUser()
  const { data, loading } = useQuery(gql(GET_BILLS_QUERY), {
    variables: { company_id: currentCompanyId },
    skip: !currentCompanyId,
  })

  const daysOverdue = (invoice: IInvoice) => {
    const startDate = parse(invoice.due_date, QBO_DATE_FORMAT, new Date())

    const interval = differenceInDays(startDate, new Date())

    return Math.abs(interval)
  }

  const inRange = (days: number | undefined): boolean => {
    // considers an invoice to be within range if minDays is 0 ("all") or if we couldn't figure
    // out a value for days_overdue
    if (minDaysOverdue === 0 || !days) return true

    return days >= minDaysOverdue
  }

  const invoices: IInvoice[] = data?.invoices.map((invoice: IInvoice) => ({
    ...invoice,
    days_overdue: daysOverdue(invoice),
  }))

  const filteredInvoices = invoices?.filter(invoice => {
    return (
      inRange(invoice.days_overdue) && invoice.remaining_balance >= minBalance
    )
  })

  return {
    invoices: filteredInvoices || [],
    loading,
  }
}

export default useInvoices
