import { SortOrder } from '../types/tables'

type TSortableField = number | undefined | null | string

const compareFields = (a: TSortableField, b: TSortableField) => {
  // Handle numbers. Zero is falsey so the null check below would be weird.
  if ('number' === typeof a && 'number' === typeof b) {
    return a - b
  }

  // Handle non-numerical falsey values. Really only meaningful for strings,
  // but if you try to sort arrays that's on you bud
  if (!a || !b) return 0
  if (!a) return 1
  if (!b) return -1

  // Bail out if it's anything but a string.
  if ('string' !== typeof a || 'string' !== typeof b) {
    return 0 // not sortable. When I can figure out how to specify my type correctly, I won't need this guard.
  }

  return a.localeCompare(b, 'en', {
    numeric: true,
  })
}

// Case sensitive.
export const compareSortableFields =
  <T extends Record<string, TSortableField>>({
    sortField,
    sortOrder,
  }: {
    sortField: keyof T
    sortOrder: SortOrder
  }) =>
  (a: T, b: T) => {
    const valueA = a[sortField]
    const valueB = b[sortField]

    const direction = sortOrder === SortOrder.ASC ? 1 : -1

    return compareFields(valueA, valueB) * direction
  }
