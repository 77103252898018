import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useCurrentUser from '~/src/hooks/use-current-user'
import ROUTES from '~/src/constants/routes'
import { useCurrentUserQuery } from '~/src/graphql/queries/generated/current-user'

const DirectCompanyRoutingPage = (): JSX.Element => {
  const { companyId, '*': splat } = useParams()
  const navigate = useNavigate()
  const { currentUserId, currentUser, setCompany } = useCurrentUser()

  const { data } = useCurrentUserQuery({
    variables: { company_id: companyId, user_id: currentUserId || '' },
    skip: !currentUserId,
    errorPolicy: 'all',
  })

  useEffect(() => {
    // You have a company membership,
    // or you're an internal user and the company exists.
    if (data?.company?.id) {
      setCompany(data.company.id)
      return navigate(splat ? `/${splat}` : ROUTES.dashboard.path)
    }

    // We loaded the company, and it doesn't exist -- for you, at least.
    if (data?.company === null) {
      setCompany(undefined)
      navigate(ROUTES.companyPicker.path)
    }
  }, [splat, companyId, currentUser, navigate, setCompany, data?.company])

  return <div></div>
}

export default DirectCompanyRoutingPage
