import React from 'react'
import Workspace from '~/src/components/workspace'
import SVGHandler from '~/src/components/svg-handler'
import { FlowIcons } from '~/src/assets/flow-icons'
import './page-header.css'
import { snapshotModalOpen } from '~/src/cache'
import { Title } from 'react-head'
import useResponsive from '~/src/hooks/use-responsive'

interface IPageHeader extends React.HTMLAttributes<HTMLElement> {
  title: string
  hideTitle?: boolean
  showSnapshotButton?: boolean
}

const PageHeader = ({
  title,
  children,
  showSnapshotButton,
}: IPageHeader): JSX.Element | null => {
  const { isTablet } = useResponsive()

  // if we are in a responsive scenario, we don't want to render
  if (!isTablet) return null

  return (
    <>
      <Title>FlowFi App | {title}</Title>
      <header className="page-header">
        <div>
          {/* Place the children into a div so the grid doesn't die */}
          {!children && <h2>{title}</h2>}
          {children}
        </div>
        <div className="page-header__controls">
          {showSnapshotButton && (
            <button
              aria-label="snapshot"
              className="page-header__button"
              type="button"
              onClick={() => snapshotModalOpen(true)}
            >
              <SVGHandler
                size="xlarge"
                icon={FlowIcons.Snapshot}
                color="gray"
              />
            </button>
          )}
          <button
            aria-label="notifications"
            className="page-header__button"
            type="button"
            disabled
          >
            <SVGHandler size="xlarge" icon={FlowIcons.Bell} color="gray" />
          </button>
          <Workspace />
        </div>
      </header>
    </>
  )
}

export default PageHeader
