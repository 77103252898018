import React from 'react'
import Classnames from 'classnames'
import ReactDatePicker from 'react-datepicker'
import { sub, startOfMonth } from 'date-fns'
import 'react-datepicker/dist/react-datepicker.css'
import '~/src/components/form-fields/lib/datepicker.css'

interface IDatePicker {
  selected?: Date | undefined
  startDate?: Date
  endDate?: Date
  disabled?: boolean
  readOnly?: boolean
  onChange: (date: Date) => void
  id?: string
  placeholder?: string
  label?: string
  placement?: TDatepickerPlacements
  showMonthYearPicker?: boolean
  dateFormat?: string
  error?: React.ReactNode
  noFuture?: boolean
}

type TDatepickerPlacements =
  | 'top-start'
  | 'top-end'
  | 'bottom-start'
  | 'bottom-end'
  | 'right-start'
  | 'right-end'
  | 'left-start'
  | 'left-end'
  | 'auto'
  | 'auto-start'
  | 'auto-end'

const DatePicker = ({
  onChange,
  disabled,
  selected,
  startDate,
  endDate,
  readOnly,
  id,
  placeholder,
  label,
  placement,
  showMonthYearPicker,
  dateFormat,
  error,
  noFuture,
}: IDatePicker) => {
  const handleChange = (date: Date) => {
    onChange(date)
  }

  const inputClasses = Classnames('form-field', {
    'form-field--invalid': error,
    'form-field--disabled': disabled,
  })

  return (
    <div className={inputClasses}>
      <label htmlFor={id} className="form-field__label">
        {label}
      </label>
      <ReactDatePicker
        id={id}
        onChange={(date: Date) => handleChange(date)}
        disabled={disabled}
        startDate={startDate}
        endDate={endDate}
        selected={selected}
        readOnly={readOnly}
        placeholderText={placeholder}
        popperClassName={'datepicker__popper'}
        popperPlacement={placement ?? 'auto'}
        useWeekdaysShort
        showMonthYearPicker={showMonthYearPicker}
        dateFormat={dateFormat}
        maxDate={noFuture ? sub(startOfMonth(new Date()), { months: 1 }) : null}
      />
      <p className="form-field__error">{error}</p>
    </div>
  )
}

export default DatePicker
