import MERCHANTS_QUERY from '~/src/graphql/queries/merchants.graphql'
import { useQuery, gql } from '@apollo/client'
import useCurrentUser from '~/src/hooks/use-current-user'
import { IMerchant } from '../types/merchant'

const useMerchants = () => {
  const { currentCompanyId } = useCurrentUser()
  const { data: merchantsData, loading } = useQuery(gql(MERCHANTS_QUERY), {
    variables: {
      company_id: currentCompanyId,
    },
  })

  const merchants: IMerchant[] = merchantsData?.merchants

  const getMerchant = (id?: string) =>
    merchants?.length
      ? merchants.find(({ id: merchantId }) => id === merchantId)
      : undefined

  return { merchants, loading, getMerchant }
}

export default useMerchants
