import { useQuery, gql } from '@apollo/client'
import PLAID_BALANCES_QUERY from '~/src/graphql/queries/plaid-balances.graphql'
import useCurrentUser from './use-current-user'
import { PlaidBalance } from '../types/balance'

const usePlaidBalances = (
  options: { skip?: boolean } = {}
): {
  accounts: string[]
  liveBalance: number
  lastUpdated: string
  loading: boolean
} => {
  const { currentCompanyId } = useCurrentUser()

  const { data, loading } = useQuery(gql(PLAID_BALANCES_QUERY), {
    variables: {
      company_id: currentCompanyId,
    },
    ...options,
  })

  const balances: PlaidBalance[] = data?.plaidCashBalances || []

  const balance = 0
  const liveBalance = balances?.reduce(
    (acc, balance) => acc + balance.currentBalance,
    balance
  )

  const accountAccum: string[] = []
  const accounts = balances.reduce(
    (acc, balance) => [...acc, balance.accountName],
    accountAccum
  )

  // To get the "last updated" date, we are simply pulling the first timestamp
  // we see in the data, as they're all milliseconds apart.
  // In the future, we may have to sort *all* timestamps and choose the "stalest" one.
  const lastUpdated = balances?.[0]?.lastUpdated

  return {
    accounts,
    liveBalance,
    lastUpdated,
    loading,
  }
}

export default usePlaidBalances
