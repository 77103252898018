export const formatCurrency = (
  amount: string | number,
  options?: Partial<Intl.NumberFormatOptions>
) => {
  const formatOptions: Intl.NumberFormatOptions = {}

  if (options?.notation === 'compact') {
    formatOptions.notation = options.notation
    formatOptions.compactDisplay = 'short'
  }

  return (
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      ...formatOptions,
    }).format(typeof amount === 'string' ? parseFloat(amount) : amount) || ''
  )
}
