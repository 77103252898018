import * as Types from '../../../types/generated/index';

import { gql } from '@apollo/client';
import { InvitationFragmentDoc } from '../../fragments/generated/invitation';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateInvitationMutationVariables = Types.Exact<{
  input: Types.CreateInvitationInput;
}>;


export type CreateInvitationMutation = { __typename?: 'Mutation', createInvitation: { __typename?: 'CreateInvitationResponse', error?: string | null, invitation?: { __typename: 'Invitation', id: string, email_address?: string | null, company_id: string, duration?: number | null, state?: Types.States | null, created?: string | null, first_name?: string | null, last_name?: string | null, role?: string | null, user_id?: string | null } | null } };


export const CreateInvitationDocument = gql`
    mutation createInvitation($input: CreateInvitationInput!) {
  createInvitation(input: $input) {
    invitation {
      ...InvitationFragment
    }
    error
  }
}
    ${InvitationFragmentDoc}`;
export type CreateInvitationMutationFn = Apollo.MutationFunction<CreateInvitationMutation, CreateInvitationMutationVariables>;

/**
 * __useCreateInvitationMutation__
 *
 * To run a mutation, you first call `useCreateInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvitationMutation, { data, loading, error }] = useCreateInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInvitationMutation(baseOptions?: Apollo.MutationHookOptions<CreateInvitationMutation, CreateInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInvitationMutation, CreateInvitationMutationVariables>(CreateInvitationDocument, options);
      }
export type CreateInvitationMutationHookResult = ReturnType<typeof useCreateInvitationMutation>;
export type CreateInvitationMutationResult = Apollo.MutationResult<CreateInvitationMutation>;
export type CreateInvitationMutationOptions = Apollo.BaseMutationOptions<CreateInvitationMutation, CreateInvitationMutationVariables>;