import React from 'react'
import Button from '~/src/components/button'
import Calendar from './calendar'
import { FlowIcons } from '~/src/assets/flow-icons'
import { useNavigate } from 'react-router-dom'
import ROUTES from '~/src/constants/routes'
import { IBannerProps } from '~/src/types/bookkeeping-banner'
import useResponsive from '~/src/hooks/use-responsive'

const BooksProcessing = ({
  calendarRange,
  dueDate,
  formattedDD,
}: IBannerProps): JSX.Element => {
  const { isDesktop } = useResponsive()
  const navigate = useNavigate()

  const [startDate] = calendarRange

  return (
    <>
      <div className="tablet:pr-20 flex flex-col items-start">
        <h2 className="bookkeeping-banner__title">
          Your books are on the way!
        </h2>
        <p className="bookkeeping-banner__text">
          You can expect them to be completed and uploaded to the FlowFi App by{' '}
          <strong>{formattedDD}</strong>.
        </p>
        <Button
          className="pl-0 mt-auto tablet:mb-4"
          theme="text"
          textLeft
          label="See your past financials"
          size="medium"
          iconPosition="right"
          icon={FlowIcons.ArrowRight}
          onClick={() => navigate(ROUTES.documents.path)}
        />
      </div>
      {isDesktop && <Calendar startDate={startDate} dueDate={dueDate} />}
    </>
  )
}

export default BooksProcessing
