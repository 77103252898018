import useMerchants from './use-merchants'
import { ISelectOption } from '~/src/types/forms'

const useMerchantOptions = () => {
  const { merchants, loading } = useMerchants()

  const merchantOptions: ISelectOption[] = [...(merchants || [])]
    .map(merchant => ({
      id: merchant.id,
      name: merchant.name || '',
    }))
    .sort((a, b) =>
      a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase())
    )

  return { merchants, loading, options: merchantOptions }
}

export default useMerchantOptions
