import React, { ElementType } from 'react'
import SVGHandler, {
  // ISVGHandler,
  TIconColors,
  TIconSizes,
} from '~/src/components/svg-handler'
// import { FlowIcons } from "~/src/assets/flow-icons";
import Classnames from 'classnames'
import './grid-cell.css'
import { Link } from 'react-router-dom'
import Button from '../button'

interface IGridCell extends React.HTMLAttributes<HTMLOrSVGElement> {
  onClick?: (args: any) => any
  name: string
  className?: string
  linkTo?: string
  disabled?: boolean
  inactive?: boolean
  as?: ElementType
  size?: 'medium' | 'small'
}

const GridCellText = ({ children }: { children?: string | null }) => {
  return <div className="grid-cell__text">{children}</div>
}

interface IGridCellIcon {
  size?: TIconSizes
  icon: string
  color?: TIconColors
}
const GridCellIcon = ({
  size = 'large',
  color = 'gray',
  ...props
}: IGridCellIcon) => {
  return (
    <SVGHandler
      size={size}
      color={color}
      className="flex-shrink-0"
      {...props}
    />
  )
}

export const GridCellWrapper = ({
  as: Tag = 'div',
  onClick,
  linkTo,
  name,
  className,
  children,
  disabled,
  inactive,
  size = 'medium',
}: React.PropsWithChildren<IGridCell>) => {
  const classnames = Classnames(
    'grid-cell',
    `grid-cell-${name}`,
    {
      ['grid-cell--button']: onClick,
      ['grid-cell--link']: linkTo,
      ['grid-cell--disabled']: disabled,
      ['grid-cell--inactive']: inactive,
      ['grid-cell--small']: size === 'small',
    },
    className
  )

  const buttonClassnames = Classnames('justify-start font-normal p-0', {
    ['h-6']: size === 'small',
  })

  if (onClick) {
    return (
      <Tag className={classnames} data-test={`grid-cell-${name}`}>
        <Button
          onClick={onClick}
          disabled={disabled}
          theme="text"
          fullWidth
          className={buttonClassnames}
        >
          {children}
        </Button>
      </Tag>
    )
  }

  if (linkTo) {
    return (
      <Tag
        className={classnames}
        onClick={onClick}
        data-test={`grid-cell-${name}`}
      >
        <Link className="flex gap-2 w-full" to={linkTo}>
          {children}
        </Link>
      </Tag>
    )
  }

  return (
    <Tag className={classnames} data-test={`grid-cell-${name}`}>
      <div className="flex items-center gap-1 w-full h-full">{children}</div>
    </Tag>
  )
}

const GridCell = {
  Cell: GridCellWrapper,
  Text: GridCellText,
  Icon: GridCellIcon,
}

export default GridCell
