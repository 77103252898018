import React from 'react'
import Classnames from 'classnames'
import SVGHandler from '~/src/components/svg-handler'
import { FlowIcons } from '~/src/assets/flow-icons'
import useResponsive from '~/src/hooks/use-responsive'

interface INavBarToggle {
  collapsed: boolean
  onClick: () => void
}

const NavBarToggle = ({ onClick, collapsed }: INavBarToggle): JSX.Element => {
  const { isDesktop } = useResponsive()
  const toggleClasses = Classnames('navigation-toggle', {
    'navigation-toggle--collapsed': collapsed,
  })

  if (!isDesktop) return <div></div>

  return (
    <button
      aria-label="toggle navigation"
      className={toggleClasses}
      onClick={onClick}
      title="Toggle Navigation"
    >
      <SVGHandler
        className="navigation-toggle__icon"
        size="medium"
        color="gray"
        icon={collapsed ? FlowIcons.ChevronRight : FlowIcons.ChevronLeft}
        title={collapsed ? 'Expand Navigation' : 'Collapse Navigation'}
      />
    </button>
  )
}

export default NavBarToggle
