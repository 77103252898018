import React from 'react'
import useCurrentUser from '~/src/hooks/use-current-user'
import {
  AssetFragment,
  AssetFragmentDoc,
} from '~/src/graphql/fragments/generated/asset'
import { useUpdateAssetMutation } from '~/src/graphql/mutations/generated/update-asset'
import useFragment from '~/src/hooks/use-fragment'
import Modal from '~/src/components/modal'
import useModal from '~/src/hooks/use-modal'
import EditForm from '~/src/components/edit-form'
import { FORMS } from '~/src/constants/forms'
import { notify } from '~/src/utilities/notify'

interface FormData {
  title: string
  description: string
}

export const EditAsset = ({
  className,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const fragment = AssetFragmentDoc
  const {
    show,
    modalId: id,
    closeModal: onClose,
  } = useModal({
    name: 'editAsset',
  })

  const item = useFragment<AssetFragment>({
    fragment,
    id: `Asset:${id}`,
  })

  const { currentCompanyId } = useCurrentUser()

  const defaultValues = item
    ? {
        title: item?.display_name || '',
        description: item?.description || '',
      }
    : null

  const [updateAsset] = useUpdateAssetMutation()

  const onSubmit = async ({ title, description }: FormData) => {
    if (!currentCompanyId || !item) {
      return
    }

    try {
      await updateAsset({
        variables: {
          id: item.id,
          company_id: currentCompanyId,
          input: {
            display_name: title,
            description,
          },
        },
      })
    } catch (e) {
      console.log("Error in EditAsset", e); /* eslint-disable-line */
      notify('An error occurred', { type: 'error' })
    }

    onClose()
  }

  const fields = {
    title: {
      required: FORMS.VALIDATION.REQUIRED_FIELD,
    },
    description: {},
  }

  const title = `Edit "${item?.display_name}"`

  return (
    <Modal open={show} onClose={onClose}>
      {item && defaultValues && (
        <EditForm
          title={title}
          fields={fields}
          onSubmit={onSubmit}
          className={className}
          defaultValues={defaultValues}
        />
      )}
    </Modal>
  )
}
