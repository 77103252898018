import debounce from 'debounce'
import React, { useState } from 'react'
import Button from '~/src/components/button'
import Modal from '~/src/components/modal'
import Pagination from '~/src/components/pagination'
import { useCompanyMembersQuery } from '~/src/graphql/queries/generated/company-members'
import usePagination from '~/src/hooks/use-pagination'
import { useCompanyContext } from '../..'
import '../../company.css'
import CompanyMemberRow from '../company-member-row/company-member-row'
import CreateInviteForm from '../invite-form'
import useResponsive from '~/src/hooks/use-responsive'

/**
 * A note on user/member naming and the concept of company memberships:
 *
 * To customers, these are users and this is a user management page, and that's that!
 * The routes and verbiage reflects that.
 *
 * Under the hood, this page displays a collection of Members and Invitations, queried as a union
 * so we can search and paginate over them.
 *
 * Members are a company's users, or in a data modeling sense,
 * all the properties of a "user", plus the properties of the
 * "company_membership" join between a "user" and a "company".
 *
 * For example, a member has a role at a company. Whereas a user doesn't have
 * a role because they exist independent of any company.
 * But a user can have many companies (through their company memberships),
 * at which they'll be a member, and have a role.
 *
 * (There are a lot of ways to model this, and if this one turns out to be
 * clunky, we can change it! Like having a CompanyMembership be its own thing,
 * instead of thinking of a Member as a superset of User.)
 *
 * This collection also includes invitations, aka *potential members*, who are neither
 * users nor members at all. An invitation is a relatively small object which will be used
 * to create a user and company membership when accepted. As such,
 * it has a subset of the properties of both.
 */
const CompanyUsers = (): JSX.Element => {
  const { isDesktop } = useResponsive()
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')

  // Receive a guaranteed-to-be-present current company and user from the parent. Company membership is loaded, but might be null for an internal user.
  const { currentCompany, currentMember, currentUser } = useCompanyContext()
  const currentCompanyId = currentCompany.id

  const { data, fetchMore } = useCompanyMembersQuery({
    variables: {
      user_id: currentUser.id,
      company_id: currentCompanyId,
      substring: search,
      config: { limit: 100, offset: 0 },
    },
  })

  const { items = [], count = 0 } = data?.members || {}

  const { setPage, windowedItems, pagination } = usePagination({
    items,
    count,
    fetchMore,
  })

  const handleSearch = debounce(
    (e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value),
    200
  )

  return (
    <section>
      <div className="company-page">
        <div className="company-page__users">
          <div className="company-page__user-controls">
            <input type="text" placeholder="Search" onChange={handleSearch} />
            <Button
              className="my-2 mr-auto pl-0 tablet:ml-auto tablet:mr-0 tablet:my-2"
              theme="text"
              label="+ Add User"
              testId="create-invite-modal-button"
              onClick={() => setModalOpen(true)}
              size="medium"
            />
            <Modal
              open={modalOpen}
              onClose={() => setModalOpen(false)}
              title="Add User"
            >
              <CreateInviteForm
                onSuccess={() => {
                  setModalOpen(false)
                  setPage(0)
                }}
              />
            </Modal>
          </div>
          {/* TODO: this needs to be the sortable table, but we'll add a static header for now */}
          {isDesktop && (
            <div className="company-page__users-header">
              <div>Name</div>
              <div>Email</div>
              <div>Role</div>
              <div></div>
            </div>
          )}

          {windowedItems &&
            windowedItems?.map((member, index) => (
              <CompanyMemberRow
                key={member.id}
                member={member}
                currentMember={currentMember}
                currentUser={currentUser}
                index={index}
              />
            ))}
          <Pagination {...pagination} />
        </div>
      </div>
    </section>
  )
}

export default CompanyUsers
