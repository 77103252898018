mutation UpdateActionItemState($data: UpdateActionItemStateInput!) {
  updateActionItemState(data: $data) {
    amortization_responses {
      action_item_id
      additional_notes
      author_id
      id
      is_amortization
      response_time
      service_period_months
      start_date
    }
    bookkeeper_id
    categorization_responses {
      account
      account_id
      action_item_id
      additional_notes
      author_id
      id
      merchant_id
      name
      response_time
    }
    category
    company_id
    due_date
    id
    miscellaneous_responses {
      action_item_id
      author_id
      id
      long_answer
      response_time
    }
    question
    state
    transaction_id
    transaction_line_item_id
    transaction_posting_type
    transaction_sync_token
  }
}
