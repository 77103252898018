import React from 'react'
import UserIcon from '~/src/components/user-icon'
import useCurrentUser from '~/src/hooks/use-current-user'
import { FlowIcons } from '~/src/assets/flow-icons'
import { commonDate } from '~/src/utilities/dates'
import { IAsset } from '~/src/types'
import { useDocumentsContext } from '../documents.context'
import { getPreviewDocument } from '~/src/utilities/s3'
import { Document as IDocument } from '~/src/types'
import GridCell from '~/src/components/grid-cell'
import AssetMenu from './asset-menu'
import FolderMenu from './folder-menu'

interface ICombinedGridRow {
  document: IDocument
}

const getDisplayDate = (document: IDocument) => {
  let date

  if ('created' in document && !!document.created) {
    date = document.created
  }

  if ('created_at' in document && !!document.created_at) {
    date = document.created_at
  }

  return date ? commonDate(new Date(parseInt(date)).toDateString()) : ''
}

const getTitle = (document: IDocument) => {
  if (document?.__typename === 'Asset') {
    return document?.display_name || document.name
  }

  return document?.title
}

const getDescription = (document: IDocument) => {
  let description
  if ('description' in document) {
    description = document?.description
  }
  return description || '—'
}

const CombinedGridRow = ({ document }: ICombinedGridRow): JSX.Element => {
  const { setFile, setDocument, setModalOpen } = useDocumentsContext()

  const user = document.user
  const { currentUser } = useCurrentUser()

  const handleOpenAsset = (asset: IAsset) => {
    setFile?.(undefined)
    setDocument?.(asset)
    getPreviewDocument(asset).then(data => {
      setFile?.(data)
      setModalOpen?.(true)
    })
  }

  const { MenuComponent, linkTo, icon, onClick } = {
    Folder: {
      MenuComponent: FolderMenu,
      icon: FlowIcons.Folder,
      linkTo: `/documents/${document.id}`,
    },
    Asset: {
      MenuComponent: AssetMenu,
      icon: FlowIcons.FileText,
      onClick: () => handleOpenAsset(document as IAsset),
    },
  }[document.__typename]

  return (
    <>
      {document && (
        <div className="sortable-grid__row" key={document.id}>
          <GridCell.Cell
            name="document-title"
            onClick={onClick}
            linkTo={linkTo}
          >
            <GridCell.Icon color="gray" size="large" icon={icon} />
            <GridCell.Text>{getTitle(document)}</GridCell.Text>
          </GridCell.Cell>

          <GridCell.Cell name="document-description">
            <GridCell.Text>{getDescription(document)}</GridCell.Text>
          </GridCell.Cell>

          <GridCell.Cell name="document-created-at">
            <GridCell.Text>{getDisplayDate(document)}</GridCell.Text>
          </GridCell.Cell>

          <GridCell.Cell name="document-controls">
            {!!user && (
              <>
                <UserIcon size="sm" user={user} />{' '}
                <GridCell.Text>{`${user.first_name} ${user.last_name} ${
                  user.id === currentUser?.id ? '(Me)' : ''
                }`}</GridCell.Text>
              </>
            )}
            <div className="document-table__row-controls">
              <MenuComponent document={document} iconColor="gray" />
            </div>
          </GridCell.Cell>
        </div>
      )}
    </>
  )
}

export default CombinedGridRow
