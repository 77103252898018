import React from 'react'
import Button from '~/src/components/button'
import './delete-form.css'

interface IDeleteable {
  first_name?: string
  last_name?: string
  email_address: string
}
interface DeleteCompanyMemberFormProps {
  member: IDeleteable
  handleConfirm: (e: React.MouseEvent) => void
}

export const DeleteCompanyMemberForm = ({
  member,
  handleConfirm,
}: DeleteCompanyMemberFormProps) => {
  const { first_name, last_name, email_address } = member

  const nameOrEmail =
    first_name && last_name ? `${first_name} ${last_name}` : email_address

  return (
    <form className="delete-company-member-form">
      <div>
        Are you sure you want to remove {nameOrEmail} from your company?
      </div>
      <Button
        type="submit"
        label="Yes, Remove"
        theme="primary"
        size="medium"
        testId="delete-member-confirmation-button"
        className="delete-company-member-form__submit"
        onClick={handleConfirm}
      />
    </form>
  )
}

export default DeleteCompanyMemberForm
