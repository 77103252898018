const BREAKPOINTS = {
  TABLET: 450,
  SM: 768,
  MD: 1024,
  LG: 1280,
  XL: 1440,
  XXL: 1600,
  XXXL: 1920,
}

export default BREAKPOINTS
