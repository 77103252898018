import * as Types from '../../../types/generated/index';

import { gql } from '@apollo/client';
import { DocumentShallowFragmentDoc } from '../../fragments/generated/document-shallow';
import { FolderShallowFragmentDoc } from '../../fragments/generated/folder-shallow';
import { AssetFragmentDoc } from '../../fragments/generated/asset';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DocumentsQueryVariables = Types.Exact<{
  company_id: Types.Scalars['ID'];
  fixed?: Types.InputMaybe<Types.Scalars['Boolean']>;
  folder_id?: Types.InputMaybe<Types.Scalars['ID']>;
  search?: Types.InputMaybe<Types.Scalars['String']>;
  sort_by?: Types.InputMaybe<Array<Types.DocumentSortConfig> | Types.DocumentSortConfig>;
  config: Types.QueryConfig;
}>;


export type DocumentsQuery = { __typename?: 'Query', documents: { __typename: 'DocumentResponse', count: number, items: Array<{ __typename: 'Asset', id: string, name?: string | null, display_name?: string | null, description?: string | null, created?: string | null, updated?: string | null, url?: string | null, company_id: string, preview_url?: string | null, thumb_url?: string | null, folder_id?: string | null, user?: { __typename?: 'User', id: string, first_name?: string | null, last_name?: string | null } | null } | { __typename: 'Folder', id: string, company_id: string, created_at: string, fixed: boolean, parent_id?: string | null, title: string, parent?: { __typename: 'Folder', id: string, company_id: string, created_at: string, fixed: boolean, title: string } | null, user?: { __typename: 'User', id: string, first_name?: string | null, last_name?: string | null, avatar?: string | null } | null }> } };


export const DocumentsQueryDocument = gql`
    query DocumentsQuery($company_id: ID!, $fixed: Boolean, $folder_id: ID, $search: String, $sort_by: [DocumentSortConfig!], $config: QueryConfig!) {
  documents(
    company_id: $company_id
    fixed: $fixed
    folder_id: $folder_id
    sort_by: $sort_by
    search: $search
    config: $config
  ) {
    __typename
    items {
      ...DocumentShallow
    }
    count
  }
}
    ${DocumentShallowFragmentDoc}
${FolderShallowFragmentDoc}
${AssetFragmentDoc}`;

/**
 * __useDocumentsQuery__
 *
 * To run a query within a React component, call `useDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentsQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *      fixed: // value for 'fixed'
 *      folder_id: // value for 'folder_id'
 *      search: // value for 'search'
 *      sort_by: // value for 'sort_by'
 *      config: // value for 'config'
 *   },
 * });
 */
export function useDocumentsQuery(baseOptions: Apollo.QueryHookOptions<DocumentsQuery, DocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentsQuery, DocumentsQueryVariables>(DocumentsQueryDocument, options);
      }
export function useDocumentsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentsQuery, DocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentsQuery, DocumentsQueryVariables>(DocumentsQueryDocument, options);
        }
export type DocumentsQueryHookResult = ReturnType<typeof useDocumentsQuery>;
export type DocumentsQueryLazyQueryHookResult = ReturnType<typeof useDocumentsQueryLazyQuery>;
export type DocumentsQueryQueryResult = Apollo.QueryResult<DocumentsQuery, DocumentsQueryVariables>;