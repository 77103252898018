import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'

interface IComboChart {
  id: string
  labels: ApexOptions['labels']
  series: ApexAxisChartSeries | ApexNonAxisChartSeries
  yFormatter?: (number: number) => string
}

/**
 * NOTE: This is being converted to just be the specific "burn" chart
 */

const ComboChart = ({ id, labels, series, yFormatter }: IComboChart) => {
  const chart = {
    series,
    options: {
      chart: {
        toolbar: { show: false },
        height: 350,
        type: 'line',
        fontFamily: 'Manrope',
      },
      tooltip: {
        enabled: true,
        custom: ({
          series,
          dataPointIndex,
          w,
        }: {
          series: ApexAxisChartSeries[]
          dataPointIndex: number
          w: any
        }) => {
          return `<div class="graph__tooltip">
            <div class="graph__tooltip-item">
              <div class="graph__marker" style="background-color: ${
                w.config.fill.colors[0]
              }"></div>
              <div class="graph__tooltip-label">${
                w.globals.seriesNames[0]
              }:</div>
              <div class="graph__tooltip-value">
                ${yFormatter?.(
                  series?.[0]?.[dataPointIndex] as unknown as number
                )}
              </div>
            </div>
            <div class="graph__tooltip-item">
              <div class="graph__marker" style="background-color: ${
                w.config.fill.colors[1]
              }"></div>
              <div class="graph__tooltip-label">${
                w.globals.seriesNames[1]
              }:</div>
              <div class="graph__tooltip-value">
                ${yFormatter?.(
                  Math.abs(series?.[1]?.[dataPointIndex] as unknown as number)
                )}
              </div>
            </div>
          </div>`
        },
      },
      stroke: {
        width: [2, 2],
        curve: 'straight',
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
        },
      },
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        hover: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        active: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        colors: ['#2FD9BA', '#436CFF'],
        opacity: 1,
      },
      labels,
      xaxis: {
        type: 'category',
        labels: {
          style: {
            colors: '#718096',
            fontSize: '10px',
          },
        },
        axisBorder: {
          color: '#EDF2F7',
        },
        axisTicks: {
          color: '#EDF2F7',
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: [
        {
          labels: {
            style: {
              colors: '#718096',
              fontSize: '10px',
            },
            formatter: yFormatter,
          },
          axisBorder: {
            color: '#EDF2F7',
          },
          axisTicks: {
            color: '#EDF2F7',
          },
        },
      ],
      legend: {
        showForSingleSeries: true,
        position: 'bottom',
        fontSize: '12px',
        markers: {
          width: 10,
          height: 10,
          fillColors: ['#2FD9BA', '#436CFF'],
          radius: 4,
        },
        labels: {
          colors: '#718096',
        },
        onItemClick: {
          toggleDataSeries: false,
        },
      },
      grid: {
        borderColor: '#EDF2F7',
      },
    },
  }

  return (
    <>
      <ReactApexChart
        id={id}
        options={chart.options as ApexOptions}
        series={chart.series}
        width="100%"
        height={300}
      />
    </>
  )
}

export default ComboChart
