import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Document, Page } from 'react-pdf/dist/esm/entry.parcel2'
import SVGHandler from '~/src/components/svg-handler'
import { FlowIcons } from '~/src/assets/flow-icons'
import { useDocumentsContext } from '../documents.context'
import { download } from '~/src/utilities/s3'
import NoPreview from '~/src/assets/images/document-nopreview.svg'

const AssetPreview = () => {
  const { isModalOpen, setModalOpen, file, document, setFile, setDocument } =
    useDocumentsContext()

  const handleClose = () => {
    setModalOpen?.(false)
    setTimeout(() => {
      setFile?.(undefined)
      setDocument?.(undefined)
    }, 350)
  }

  const containerWidth = window.innerWidth / 2.5

  return (
    <Transition show={isModalOpen} as={Fragment}>
      <Dialog onClose={handleClose} className="document-viewer">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="document-viewer__backdrop" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95 -translate-y-9"
          enterTo="opacity-100 scale-100 translate-y-0"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100 translate-y-0"
          leaveTo="opacity-0 scale-95 -translate-y-9"
        >
          <div className="document-viewer__wrap">
            <Dialog.Panel className="document-viewer__panel">
              <button
                aria-label="dismiss modal"
                className="ml-auto"
                onClick={handleClose}
              >
                <SVGHandler
                  size="large"
                  icon={FlowIcons.X}
                  title="Dismiss Modal"
                  color="white"
                />
              </button>
              <div className="document-viewer__document">
                {file && (
                  <Document
                    className="w-full flex justify-center"
                    file={{ data: file }}
                  >
                    <Page pageNumber={1} width={containerWidth} />
                  </Document>
                )}
                {file === undefined && (
                  <div className="document-viewer__nopreview">
                    <img
                      src={NoPreview}
                      alt="Sad magnifying glass over a document."
                    />
                    <h3>No preview available</h3>
                    <p>
                      Hmm... looks like this item doesn't have a preview we can
                      show you.
                    </p>
                  </div>
                )}
                {document && (
                  <div className="document-viewer__info">
                    <div>
                      <h3>{document?.name}</h3>
                      <p>{document?.description}</p>
                    </div>
                    <div>
                      <button
                        aria-label="download file"
                        className="ml-auto"
                        onClick={() => download(document)}
                      >
                        <SVGHandler
                          size="large"
                          icon={FlowIcons.Download}
                          title="Download"
                          color="white"
                        />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </Dialog.Panel>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  )
}

export default AssetPreview
