import React from 'react'
import Classnames from 'classnames'
import './loader.css'

interface ILoader {
  fullScreen?: boolean
  size: keyof typeof LoaderSizes
  centered?: boolean
  altMask?: boolean // changes the color of the center mask from white to gray
}

enum LoaderSizes {
  sm = 'sm',
  md = 'md',
  lg = 'lg',
}

const Loader = ({
  fullScreen,
  size,
  className,
  centered,
  altMask,
}: ILoader & React.HTMLAttributes<HTMLDivElement>): JSX.Element => {
  const loaderClasses = Classnames('loader', {
    'loader--fullscreen': fullScreen,
    'loader--centered': centered,
    'loader--alt': altMask,
    [`loader--${size}`]: size,
    ...(className ? { [className]: className } : {}),
  })
  return (
    <div className={loaderClasses}>
      <div className="loader__indicator" />
    </div>
  )
}

export default Loader
