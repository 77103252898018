import { Listbox, Transition } from '@headlessui/react'
import React from 'react'
import { FlowIcons } from '~/src/assets/flow-icons'
import SVGHandler from '~/src/components/svg-handler'
import './select.css'

interface ISelect {
  label: string
  name: string
  value: ISelectOption
  options: ISelectOption[]
  className?: string
  onChange: (selected: ISelectOption) => void
}

export interface ISelectOption {
  id: string | number
  name: string
  [key: string]: unknown
}

export const Select = ({
  label,
  name,
  value,
  options,
  className,
  onChange,
}: ISelect): JSX.Element => {
  const handleChange = (selected: ISelectOption) => {
    onChange(selected)
  }

  return (
    <Listbox
      as="div"
      className={className || ''}
      value={value}
      name={name}
      onChange={(selected: ISelectOption) => handleChange(selected)}
    >
      {({ open }) => (
        <>
          <Listbox.Label>{label}</Listbox.Label>
          <div className="select">
            <Listbox.Button className="select__trigger">
              <div className="select__trigger-text">{value.name}</div>
              <div className="select__trigger-icon">
                <SVGHandler
                  size="small"
                  icon={open ? FlowIcons.ChevronUp : FlowIcons.ChevronDown}
                  color={open ? 'black' : 'gray'}
                />
              </div>
            </Listbox.Button>

            <Transition
              show={open}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options static className="select__popover">
                {options.map((option, index) => (
                  <Listbox.Option
                    key={`${option.name}-${index}`}
                    value={option}
                  >
                    {({ selected, active }) => (
                      <div
                        className={`${
                          active ? 'select__popover-item--active' : ''
                        } select__popover-item`}
                      >
                        <span
                          className={`${
                            selected ? 'select__popover-item-text--active' : ''
                          } select__popover-item-text`}
                        >
                          {option.name}
                        </span>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}

export default Select
