import { useQuery, gql } from '@apollo/client'
import TEAM_MEMBERS_QUERY from '~/src/graphql/queries/team-members.graphql'
import { QueryConfig } from '~/src/types/generated'
import { IMember } from '../types/member'
import useCurrentUser from './use-current-user'
import { DEFAULT_PAGE_SIZE } from '~/src/constants/pagination'
import { Roles, StaffRoles } from '~/src/types/roles'

const useTeam = (
  options?: QueryConfig
): {
  teamMembers: IMember[]
  userIds: string[]
  roles: Roles[]
  count: number
  loading: boolean
} => {
  const { currentCompanyId } = useCurrentUser()
  const { offset = 0, limit: defaultLimit = DEFAULT_PAGE_SIZE } = options || {}
  const { data, loading } = useQuery(gql(TEAM_MEMBERS_QUERY), {
    variables: {
      company_id: currentCompanyId,
      config: {
        offset,
        limit: defaultLimit,
      },
    },
  })

  const { items, count } = data?.teamMembers || {}
  const teamMembers: IMember[] = items || []

  // Unique user ids for aggregation purposes; duplicate roles represent multiple roles for a single user.
  const accum: string[] = []
  const userIds =
    teamMembers?.reduce(
      (acc, member) =>
        acc.includes(member.user_id) ? acc : [...acc, member.user_id],
      accum
    ) || []

  // Roles are unique within a company, so check which ones are already
  // given to team members.
  const roleAccum: Roles[] = []
  const roles = teamMembers.reduce(
    (acc, member) =>
      acc.includes(member.role) || !(member.role in StaffRoles)
        ? acc
        : [...acc, member.role],
    roleAccum
  )

  return {
    teamMembers,
    userIds,
    roles,
    count,
    loading,
  }
}

export default useTeam
