import React from 'react'
import Button from '~/src/components/button'
import Modal from '~/src/components/modal'
import { useForm } from 'react-hook-form'
import { FormFields } from '~/src/components/form-fields'
import { FORMS } from '~/src/constants/forms'
import { useCreateFolderMutation } from '~/src/graphql/mutations/generated/create-folder'
import useCurrentUser from '~/src/hooks/use-current-user'
import useModal from '~/src/hooks/use-modal'
import { notify } from '~/src/utilities/notify'
interface ICreateFolder {
  parentId?: string
}

type FormData = {
  title: string
}

const CreateFolder = ({
  parentId,
  className,
}: ICreateFolder & React.HTMLAttributes<HTMLDivElement>) => {
  const { currentCompanyId, currentUserId } = useCurrentUser()

  const { show, closeModal: onClose } = useModal({ name: 'createFolder' })

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>({
    mode: 'onSubmit',
    defaultValues: {
      title: '',
    },
  })

  const [createFolder, { loading }] = useCreateFolderMutation({
    refetchQueries: ['DocumentsQuery'],
  })

  const onSubmit = async ({ title }: FormData) => {
    if (!currentCompanyId) {
      return
    }

    try {
      await createFolder({
        variables: {
          input: {
            name: title,
            company_id: currentCompanyId,
            user_id: currentUserId,
            parent_id: parentId,
          },
        },
      })
    } catch (e) {
      notify("Couldn't create folder", { type: 'error' })
    }

    reset()
    onClose()
  }

  return (
    <Modal title="Add Folder" open={show} onClose={() => onClose()}>
      <div className={className}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <section className="folder-form__inner-container">
            <FormFields.Text
              {...register('title', {
                required: FORMS.VALIDATION.REQUIRED_FIELD,
              })}
              label="Title"
              error={errors.title?.message}
            />
          </section>
          <Button
            className="w-full"
            type="submit"
            label="Confirm"
            disabled={loading}
          />
        </form>
      </div>
    </Modal>
  )
}

export default CreateFolder
