import { useMemo } from 'react'
import { useReactiveVar } from '@apollo/client'
import { modalItemId, modalKey } from '~/src/cache'
import {
  TModalNames,
  TModalCloseEvents,
  TModalSetterArgs,
  MODAL_CONFIG,
} from '../types/modals'

export const closeModal = (event?: TModalCloseEvents) => {
  const _event = event

  modalKey('')
  modalItemId('')
}

interface IUseModal {
  name: TModalNames
}

// The name is optional in the hook, in case it's verbose to declare many setters.
const useModal = (args?: IUseModal) => {
  const { name } = args || {}
  const modalName = useReactiveVar(modalKey)
  const itemId = useReactiveVar(modalItemId)

  const values = useMemo(() => {
    // You can use this with no args/just an id when the name was provided to the hook
    // e.g. (const { setModalOpen } = useModal({ name: "myModal" }))
    // or by passing the name in the `setModal({ name: "myModal" })` call itself.
    const setModalOpen = <T extends TModalNames>(
      args?: Partial<IUseModal> & TModalSetterArgs<T>
    ) => {
      const nameToSet = name || args?.name
      const currentName = modalKey()

      if (!nameToSet) {
        throw new Error('Must provide a name to open a named modal')
      }

      if (args?.id) {
        modalItemId(args.id)
      }

      if (nameToSet && nameToSet !== currentName) {
        modalKey(nameToSet)
      }
    }

    const match = name === modalName
    const idRequired = name && MODAL_CONFIG[name].idRequired
    const show = idRequired ? !!itemId && match : match

    return {
      setModalOpen,
      closeModal,
      modalId: itemId,
      show,
    }
  }, [itemId, modalName, name])

  return values
}

export default useModal
