import React from 'react'
import useSubscriptions, {
  ISortableSubscription,
} from '~/src/hooks/use-subscriptions'
import SVGHandler from '~/src/components/svg-handler'
import { FlowIcons } from '~/src/assets/flow-icons'
import BillsPaid from '~/src/assets/images/bills-paid.svg'
import SortableGrid from '~/src/components/sortable'
import useSortableGrid, { defaultOnSort } from '~/src/hooks/use-sortable-grid'
import { TSortableColumn } from '~/src/components/sortable/components/sortable-column'
import Loader from '~/src/components/loader'
// import SubscriptionsExport from "../subscriptions-export";
import { useReactiveVar } from '@apollo/client'
import { subscriptionsTextFilter } from '~/src/cache'
import ScrollContainer from '~/src/components/scroll-container'
import { formatCurrency } from '~/src/utilities/currency'
import { parse, format } from 'date-fns'
import { QBO_DATE_FORMAT } from '../../config'
import useResponsive from '~/src/hooks/use-responsive'

// Columns for sortable table
const columns: TSortableColumn<ISortableSubscription>[] = [
  {
    label: 'Vendor',
    accessor: 'vendorName',
    sortable: true,
    default: true,
  },
  {
    label: 'Source',
  },
  {
    label: 'Last Amount Paid',
    accessor: 'amount',
    sortable: true,
  },
  {
    label: 'Next Expected Payment',
    accessor: 'nextExpectedTransactionDate',
    sortable: true,
  },
]

const Subscriptions = () => {
  const { isDesktop } = useResponsive()
  const { sortableSubscriptions, loading } = useSubscriptions()

  const filter = useReactiveVar(subscriptionsTextFilter)

  const {
    handleSortingChange,
    sortOrder,
    sortField,
    data: subscriptions,
  } = useSortableGrid({
    columns,
    filterFields: [
      'vendorName',
      'originatingAccountName',
      'categorizedAccountName',
    ],
    searchTerm: filter,
    data: sortableSubscriptions,
    onSort: defaultOnSort,
  })

  const showTable = !!subscriptions?.length

  return (
    <>
      {loading ? (
        <Loader size="lg" centered />
      ) : (
        <div className="h-full">
          {showTable && (
            <ScrollContainer className="subscriptions-table-wrap">
              {isDesktop ? (
                <SortableGrid
                  className="subscriptions-table  max-w-fit"
                  columns={columns}
                  handleSortingChange={handleSortingChange}
                  sortOrder={sortOrder}
                  sortField={sortField}
                >
                  {subscriptions.map(subscription => {
                    return (
                      <div className="sortable-grid__row" key={subscription.id}>
                        <div className="whitespace-nowrap overflow-hidden text-ellipsis">
                          {subscription.vendorName || 'N/A'}
                        </div>
                        <div className="whitespace-nowrap overflow-hidden text-ellipsis">
                          {subscription.originatingAccount?.name || 'N/A'}
                        </div>
                        <div>{formatCurrency(subscription.amount || 0)}</div>
                        <div>
                          {format(
                            parse(
                              subscription.nextExpectedTransactionDate,
                              QBO_DATE_FORMAT,
                              new Date()
                            ),
                            'MM/dd/yyyy'
                          )}
                        </div>
                      </div>
                    )
                  })}
                </SortableGrid>
              ) : (
                <>
                  {subscriptions.map(subscription => {
                    return (
                      <div className="flex flex-col gap-1 p-2.5 border-b text-sm border-grayscale-300">
                        <div>{subscription.vendorName || 'N/A'}</div>
                        <div className="text-xs flex flex-row justify-between items-center">
                          <span className="text-grayscale-600">
                            Orig Acct:{' '}
                          </span>
                          {subscription.originatingAccount?.name || 'N/A'}
                        </div>
                        <div className="text-xs flex flex-row justify-between items-center">
                          <span className="text-grayscale-600">
                            Expected Amt:{' '}
                          </span>
                          {formatCurrency(subscription.amount || 0)}
                        </div>
                        <div className="text-xs flex flex-row justify-between items-center">
                          <span className="text-grayscale-600">
                            Expected Txn Date:
                          </span>
                          {format(
                            parse(
                              subscription.nextExpectedTransactionDate,
                              QBO_DATE_FORMAT,
                              new Date()
                            ),
                            'MM/dd/yyyy'
                          )}
                        </div>
                      </div>
                    )
                  })}
                </>
              )}
              {filter && !subscriptions.length && (
                <div className="my-4 text-center">No matching results.</div>
              )}
            </ScrollContainer>
          )}

          {/* If there are no subscriptions at all to show, show a filler image. */}
          {!showTable && (
            <div className="w-full h-full flex flex-col items-center justify-center">
              <img
                src={BillsPaid}
                alt="A green checkmark shown over a representation of a bill."
              />
              <div className="mt-2 text-sm">
                You have no current subscriptions.
              </div>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export const SubscriptionsHeader = () => {
  const handleFilterChange = (val: string) => {
    subscriptionsTextFilter(val)
  }

  return (
    <>
      <div className="flex-grow ml-10 my-4 relative">
        <SVGHandler
          className="absolute left-2 top-3"
          size="small"
          icon={FlowIcons.Search}
          color="gray"
        />
        <input
          className="!pl-8"
          placeholder="Search"
          type="text"
          onChange={e => handleFilterChange(e.target.value)}
        />
      </div>
      {/* <SubscriptionsExport /> */}
    </>
  )
}

export default Subscriptions
