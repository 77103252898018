import * as Types from '../../../types/generated/index';

import { gql } from '@apollo/client';
import { CompanyFragmentDoc } from '../../fragments/generated/company';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserCompaniesQueryVariables = Types.Exact<{
  user_id?: Types.InputMaybe<Types.Scalars['ID']>;
  config?: Types.InputMaybe<Types.QueryConfig>;
}>;


export type UserCompaniesQuery = { __typename?: 'Query', companies: Array<{ __typename?: 'Company', avatar?: string | null, books_start?: string | null, created?: string | null, domain?: string | null, employees?: string | null, fiscal_start?: string | null, id: string, industry?: string | null, location_city?: string | null, location_state?: string | null, location_zip?: string | null, name?: string | null, primary_business_activity?: string | null, primary_poc?: string | null, revenue_model?: string | null, stage?: string | null, state?: Types.States | null, type?: string | null, updated?: string | null, website?: string | null, codat_id?: string | null }> };


export const UserCompaniesQueryDocument = gql`
    query UserCompaniesQuery($user_id: ID, $config: QueryConfig) {
  companies(user_id: $user_id, config: $config) {
    ...CompanyFragment
  }
}
    ${CompanyFragmentDoc}`;

/**
 * __useUserCompaniesQuery__
 *
 * To run a query within a React component, call `useUserCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCompaniesQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      config: // value for 'config'
 *   },
 * });
 */
export function useUserCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<UserCompaniesQuery, UserCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserCompaniesQuery, UserCompaniesQueryVariables>(UserCompaniesQueryDocument, options);
      }
export function useUserCompaniesQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserCompaniesQuery, UserCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserCompaniesQuery, UserCompaniesQueryVariables>(UserCompaniesQueryDocument, options);
        }
export type UserCompaniesQueryHookResult = ReturnType<typeof useUserCompaniesQuery>;
export type UserCompaniesQueryLazyQueryHookResult = ReturnType<typeof useUserCompaniesQueryLazyQuery>;
export type UserCompaniesQueryQueryResult = Apollo.QueryResult<UserCompaniesQuery, UserCompaniesQueryVariables>;