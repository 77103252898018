import { createContext, useContext } from 'react'
import { IAsset } from '~/src/types/asset'

export type TDocumentsContext = {
  document: IAsset | undefined
  setDocument?: (document: IAsset | undefined) => void
  file: Uint8Array | undefined
  setFile?: (file: Uint8Array | undefined) => void
  isModalOpen: boolean
  setModalOpen?: (isOpen: boolean) => void
  uploading: boolean
  searchTerm: string
  setSearchTerm?: (searchTerm: string) => void
  setUploading?: (status: boolean) => void
  setUploadModalOpen?: (status: boolean) => void
  isUploadModalOpen: boolean
  setPage?: (page: number) => void
  page: number
  handlePageClick?: (event: { selected: number }) => void
}

export const DocumentsContext = createContext<TDocumentsContext>({
  document: undefined,
  file: undefined,
  isModalOpen: false,
  searchTerm: '',
  isUploadModalOpen: false,
  uploading: false,
  page: 0,
})

export const useDocumentsContext = () => useContext(DocumentsContext)
