import * as Types from '../../../types/generated/index';

import { gql } from '@apollo/client';
import { FolderShallowFragmentDoc } from '../../fragments/generated/folder-shallow';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FoldersQueryVariables = Types.Exact<{
  company_id: Types.Scalars['ID'];
  parent_id?: Types.InputMaybe<Types.Scalars['ID']>;
  fixed?: Types.InputMaybe<Types.Scalars['Boolean']>;
  search?: Types.InputMaybe<Types.Scalars['String']>;
  config?: Types.InputMaybe<Types.QueryConfig>;
}>;


export type FoldersQuery = { __typename?: 'Query', folders?: { __typename?: 'FoldersResponse', items: Array<{ __typename: 'Folder', id: string, company_id: string, created_at: string, fixed: boolean, parent_id?: string | null, title: string, parent?: { __typename: 'Folder', id: string, company_id: string, created_at: string, fixed: boolean, title: string } | null, user?: { __typename: 'User', id: string, first_name?: string | null, last_name?: string | null, avatar?: string | null } | null }> } | null };


export const FoldersDocument = gql`
    query Folders($company_id: ID!, $parent_id: ID, $fixed: Boolean, $search: String, $config: QueryConfig) {
  folders(
    company_id: $company_id
    parent_id: $parent_id
    search: $search
    fixed: $fixed
    config: $config
  ) {
    items {
      ...FolderShallow
    }
  }
}
    ${FolderShallowFragmentDoc}`;

/**
 * __useFoldersQuery__
 *
 * To run a query within a React component, call `useFoldersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoldersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoldersQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *      parent_id: // value for 'parent_id'
 *      fixed: // value for 'fixed'
 *      search: // value for 'search'
 *      config: // value for 'config'
 *   },
 * });
 */
export function useFoldersQuery(baseOptions: Apollo.QueryHookOptions<FoldersQuery, FoldersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FoldersQuery, FoldersQueryVariables>(FoldersDocument, options);
      }
export function useFoldersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FoldersQuery, FoldersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FoldersQuery, FoldersQueryVariables>(FoldersDocument, options);
        }
export type FoldersQueryHookResult = ReturnType<typeof useFoldersQuery>;
export type FoldersLazyQueryHookResult = ReturnType<typeof useFoldersLazyQuery>;
export type FoldersQueryResult = Apollo.QueryResult<FoldersQuery, FoldersQueryVariables>;