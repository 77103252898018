import React from 'react'
import InlineMenu from '~/src/components/inline-menu'
import { TIconColors } from '~/src/components/svg-handler'
import { Folder } from '~/src/types'
import { notify } from '~/src/utilities/notify'
import useModal from '~/src/hooks/use-modal'
// import ENDPOINTS from "~/src/graphql/endpoints";

// EXPL: disabling folder downloads for launch due to a bug.
interface IFolderMenu {
  document: Folder
  iconColor: TIconColors
  borderless?: boolean
}

const FolderMenu = ({
  iconColor,
  borderless,
  document: folder,
}: IFolderMenu) => {
  const id = folder.id

  const { setModalOpen } = useModal()
  // const downloadUri = `${ENDPOINTS.root.uri}downloads?company_id=${folder?.company_id}&folder_id=${id}`;

  const baseMenuItems = [
    <button
      aria-label="share folder"
      key="share"
      onClick={() => {
        navigator.clipboard.writeText(
          `${window.location.origin}/#/documents/${id}`
        )

        notify('Copied share URL!', { type: 'success' })
      }}
      className="menu__item"
    >
      Share
    </button>,
    // <a
    //   aria-label="download folder"
    //   key="download"
    //   href={downloadUri}
    //   className="menu__item"
    // >
    //   Download
    // </a>,
  ]

  const conditionalMenuItems = [
    <button
      aria-label="move folder"
      key="move"
      onClick={() => setModalOpen({ name: 'moveFolder', id })}
      className="menu__item"
    >
      Move to ...
    </button>,
    <button
      aria-label="rename folder"
      key="rename"
      onClick={() => setModalOpen({ name: 'editFolder', id })}
      className="menu__item"
    >
      Edit
    </button>,

    <button
      aria-label="delete folder"
      key="delete"
      onClick={() => setModalOpen({ name: 'deleteFolder', id })}
      className="menu__item"
    >
      Delete
    </button>,
  ]

  const children = folder?.fixed
    ? baseMenuItems
    : baseMenuItems.concat(conditionalMenuItems)

  return (
    <InlineMenu iconColor={iconColor} borderless={borderless}>
      {children}
    </InlineMenu>
  )
}

export default FolderMenu
