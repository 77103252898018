import React, { ReactNode, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import Button from '~/src/components/button'
import { FormFields } from '~/src/components/form-fields'
import { FORMS } from '~/src/constants/forms'
import { IAccount } from '~/src/types/account'
import { IMerchant } from '~/src/types/merchant'
import useCreateActionItemReply from '~/src/hooks/use-create-action-item-reply'
import {
  ActionItemCategory,
  IActionItem,
  IAmortizationResponse,
  ICategorizationResponse,
  IMiscellaneousResponse,
} from '~/src/types/action-items'
import { ISelectOption } from '~/src/types/forms'
import { monthOptions } from '~/src/utilities/select'

const getAmortizationValue = (
  amortization: AmortizationOptions
): boolean | undefined =>
  amortization === 'yes' ? true : amortization === 'no' ? false : undefined

const convertAmortizationValue = (amortization?: boolean) =>
  amortization ? 'yes' : amortization === false ? 'no' : undefined

const CREATE_MERCHANT_ID = 'create_new_merchant'

interface IActionItemForm {
  currentUserId: string
  onClose?: () => void
  actionItem: IActionItem
  readOnly?: boolean
  reply?:
    | IAmortizationResponse
    | IMiscellaneousResponse
    | ICategorizationResponse
  accounts: IAccount[]
  merchants: IMerchant[]
  accountOptions: ISelectOption[]
  merchantOptions: ISelectOption[]
}

type AmortizationOptions = 'yes' | 'no'

type FormData = {
  isAmortization: AmortizationOptions
  notes: string
  merchantOption: ISelectOption
  accountOption: ISelectOption
  startDate: Date
  months: ISelectOption
}

const ActionItemForm = ({
  currentUserId,
  onClose,
  actionItem,
  readOnly,
  reply,
  accounts,
  merchants,
  accountOptions,
  merchantOptions,
}: IActionItemForm): JSX.Element => {
  const { id: actionItemId, category } = actionItem
  const createActionItemReply = useCreateActionItemReply({
    authorId: currentUserId,
    actionItemId,
    actionItemType: actionItem.category,
  })

  const onSubmit = async (data: FormData) => {
    const {
      isAmortization: _isAmortization,
      notes,
      startDate,
      months,
      accountOption,
      merchantOption,
    } = data

    const isAmortization = getAmortizationValue(_isAmortization)

    const chosenAccount = accounts.find(({ id }) => id === accountOption?.id)
    const chosenMerchant =
      merchantOption?.id === CREATE_MERCHANT_ID
        ? {
            id: '',
            name: merchantOption.name,
          }
        : merchants.find(({ id }) => id === merchantOption?.id)

    const replyVars = {
      notes,
      is_amortization: isAmortization,
      start_date: startDate,
      service_period_months: months?.id,
      account_id: chosenAccount?.id,
      account_name: chosenAccount?.name,
      merchant_id: chosenMerchant?.id,
      merchant_name: chosenMerchant?.name,
    }

    createActionItemReply(replyVars)
  }

  const defaultValues: Partial<FormData> = {}

  if (reply) {
    if (reply.__typename === 'AmortizationActionItemResponse') {
      Object.assign(defaultValues, {
        isAmortization: convertAmortizationValue(reply.is_amortization),
        startDate: reply.start_date ? new Date(reply.start_date) : undefined,
        months: monthOptions.find(
          ({ id }) => id === reply.service_period_months
        ),
        notes: reply.additional_notes,
      })
    }
    if (reply.__typename === 'CategorizationActionItemResponse') {
      Object.assign(defaultValues, {
        merchantOption:
          // an empty string indicates a merchant to be created
          reply.merchant_id === ''
            ? {
                id: CREATE_MERCHANT_ID,
                name: reply.name,
              }
            : merchantOptions.find(
                ({ id }) => reply?.merchant_id === id.toString()
              ),
        accountOption: accountOptions.find(
          ({ id }) => reply?.account_id === id.toString()
        ),
        notes: reply.additional_notes,
      })
    }
    if (reply.__typename === 'MiscellaneousActionItemResponse') {
      Object.assign(defaultValues, {
        notes: reply.long_answer,
      })
    }
  } else {
    if (actionItem.category === ActionItemCategory.amortization) {
      Object.assign(defaultValues, {
        startDate: new Date(),
      })
    }
  }

  const {
    handleSubmit,
    register,
    watch,
    control,
    formState: { errors },
  } = useForm<FormData>({
    mode: 'onTouched',
    defaultValues,
  })

  const { isAmortization: _isAmortization } = watch()
  const isAmortization = getAmortizationValue(_isAmortization)

  // In this useEffect, we register any rich text fields
  useEffect(() => {
    register('notes')
  }, [register])

  const validatedOnSubmit = handleSubmit(data => {
    onSubmit(data)
    onClose?.()
  })

  return (
    <form className="action-item__form" onSubmit={validatedOnSubmit}>
      {category === ActionItemCategory.categorization && (
        <div className="grid grid-cols-1 tablet:grid-cols-3 gap-4 my-4">
          <FormFields.SelectWithFilter
            disabled={readOnly}
            label="Merchant"
            name="merchantOption"
            control={control}
            rules={{
              ...FORMS.VALIDATION.REQUIRED_SELECT,
            }}
            options={merchantOptions}
            error={errors?.merchantOption?.message as ReactNode}
            create={{
              id: CREATE_MERCHANT_ID,
              actionText: 'Create',
            }}
          />
          <FormFields.Select
            disabled={readOnly}
            label="Account"
            name="accountOption"
            control={control}
            rules={{
              ...FORMS.VALIDATION.REQUIRED_SELECT,
            }}
            options={accountOptions}
            error={errors?.accountOption?.message as ReactNode}
          />
        </div>
      )}

      {category === ActionItemCategory.amortization && (
        <div className="action-item-form__amortization-fields">
          <div>
            <label>Service Period</label>
            <div>
              <div className="flex gap-4">
                <FormFields.Radio
                  disabled={readOnly}
                  value="yes"
                  label="Yes"
                  {...register('isAmortization', {
                    required: true,
                  })}
                />
                <FormFields.Radio
                  disabled={readOnly}
                  value="no"
                  label="No"
                  {...register('isAmortization', {
                    required: true,
                  })}
                />
              </div>
              {errors.isAmortization?.type === 'required' && (
                <p className="form-field__error text-error mb-4">
                  {'You must choose Yes or No' as React.ReactNode}
                </p>
              )}
            </div>
          </div>
          <Controller
            render={({ field: { onChange, value } }) => (
              <FormFields.DatePicker
                label="Start Date"
                disabled={isAmortization === false || readOnly}
                selected={value}
                onChange={onChange}
                placement="bottom-start"
                error={
                  isAmortization
                    ? (errors?.startDate?.message as ReactNode)
                    : undefined
                }
              />
            )}
            rules={{
              required: isAmortization
                ? FORMS.VALIDATION.REQUIRED_FIELD
                : undefined,
            }}
            control={control}
            name="startDate"
            defaultValue={defaultValues.startDate}
          />

          <FormFields.Select
            disabled={isAmortization === false || readOnly}
            label="Service Period Length"
            options={monthOptions}
            control={control}
            name="months"
            error={
              isAmortization
                ? (errors?.months?.message as ReactNode)
                : undefined
            }
            rules={{
              required: isAmortization
                ? FORMS.VALIDATION.REQUIRED_FIELD
                : undefined,
            }}
          />
        </div>
      )}

      <Controller
        render={({ field: { onChange, value } }) => (
          <FormFields.RichText
            disabled={readOnly}
            id="notes"
            value={value}
            onChange={onChange}
            error={errors?.notes?.message as ReactNode}
            label={`Additional Notes${
              category !== ActionItemCategory.miscellaneous ? ' (optional)' : ''
            }`}
          />
        )}
        rules={{
          required:
            category === ActionItemCategory.miscellaneous
              ? FORMS.VALIDATION.REQUIRED_FIELD
              : undefined,
        }}
        control={control}
        name="notes"
      />

      {!readOnly && (
        <div className="action-item__form-controls">
          <Button
            type="button"
            theme="secondary"
            label="Cancel"
            onClick={onClose}
          />
          <Button type="submit" label="Submit Answer" />
        </div>
      )}
    </form>
  )
}

export default ActionItemForm
