export interface IBannerProps {
  calendarRange: Date[]
  dueDate: Date
  bookMonth: string
  formattedDD: string
}

export enum BookStatus {
  READY = 'ready',
  PROCESSING = 'processing',
  NEEDS_RESPONSE = 'needs_response',
  UNBLOCKED = 'unblocked',
}
