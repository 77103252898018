import React from 'react'
import TabSet from '~/src/components/tabset'
import UserIcon from '~/src/components/user-icon'
import ROUTES from '~/src/constants/routes'
import useCurrentUser from '~/src/hooks/use-current-user'
import { Outlet, useOutletContext } from 'react-router-dom'
import PageHeader from '~/src/components/page-header'
import { IMember } from '~/src/types/member'
import { IUser } from '~/src/types/user'

type ProfileContext = { currentUser: IUser; currentMember: IMember | null }

export const useProfileContext = () => {
  return useOutletContext<ProfileContext>()
}

const ProfilePage = (): JSX.Element => {
  const { currentUser, currentMember } = useCurrentUser()

  return (
    <>
      <PageHeader title="Profile">
        <div className="flex flex-row items-center gap-4">
          <UserIcon user={currentUser} size="xl" />
          <h2>
            {currentUser?.first_name} {currentUser?.last_name}
          </h2>
        </div>
      </PageHeader>
      <section>
        <TabSet routes={{ general: ROUTES.profile.subroutes.general }} />
        {/* `currentMember` is `null` if user is not a member of the company */}
        {currentUser && currentMember !== undefined && (
          <Outlet context={{ currentUser, currentMember }} />
        )}
      </section>
    </>
  )
}

export default ProfilePage
