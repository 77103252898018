import React from 'react'
import { useMutation, gql } from '@apollo/client'
import Button from '~/src/components/button'
import CREATE_TEAM_MEMBER_MUTATION from '~/src/graphql/mutations/create-team-member.graphql'
import './add-role-form.css'
import useCurrentUser from '~/src/hooks/use-current-user'
import { FormFields } from '~/src/components/form-fields'
import { useForm } from 'react-hook-form'
import { staffRoleOptions } from '~/src/utilities/select'
import { ISelectOption } from '~/src/types/forms'
import States from '~/src/types/states'
import useTeam from '~/src/hooks/use-team'

interface IAddRoleFormProps {
  onSuccess?: VoidFunction
}

// Define the fields that can exist on the form, so useForm can reference expected data and infer type.
type FormData = {
  memberIdOption: ISelectOption
  roleOption: ISelectOption
}

export const AddRoleForm = ({ onSuccess }: IAddRoleFormProps) => {
  const { currentCompanyId } = useCurrentUser()

  const [createTeamMembership] = useMutation(gql(CREATE_TEAM_MEMBER_MUTATION), {
    refetchQueries: ['TeamMemberQuery', 'MemberQuery'],
  })

  const { teamMembers, userIds, roles, loading } = useTeam({
    limit: 100,
  })

  const onSubmit = async (data: FormData) => {
    const { roleOption, memberIdOption } = data

    const { email_id } =
      teamMembers.find(({ id }) => id === memberIdOption.id) || {}

    await createTeamMembership({
      variables: {
        input: {
          email_id,
          company_id: currentCompanyId,
          state: States.active,
          role: roleOption.id,
        },
      },
    })

    onSuccess?.()
  }

  const accum: ISelectOption[] = []
  const memberOptions: ISelectOption[] = userIds.reduce((acc, id) => {
    const member = teamMembers.find(({ user_id }) => user_id === id)
    if (!member?.id) {
      return acc
    }
    return [
      ...acc,
      {
        id: member.id,
        name: `${member.first_name} ${member.last_name}`,
      },
    ]
  }, accum)

  const roleOptions = staffRoleOptions.filter(
    option => !roles.includes(option.id)
  )

  // Form configuration
  const { handleSubmit, control } = useForm<FormData>({
    mode: 'onSubmit',
    defaultValues: {
      memberIdOption: memberOptions[0],
      roleOption: roleOptions[0],
    },
  })

  return (
    <form className="add-role-form" onSubmit={handleSubmit(onSubmit)}>
      <FormFields.Select
        label="User"
        name="memberIdOption"
        control={control}
        options={memberOptions}
      />
      <FormFields.Select
        label="Role"
        name="roleOption"
        control={control}
        options={roleOptions}
      />
      <Button
        type="submit"
        label={loading ? 'Loading ...' : 'Add Role'}
        theme="primary"
        size="medium"
        className="add-role-form__submit"
      />
    </form>
  )
}

export default AddRoleForm
