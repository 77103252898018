import { ISelectOption } from '~/src/types/forms'
import useAccounts from './use-accounts'

const useAccountOptions = () => {
  const { accounts: _accounts, loading } = useAccounts()

  const accounts = _accounts || []

  const accountOptions: ISelectOption[] = accounts
    .filter(({ id }) => id)
    .sort((a, b) => {
      const nameA = a.name || ''
      const nameB = b.name || ''
      return nameA.toLocaleLowerCase().localeCompare(nameB.toLocaleLowerCase())
    })
    .map(account => {
      return {
        id: account.id as string,
        name: `${account.accountNumber || ''} ${account.name}`.trim(),
      }
    })

  return { accounts, loading, options: accountOptions }
}

export default useAccountOptions
