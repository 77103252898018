import React from 'react'
import { FlowIcons } from '~/src/assets/flow-icons'
import Button from '~/src/components/button'

const AccountPickerButton = ({ onOpen }: { onOpen: VoidFunction }) => {
  return (
    <Button
      label="toggle menu"
      className="account-picker-button ml-auto relative -right-4"
      theme="text"
      icon={FlowIcons.Dots}
      iconPosition="left"
      iconColor="gray"
      iconOnly
      size="medium"
      onClick={onOpen}
    />
  )
}

export default AccountPickerButton
