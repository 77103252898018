import React, { useEffect } from 'react'
import ComboChart from '~/src/components/graphs/combo'
import Info from '~/src/components/info'
import useBurn from '~/src/hooks/use-burn'
import { formatCurrency } from '~/src/utilities/currency'
import Loader from '~/src/components/loader'
import { GRAPH_DATE_FORMAT } from '../../config'
import SVGHandler from '~/src/components/svg-handler'
import { FlowIcons } from '~/src/assets/flow-icons'
import { format } from 'date-fns'
import ReactTooltip from 'react-tooltip'
import { v4 } from 'uuid'
import { TCardSettings } from '~/src/types/card-settings'

const BurnRateCard = ({ startDate, endDate }: TCardSettings) => {
  const {
    netBurnByMonth,
    cashBalancesByMonth,
    burnRate,
    nearZero,
    lastMonthFormatted,
    zeroCashMonth,
    loading,
  } = useBurn({
    startDate,
    endDate,
  })

  const cashBalancesData = {
    name: 'Cash Balances',
    type: 'line',
    color: '#2FD9BA',
    data: cashBalancesByMonth.map(({ sum }) => sum),
  }

  const burnRateData = {
    name: 'Net Burn',
    type: 'column',
    color: '#436CFF',
    data: netBurnByMonth.map(({ sum }) => sum),
  }

  // Tooltip. The useEffect is necessary to attach the tooltip since its
  // parent is conditionally rendered
  const tipId = v4()
  const tipMsg = 'You are running out of cash in less than three months.'

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [nearZero])

  return (
    <>
      {loading ? (
        <Loader size="lg" centered />
      ) : (
        <>
          <ComboChart
            id="burn-rate"
            labels={netBurnByMonth.map(({ date }) =>
              format(date, GRAPH_DATE_FORMAT)
            )}
            series={[cashBalancesData, burnRateData]}
            yFormatter={(number: number) =>
              formatCurrency(number, {
                notation: 'compact',
              })
            }
          />
          <div className="mt-3 grid grid-cols-2 gap-4">
            <div>
              <div className="mb-2 text-xs flex items-center gap-1 text-grayscale-600">
                <SVGHandler
                  size="small"
                  icon={FlowIcons.BurnRate}
                  color="gray"
                />
                Burn Rate (as of {lastMonthFormatted})
              </div>
              <div className="text-base flex items-center gap-2">
                {burnRate !== 0
                  ? formatCurrency(burnRate)
                  : formatCurrency(burnRate)}{' '}
                {nearZero && (
                  <div
                    data-tip={tipMsg}
                    data-for={tipId}
                    className="flex flex-col items-center"
                  >
                    <SVGHandler
                      size="small"
                      icon={FlowIcons.AlertFill}
                      color="warning"
                    />
                  </div>
                )}
              </div>
              <ReactTooltip
                className="panopticon-tooltip"
                id={tipId}
                place="bottom"
                effect="solid"
              />
            </div>
            <div>
              <div className="mb-2 text-xs flex items-center gap-1 text-grayscale-600">
                <SVGHandler
                  size="small"
                  icon={FlowIcons.ZeroCash}
                  color="gray"
                />
                <span>Zero Cash Date</span>
              </div>
              <div>{zeroCashMonth}</div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export const BurnRateCardHeader = () => {
  return (
    <Info message="Burn Rate is calculated by subtracting your total monthly expenses from your total monthly income, found on your Profit and Loss." />
  )
}

export default BurnRateCard
