import React, { useState } from 'react'
import BillsPaid from '~/src/assets/images/bills-paid.svg'
import { IBill } from '~/src/types/bills'
import SortableGrid from '~/src/components/sortable'
import Button from '~/src/components/button'
import SVGHandler from '~/src/components/svg-handler'
import { FlowIcons } from '~/src/assets/flow-icons'
import { formatCurrency } from '~/src/utilities/currency'
import useBills from '~/src/hooks/use-bills'
import useSortableGrid, { defaultOnSort } from '~/src/hooks/use-sortable-grid'
import { TSortableColumn } from '~/src/components/sortable/components/sortable-column'
import Loader from '~/src/components/loader'
import ScrollContainer from '~/src/components/scroll-container'
import { TCardSettings } from '~/src/types/card-settings'
import { add, format } from 'date-fns'
import { GOOGLE_DATE_FORMAT } from '../../config'
import useResponsive from '~/src/hooks/use-responsive'

// Columns for sortable table
const columns: TSortableColumn<IBill & { line_item_descriptions: string[] }>[] =
  [
    {
      label: 'Vendor',
      accessor: 'vendor_name',
      sortable: true,
      default: true,
    },
    {
      label: 'Balance',
      accessor: 'remaining_balance',
      sortable: true,
    },
  ]

const Bills = ({ minDaysOverdue, minBalance }: TCardSettings): JSX.Element => {
  const { isDesktop } = useResponsive()
  const { bills: unsortedBills, loading } = useBills({
    minBalance: minBalance || 0,
    minDaysOverdue: minDaysOverdue || 90,
  })

  const billsWithLineItemDescriptions = unsortedBills.map(bill => ({
    ...bill,
    line_item_descriptions: bill.line_items.map(
      lineItem => lineItem.description
    ),
  }))

  const [filter, setFilter] = useState<string>('')

  const {
    handleSortingChange,
    sortOrder,
    sortField,
    data: bills,
  } = useSortableGrid({
    columns,
    searchTerm: filter,
    filterFields: ['vendor_name', 'line_item_descriptions'],
    data: billsWithLineItemDescriptions,
    onSort: defaultOnSort,
  })

  const totalInRange = (bills: IBill[]) => {
    return bills.reduce((acc, curr) => acc + curr.remaining_balance, 0)
  }

  const showTable = !!unsortedBills?.length

  // Handle creating a GCal link
  const generateGoogleEventUrl = (bill: IBill) => {
    const tomorrowDateString = format(
      add(new Date(), {
        days: 1,
      }),
      GOOGLE_DATE_FORMAT
    ) // tomorrow

    const prefix = 'https://calendar.google.com/calendar/render?action=TEMPLATE'
    const titleFragment = `&text=${bill.vendor_name} bill pay reminder`
    const dateFragment = `&dates=${tomorrowDateString}T120000/${tomorrowDateString}T121500` // tomorrow at 12 pm - 12:15 pm
    const descFragment =
      `&details=Reminder to pay bill <a href="${bill.deep_link}">${bill.doc_number}</a>` +
      `, courtesy of Flow Finance.` +
      (bill.due_date ? ` This bill was due on ${bill.due_date}.` : '') +
      '\n\nPlease update the date and time of this Calendar Event as appropriate.'
    const url = prefix + titleFragment + dateFragment + descFragment
    return encodeURI(url)
  }

  const overdueMsg =
    minDaysOverdue && minDaysOverdue > 0
      ? `${minDaysOverdue}+ days overdue`
      : 'Overdue'

  return (
    <>
      {loading ? (
        <Loader size="lg" centered />
      ) : (
        <div className="h-full flex flex-col">
          <div className="my-4 relative">
            <SVGHandler
              className="absolute left-2 top-3"
              size="small"
              icon={FlowIcons.Search}
              color="gray"
            />
            <input
              className="!pl-8"
              placeholder="Search"
              type="text"
              onChange={e => setFilter(e.target.value)}
            />
          </div>
          {showTable && (
            <>
              <ScrollContainer className="bills-table-wrap">
                <SortableGrid
                  className="bills-invoices-table"
                  columns={columns}
                  handleSortingChange={handleSortingChange}
                  sortOrder={sortOrder}
                  sortField={sortField}
                >
                  {bills?.map(bill => {
                    return (
                      <div className="sortable-grid__row" key={bill.id}>
                        <div>
                          <a
                            className="text-grayscale-900 hover:text-primary"
                            href={isDesktop ? bill.deep_link : '#'}
                            aria-label="view this bill on QBO"
                            target={isDesktop ? '_blank' : '_top'}
                          >
                            <div className="flex items-center h-full">
                              <div className="w-full">
                                <div className="overflow-x-hidden text-ellipsis whitespace-nowrap">
                                  {bill.vendor_name}
                                </div>
                                {!!bill.days_overdue &&
                                  bill.days_overdue > 0 && (
                                    <div className="text-xs text-grayscale-600">
                                      {bill.days_overdue}{' '}
                                      {bill.days_overdue > 1 ? 'days' : 'day'}{' '}
                                      overdue
                                    </div>
                                  )}
                              </div>
                            </div>
                          </a>
                        </div>

                        <div className="h-full flex items-center">
                          {formatCurrency(bill.remaining_balance)}
                          <Button
                            iconOnly
                            label="send calendar event"
                            theme="text"
                            className="ml-auto p-0 !w-8 !h-8 border-grayscale-300"
                            icon={FlowIcons.Calendar}
                            iconPosition="left"
                            iconColor="gray"
                            onClick={() =>
                              window.open(generateGoogleEventUrl(bill), 'blank')
                            }
                            size="medium"
                          />
                        </div>
                      </div>
                    )
                  })}
                </SortableGrid>
                {filter && !bills.length && (
                  <div className="my-4 text-center">No matching results.</div>
                )}
              </ScrollContainer>

              {!!bills.length && (
                <div className="mt-auto pt-4">
                  <div className="mb-2 text-xs flex items-center gap-2 text-grayscale-600">
                    <SVGHandler
                      size="small"
                      icon={FlowIcons.FileInvoice}
                      color="gray"
                    />
                    <span>Unpaid Bills</span>
                  </div>
                  <div>
                    <div>
                      {formatCurrency(totalInRange(bills))} from {bills.length}{' '}
                      {bills.length !== 1 ? 'bills' : 'bill'}.
                    </div>
                    <span className="italic text-xs text-grayscale-600">
                      ({overdueMsg}, {formatCurrency(minBalance as number)} min
                      remaining balance. )
                    </span>
                  </div>
                </div>
              )}
            </>
          )}
          {/* If there are no bills at all to show, show a filler image. */}
          {!showTable && (
            <div className="w-full h-full flex flex-col items-center justify-center">
              <img
                src={BillsPaid}
                alt="A green checkmark shown over a representation of a bill."
              />
              <div className="mt-2 text-sm">All your bills are paid.</div>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default Bills
