import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { FlowIcons } from '~/src/assets/flow-icons'
import Modal from '~/src/components/modal'
import Button from '~/src/components/button'
import Select, { ISelectOption } from '~/src/components/select'
import SVGHandler from '~/src/components/svg-handler'
import UserIcon from '~/src/components/user-icon'
import ACCENT_COLORS from '~/src/constants/graph-colors'
import DELETE_INVITATION from '~/src/graphql/mutations/delete-invitation.graphql'
import DELETE_MEMBER from '~/src/graphql/mutations/delete-member.graphql'
import UPDATE_MEMBER from '~/src/graphql/mutations/update-member.graphql'
import UPDATE_INVITATION from '~/src/graphql/mutations/update-invitation.graphql'
import { IMember } from '~/src/types/member'
import { CustomerRoles } from '~/src/types/roles'
import { notify } from '~/src/utilities/notify'
import { getMemberPagePermissions } from '~/src/utilities/permissions'
import { capitalizeFirstLetter } from '~/src/utilities/string'
import DeleteCompanyMemberForm from '../delete-form'
import './company-member-row.css'
import { IUser } from '~/src/types/user'
import { useResendInvitationMutation } from '~/src/graphql/mutations/generated/resend-invitation'
import { IInvitation } from '~/src/types/invitation'
import useResponsive from '~/src/hooks/use-responsive'

interface IMemberRowProps {
  member: IMember | IInvitation
  index: number
  currentMember: IMember
  currentUser: IUser
}

const roleOptions: ISelectOption[] = [
  CustomerRoles.admin,
  CustomerRoles.member,
].map(role => ({
  name: capitalizeFirstLetter(role),
  id: 0,
  value: role,
}))

const CompanyMemberRow = ({
  member,
  index,
  currentMember,
  currentUser,
}: IMemberRowProps): JSX.Element => {
  const { isTablet } = useResponsive()
  const [, memberRoleOption] = roleOptions
  const defaultRoleOption =
    roleOptions.find(({ value }) => value === member.role) ?? memberRoleOption
  const [role, setRole] = useState(defaultRoleOption)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [expanded, setExpanded] = useState<boolean>(false) // only for responsive mode

  const { first_name, last_name, email_address } = member || {}

  const invitation = member?.__typename === 'Invitation' ? member : null

  const { canEdit } = getMemberPagePermissions(currentUser, currentMember)

  const mutationOptions = {
    refetchQueries: ['CompanyMembersQuery'],
  }

  const [resendInvitation, { loading: invitationEmailLoading }] =
    useResendInvitationMutation({
      variables: {
        input: {
          baseUrl: window.location.origin,
          id: invitation?.id || '',
          sender_id: currentUser.id,
        },
      },
    })

  const [updateInvitation] = useMutation(
    gql(UPDATE_INVITATION),
    mutationOptions
  )

  const [deleteInvitation] = useMutation(gql(DELETE_INVITATION), {
    ...mutationOptions,
    variables: { id: member.id },
  })

  const [updateMember] = useMutation(gql(UPDATE_MEMBER), mutationOptions)

  const [deleteMember] = useMutation(gql(DELETE_MEMBER), {
    ...mutationOptions,
    variables: { id: member.id },
  })

  const handleConfirm = async (e: React.MouseEvent) => {
    e.preventDefault()
    const action = invitation ? deleteInvitation : deleteMember
    const response = await action()
    if (
      response.data?.deleteInvitation?.id ||
      response.data?.deleteMember?.id
    ) {
      notify('User deleted.', {
        type: 'success',
      })
      setModalOpen(false)
    }
  }

  const handleResendInvitation = async () => {
    try {
      await resendInvitation()
      notify('Invitation email resent!', {
        type: 'success',
      })
    } catch (e) {
      notify('Error sending invitation', {
        type: 'error',
      })
    }
  }

  const updateRole = async (option: ISelectOption) => {
    setRole(option)
    const action = invitation ? updateInvitation : updateMember
    const response = await action({
      variables: {
        input: {
          id: member.id,
          role: option.value,
          state: member.state,
        },
      },
    })
    if (
      response.data?.updateInvitation?.id ||
      response.data?.updateMember?.id
    ) {
      notify('Role updated.', {
        type: 'success',
      })
    } else {
      notify('Role could not be updated. Please try again later.', {
        type: 'error',
      })
    }
  }

  return (
    <div className="member-row">
      {isTablet ? (
        <>
          <div className="member-row__name">
            <UserIcon
              className="member-row__user-icon"
              user={member}
              size="sm"
              color={ACCENT_COLORS[index % 10]}
            />
            <div className="member-row__user-name">
              {first_name} {last_name}
            </div>
            <div className="member-row__invitation-state">
              {invitation && capitalizeFirstLetter(invitation.state)}
            </div>
          </div>
          <div className="member-row__email">{email_address}</div>
          <div>
            {canEdit ? (
              <Select
                name="role"
                label={''}
                value={role}
                onChange={updateRole}
                options={roleOptions}
              />
            ) : (
              role.name
            )}
          </div>
          <div className="member-row__actions">
            {invitation && canEdit && (
              <Button
                theme="text"
                label="Resend Invite"
                className="member-row__resend-email"
                onClick={() => handleResendInvitation()}
                disabled={invitationEmailLoading}
              />
            )}
            {canEdit && (
              <button
                aria-label="remove member"
                className="member-row__delete-button"
                data-test={`delete-member-button-${email_address}`}
                onClick={() => setModalOpen(true)}
              >
                <SVGHandler size="medium" icon={FlowIcons.Trash} color="gray" />
              </button>
            )}
          </div>
        </>
      ) : (
        <div className="p-3 bg-white rounded-lg border border-grayscale-300">
          <div
            className="flex flex-row items-center gap-2"
            onClick={() => setExpanded(!expanded)}
          >
            <UserIcon
              className="member-row__user-icon"
              user={member}
              size="sm"
              color={ACCENT_COLORS[index % 10]}
            />
            <div className="flex flex-col gap-3">
              <div className="flex flex-row items-center gap-2">
                <div className="font-bold">
                  {first_name} {last_name}
                </div>
                <div className="member-row__invitation-state">
                  {invitation && capitalizeFirstLetter(invitation.state)}
                </div>
              </div>
              <div className="text-grayscale-600 text-xs">{member.role}</div>
            </div>
            <SVGHandler
              className="ml-auto"
              size="medium"
              icon={expanded ? FlowIcons.ChevronDown : FlowIcons.ChevronRight}
              color="lightgray"
            />
          </div>
          {expanded && (
            <div className="mt-3 pt-3 border-t border-grayscale-300">
              <label>Email</label>
              <div className="member-row__email mb-2">{email_address}</div>
              <div>
                {canEdit ? (
                  <Select
                    name="role"
                    label={'Role'}
                    value={role}
                    onChange={updateRole}
                    options={roleOptions}
                  />
                ) : (
                  role.name
                )}
              </div>
              <div className="flex items-center justify-between mt-3 pt-3 border-t border-grayscale-300">
                {canEdit && (
                  <button
                    aria-label="remove member"
                    className="member-row__delete-button"
                    data-test={`delete-member-button-${email_address}`}
                    onClick={() => setModalOpen(true)}
                  >
                    <SVGHandler
                      size="medium"
                      icon={FlowIcons.Trash}
                      color="gray"
                    />
                  </button>
                )}
                {invitation && canEdit && (
                  <Button
                    theme="text"
                    label="Resend Invite"
                    className="member-row__resend-email pr-0"
                    onClick={() => handleResendInvitation()}
                    disabled={invitationEmailLoading}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      )}

      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        title="Delete Member"
      >
        <DeleteCompanyMemberForm
          member={member}
          handleConfirm={handleConfirm}
        />
      </Modal>
    </div>
  )
}

export default CompanyMemberRow
