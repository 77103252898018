import useCurrentUser from '~/src/hooks/use-current-user'
import { useAccountsQuery } from '../graphql/queries/generated/accounts'
import { AccountType } from '../types/account'

export interface IUseAccountArgs {
  accountTypes?: AccountType[]
  skip?: boolean
}

const useAccounts = (options?: IUseAccountArgs) => {
  const { currentCompanyId } = useCurrentUser()
  const { data, loading } = useAccountsQuery({
    variables: {
      company_id: currentCompanyId || '',
      account_type: options?.accountTypes,
    },
    skip: !currentCompanyId || options?.skip,
  })

  const accounts = data?.accounts

  const getAccount = (id: string) =>
    accounts?.find(account => account.id === id)

  return { accounts, loading, getAccount }
}

export default useAccounts
