import React from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '~/src/components/button'
import { Invitation } from '~/src/components/invitation'
import ROUTES from '~/src/constants/routes'
import { Title } from 'react-head'

export const InvalidInvitation = (): JSX.Element => {
  const navigate = useNavigate()

  return (
    <>
      <Title>FlowFi App | Invalid Invitation</Title>
      <Invitation
        message="Invalid invite"
        messageDetail="This invite is not valid. If you believe this was an error please contact your admin."
        button={
          <Button
            label="Back to home"
            theme="text"
            onClick={() => navigate(ROUTES.dashboard.path)}
          />
        }
      />
    </>
  )
}
