import useCurrentUser from './use-current-user'
import { useFoldersQuery } from '~/src/graphql/queries/generated/folders'
import { SortOrder } from '~/src/types/tables'
import { SortableDocumentFields } from '~/src/types'
import { getTree } from '../utilities/tree'
import useFragment from '~/src/hooks/use-fragment'
import {
  FolderBaseFragmentDoc,
  FolderBaseFragment,
} from '~/src/graphql/fragments/generated/folder-base'

const folderQueryVars = {
  sort_by: [
    {
      direction: SortOrder.ASC,
      sort_field: SortableDocumentFields.TITLE,
      priority: 1,
    },
  ],
  config: {
    // Arbitrarily high limit because we don't have pagination in the modal.
    limit: 1000,
    offset: 0,
  },
}

const useFolderTree = () => {
  const { currentCompany } = useCurrentUser()
  const { id: currentCompanyId } = currentCompany

  const { data: foldersData } = useFoldersQuery({
    variables: {
      company_id: currentCompanyId || '',
      ...folderQueryVars,
    },
    skip: !currentCompanyId,
  })

  const rootFolder = useFragment<FolderBaseFragment>({
    fragment: FolderBaseFragmentDoc,
    id: `Folder:${currentCompany.rootFolder.id}`,
  })

  const { items = [] } = foldersData?.folders || {}

  const transformForTree = ({ title, ...rest }: FolderBaseFragment) => ({
    ...rest,
    label: title,
  })

  const tree =
    rootFolder &&
    items.length &&
    getTree(rootFolder, items, 'parent_id', transformForTree)

  return { tree }
}

export default useFolderTree
