import React, { useState } from 'react'
import Modal from '~/src/components/modal'
import Button from '~/src/components/button'
import { FlowIcons } from '~/src/assets/flow-icons'
import { useForm } from 'react-hook-form'
import { FormFields } from '~/src/components/form-fields'
import BillsInvoicesSettingsButton from './bills-invoices-settings-button'
import { TCardSettings, TDaysOverdue } from '~/src/types/card-settings'

export const useBillsInvoicesSettings = () => {
  const [isBillsInvoicesSettingsOpen, setBillsInvoicesSettingsOpen] =
    useState(false)

  return { isBillsInvoicesSettingsOpen, setBillsInvoicesSettingsOpen }
}

interface IBillsInvoicesSettingsProps {
  defaultValues: BillsInvoicesSettingsFormData
  isBillsInvoicesSettingsOpen: boolean
  setBillsInvoicesSettingsOpen: (arg: boolean) => void
  setCardSettings: (arg: Partial<TCardSettings>) => void
  title: string
}

export type BillsInvoicesSettingsFormData = {
  minBalance: number
  minDaysOverdue: TDaysOverdue
}

const BillsInvoicesSettings = ({
  defaultValues,
  isBillsInvoicesSettingsOpen,
  setBillsInvoicesSettingsOpen,
  setCardSettings,
  title,
}: IBillsInvoicesSettingsProps) => {
  const onClose = () => setBillsInvoicesSettingsOpen(false)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<BillsInvoicesSettingsFormData>({
    defaultValues,
  })

  // Default form state
  const defaultFormValues: BillsInvoicesSettingsFormData = {
    minBalance: 0,
    minDaysOverdue: 90,
  }

  const onSubmit = handleSubmit(data => {
    setCardSettings({
      minBalance: parseInt(data.minBalance.toString()),
      minDaysOverdue: data.minDaysOverdue,
    })
    onClose()
  })

  return (
    <>
      <BillsInvoicesSettingsButton
        setBillsInvoicesSettingsOpen={setBillsInvoicesSettingsOpen}
      />
      <Modal title={title} open={isBillsInvoicesSettingsOpen} onClose={onClose}>
        <form onSubmit={onSubmit}>
          <FormFields.Text
            {...register('minBalance', { required: true })}
            label="Min Balance"
            error={errors.minBalance?.message}
          />

          <div className="form-field mb-8">
            <label htmlFor="minDaysOverdue" className="form-field__label">
              No. of Days Overdue
            </label>
            <select id="minDaysOverdue" {...register('minDaysOverdue')}>
              <option value={30}>30 days</option>
              <option value={90}>90 days</option>
              <option value={180}>180 days</option>
              <option value={365}>365 days</option>
              <option value={0}>All</option>
            </select>
          </div>

          <Button
            type="submit"
            label="Apply"
            size="medium"
            theme="primary"
            className="w-full"
          />
          <Button
            type="button"
            label="Reset"
            size="medium"
            icon={FlowIcons.Undo}
            theme="text"
            onClick={() => reset(defaultFormValues)}
            className="w-full"
          />
        </form>
      </Modal>
    </>
  )
}

export default BillsInvoicesSettings
