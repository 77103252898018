import * as Types from '../../../types/generated/index';

import { gql } from '@apollo/client';
import { UserFragmentDoc } from '../../fragments/generated/user';
import { MemberFragmentDoc } from '../../fragments/generated/member';
import { CompanyFragmentDoc } from '../../fragments/generated/company';
import { FolderShallowFragmentDoc } from '../../fragments/generated/folder-shallow';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CurrentUserQueryVariables = Types.Exact<{
  user_id: Types.Scalars['ID'];
  company_id?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type CurrentUserQuery = { __typename?: 'Query', user?: { __typename?: 'User', avatar?: string | null, first_name?: string | null, id: string, last_name?: string | null, state?: Types.States | null, type?: string | null } | null, member?: { __typename: 'Member', id: string, role?: string | null, state?: Types.States | null, company_id: string, user_id?: string | null, first_name?: string | null, last_name?: string | null, email_id: string, avatar?: string | null, created?: string | null, updated?: string | null, type?: string | null, email_address?: string | null } | null, company?: { __typename?: 'Company', avatar?: string | null, books_start?: string | null, created?: string | null, domain?: string | null, employees?: string | null, fiscal_start?: string | null, id: string, industry?: string | null, location_city?: string | null, location_state?: string | null, location_zip?: string | null, name?: string | null, primary_business_activity?: string | null, primary_poc?: string | null, revenue_model?: string | null, stage?: string | null, state?: Types.States | null, type?: string | null, updated?: string | null, website?: string | null, codat_id?: string | null, rootFolder?: { __typename: 'Folder', id: string, company_id: string, created_at: string, fixed: boolean, parent_id?: string | null, title: string, parent?: { __typename: 'Folder', id: string, company_id: string, created_at: string, fixed: boolean, title: string } | null, user?: { __typename: 'User', id: string, first_name?: string | null, last_name?: string | null, avatar?: string | null } | null } | null } | null };


export const CurrentUserQueryDocument = gql`
    query CurrentUserQuery($user_id: ID!, $company_id: ID) {
  user(id: $user_id) {
    ...UserFragment
  }
  member(user_id: $user_id, company_id: $company_id) {
    ...MemberFragment
  }
  company(id: $company_id) {
    ...CompanyFragment
    rootFolder {
      ...FolderShallow
    }
  }
}
    ${UserFragmentDoc}
${MemberFragmentDoc}
${CompanyFragmentDoc}
${FolderShallowFragmentDoc}`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserQueryDocument, options);
      }
export function useCurrentUserQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserQueryDocument, options);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserQueryLazyQueryHookResult = ReturnType<typeof useCurrentUserQueryLazyQuery>;
export type CurrentUserQueryQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;