import React from 'react'
import BarChart from '~/src/components/graphs/bar'
import useTopVendorExpenses from '~/src/hooks/use-top-vendor-expenses'
import Loader from '~/src/components/loader'
import { formatCurrency } from '~/src/utilities/currency'

const NUM_TO_DISPLAY = 10

const TopPaidVendors = ({
  startDate,
  endDate,
}: {
  startDate: Date
  endDate: Date
}) => {
  const { topPaidVendors, loading } = useTopVendorExpenses({
    startDate,
    endDate,
  })

  const labels = topPaidVendors
    .map(vendor => vendor.vendorName)
    .reverse()
    .slice(0, NUM_TO_DISPLAY)

  const sums = topPaidVendors
    .map(vendor => vendor.totalAmountPaid)
    .reverse()
    .slice(0, NUM_TO_DISPLAY)

  return (
    <>
      {loading ? (
        <Loader size="lg" centered />
      ) : (
        <BarChart
          id="top-paid-vendors"
          seriesName="Total"
          horizontal
          distributed
          data={sums}
          labels={labels}
          xFormatter={(number: number) =>
            formatCurrency(number, {
              notation: 'compact',
            })
          }
        />
      )}
    </>
  )
}

export default TopPaidVendors
