# import UserFragment from "../fragments/user.graphql"

mutation ChangePasswordMutation(
  $id: ID!
  $password: String!
  $new_password: String!
  $password_confirmation: String!
) {
  changePassword(
    id: $id
    password: $password
    new_password: $new_password
    password_confirmation: $password_confirmation
  ) {
    ... on User {
      ...UserFragment
    }
    ... on PasswordIncorrect {
      message
    }
    ... on PasswordNotUnique {
      message
    }
    ... on PasswordUnconfirmed {
      message
    }
  }
}
