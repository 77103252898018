import React, { useState } from 'react'
import useGoogleLogin from '~/src/hooks/use-google-login'
import Button from '~/src/components/button'
import { FormFields } from '~/src/components/form-fields'
import { useForm } from 'react-hook-form'
import { FORMS } from '~/src/constants/forms'
import './entry-form.css'
import Modal from '../modal'
import ResetPassword from '../reset-password'
import EmailSent from '../email-sent'
import { CredentialResponse } from 'google-one-tap'
import Logo from '../logo'

const buttonId = 'google-button-anchor'

interface EntryFormProps {
  error?: string
  email?: string
  children?: React.ReactNode
  description?: string
  onGoogleAuthSuccess: (args: CredentialResponse) => void
  onSubmit: (args: FormData) => void
  submitText: string
  showDisclaimer?: boolean
  showPasswordConfirm?: boolean
  showForgotPassword?: boolean
}

// Define the fields that can exist on the form, so useForm can reference expected data and infer type.
type FormData = {
  email: string
  password: string
  confirmPassword?: string
  disclaimer?: boolean
}

const EntryForm = ({
  error,
  email,
  children,
  description,
  onGoogleAuthSuccess,
  onSubmit,
  submitText,
  showDisclaimer,
  showPasswordConfirm,
  showForgotPassword,
}: EntryFormProps) => {
  const [showModal, setShowModal] = useState(false)
  const [confirmed, setConfirmed] = useState(false)

  // Download Google's "Sign in with Google" script and provide their button.
  useGoogleLogin({
    buttonId,
    callback: onGoogleAuthSuccess,
  })

  // Form configuration
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      email,
    },
  })

  return (
    <>
      <Logo className="mx-auto mb-0" collapsed={false} />
      <section className="entry-form__section">
        <h2 className="entry-form__header">Welcome to FlowFi</h2>
        <p className="entry-form__description">{description}</p>

        <form className="entry-form" onSubmit={handleSubmit(onSubmit)}>
          <p className="form__error-block">{error}</p>
          <div id={buttonId} />
          <div className="entry-form__divider">
            <span className="entry-form__divider-text">OR</span>
          </div>
          <FormFields.Text
            {...register('email', {
              required: FORMS.VALIDATION.REQUIRED_FIELD,
            })}
            id="email"
            label="Email Address"
            error={errors.email?.message}
            testId="email"
          />

          <FormFields.Password
            {...register('password', {
              required: FORMS.VALIDATION.REQUIRED_FIELD,
            })}
            id="password"
            label="Password"
            error={errors.password?.message}
            testId="password"
          />
          {showPasswordConfirm && (
            <FormFields.Password
              {...register('confirmPassword', {
                required: FORMS.VALIDATION.REQUIRED_FIELD,
              })}
              id="confirm-password"
              label="Confirm Password"
              error={errors.password?.message}
              testId="password-confirmation"
            />
          )}

          {showDisclaimer && (
            <FormFields.Checkbox
              {...register('disclaimer', {
                required: FORMS.VALIDATION.REQUIRED_FIELD,
              })}
              id="disclaimer"
              label="I agree with the Terms and Conditions and Privacy Statement."
              error={errors.disclaimer?.message}
            />
          )}

          {showForgotPassword && (
            <div className="entry-form__forgot-password">
              <button
                type="button"
                aria-label="forgot password?"
                className="change-password__forgot !mb-5"
                onClick={() => setShowModal(true)}
              >
                Forgot Password?
              </button>
            </div>
          )}

          <Button
            type="submit"
            size="medium"
            fullWidth
            theme="primary"
            label={submitText}
            testId="entry"
          />
        </form>

        {children}

        <Modal
          title="Reset Password"
          open={showModal}
          onClose={() => {
            setConfirmed(false)
            setShowModal(false)
          }}
        >
          {confirmed ? (
            <EmailSent />
          ) : (
            <ResetPassword onConfirm={() => setConfirmed(true)} />
          )}
        </Modal>
      </section>
    </>
  )
}

export default EntryForm
