import React, { useCallback } from 'react'
import Button from '~/src/components/button'
import Modal from '~/src/components/modal'
import ScrollContainer from '~/src/components/scroll-container'
import SVGHandler from '~/src/components/svg-handler'
import { FlowIcons } from '~/src/assets/flow-icons'
import useCreateAsset from '~/src/hooks/use-create-asset'
import { useDropzone } from 'react-dropzone'
import { notify } from '~/src/utilities/notify'
import * as Sentry from '@sentry/react'
import { useDocumentsContext } from '../documents.context'
import { FormFields } from '~/src/components/form-fields'
import { useForm, useFieldArray } from 'react-hook-form'
import { FORMS } from '~/src/constants/forms'

type FormData = {
  filesToUpload: Array<{
    file: File
    asset: { title: string; description: string; folder_id?: string }
  }>
}
interface IAssetUpload {
  show: boolean
  onClose: () => void
  folderId?: string
}

const AssetUpload = ({
  show,
  onClose,
  className,
  folderId,
}: IAssetUpload & React.HTMLAttributes<HTMLDivElement>) => {
  // "Loading" state handler.
  const { uploading, setUploading } = useDocumentsContext()

  // The batch upload function that both uploads files and associates them with asset objects.
  const { createAssets } = useCreateAsset()

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    mode: 'onSubmit',
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'filesToUpload',
  })

  const onDrop = useCallback(
    (acceptedFiles: File[]) =>
      acceptedFiles.map(file => {
        const defaultValues = {
          file,
          asset: {
            title: file.name,
            description: '',
            folder_id: folderId,
          },
        }

        append(defaultValues)
      }),
    [folderId, append]
  )

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
    useDropzone({
      onDrop,
      multiple: true,
      maxFiles: 10,
      maxSize: 104857600, // 100 MB
    })

  const onSubmit = async ({ filesToUpload }: FormData) => {
    const successMessage = `Your ${
      filesToUpload.length === 1 ? 'file was' : 'files were'
    } successfully uploaded.`

    try {
      setUploading?.(true)
      await createAssets(filesToUpload)
      setUploading?.(false)
      notify(successMessage, {
        type: 'success',
      })
    } catch (err) {
      notify('Unable to upload file.', {
        type: 'error',
      })
      Sentry.captureException(err)
    }

    handleClose()
  }

  const handleClose = () => {
    acceptedFiles.length = 0
    reset()

    onClose()
  }

  const showDropzone = !uploading && !fields.length
  const showMetadataForm = !uploading && !!fields.length

  return (
    <Modal title="Upload a File" open={show} onClose={handleClose}>
      <div className={className}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <section className="container">
            {uploading && (
              <div className="text-grayscale-900 text-sm mb-4">
                Your files are uploading! It's safe to close this dialog; we'll
                notify you when they're done.
              </div>
            )}
            {showDropzone ? (
              <div
                {...getRootProps({
                  className:
                    'dropzone mb-4 p-6 rounded-lg border border-dashed border-primary flex flex-col items-center gap-4',
                })}
                data-test="upload-file-dropzone"
              >
                <input {...getInputProps()} />

                {isDragActive ? (
                  <p className="text-primary">Drop file here.</p>
                ) : (
                  <p className="text-grayscale-900">
                    Drop file here or{' '}
                    <span className="font-semibold text-primary">browse</span>{' '}
                    files
                  </p>
                )}
                <p className="text-grayscale-600 text-xs">
                  Maximum file size: 100 MB
                </p>
              </div>
            ) : null}
            {showMetadataForm ? (
              <ScrollContainer className="document-upload__wrap">
                <ul className="mb-4">
                  {fields.map(({ file }, i) => {
                    return (
                      <li
                        key={i}
                        className="document-upload__file flex flex-col gap-4"
                      >
                        <div className="document-upload__file-info">
                          <SVGHandler
                            className="document-upload__file-icon"
                            icon={FlowIcons.FileText}
                            size="medium"
                            color="gray"
                          />
                          <span className="document-upload__filename">
                            {file.name}
                          </span>
                          <span className="document-upload__file-size">
                            ({(file.size / 1000).toFixed(0)} KB)
                          </span>
                          <button
                            aria-label="remove file"
                            className="document-upload__file-delete"
                            type="button"
                            onClick={() => remove(i)}
                          >
                            Delete
                          </button>
                        </div>

                        <FormFields.Text
                          {...register(`filesToUpload.${i}.asset.title`, {
                            required: FORMS.VALIDATION.REQUIRED_FIELD,
                          })}
                          label="Title"
                          placeholder={file.name}
                          testId="create-form-title-field"
                          error={
                            errors.filesToUpload?.[i]?.asset?.title?.message
                          }
                        />
                        <FormFields.Text
                          {...register(`filesToUpload.${i}.asset.description`)}
                          label="Description"
                          testId="create-form-description-field"
                          placeholder="No description"
                        />
                      </li>
                    )
                  })}
                </ul>
              </ScrollContainer>
            ) : null}
          </section>

          {uploading ? (
            <Button
              className="w-full"
              type="button"
              label="Close"
              data-test="upload-asset-close-button"
              onClick={() => handleClose()}
            />
          ) : (
            <Button
              className="w-full"
              type="submit"
              label="Confirm"
              testId="confirm-file-upload-button"
            />
          )}
        </form>
      </div>
    </Modal>
  )
}

export default AssetUpload
