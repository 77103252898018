import { useQuery, gql } from '@apollo/client'
import EXPENSES_QUERY from '~/src/graphql/queries/expenses.graphql'
import useCurrentUser from './use-current-user'

const useExpenses = () => {
  const { currentCompanyId } = useCurrentUser()
  const { data, loading } = useQuery(gql(EXPENSES_QUERY), {
    variables: { company_id: currentCompanyId },
    skip: !currentCompanyId,
  })

  return { data, loading }
}

export default useExpenses
