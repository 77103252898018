export enum Roles {
  owner = 'owner',
  admin = 'admin',
  member = 'member',
  sales = 'sales',
  bookkeeper = 'bookkeeper',
  cfo = 'cfo',
}

export default Roles

export const StaffRoles = {
  bookkeeper: Roles.bookkeeper,
  sales: Roles.sales,
  cfo: Roles.cfo,
}

export const CustomerRoles = {
  member: Roles.member,
  admin: Roles.admin,
} as const
