import React, { useEffect, useState, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { FlowIcons } from '~/src/assets/flow-icons'
import Button from '~/src/components/button'
import { FormFields } from '~/src/components/form-fields'
import Modal from '~/src/components/modal'
import ScrollContainer from '~/src/components/scroll-container'
import { IAccount, IAccountWithChildren } from '~/src/types/account'
import { TCardSettings } from '~/src/types/card-settings'
import {
  getAccountChildren,
  getAccountParents,
  onlyParents,
} from '~/src/utilities/accounts'
import AccountField from '../account-field'
import AccountPickerButton from './account-picker-button'
import './account-picker.css'
import getHelpers from './utilities'
import { getAccountPickerDefaultValues } from './utilities'

export const useAccountPicker = () => {
  const [isAccountPickerOpen, setAccountPickerOpen] = useState(false)

  return { isAccountPickerOpen, setAccountPickerOpen }
}

interface IAccountPickerProps {
  accounts: IAccount[]
  accountIds: string[]
  isAccountPickerOpen: boolean
  setAccountPickerOpen: (arg: boolean) => void
  setCardSettings: (arg: Partial<TCardSettings>, noSave?: boolean) => void
  maxAccounts?: number
  title: string
}

export type AccountPickerFormAccountIds = {
  [id: string]: boolean
}

export type AccountPickerFormData = {
  accountIds: AccountPickerFormAccountIds
  search: ''
}

const convertMapToList = (map: AccountPickerFormAccountIds): string[] =>
  Object.entries(map).reduce(
    (acc: string[], [key, val]) => (val ? [...acc, key] : acc),
    []
  )

const AccountPicker = ({
  accounts,
  accountIds: _accountIds,
  isAccountPickerOpen,
  maxAccounts,
  setAccountPickerOpen,
  setCardSettings,
  title,
}: IAccountPickerProps) => {
  const defaultAccountIds = getAccountPickerDefaultValues({
    accounts,
    accountIds: _accountIds,
  })

  const accountsWithChildren = accounts.map(account => ({
    ...account,
    children: getAccountChildren(account, accounts),
    parentIds: getAccountParents(account, accounts).map(({ id }) => id),
  })) as unknown as IAccountWithChildren[]

  // Parents are accounts that aren't the children of other accounts in the set
  // They may or may not have children of their own.
  const parents = onlyParents(accountsWithChildren)

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<AccountPickerFormData>({
    defaultValues: { accountIds: defaultAccountIds, search: '' },
    mode: 'onChange',
  })

  const { search, accountIds } = watch()

  const { setChildren, isIndeterminate, noneChecked, someChecked, numChecked } =
    getHelpers(getValues, watch, setValue, accountsWithChildren)

  const onClose = () => setAccountPickerOpen(false)

  const validate = useCallback(() => {
    if (numChecked === 0) {
      setError('accountIds', {
        type: 'validate',
        message: 'You must select at least one item.',
      })
      return
    }

    // if there is a max and more than the max accounts selected
    if (maxAccounts !== undefined && numChecked > maxAccounts) {
      setError('accountIds', {
        type: 'validate',
        message: 'You have selected the maximum amount of items.',
      })
      return
    }

    clearErrors('accountIds')
  }, [clearErrors, maxAccounts, numChecked, setError])

  useEffect(() => {
    validate()
  }, [numChecked, validate, maxAccounts])

  const onChange = (id: string, value: boolean) => {
    setValue(`accountIds.${id}`, value)

    const account = accountsWithChildren.find(
      ({ id: accountId }) => accountId === id
    )

    // This would be weird, tbh.
    if (!account) {
      return
    }

    // Setting a parent's value always sets the children to the same value.
    setChildren(account, value)
  }

  const onSubmit = handleSubmit(data => {
    setCardSettings({
      accountIds: convertMapToList(data.accountIds),
    })

    setAccountPickerOpen(false)
  })

  const onOpen = () => {
    resetForm()
    setAccountPickerOpen(true)
  }

  const resetForm = () => reset({ accountIds: defaultAccountIds, search: '' })

  const applyButtonLabel = `Apply${
    maxAccounts !== undefined ? ` (${numChecked}/${maxAccounts})` : ''
  }`

  const onSelectAll = (checked: boolean) => {
    setValue(
      'accountIds',
      Object.keys(accountIds).reduce(
        (newAccountIds, id) => ({
          ...newAccountIds,
          [id]: checked,
        }),
        {}
      )
    )
  }

  return (
    <>
      <AccountPickerButton onOpen={onOpen} />
      <Modal
        title={`${title} Settings`}
        open={isAccountPickerOpen}
        onClose={onClose}
      >
        <form onSubmit={onSubmit}>
          <FormFields.Text
            {...register('search')}
            id="search"
            label=""
            placeholder="Search"
          />
          {!maxAccounts && (
            <FormFields.Checkbox
              className="mt-4"
              label={noneChecked ? 'Select All' : 'Deselect All'}
              checked={someChecked}
              labelRight
              onChange={e => {
                onSelectAll(e.target.checked)
              }}
              indeterminate={someChecked}
            />
          )}
          <ScrollContainer className="account-tree-wrap">
            <ul className="account-tree">
              {parents.map(parent => (
                <AccountField
                  accountsWithChildren={accountsWithChildren}
                  isIndeterminate={isIndeterminate}
                  key={`${parent.id} ${parent.name}`}
                  register={register}
                  onChange={onChange}
                  path={parent.id as string}
                  account={parent}
                  search={search}
                />
              ))}
            </ul>
          </ScrollContainer>
          {errors.accountIds && (
            <p className="form-field__error text-error mb-4">
              {errors.accountIds?.message as React.ReactNode}
            </p>
          )}
          <Button
            disabled={!!errors?.accountIds?.length}
            type="submit"
            label={applyButtonLabel}
            size="medium"
            theme="primary"
            className="w-full"
          />
          <Button
            type="button"
            label="Reset"
            size="medium"
            icon={FlowIcons.Undo}
            theme="text"
            onClick={resetForm}
            className="w-full"
          />
        </form>
      </Modal>
    </>
  )
}

export default AccountPicker
