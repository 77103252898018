import React from 'react'
import { useDeleteFolderMutation } from '~/src/graphql/mutations/generated/delete-folder'
import {
  FolderShallowFragment,
  FolderShallowFragmentDoc as fragment,
} from '~/src/graphql/fragments/generated/folder-shallow'
import DeleteForm from '~/src/components/delete-form'
import { FetchResult } from '@apollo/client'
import DeleteDocument from './delete-document'
import { FlowIcons } from '~/src/assets/flow-icons'

const DeleteFolder = () => {
  const [deleteFolder, { loading }] = useDeleteFolderMutation()

  const isSuccess = (response: FetchResult) => response.data?.deleteFolder?.id

  const deleteItem = async ({ id, company_id }: FolderShallowFragment) =>
    deleteFolder({
      variables: {
        id,
        company_id,
      },
      refetchQueries: ['DocumentsQuery'],
    })

  const contents = ({ title }: FolderShallowFragment) => (
    <DeleteDocument icon={FlowIcons.FileText} title={title} />
  )

  return (
    <DeleteForm<FolderShallowFragment>
      type="Folder"
      fragment={fragment}
      isSuccess={isSuccess}
      onSuccess="Your folder has been deleted."
      onError="Could not delete folder."
      deleteItem={deleteItem}
      loading={loading}
      contents={contents}
    />
  )
}

export default DeleteFolder
