import React from 'react'
import { Listbox, Transition } from '@headlessui/react'
import SVGHandler from '~/src/components/svg-handler'
import { FlowIcons } from '~/src/assets/flow-icons'
import Classnames from 'classnames'
import { useController, FieldValues } from 'react-hook-form'
import { SelectProps } from '~/src/types/forms'

export const Select = <T extends FieldValues>(
  props: SelectProps<T>
): JSX.Element => {
  const selectClasses = Classnames('form-field', {
    'form-field--invalid': props.error,
    'form-field--disabled': props.disabled,
  })

  const {
    field: { value, onChange },
  } = useController(props)

  return (
    <Listbox
      as="div"
      className={props.className || ''}
      value={value}
      name={props.name}
      disabled={props.disabled}
      onChange={onChange}
    >
      {({ open }) => (
        <div className={selectClasses}>
          <Listbox.Label className="form-field__label">
            {props.label}
          </Listbox.Label>
          <div className="select">
            <Listbox.Button className="select__trigger">
              <div className="select__trigger-text">
                {value ? value.name : 'Select'}
              </div>
              <div className="select__trigger-icon">
                <SVGHandler
                  size="small"
                  icon={open ? FlowIcons.ChevronUp : FlowIcons.ChevronDown}
                  color={open ? 'black' : 'gray'}
                />
              </div>
            </Listbox.Button>

            <Transition
              show={open}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options static className="select__popover">
                {props.options.map((option, index) => (
                  <Listbox.Option
                    key={`${option.name}-${index}`}
                    value={option}
                  >
                    {({ selected, active }) => (
                      <div
                        className={`${
                          active ? 'select__popover-item--active' : ''
                        } select__popover-item`}
                      >
                        <span
                          className={`${
                            selected ? 'select__popover-item-text--active' : ''
                          } select__popover-item-text`}
                        >
                          {option.name}
                        </span>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
          <p className="form-field__error">{props.error}</p>
        </div>
      )}
    </Listbox>
  )
}

export default Select
