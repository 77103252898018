import React from 'react'
import Button from '../../button'
import { FlowIcons } from '~/src/assets/flow-icons'
import { useNavigate } from 'react-router-dom'
import ROUTES from '~/src/constants/routes'
import ActionItems from '~/src/assets/images/answered-action-items.svg'
import useResponsive from '~/src/hooks/use-responsive'

const BooksResponded = (): JSX.Element => {
  const { isDesktop } = useResponsive()
  const navigate = useNavigate()

  return (
    <>
      <div className="tablet:pr-20 flex flex-col items-start">
        <h2 className="bookkeeping-banner__title">
          Thank you for answering your Action Items!
        </h2>
        <p className="bookkeeping-banner__text">
          We'll go ahead and resolve these for you, and let you know once the
          work is done or if we have further questions.
        </p>
        <Button
          className="pl-0 mt-auto tablet:mb-4"
          theme="text"
          textLeft
          label="See your past financials"
          size="medium"
          iconPosition="right"
          icon={FlowIcons.ArrowRight}
          onClick={() => navigate(ROUTES.documents.path)}
        />
      </div>
      {isDesktop && (
        <img src={ActionItems} alt="a list of completed action items" />
      )}
    </>
  )
}

export default BooksResponded
