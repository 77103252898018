import * as Types from '../../../types/generated/index';

import { gql } from '@apollo/client';
export type MemberFragment = { __typename: 'Member', id: string, role?: string | null, state?: Types.States | null, company_id: string, user_id?: string | null, first_name?: string | null, last_name?: string | null, email_id: string, avatar?: string | null, created?: string | null, updated?: string | null, type?: string | null, email_address?: string | null };

export const MemberFragmentDoc = gql`
    fragment MemberFragment on Member {
  __typename
  id
  role
  state
  company_id
  user_id
  first_name
  last_name
  email_address: email
  email_id
  avatar
  created
  updated
  type
}
    `;