import { gql, useQuery } from '@apollo/client'
import COMPANY_QUERY from '~/src/graphql/queries/company.graphql'

const useCompany = (companyId?: string, options?: { skip?: boolean }) => {
  const { data, loading } = useQuery(gql(COMPANY_QUERY), {
    variables: {
      id: companyId,
    },
    skip: !companyId || options?.skip,
  })

  const company = data?.company

  return { company, loading }
}

export default useCompany
