import React from 'react'
import SVGHandler from '../svg-handler'
import { FlowIcons } from '~/src/assets/flow-icons'
import Classnames from 'classnames'
import './percent-change.css'

interface IPercentChange {
  value: number
}

const PercentChange = ({ value }: IPercentChange) => {
  // TODO: This might have to also display a "zero" state in the future.
  const sign = Math.sign(value)

  const icon = sign === -1 ? FlowIcons.ArrowDown : FlowIcons.ArrowUp

  const color = sign === -1 ? 'error' : 'success'

  const changeClasses = Classnames('percent-changed', {
    'percent-changed--positive': sign === 1,
    'percent-changed--negative': sign === -1,
  })

  return (
    <>
      {sign !== 0 ? (
        <div className={changeClasses}>
          <SVGHandler size="small" icon={icon} color={color} />
          {Math.abs(value)}%
        </div>
      ) : null}
    </>
  )
}

export default PercentChange
