import { DocumentNode, useApolloClient } from '@apollo/client'

interface IUseFragmentArgs {
  fragment: DocumentNode
  id: string
}

export const useFragment = <T extends { id: string }>({
  fragment,
  id,
}: IUseFragmentArgs) => {
  const client = useApolloClient()

  const item = client.readFragment<T>({
    fragment,
    id,
  })

  return item
}

export default useFragment
