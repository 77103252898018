import { useState, useCallback, useEffect } from 'react'
import {
  getFromLocalStorage,
  setInLocalStorage,
} from '~/src/utilities/local-storage'
import useCurrentUser from './use-current-user'
import { TCardSettings } from '../types/card-settings'

interface IUseDashboardSettings {
  id: string
  initialSettings?: TCardSettings
}

const getSavedCompanySettings = (
  currentUserId?: string,
  currentCompanyId?: string
) =>
  (currentUserId &&
    currentCompanyId &&
    getFromLocalStorage({
      key: currentUserId,
      path: currentCompanyId,
    })) ??
  {}

const hydrateSettings = ({
  initialSettings,
  currentUserId,
  currentCompanyId,
  cardId,
}: {
  initialSettings?: TCardSettings
  currentUserId?: string
  currentCompanyId?: string
  cardId: string
}) => {
  const saved = getSavedCompanySettings(currentUserId, currentCompanyId)[cardId]

  if (!initialSettings) {
    return
  }

  return {
    range: saved?.range || initialSettings.range,
    startDate: saved?.startDate
      ? new Date(saved.startDate)
      : initialSettings.startDate,
    endDate: saved?.endDate ? new Date(saved.endDate) : initialSettings.endDate,
    accountIds: saved?.accountIds || initialSettings.accountIds,
    minDaysOverdue: saved?.minDaysOverdue || initialSettings.minDaysOverdue,
    minBalance: saved?.minBalance || initialSettings.minBalance,
  }
}

/**
 *
 * This hook could technically be generic, if it took a type argument; for a unique key, it lets you
 * hold user-scoped data in local state, save it to localStorage, and rehydrate it on load.
 * Pretty useful for dashboard settings!
 */
const useDashboardSettings = ({
  id,
  initialSettings,
}: IUseDashboardSettings) => {
  const { currentUserId, currentCompanyId } = useCurrentUser()

  const [settings, setSettings] = useState<TCardSettings>()

  useEffect(() => {
    if (![currentUserId, currentCompanyId, id, initialSettings].every(x => x)) {
      return
    }

    setSettings(
      hydrateSettings({
        initialSettings,
        currentCompanyId,
        currentUserId,
        cardId: id,
      })
    )
  }, [currentUserId, currentCompanyId, id, initialSettings])

  // Shallow merge.
  const updateSettings = useCallback(
    (value: Partial<TCardSettings>, noSave?: boolean) => {
      if (!(currentUserId && currentCompanyId)) {
        return
      }

      const newCardSettings = {
        ...settings,
        ...value,
      }

      const savedCompanySettings = getSavedCompanySettings(
        currentUserId,
        currentCompanyId
      )

      if (!noSave) {
        setInLocalStorage({
          key: currentUserId,
          path: currentCompanyId,
          value: {
            ...savedCompanySettings,
            [id]: newCardSettings,
          },
        })
      }
      setSettings(newCardSettings)
    },
    [currentUserId, id, settings, currentCompanyId]
  )

  return {
    settings,
    updateSettings,
  }
}

export default useDashboardSettings
