# import BalanceFragment from "../fragments/balance.graphql"
# import AccountFragment from "../fragments/account.graphql"

query GetCashBalances($company_id: ID!, $account_type: [AccountType!]) {
  accounts(company_id: $company_id, account_type: $account_type) {
    ...AccountFragment
  }
  cashBalances(company_id: $company_id) {
    ...BalanceFragment
  }
}
