import { gql, useQuery } from '@apollo/client'
import GET_INVITATION_QUERY from '~/src/graphql/queries/invitation.graphql'

interface IUseInvitationOptions {
  invitationId?: string
}

const useInvitation = (options: IUseInvitationOptions) => {
  const { invitationId } = options

  const { data: invitationData, loading } = useQuery(
    gql(GET_INVITATION_QUERY),
    {
      variables: { id: invitationId },
      skip: !invitationId,
    }
  )

  const invitation = invitationData?.invitation

  return { invitation, loading }
}

export default useInvitation
