import { useMemo } from 'react'
import { isWithinInterval, parse } from 'date-fns'
import { groupBy, sortBy, sumBy } from 'lodash'
import { QBO_DATE_FORMAT } from '../pages/dashboard/config'
import { Balance } from '../types/balance'
import useExpenses from './use-expenses'

export interface IUseTopExpenseAccounts {
  sumsByAccount: { id: string; sum: number }[]
  accounts: Balance[]
  loading: boolean
}

const isInRange = (date: string, interval: { start: Date; end: Date }) =>
  isWithinInterval(parse(date, QBO_DATE_FORMAT, new Date()), interval)

const useTopExpenseAccounts = ({
  startDate,
  endDate,
}: {
  startDate: Date
  endDate: Date
}): IUseTopExpenseAccounts => {
  const { data, loading } = useExpenses()

  const memoized = useMemo(() => {
    const expenses: Balance[] = data?.spend || []

    // Only include values in the range. We're building composite data for the whole range.
    const balancesInRange = expenses.filter(balance => {
      const { start: balanceDate } = balance

      return isInRange(balanceDate, {
        start: startDate,
        end: endDate,
      })
    })

    const groupedByAccount = groupBy(balancesInRange, 'accountId')

    const sumsByAccount = Object.keys(groupedByAccount).map(id => ({
      id,
      sum: sumBy(groupedByAccount[id], 'value'),
    }))

    const filtered = sumsByAccount.filter(({ sum }) => sum > 0)
    const sorted = sortBy(filtered, 'sum').reverse()

    return sorted
  }, [data?.spend, startDate, endDate])

  return {
    sumsByAccount: memoized,
    accounts: data?.spend,
    loading,
  }
}
export default useTopExpenseAccounts
