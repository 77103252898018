import React from 'react'
import { useForm } from 'react-hook-form'
import Button from '~/src/components/button'
import { FormFields } from '~/src/components/form-fields'
import { FORMS } from '~/src/constants/forms'
import { useCreateInvitationMutation } from '~/src/graphql/mutations/generated/create-invitation'
import { ISelectOption } from '~/src/types/forms'
import UserTypes from '~/src/types/user-types'
import { notify } from '~/src/utilities/notify'
import { customerRoleOptions } from '~/src/utilities/select'
import { useCompanyContext } from '../..'
import './invite-form.css'

interface CreateInviteFormProps {
  onSuccess?: VoidFunction
}

// Define the fields that can exist on the form, so useForm can reference expected data and infer type.
type FormData = {
  firstName: string
  lastName: string
  email: string
  role: ISelectOption
}

export const CreateInviteForm = ({ onSuccess }: CreateInviteFormProps) => {
  const { currentCompany, currentUser } = useCompanyContext()

  const [createInvitation, { data, loading }] = useCreateInvitationMutation({
    refetchQueries: ['CompanyMembersQuery'],
  })

  const error = data?.createInvitation?.error

  const onSubmit = async (data: FormData) => {
    const { firstName, lastName, email, role } = data

    try {
      const response = await createInvitation({
        variables: {
          input: {
            first_name: firstName,
            last_name: lastName,
            company_id: currentCompany.id,
            email_address: email,
            role: role.id as string,
            type: UserTypes.customer,
            created_by_id: currentUser.id,
            baseUrl: window.location.origin,
          },
        },
      })

      if (response.data?.createInvitation?.invitation) {
        onSuccess?.()
      }
    } catch (e) {
      notify('Unable to send invitation. Please try again later.', {
        type: 'error',
      })
    }
  }

  // Form configuration
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>({
    mode: 'onSubmit',
    // react-hook-form prefers empty default values to undefined.
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      role: customerRoleOptions[0],
    },
  })

  return (
    <form className="invite-form" onSubmit={handleSubmit(onSubmit)}>
      <p className="form__error-block">{error}</p>
      <FormFields.Text
        {...register('firstName', {
          required: FORMS.VALIDATION.REQUIRED_FIELD,
        })}
        label="First Name"
        error={errors.firstName?.message}
        testId="first-name"
      />
      <FormFields.Text
        {...register('lastName', {
          required: FORMS.VALIDATION.REQUIRED_FIELD,
        })}
        label="Last Name"
        testId="last-name"
        error={errors.lastName?.message}
      />
      <FormFields.Text
        {...register('email', {
          ...FORMS.VALIDATION.EMAIL,
        })}
        label="Email"
        testId="email"
        error={errors.email?.message}
      />
      <FormFields.Select
        label="Role"
        name="role"
        testId="role"
        control={control}
        options={customerRoleOptions}
      />
      <Button
        type="submit"
        label={loading ? 'Loading ...' : 'Send Invite'}
        theme="primary"
        size="medium"
        testId="send-invite"
        className="invite-form__submit"
      />
    </form>
  )
}

export default CreateInviteForm
