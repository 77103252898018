# import JiraEpicFragment from "../fragments/jira-epic.graphql"

query JiraEpicsQuery($company_id: ID!) {
  inProgressJiraEpics(company_id: $company_id) {
    ...JiraEpicFragment
    duedate
  }
  blockedJiraEpics(company_id: $company_id) {
    ...JiraEpicFragment
  }
  unblockedJiraEpics(company_id: $company_id) {
    ...JiraEpicFragment
  }
  closedJiraEpics(company_id: $company_id) {
    ...JiraEpicFragment
  }
}
