import React from 'react'

type TTabDefinition = {
  name: string
  key: string
}

interface ITabs {
  tabs: TTabDefinition[]
  onChange: (key: string) => void
  activeTab: string
}

const Tabs = ({ tabs, activeTab, onChange }: ITabs) => {
  return (
    <ul className="snapshot-tabs">
      {tabs.map(tab => {
        return (
          <li key={tab.key}>
            <button
              aria-label={tab.name}
              className={`snapshot-tabs__button ${
                tab.key === activeTab ? 'snapshot-tabs__button--active' : ''
              }`}
              onClick={() => onChange(tab.key)}
            >
              {tab.name}
            </button>
          </li>
        )
      })}
    </ul>
  )
}

export default Tabs
