enum UserTypes {
  admin = 'admin',
  developer = 'developer',
  customer = 'customer',
  accountant = 'accountant',
  representative = 'representative',
}

export { UserTypes }
export default UserTypes
