import React, { useState } from 'react'
import { FlowIcons } from '~/src/assets/flow-icons'
import Button from '~/src/components/button'
import { FormFields } from '~/src/components/form-fields'
import Classnames from 'classnames'
import { UseFormRegisterReturn } from 'react-hook-form'
import './nested-form.css'

export interface NestedFormItem {
  children: NestedFormItem[]
  id: string
  label: string
}

export interface INestedFormFieldStates<T = NestedFormItem> {
  disabled: boolean | ((item: T) => boolean)
  selected: boolean | ((item: T) => boolean)
  hidden: boolean | ((item: T) => boolean)
}
export interface INestedFormProps<T extends NestedFormItem>
  extends INestedFormFieldStates {
  // The nested field
  tree: T
  // A search string
  search?: string
  // Optional: a custom label component
  label?: (
    item: NestedFormItem,
    states: Partial<INestedFormFieldStates>
  ) => JSX.Element
  registerProps: UseFormRegisterReturn<string>
}

const displayProperties = ['hidden', 'disabled', 'selected'] as const

/**
 *
 * A nested checkbox tree that exists in the context of a form.
 */
const NestedForm = <T extends NestedFormItem>(props: INestedFormProps<T>) => {
  const { tree: item, registerProps } = props

  const [childrenShown, toggleChildren] = useState<boolean>(true)

  const [hidden, disabled, selected] = displayProperties.map(key => {
    const property = props[key]
    if (typeof property === 'boolean') {
      return property
    }
    return property(item)
  })

  const ownClasses = Classnames('form-item', {
    'form-item--has-children': !!item.children.length,
    'form-item--hidden': hidden,
    'form-item--disabled': disabled,
  })

  const childClasses = Classnames('form-tree', {
    'form-tree--hidden': !childrenShown,
  })

  const innerWrapperClasses = Classnames('form-tree__inner-wrapper', {
    'form-tree__inner-wrapper--has-children': !!item.children.length,
  })

  const labelClasses = Classnames('form-tree__label', {
    'form-tree__label--disabled': disabled,
    'form-tree__label--selected': selected,
  })

  const label = (
    <div className={labelClasses}>
      {props.label
        ? props.label?.(item, { disabled, selected, hidden })
        : item.label}
    </div>
  )

  return (
    <li className={ownClasses}>
      <div className={innerWrapperClasses}>
        {!!item.children?.length && (
          <Button
            label="toggle children"
            className="h-5 px-0"
            type="button"
            onClick={() => toggleChildren(!childrenShown)}
            icon={
              childrenShown ? FlowIcons.ChevronDown : FlowIcons.ChevronRight
            }
            iconColor="black"
            iconOnly
            theme="text"
          />
        )}

        <FormFields.Radio
          {...registerProps}
          disabled={disabled}
          value={item.id}
          label={label}
          labelRight
        />
      </div>
      {item.children && (
        <ul className={childClasses}>
          {item.children.map(child => (
            <NestedForm
              {...props}
              key={`${child.id} ${child.label}`}
              tree={child}
            />
          ))}
        </ul>
      )}
    </li>
  )
}

export default NestedForm
