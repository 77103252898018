import React, { useState } from 'react'
import Button from '~/src/components/button'
import Loader from '~/src/components/loader'
import Modal from '~/src/components/modal'
import useCurrentUser from '~/src/hooks/use-current-user'
import useTeam from '~/src/hooks/use-team'
import { StaffRoles } from '~/src/types/roles'
import { getTeamPagePermissions } from '~/src/utilities/permissions'
import AddRoleForm from './components/add-role-form'
import TeamCard from './components/team-card'
import PageHeader from '~/src/components/page-header'
import './team.css'

const TeamPage = (): JSX.Element => {
  const { currentUser } = useCurrentUser()
  const [modalOpen, setModalOpen] = useState(false)

  const {
    teamMembers,
    roles: teamRoles,
    loading,
  } = useTeam({
    limit: 100,
  })

  const canEdit = getTeamPagePermissions(currentUser).canEdit
  const canAdd = canEdit && Object.keys(StaffRoles).length > teamRoles.length

  return (
    <>
      <PageHeader title="Team" />
      <section>
        <div className="team-controls">
          {canAdd && (
            <Button
              label="+ Add Role"
              className="ml-auto"
              theme="text"
              size="medium"
              onClick={() => setModalOpen(true)}
            />
          )}
          <Modal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            title="Add Role"
          >
            <AddRoleForm onSuccess={() => setModalOpen(false)} />
          </Modal>
        </div>

        {loading ? (
          <Loader size="lg" fullScreen />
        ) : (
          <div className="team__cards">
            {teamRoles.map((role, index) => {
              const member = teamMembers.find(tm => tm.role === role)
              return (
                member && (
                  <TeamCard
                    key={member.id}
                    member={member}
                    index={index}
                    canEdit={canEdit}
                  />
                )
              )
            })}
          </div>
        )}
      </section>
    </>
  )
}

export default TeamPage
