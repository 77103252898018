import * as Types from '../../../types/generated/index';

import { gql } from '@apollo/client';
import { MemberFragmentDoc } from '../../fragments/generated/member';
import { InvitationFragmentDoc } from '../../fragments/generated/invitation';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CompanyMembersQueryVariables = Types.Exact<{
  company_id?: Types.InputMaybe<Types.Scalars['ID']>;
  user_id: Types.Scalars['ID'];
  substring?: Types.InputMaybe<Types.Scalars['String']>;
  config?: Types.InputMaybe<Types.QueryConfig>;
}>;


export type CompanyMembersQuery = { __typename?: 'Query', members: { __typename?: 'CompanyMemberResponse', count: number, items: Array<{ __typename: 'Invitation', id: string, email_address?: string | null, company_id: string, duration?: number | null, state?: Types.States | null, created?: string | null, first_name?: string | null, last_name?: string | null, role?: string | null, user_id?: string | null } | { __typename: 'Member', id: string, role?: string | null, state?: Types.States | null, company_id: string, user_id?: string | null, first_name?: string | null, last_name?: string | null, email_id: string, avatar?: string | null, created?: string | null, updated?: string | null, type?: string | null, email_address?: string | null }> } };


export const CompanyMembersQueryDocument = gql`
    query CompanyMembersQuery($company_id: ID, $user_id: ID!, $substring: String, $config: QueryConfig) {
  members(
    company_id: $company_id
    user_id: $user_id
    substring: $substring
    config: $config
  ) {
    items {
      ... on Member {
        ...MemberFragment
      }
      ... on Invitation {
        ...InvitationFragment
      }
    }
    count
  }
}
    ${MemberFragmentDoc}
${InvitationFragmentDoc}`;

/**
 * __useCompanyMembersQuery__
 *
 * To run a query within a React component, call `useCompanyMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyMembersQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *      user_id: // value for 'user_id'
 *      substring: // value for 'substring'
 *      config: // value for 'config'
 *   },
 * });
 */
export function useCompanyMembersQuery(baseOptions: Apollo.QueryHookOptions<CompanyMembersQuery, CompanyMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyMembersQuery, CompanyMembersQueryVariables>(CompanyMembersQueryDocument, options);
      }
export function useCompanyMembersQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyMembersQuery, CompanyMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyMembersQuery, CompanyMembersQueryVariables>(CompanyMembersQueryDocument, options);
        }
export type CompanyMembersQueryHookResult = ReturnType<typeof useCompanyMembersQuery>;
export type CompanyMembersQueryLazyQueryHookResult = ReturnType<typeof useCompanyMembersQueryLazyQuery>;
export type CompanyMembersQueryQueryResult = Apollo.QueryResult<CompanyMembersQuery, CompanyMembersQueryVariables>;