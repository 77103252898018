import * as Types from '../../../types/generated/index';

import { gql } from '@apollo/client';
import { AssetFragmentDoc } from '../../fragments/generated/asset';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PaginatedAssetQueryVariables = Types.Exact<{
  company_id: Types.Scalars['ID'];
  search?: Types.InputMaybe<Types.Scalars['String']>;
  config?: Types.InputMaybe<Types.QueryConfig>;
  sort_by?: Types.InputMaybe<Array<Types.AssetSortConfig> | Types.AssetSortConfig>;
}>;


export type PaginatedAssetQuery = { __typename?: 'Query', paginatedAssets?: { __typename?: 'AssetsResponse', count: number, items: Array<{ __typename: 'Asset', id: string, name?: string | null, display_name?: string | null, description?: string | null, created?: string | null, updated?: string | null, url?: string | null, company_id: string, preview_url?: string | null, thumb_url?: string | null, folder_id?: string | null, user?: { __typename?: 'User', id: string, first_name?: string | null, last_name?: string | null } | null }> } | null };


export const PaginatedAssetQueryDocument = gql`
    query PaginatedAssetQuery($company_id: ID!, $search: String, $config: QueryConfig, $sort_by: [AssetSortConfig!]) {
  paginatedAssets(
    company_id: $company_id
    search: $search
    config: $config
    sort_by: $sort_by
  ) {
    count
    items {
      ...AssetFragment
    }
  }
}
    ${AssetFragmentDoc}`;

/**
 * __usePaginatedAssetQuery__
 *
 * To run a query within a React component, call `usePaginatedAssetQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedAssetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedAssetQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *      search: // value for 'search'
 *      config: // value for 'config'
 *      sort_by: // value for 'sort_by'
 *   },
 * });
 */
export function usePaginatedAssetQuery(baseOptions: Apollo.QueryHookOptions<PaginatedAssetQuery, PaginatedAssetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaginatedAssetQuery, PaginatedAssetQueryVariables>(PaginatedAssetQueryDocument, options);
      }
export function usePaginatedAssetQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaginatedAssetQuery, PaginatedAssetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaginatedAssetQuery, PaginatedAssetQueryVariables>(PaginatedAssetQueryDocument, options);
        }
export type PaginatedAssetQueryHookResult = ReturnType<typeof usePaginatedAssetQuery>;
export type PaginatedAssetQueryLazyQueryHookResult = ReturnType<typeof usePaginatedAssetQueryLazyQuery>;
export type PaginatedAssetQueryQueryResult = Apollo.QueryResult<PaginatedAssetQuery, PaginatedAssetQueryVariables>;