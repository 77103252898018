import * as Types from '../../../types/generated/index';

import { gql } from '@apollo/client';
export type FolderShallowFragment = { __typename: 'Folder', id: string, company_id: string, created_at: string, fixed: boolean, parent_id?: string | null, title: string, parent?: { __typename: 'Folder', id: string, company_id: string, created_at: string, fixed: boolean, title: string } | null, user?: { __typename: 'User', id: string, first_name?: string | null, last_name?: string | null, avatar?: string | null } | null };

export const FolderShallowFragmentDoc = gql`
    fragment FolderShallow on Folder {
  __typename
  id
  title: name
  company_id
  created_at
  fixed
  parent_id
  parent {
    __typename
    id
    title: name
    company_id
    created_at
    fixed
  }
  user {
    __typename
    id
    first_name
    last_name
    avatar
  }
}
    `;