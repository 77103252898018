import React from 'react'
import SVGHandler from '~/src/components/svg-handler'
import { FlowIcons } from '~/src/assets/flow-icons'
import { notify } from '~/src/utilities/notify'

interface ITeamEmail {
  email: string
}

const TeamEmail = ({ email }: ITeamEmail): JSX.Element => {
  const copyEmail = (email: string) => {
    navigator.clipboard.writeText(email)

    notify('Copied!', { type: 'success' })
  }

  return (
    <div onClick={() => copyEmail(email)} className="team-member__email">
      {email}
      <SVGHandler
        className="team-member__email-icon"
        icon={FlowIcons.Copy}
        size="medium"
        color="gray"
      />
    </div>
  )
}

export default TeamEmail
