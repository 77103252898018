import React, { useState } from 'react'
import { IChartRefs } from '~/src/types/graphs'
import DashboardReports from './components/dashboard-reports'
// import FinancialReports from "./components/financial-reports"; // EXPL: Disabling due to a backend issue
import SnapshotTabs from './components/tabs'
import './snapshot.css'

interface ISnapshot {
  graphRefs: IChartRefs
}

const tabs = [
  { key: 'dashboard', name: 'Dashboard Reports' },
  // { key: "financial", name: "Financial Statements" },
]

const Snapshot = ({ graphRefs }: ISnapshot): JSX.Element => {
  const [activeTab, setActiveTab] = useState<string>('dashboard')
  return (
    <div>
      <SnapshotTabs tabs={tabs} onChange={setActiveTab} activeTab={activeTab} />
      {activeTab === 'dashboard' && <DashboardReports graphRefs={graphRefs} />}
      {/* {activeTab === "financial" && <FinancialReports />} */}
    </div>
  )
}

export default Snapshot
