import React from 'react'
import Classnames from 'classnames'
import './svg-handler.css'

export interface ISVGHandler {
  icon: string
  size?: TIconSizes
  color?: TIconColors
}

export type TIconSizes =
  | 'smallest'
  | 'smaller'
  | 'small'
  | 'medium'
  | 'large'
  | 'xlarge'
  | 'custom' // directly pass classes for sizing in this case

export type TIconColors =
  | 'primary'
  | 'gray'
  | 'lightgray'
  | 'black'
  | 'warning'
  | 'error'
  | 'success'
  | 'white'

const SVGHandler = ({
  icon,
  size,
  color,
  title,
  className,
}: ISVGHandler & React.HTMLAttributes<HTMLElement>) => {
  const svgClasses = Classnames('svg', {
    [`svg--${color}`]: color,
    [`svg--${size}`]: size,
    ...(className ? { [className]: className } : {}),
  })

  const styles = {
    WebkitMaskImage: `url(${icon})`,
    maskImage: `url(${icon})`,
    WebkitMaskSize: 'cover',
    maskSize: 'cover',
  }

  return color ? (
    <i className={svgClasses} style={styles} title={title} />
  ) : (
    <img src={icon} className={svgClasses} alt={title} />
  )
}

export default SVGHandler
