import { useQuery, gql } from '@apollo/client'
import SUBSCRIPTIONS_QUERY from '~/src/graphql/queries/subscriptions.graphql'
import useCurrentUser from './use-current-user'
import { ISubscription, ITransaction } from '../types/subscription'
import { parse, isAfter, parseISO, compareDesc } from 'date-fns'
import useAccounts from '~/src/hooks/use-accounts'
import { IAccount } from '~/src/types/account'
import useMerchants from './use-merchants'
import { QBO_DATE_FORMAT } from '../pages/dashboard/config'

interface IUseSubscriptionResponse {
  sortableSubscriptions: ISortableSubscription[]
  subscriptions: ISubscription[]
  loading: boolean
}

export type ISortableSubscription = {
  id: string
  amount?: number
  vendorName?: string
  originatingAccount: IAccount
  categorizedAccount: IAccount
  nextExpectedTransactionDate: string
  originatingAccountName: string
  categorizedAccountName: string
}

const useSubscriptions = (): IUseSubscriptionResponse => {
  const { currentCompanyId } = useCurrentUser()
  const { getMerchant } = useMerchants()
  const { getAccount, loading: accountsLoading } = useAccounts()
  const { data, loading } = useQuery(gql(SUBSCRIPTIONS_QUERY), {
    variables: {
      company_id: currentCompanyId,
    },
    skip: !currentCompanyId || accountsLoading,
  })

  const subscriptions: ISubscription[] = data?.subscriptions || []

  const convertSubscriptionToTableSubscription = (
    sub: ISubscription
  ): ISortableSubscription => {
    // Some of the information we want to show is actually about the subscription's
    // most recent transaction.
    const sortedTransactions = [...sub.priorTransactions].sort((a, b) => {
      const [dateA, dateB] = [a, b].map(({ transactionDate }: ITransaction) =>
        parse(transactionDate, QBO_DATE_FORMAT, new Date())
      )

      return compareDesc(dateA, dateB)
    })

    const mostRecentTransaction = sortedTransactions[0]

    const { amount, originatingAccountId, categorizedAccountId, merchantId } =
      mostRecentTransaction

    const merchantName =
      (merchantId && getMerchant(merchantId)?.name) ?? sub.vendorName

    const originatingAccount = getAccount(originatingAccountId) || {}
    const categorizedAccount = getAccount(categorizedAccountId) || {}

    return {
      id: sub.id,
      amount,
      nextExpectedTransactionDate: sub.nextExpectedTransactionDate,
      vendorName: merchantName,
      originatingAccount,
      originatingAccountName: originatingAccount?.name || '',
      categorizedAccount,
      categorizedAccountName: categorizedAccount?.name || '',
    }
  }

  const sortableSubscriptions: ISortableSubscription[] = subscriptions
    .filter(sub =>
      isAfter(parseISO(sub.nextExpectedTransactionDate), new Date())
    )
    .map(convertSubscriptionToTableSubscription)

  return {
    sortableSubscriptions,
    subscriptions,
    loading,
  }
}

export default useSubscriptions
