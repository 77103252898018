import React from 'react'
import AssetPreview from './asset-preview'
import DocumentUpload from './asset-upload'
import Pagination from '~/src/components/pagination'
import DocumentGridRow from './document-grid-row'
import SortableGrid from '~/src/components/sortable'
import { TSortableColumn } from '~/src/components/sortable/components/sortable-column'
import { useDocumentsContext } from '../documents.context'
import { Document, SortableDocumentFields } from '~/src/types'
import useSortableGrid from '~/src/hooks/use-sortable-grid'
import {
  DocumentsQueryVariables,
  useDocumentsQuery,
} from '~/src/graphql/queries/generated/documents'
import useCurrentUser from '~/src/hooks/use-current-user'
import { SortOrder } from '~/src/types/tables'
import GridCell from '~/src/components/grid-cell'
import NoDocuments from '~/src/assets/images/sad-folder.svg'
import ClassNames from 'classnames'
import usePagination from '~/src/hooks/use-pagination'
import Loader from '~/src/components/loader'

type SortableDocumentColumn = TSortableColumn<Document, SortableDocumentFields>

// Columns for sortable table
const columns: SortableDocumentColumn[] = [
  {
    label: 'Title',
    accessor: SortableDocumentFields.TITLE,
    sortable: true,
    initialSortOrder: SortOrder.ASC,
    priority: 1,
  },
  {
    label: 'Description',
  },
  {
    label: 'Date uploaded',
    accessor: SortableDocumentFields.CREATED_AT,
    sortable: true,
    default: true,
    priority: 2,
    initialSortOrder: SortOrder.DESC,
  },
  {
    label: 'Uploaded by',
    accessor: SortableDocumentFields.CREATED_BY_NAME,
    sortable: true,
  },
]

// Lets us set additional default sorts applied after the user's choice of sort.
const getSecondarySorts = <T extends SortableDocumentColumn>(
  primaryAccessor: T['accessor'],
  columns: T[]
) =>
  columns
    .filter(
      ({ priority, accessor, initialSortOrder }) =>
        !!priority && !!initialSortOrder && accessor !== primaryAccessor
    )
    .map(({ accessor, initialSortOrder, priority }) => ({
      direction: initialSortOrder as SortOrder,
      sort_field: accessor as SortableDocumentFields,
      priority: priority ? priority + 1 : 0,
    }))

const DocumentGrid = ({ rootFolderId }: { rootFolderId?: string }) => {
  const { sortOrder, sortField, handleSortingChange } = useSortableGrid<
    Document,
    SortableDocumentFields
  >({
    columns,
  })

  const limit = 8

  const { currentCompanyId } = useCurrentUser()
  const { searchTerm, isUploadModalOpen, setUploadModalOpen } =
    useDocumentsContext()

  const secondarySorts = getSecondarySorts(sortField, columns)

  const documentQueryVars: DocumentsQueryVariables = {
    fixed: false,
    folder_id: rootFolderId,
    company_id: currentCompanyId || '',
    search: searchTerm,
    sort_by: [
      {
        direction: sortOrder,
        sort_field: sortField,
        priority: 1,
      },
      ...secondarySorts,
    ],
    config: {
      limit: limit * 10,
      offset: 0,
    },
  }

  const { data, loading, fetchMore } = useDocumentsQuery({
    variables: documentQueryVars,
    skip: !currentCompanyId || !rootFolderId,
  })

  const { items = [], count = 0 } = data?.documents || {}

  const { pagination, windowedItems } = usePagination<Document>({
    items,
    count,
    fetchMore,
  })

  const overlayClasses = ClassNames('document-grid__overlay', {
    'opacity-0': loading,
  })

  const overlay = (
    <div className={overlayClasses}>
      <img src={NoDocuments} alt="A picture of an empty folder." />
      <h3>
        {searchTerm
          ? 'No documents match your query.'
          : 'This folder is empty.'}
      </h3>
    </div>
  )

  return (
    <div className="p-4 rounded-lg border bg-white border-grayscale-300">
      <div>
        <SortableGrid<Document, SortableDocumentFields>
          className="documents-table"
          columns={columns}
          handleSortingChange={handleSortingChange}
          sortOrder={sortOrder}
          sortField={sortField}
        >
          {loading ? <Loader className="pt-20" size="lg" centered /> : null}
          {Array.from({ ...windowedItems, length: limit }).map(
            (document, index) => {
              return !document ? (
                <div
                  className="sortable-grid__row border-transparent hover:bg-transparent"
                  key={`spacer:${index}`}
                >
                  <GridCell.Cell name="filler"></GridCell.Cell>
                </div>
              ) : (
                <DocumentGridRow key={document?.id} document={document} />
              )
            }
          )}
          {!windowedItems.length && overlay}
        </SortableGrid>

        <Pagination {...pagination} />
        <AssetPreview />
        <DocumentUpload
          folderId={rootFolderId}
          show={isUploadModalOpen}
          onClose={() => setUploadModalOpen?.(false)}
        />
      </div>
    </div>
  )
}

export default DocumentGrid
