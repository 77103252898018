import React from 'react'
import { useForm } from 'react-hook-form'
import { FormFields } from '~/src/components/form-fields'
import { FORMS } from '~/src/constants/forms'
import { ICompany } from '~/src/types/company'

// Define the fields that can exist on the form, so useForm can reference expected data and infer type.
type FormData = {
  name: string
}

const CompanyGeneral = ({ company }: { company: ICompany }): JSX.Element => {
  // Form configuration
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      name: company.name,
    },
    mode: 'onSubmit',
  })

  const onSubmit = () => {
    return
  }

  return (
    <form className="company-page__form" onSubmit={handleSubmit(onSubmit)}>
      <FormFields.Text
        {...register('name', {
          required: FORMS.VALIDATION.REQUIRED_FIELD,
        })}
        id="name"
        label="Company Name"
        error={errors.name?.message}
        disabled
      />
      <div />
    </form>
  )
}

export default CompanyGeneral
