import React from 'react'
import Classnames from 'classnames'
import { InputProps } from '~/src/types/forms'

const Text = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const inputClasses = Classnames('form-field', {
    'form-field--invalid': props.error,
    'form-field--disabled': props.disabled,
  })

  const { testId, ...rest } = props

  return (
    <div className={inputClasses}>
      <label htmlFor={props.id} className="form-field__label">
        {props.label}
      </label>
      <input
        className="form-field__input"
        type="text"
        ref={ref}
        data-test={testId}
        {...rest}
      />
      <p className="form-field__error">{props.error}</p>
    </div>
  )
})

export default Text
