import { IAccount, IAccountWithChildren } from '../types/account'
import { sum } from 'lodash'
import { getParents, getChildren } from './tree'

export const getAccountChildren = (
  parent: IAccount,
  accounts: IAccount[]
): IAccount[] => {
  return getChildren(parent, accounts)
}

export const getAccountParents = (
  child: IAccount,
  accounts: IAccount[]
): IAccount[] => {
  return getParents<IAccount>(child, accounts)
}

export const sumAccounts = (
  accounts: IAccount[],
  sumsByAccount: { id: string; sum: number }[]
) => {
  // For each account, get its total amount for the time period from our list of sums.
  const sums = accounts.map(({ id }) => {
    // If the account doesn't have an id, we'll be unable to find it.
    if (!id) {
      return 0
    }
    // Many accounts won't be in the map because they won't
    // have transactions during this time period, so an undefined value here
    // is perfectly expected.
    return sumsByAccount.find(({ id: accountId }) => id === accountId)?.sum || 0
  })

  return sum(sums)
}

export const onlyParents = (list: IAccountWithChildren[]) => {
  return list.filter(
    account =>
      !list.find(
        ({ children }) => children?.map(({ id }) => id).includes(account.id)
      )
  )
}
