import React, { useState } from 'react'
import Modal from '~/src/components/modal'
import DeleteTeamRoleForm from '../delete-form'
import UserIcon from '~/src/components/user-icon'
import TeamEmail from '~/src/pages/team/components/team-email'
import SVGHandler from '~/src/components/svg-handler'
import { FlowIcons } from '~/src/assets/flow-icons'
import ACCENT_COLORS from '~/src/constants/graph-colors'
import { IMember } from '~/src/types/member'
import { StaffRoles } from '~/src/types/roles'

interface ITeamCardProps {
  member: IMember
  canEdit: boolean
  index: number
}

const roleDisplayName = {
  [StaffRoles.cfo]: 'CFO',
}

const TeamCard = ({ member, canEdit, index }: ITeamCardProps) => {
  const { first_name, last_name, email_address, id } = member
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <div className="team-card__wrapper">
      <div className="team-card" key={id}>
        {canEdit && (
          <button
            aria-label="delete member"
            className="team-member__delete-button"
            onClick={() => setModalOpen(true)}
          >
            <SVGHandler size="medium" icon={FlowIcons.Trash} color="gray" />
          </button>
        )}

        <UserIcon user={member} size="xl" color={ACCENT_COLORS[index % 10]} />
        <div className="team-member__name">{`${first_name} ${last_name}`}</div>
        <TeamEmail email={email_address} />
        <div className="team-member__role">
          {roleDisplayName[member.role] || member.role}
        </div>

        <div className="team-member__contact">
          <a aria-label="contact email" href={`mailto:${member.email_address}`}>
            Contact
          </a>
        </div>

        <Modal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          title="Delete Member"
        >
          <DeleteTeamRoleForm
            member={member}
            onClose={() => setModalOpen(false)}
          />
        </Modal>
      </div>
    </div>
  )
}

export default TeamCard
