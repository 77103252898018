import React from 'react'
import Link from '~/src/components/link'
import LogoIcon from '~/src/assets/images/logo.svg'
import LogoIconCollapsed from '~/src/assets/images/logo-collapsed.svg'
import Classnames from 'classnames'
import './logo.css'

interface ILogo {
  collapsed: boolean
}

const Logo = ({
  collapsed,
  className,
}: ILogo & React.HTMLAttributes<HTMLHeadingElement>): JSX.Element => {
  const logoClasses = Classnames('app-logo', {
    'app-logo--condensed': collapsed,
    ...(className ? { [className]: className } : {}),
  })

  return (
    <h1 className={logoClasses}>
      <Link to={'/'}>
        <object
          type="image/svg+xml"
          data={collapsed ? LogoIconCollapsed : LogoIcon}
        >
          Flow Finance logo
        </object>
      </Link>
    </h1>
  )
}

export default Logo
