export interface IActionItem {
  id: string
  company_id: string
  bookkeeper_id: string
  due_date?: string
  question: string
  category: ActionItemCategory
  state: ActionItemState
  amortization_responses?: IAmortizationResponse[] | null
  categorization_responses?: ICategorizationResponse[] | null
  miscellaneous_responses?: IMiscellaneousResponse[] | null
  transaction_id?: string | null
  transaction_line_item_id?: string | null
  transaction_sync_token?: string | null
  transaction_posting_type?: keyof typeof PostingType | null
}

export interface IAmortizationResponse {
  __typename: 'AmortizationActionItemResponse'
  id: string
  action_item_id: string
  is_amortization: boolean
  start_date: string
  service_period_months: number
  additional_notes: string
}

export interface ICategorizationResponse {
  __typename: 'CategorizationActionItemResponse'
  id: string
  action_item_id: string
  name: string
  account: string
  additional_notes: string
  merchant_id?: string | null
  account_id: string | null
}

export interface IMiscellaneousResponse {
  __typename: 'MiscellaneousActionItemResponse'
  id: string
  action_item_id: string
  long_answer: string
}

export enum ActionItemCategory {
  categorization = 'categorization',
  miscellaneous = 'miscellaneous',
  amortization = 'amortization',
}

export enum ActionItemState {
  // These are the only customer-facing states
  awaiting_reply = 'awaiting_reply',
  ready_for_action = 'ready_for_action',
  // these are internal to Skyforge
  draft = 'draft',
  closed = 'closed',
  reopened = 'reopened',
}

export interface ICreateActionItemInput {
  company_id: string
  bookkeeper_id: string
  due_date?: string | null
  question: string
  category: string
  state: string
  transaction_id?: string | null
  transaction_line_item_id?: string | null
  transaction_sync_token?: string | null
  transaction_posting_type?: PostingType | null
}

export interface IUpdateActionItemStateInput {
  action_item_id: string
  state: ActionItemState
}

export interface IDeleteActionItemInput {
  action_item_id: string
}

export interface IUpdateActionItemContentsInput {
  action_item_id: string
  question: string
  due_date?: string | null
  category: ActionItemCategory
}

export enum PostingType {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
}
