/**
 * Util function to import a third party script.
 */

export default async (
  document: Document,
  id: string,
  src: string,
  onLoad: () => void
) => {
  // Find the first script tag on the page
  const firstJavascriptElement = document.getElementsByTagName('script')[0]
  const script = document.createElement('script')
  script.id = id
  script.src = src
  script.async = true
  script.defer = true

  // If there are existing scripts, insert the Google script before it.
  if (firstJavascriptElement && firstJavascriptElement.parentNode) {
    firstJavascriptElement.parentNode.insertBefore(
      script,
      firstJavascriptElement
    )
  } else {
    // otherwise just start our own party
    document.head.appendChild(script)
  }

  script.onload = () => {
    onLoad?.()
  }
}
