import React from 'react'
import BarChart from '~/src/components/graphs/bar'
import useRevenue from '~/src/hooks/use-revenue'
import Loader from '~/src/components/loader'
import { formatCurrency } from '~/src/utilities/currency'
import { GRAPH_DATE_FORMAT } from '../../config'
import { format } from 'date-fns'
import { TCardSettings } from '~/src/types/card-settings'

const RevenueCard = ({ startDate, endDate, accountIds }: TCardSettings) => {
  const { netSumsByMonth: sumsByMonth, loading } = useRevenue({
    startDate,
    endDate,
    accountIds,
  })

  // Map over sorted list of months.
  const values = sumsByMonth.map(({ sum }) => sum)
  const labels = sumsByMonth.map(({ date }) => format(date, GRAPH_DATE_FORMAT))

  return (
    <>
      {loading ? (
        <Loader size="lg" centered />
      ) : (
        <BarChart
          id="revenue"
          labels={labels}
          seriesName="Revenue"
          data={values}
          labelFormat={{ x: 'dd/MM' }}
          yFormatter={(number: number) =>
            formatCurrency(number, {
              notation: 'compact',
            })
          }
        />
      )}
    </>
  )
}
export default RevenueCard
