import * as Types from '../../../types/generated/index';

import { gql } from '@apollo/client';
import { FolderShallowFragmentDoc } from '../../fragments/generated/folder-shallow';
import { FolderBaseFragmentDoc } from '../../fragments/generated/folder-base';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FolderQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']>;
  company_id?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type FolderQuery = { __typename?: 'Query', folder?: { __typename: 'Folder', id: string, company_id: string, created_at: string, fixed: boolean, parent_id?: string | null, title: string, parents: Array<{ __typename?: 'Folder', id: string, company_id: string, parent_id?: string | null, created_at: string, fixed: boolean, title: string }>, parent?: { __typename: 'Folder', id: string, company_id: string, created_at: string, fixed: boolean, title: string } | null, user?: { __typename: 'User', id: string, first_name?: string | null, last_name?: string | null, avatar?: string | null } | null } | null };


export const FolderDocument = gql`
    query Folder($id: ID, $company_id: ID) {
  folder(id: $id, company_id: $company_id) {
    ...FolderShallow
    parents {
      ...FolderBase
    }
  }
}
    ${FolderShallowFragmentDoc}
${FolderBaseFragmentDoc}`;

/**
 * __useFolderQuery__
 *
 * To run a query within a React component, call `useFolderQuery` and pass it any options that fit your needs.
 * When your component renders, `useFolderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFolderQuery({
 *   variables: {
 *      id: // value for 'id'
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useFolderQuery(baseOptions?: Apollo.QueryHookOptions<FolderQuery, FolderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FolderQuery, FolderQueryVariables>(FolderDocument, options);
      }
export function useFolderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FolderQuery, FolderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FolderQuery, FolderQueryVariables>(FolderDocument, options);
        }
export type FolderQueryHookResult = ReturnType<typeof useFolderQuery>;
export type FolderLazyQueryHookResult = ReturnType<typeof useFolderLazyQuery>;
export type FolderQueryResult = Apollo.QueryResult<FolderQuery, FolderQueryVariables>;