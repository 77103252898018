import {
  format,
  sub,
  set,
  startOfMonth,
  endOfMonth,
  isWithinInterval,
} from 'date-fns'
import { SHORT_DATE_FORMAT } from '../pages/dashboard/config'

export const commonDate = (dateString: string) => {
  return format(new Date(dateString), 'MM-dd-yyyy')
}

// returns the previous month in our short date format
export const getPrevMonthString = () => {
  return format(sub(new Date(), { months: 1 }), SHORT_DATE_FORMAT)
}

/**
 * With no arguments, this gets you last month.
 * An offset of 2 gets you two months ago. And so on.
 */
export const firstDayOfNthMonth = (offset = 1) => {
  const firstDayOfMonth = set(new Date(), { date: 1 })
  return sub(firstDayOfMonth, { months: offset })
}

export const isDateWithinMonth = (date: Date, month: Date) => {
  const start = startOfMonth(month)
  const end = endOfMonth(month)

  return isWithinInterval(date, { start, end })
}

export const startDateFromRange = (range: number) =>
  startOfMonth(
    sub(new Date(), {
      months: range,
    })
  )

export const endDateFromRange = () => endOfMonth(sub(new Date(), { months: 1 }))
