# import ActionItemFragment from "../fragments/action-item.graphql"

query GetActionItems(
  $company_id: ID!
  $states: [ActionItemState!]
  $config: QueryConfig
) {
  actionItems(company_id: $company_id, states: $states, config: $config) {
    ...ActionItemFragment
  }
}
