import React from 'react'
import '../documents.css'
import { useParams, redirect, LoaderFunction } from 'react-router-dom'
import {
  AssetQueryDocument,
  useAssetQuery,
} from '~/src/graphql/queries/generated/asset'
import client from '~/src/apollo/client'
import { selectedCompanyId } from '~/src/cache'
import ROUTES from '~/src/constants/routes'
import useCurrentUser from '~/src/hooks/use-current-user'
import PageHeader from '~/src/components/page-header'
import { download } from '~/src/utilities/s3'
import { notify } from '~/src/utilities/notify'
import { FlowIcons } from '~/src/assets/flow-icons'
import Button from '~/src/components/button'
import FileIcon from '~/src/assets/images/generic-file.svg'

export const fileLoader: LoaderFunction = async ({ params }) => {
  const { assetId } = params
  const currentCompanyId = selectedCompanyId()

  if (!assetId && currentCompanyId) {
    throw redirect(ROUTES.documents.path)
  }

  const { data } = await client.query({
    query: AssetQueryDocument,
    variables: { id: assetId, company_id: currentCompanyId },
  })

  return data?.asset
}

const File = (): JSX.Element => {
  const { assetId } = useParams()
  const { currentCompanyId } = useCurrentUser()

  const { data: assetData } = useAssetQuery({
    variables: { id: assetId || '', company_id: currentCompanyId || '' },
    skip: !currentCompanyId || !assetId,
  })

  const asset = assetData?.asset

  return (
    <>
      <PageHeader title={'Viewing File'} />

      <div className="flex flex-col items-center my-20">
        <img src={FileIcon} alt="A file icon" />
        <h3>{asset?.display_name}</h3>
        <p className="mb-4 italic">{asset?.name}</p>
        <p>{asset?.description}</p>
        <div className="mt-10 flex flex-row items-center">
          <Button
            className="ml-auto"
            theme="text"
            testId="upload-file-button"
            label={'Download'}
            onClick={() => (asset ? download(asset) : null)}
            size="medium"
            icon={FlowIcons.FileDownload}
            iconColor={'primary'}
            disabled={!asset}
          />
          <Button
            className="ml-auto"
            theme="text"
            testId="upload-file-button"
            label={'Share'}
            onClick={() => {
              navigator.clipboard.writeText(
                `${window.location.origin}/#/documents/file/${asset?.id}`
              )
              notify('Copied share URL!', { type: 'success' })
            }}
            size="medium"
            icon={FlowIcons.Share}
            iconColor={'primary'}
          />
        </div>
      </div>
    </>
  )
}

export default File
