import React from 'react'
import Classnames from 'classnames'
import './banner.css'

interface IBanner {
  message: string
  theme?: TBannerTheme
}

type TBannerTheme = 'primary' | 'warning' | 'error'

const Banner = ({
  message,
  theme = 'primary',
  className,
}: IBanner & React.HTMLAttributes<HTMLElement>): JSX.Element => {
  const bannerClasses = Classnames('banner', {
    [`banner--${theme}`]: theme,
    ...(className ? { [className]: className } : {}),
  })

  return <div className={bannerClasses}>{message}</div>
}

export default Banner
