import * as Types from '../../../types/generated/index';

import { gql } from '@apollo/client';
export type CompanyFragment = { __typename?: 'Company', avatar?: string | null, books_start?: string | null, created?: string | null, domain?: string | null, employees?: string | null, fiscal_start?: string | null, id: string, industry?: string | null, location_city?: string | null, location_state?: string | null, location_zip?: string | null, name?: string | null, primary_business_activity?: string | null, primary_poc?: string | null, revenue_model?: string | null, stage?: string | null, state?: Types.States | null, type?: string | null, updated?: string | null, website?: string | null, codat_id?: string | null };

export const CompanyFragmentDoc = gql`
    fragment CompanyFragment on Company {
  avatar
  books_start
  created
  domain
  employees
  fiscal_start
  id
  industry
  location_city
  location_state
  location_zip
  name
  primary_business_activity
  primary_poc
  revenue_model
  stage
  state
  type
  updated
  website
  codat_id
}
    `;