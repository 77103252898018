import { useMediaQuery } from 'react-responsive'
import BREAKPOINTS from '../constants/breakpoints'

const useResponsive = () => {
  /**
   * This hook's primary purpose is to determine if we are at the minimum
   * size to be counted as a tablet or desktop. Other media queries from the
   * Breakpoints constant can be constructed should we need them.
   *
   * If neither isTablet or isDesktop are true, we're mobile.
   */
  const isTablet = useMediaQuery({ minWidth: BREAKPOINTS.TABLET })
  const isDesktop = useMediaQuery({ minWidth: BREAKPOINTS.MD })

  return {
    isTablet,
    isDesktop,
  }
}

export default useResponsive
