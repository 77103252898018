import React from 'react'
import { Outlet } from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'
import Classnames from 'classnames'
import { navCollapsed } from '~/src/cache'
import NavBar from '~/src/components/navbar'
import useCurrentUser from '~/src/hooks/use-current-user'
import SnapshotModal, { GRAPH_REFS } from '~/src/components/snapshot-modal'
import { useLocation } from 'react-router-dom'
import useResponsive from '~/src/hooks/use-responsive'

export const NavContainer = () => {
  const { isDesktop } = useResponsive()
  const location = useLocation()
  const navToggled = useReactiveVar(navCollapsed)
  const { loggedIn, currentCompany } = useCurrentUser()
  const onInvitationPage = location.pathname.includes('invitation')
  const showNav = !!(loggedIn && currentCompany && !onInvitationPage)

  const appContainerClasses = Classnames('app-container', {
    'app-container--nav': showNav,
    'app-container--nav-condensed': (showNav && navToggled) || !isDesktop,
    'app-container--white': !loggedIn || !currentCompany,
  })

  return (
    <>
      <div className={appContainerClasses}>
        {showNav && <NavBar />}
        <main className="app-view-container">
          <Outlet key={location.pathname} />
        </main>
      </div>
      <SnapshotModal graphRefs={GRAPH_REFS} />
    </>
  )
}
