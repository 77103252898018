import React, { HTMLAttributes } from 'react'
import useFragment from '~/src/hooks/use-fragment'
import Confirm from '~/src/components/confirm'
import { notify, NotifyOptions } from '~/src/utilities/notify'
import useModal from '~/src/hooks/use-modal'
import { DocumentNode } from 'graphql'
import { TModalItems, TModalNames } from '~/src/types/modals'
import { FetchResult } from '@apollo/client'

type DeleteFormAction = string | VoidFunction

interface IDeleteProps<T> extends Omit<HTMLAttributes<HTMLElement>, 'onError'> {
  type: TModalItems
  fragment: DocumentNode
  isSuccess: (response: FetchResult) => boolean
  deleteItem: (item: T) => Promise<FetchResult>
  onSuccess: DeleteFormAction
  onError: DeleteFormAction
  contents: (item: T) => JSX.Element
  loading?: boolean
}

const getAction =
  (action: DeleteFormAction, type: NotifyOptions['type']) => () =>
    typeof action === 'string' ? notify(action, { type }) : action()

const action = 'delete'

const DeleteForm = <T extends { id: string }>({
  type,
  contents: Contents,
  isSuccess,
  onError,
  onSuccess,
  deleteItem,
  fragment,
  loading,
}: IDeleteProps<T>) => {
  const name = `${action}${type}` as TModalNames
  const {
    modalId: id,
    show,
    closeModal,
  } = useModal({
    name,
  })

  const handleError = getAction(onError, 'error')
  const handleSuccess = getAction(onSuccess, 'success')

  const item = useFragment<T>({
    fragment,
    id: `${type}:${id}`,
  })

  const onConfirm = async () => {
    closeModal()
    try {
      if (!item) throw new Error()
      const response = await deleteItem(item)
      if (isSuccess(response)) {
        return handleSuccess()
      }
      throw new Error()
    } catch (e) {
      handleError()
    }
  }

  return (
    <Confirm
      label=""
      open={show}
      onClose={closeModal}
      confirmAction={onConfirm}
      buttonTheme="error"
      buttonDisabled={!!loading}
      confirmText="Yes, Delete"
    >
      {item && <Contents {...item} />}
    </Confirm>
  )
}

export default DeleteForm
