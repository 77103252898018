import Classnames from 'classnames'
import React, { useRef, useState } from 'react'
import CompanyIcon from '~/src/components/company-icon'
import Logo from '~/src/components/logo'
import useIntersection from '~/src/hooks/use-intersection'
import { Title } from 'react-head'
import './company-picker.css'
import useCurrentUser from '~/src/hooks/use-current-user'

const CompanyPickerPage = () => {
  const { companies, currentUser, setCompany } = useCurrentUser()
  const [filter, setFilter] = useState<string>('')
  const filteredCompanies = companies.filter(
    company =>
      company.name?.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
  )

  const onSubmit = (value: string) => {
    setCompany(value)
    setFilter('')
  }

  const welcomeText = currentUser?.first_name
    ? `Welcome back, ${currentUser?.first_name}! `
    : 'Welcome back!'

  const companyListClasses = Classnames('company-picker__list', {
    'company-picker__list--scrollable': companies?.length > 5,
  })

  // Use a fancyboi IntersectionObserver to show/hide the shadow at the end of the list.
  const itemList = useRef<HTMLDivElement>(null)
  const lastItem = useRef<HTMLDivElement>(null)
  const onScreen = useIntersection(itemList, lastItem)

  const shadowClasses = Classnames('company-picker__shadow', {
    'company-picker__shadow--visible': !onScreen,
  })

  return (
    <section className="company-picker">
      <Title>FlowFi App | Choose a Company</Title>
      <Logo collapsed={false} />
      <h2 className="company-picker__title">{welcomeText} 👋</h2>
      <div className="company-picker__inner">
        <div>
          <label className="text-center" htmlFor="company-picker">
            Please select a company
          </label>

          <div id="company-picker">
            {companies?.length > 5 ? (
              <input
                placeholder="Search"
                type="text"
                className="company-picker__filter"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFilter(e.target.value)
                }
              />
            ) : null}
            <div ref={itemList} className={companyListClasses}>
              {filteredCompanies.map(company => (
                <button
                  aria-label={company.name}
                  className="company-picker__button"
                  key={company.id}
                  onClick={() => onSubmit(company.id)}
                >
                  <CompanyIcon size="md" company={company} />
                  {company.name}
                  <div className="company-picker__button-select">Select →</div>
                </button>
              ))}
              <div ref={lastItem} />
            </div>
          </div>
          {companies?.length > 5 ? <div className={shadowClasses}></div> : null}
        </div>
      </div>
    </section>
  )
}

export default CompanyPickerPage
