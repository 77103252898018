export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

/** Many of these fields appear to come from Intuit ('chart of accounts') and documentation for them is here: 'https://developer.intuit.com/app/developer/qbo/docs/api/accounting/most-commonly-used/account' */
export type Account = {
  __typename?: 'Account';
  accountNumber?: Maybe<Scalars['String']>;
  accountSubTypeEnum?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  last4?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export enum AccountType {
  ACCOUNTS_PAYABLE = 'ACCOUNTS_PAYABLE',
  ACCOUNTS_RECEIVABLE = 'ACCOUNTS_RECEIVABLE',
  BANK = 'BANK',
  COGS = 'COGS',
  CREDIT_CARDS = 'CREDIT_CARDS',
  EQUITY = 'EQUITY',
  EXPENSE = 'EXPENSE',
  FIXED_ASSETS = 'FIXED_ASSETS',
  INCOME = 'INCOME',
  LONG_TERM_LIABILITIES = 'LONG_TERM_LIABILITIES',
  OTHER_ASSETS = 'OTHER_ASSETS',
  OTHER_CURRENT_ASSETS = 'OTHER_CURRENT_ASSETS',
  OTHER_CURRENT_LIABILITIES = 'OTHER_CURRENT_LIABILITIES',
  OTHER_EXPENSES = 'OTHER_EXPENSES',
  OTHER_INCOME = 'OTHER_INCOME'
}

export type ActionItem = {
  __typename?: 'ActionItem';
  amortization_responses?: Maybe<Array<AmortizationActionItemResponse>>;
  bookkeeper_id: Scalars['ID'];
  categorization_responses?: Maybe<Array<CategorizationActionItemResponse>>;
  category: Scalars['String'];
  company_id: Scalars['ID'];
  created_at: Scalars['String'];
  due_date?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  jira_id?: Maybe<Scalars['String']>;
  miscellaneous_responses?: Maybe<Array<MiscellaneousActionItemResponse>>;
  question: Scalars['String'];
  state: Scalars['String'];
  transaction_id?: Maybe<Scalars['String']>;
  transaction_line_item_id?: Maybe<Scalars['String']>;
  transaction_posting_type?: Maybe<Scalars['String']>;
  transaction_sync_token?: Maybe<Scalars['String']>;
  updated_at: Scalars['String'];
};

export enum ActionItemState {
  AWAITING_REPLY = 'awaiting_reply',
  CLOSED = 'closed',
  DRAFT = 'draft',
  READY_FOR_ACTION = 'ready_for_action',
  REOPENED = 'reopened'
}

export type AmortizationActionItemResponse = {
  __typename?: 'AmortizationActionItemResponse';
  action_item_id: Scalars['ID'];
  additional_notes?: Maybe<Scalars['String']>;
  author_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  is_amortization: Scalars['Boolean'];
  response_time: Scalars['String'];
  service_period_months?: Maybe<Scalars['Float']>;
  start_date?: Maybe<Scalars['String']>;
};

export type AmortizationActionItemResponseInput = {
  action_item_id?: InputMaybe<Scalars['ID']>;
  additional_notes?: InputMaybe<Scalars['String']>;
  author_id?: InputMaybe<Scalars['ID']>;
  is_amortization?: InputMaybe<Scalars['Boolean']>;
  service_period_months?: InputMaybe<Scalars['Float']>;
  start_date?: InputMaybe<Scalars['String']>;
};

export type Asset = {
  __typename?: 'Asset';
  company_id: Scalars['ID'];
  /** Unix timestamp */
  created?: Maybe<Scalars['String']>;
  /** An optional description about the file */
  description?: Maybe<Scalars['String']>;
  /** An editable name to display for the file */
  display_name?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  /** Optional: the folder this asset is in */
  folder_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  last_name?: Maybe<Scalars['String']>;
  /** The name of the file as uploaded */
  name?: Maybe<Scalars['String']>;
  /** Url to access a preview if it exists */
  preview_url?: Maybe<Scalars['String']>;
  /** Url to access a thumbnail if it exists */
  thumb_url?: Maybe<Scalars['String']>;
  /** Unix timestamp */
  updated?: Maybe<Scalars['String']>;
  /** The url to access the original file */
  url?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  /** The user associated with this asset, usually its creator */
  user_id?: Maybe<Scalars['ID']>;
  user_role?: Maybe<Scalars['String']>;
  user_type?: Maybe<Scalars['String']>;
};

export type AssetSortConfig = {
  direction?: InputMaybe<SortOrder>;
  priority: Scalars['Float'];
  sort_field: SortableAssetFields;
};

export type AssetsResponse = {
  __typename?: 'AssetsResponse';
  count: Scalars['Float'];
  items: Array<Asset>;
};

export type AuthResponse = {
  __typename?: 'AuthResponse';
  error?: Maybe<Scalars['String']>;
  member?: Maybe<Member>;
  token?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type CardDataBalance = {
  __typename?: 'CardDataBalance';
  /** @deprecated Unclear field name */
  account?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['String']>;
  accountName?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  value: Scalars['Float'];
};

/** A net sum of cash balances for a time period. Previously we returned these as regular 'balance' objects with the name 'Net Burn' etc, but the names won't match to an account, and they don't have external ids. */
export type CardDataBalanceSum = {
  __typename?: 'CardDataBalanceSum';
  /** @deprecated Unclear field name */
  account?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  value: Scalars['Float'];
};

export type CardDataBill = {
  __typename?: 'CardDataBill';
  deep_link: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  doc_number?: Maybe<Scalars['String']>;
  due_date: Scalars['String'];
  id: Scalars['ID'];
  line_items: Array<IaparLineItem>;
  original_balance: Scalars['Float'];
  remaining_balance: Scalars['Float'];
  transaction_date: Scalars['String'];
  vendor_email?: Maybe<Scalars['String']>;
  vendor_name: Scalars['String'];
};

export type CardDataInvoice = {
  __typename?: 'CardDataInvoice';
  customer_email?: Maybe<Scalars['String']>;
  customer_name: Scalars['String'];
  deep_link: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  doc_number?: Maybe<Scalars['String']>;
  due_date: Scalars['String'];
  id: Scalars['ID'];
  line_items: Array<IaparLineItem>;
  original_balance: Scalars['Float'];
  remaining_balance: Scalars['Float'];
  transaction_date: Scalars['String'];
};

export type CategorizationActionItemResponse = {
  __typename?: 'CategorizationActionItemResponse';
  account: Scalars['String'];
  account_id?: Maybe<Scalars['String']>;
  action_item_id: Scalars['ID'];
  additional_notes?: Maybe<Scalars['String']>;
  author_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  merchant_id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  response_time: Scalars['String'];
};

export type CategorizationActionItemResponseInput = {
  account?: InputMaybe<Scalars['String']>;
  account_id?: InputMaybe<Scalars['String']>;
  action_item_id?: InputMaybe<Scalars['ID']>;
  additional_notes?: InputMaybe<Scalars['String']>;
  author_id?: InputMaybe<Scalars['ID']>;
  merchant_id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type ChangePasswordResponse = PasswordIncorrect | PasswordNotUnique | PasswordUnconfirmed | User;

export type Company = {
  __typename?: 'Company';
  avatar?: Maybe<Scalars['String']>;
  books_start?: Maybe<Scalars['String']>;
  codat_id?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  employees?: Maybe<Scalars['String']>;
  fiscal_start?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  industry?: Maybe<Scalars['String']>;
  location_city?: Maybe<Scalars['String']>;
  location_state?: Maybe<Scalars['String']>;
  location_zip?: Maybe<Scalars['String']>;
  members: CompanyMemberResponse;
  name?: Maybe<Scalars['String']>;
  primary_business_activity?: Maybe<Scalars['String']>;
  primary_poc?: Maybe<Scalars['String']>;
  revenue_model?: Maybe<Scalars['String']>;
  rootFolder?: Maybe<Folder>;
  root_folder_id?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['String']>;
  state?: Maybe<States>;
  teamMembers: MemberResponse;
  type?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};


export type CompanyMembersArgs = {
  config?: InputMaybe<QueryConfig>;
  substring?: InputMaybe<Scalars['String']>;
};


export type CompanyTeamMembersArgs = {
  config?: InputMaybe<QueryConfig>;
  substring?: InputMaybe<Scalars['String']>;
};

export type CompanyMemberResponse = {
  __typename?: 'CompanyMemberResponse';
  count: Scalars['Float'];
  items: Array<CompanyMemberUnion>;
};

/** May contain 'duplicates'! Neither company memberships or invitations have unique constraints on users, and company memberships actively leverage this to allow Flow staff to hold multiple roles at a company. */
export type CompanyMemberUnion = Invitation | Member;

export type CreateActionItemInput = {
  bookkeeper_id?: InputMaybe<Scalars['ID']>;
  category?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['ID']>;
  due_date?: InputMaybe<Scalars['String']>;
  jira_id?: InputMaybe<Scalars['String']>;
  question?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  transaction_id?: InputMaybe<Scalars['String']>;
  transaction_line_item_id?: InputMaybe<Scalars['String']>;
  transaction_posting_type?: InputMaybe<Scalars['String']>;
  transaction_sync_token?: InputMaybe<Scalars['String']>;
};

export type CreateAssetInput = {
  company_id: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  display_name?: InputMaybe<Scalars['String']>;
  /** The id of the parent folder, if one exists */
  folder_id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  preview_url?: InputMaybe<Scalars['String']>;
  thumb_url?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
  /** The user associated with this action, if one exists */
  user_id?: InputMaybe<Scalars['ID']>;
};

export type CreateFolderInput = {
  company_id: Scalars['ID'];
  /** True if this folder should be 'fixed' or uneditable */
  fixed?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  /** The id of the parent folder, if one exists */
  parent_id?: InputMaybe<Scalars['ID']>;
  /** The user associated with this action, if one exists */
  user_id?: InputMaybe<Scalars['ID']>;
};

export type CreateInvitationInput = {
  baseUrl: Scalars['String'];
  company_id: Scalars['String'];
  created_by_id: Scalars['ID'];
  email_address: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  role: Scalars['String'];
  type: Scalars['String'];
};

export type CreateInvitationResponse = {
  __typename?: 'CreateInvitationResponse';
  error?: Maybe<Scalars['String']>;
  invitation?: Maybe<Invitation>;
};

export type CreatePasswordResetResponse = {
  __typename?: 'CreatePasswordResetResponse';
  error?: Maybe<Scalars['String']>;
};

export type DeleteActionItemInput = {
  action_item_id?: InputMaybe<Scalars['ID']>;
};

export type DeleteAssetResponse = {
  __typename?: 'DeleteAssetResponse';
  id: Scalars['ID'];
};

export type DeleteFolderResponse = {
  __typename?: 'DeleteFolderResponse';
  id: Scalars['ID'];
};

export type DeleteInvitationResponse = {
  __typename?: 'DeleteInvitationResponse';
  id: Scalars['ID'];
};

export type DeleteMemberResponse = {
  __typename?: 'DeleteMemberResponse';
  id: Scalars['ID'];
};

export type DeleteUserResponse = {
  __typename?: 'DeleteUserResponse';
  /** If successful, the ID of the deleted user */
  id?: Maybe<Scalars['String']>;
};

/** A union of folders and assets */
export type Document = Asset | Folder;

export type DocumentResponse = {
  __typename?: 'DocumentResponse';
  count: Scalars['Float'];
  items: Array<Document>;
};

export type DocumentSortConfig = {
  direction?: InputMaybe<SortOrder>;
  priority: Scalars['Float'];
  sort_field: SortableDocumentFields;
};

export type Email = {
  __typename?: 'Email';
  address: Scalars['String'];
  created?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  state: States;
  updated?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type Expense = {
  __typename?: 'Expense';
  /** Format: '205.36'. Can be negative: '-367.36'  */
  amount: Scalars['Float'];
  /** Format: '2022-03-01' */
  date: Scalars['String'];
};

export type Folder = {
  __typename?: 'Folder';
  company_id: Scalars['ID'];
  created_at: Scalars['String'];
  created_by_id?: Maybe<Scalars['ID']>;
  documents: DocumentResponse;
  fixed: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  /** A folder's parent */
  parent?: Maybe<Folder>;
  parent_id?: Maybe<Scalars['ID']>;
  parents: Array<Folder>;
  updated_at: Scalars['String'];
  updated_by_id?: Maybe<Scalars['ID']>;
  user?: Maybe<User>;
};


export type FolderDocumentsArgs = {
  company_id: Scalars['ID'];
  config?: InputMaybe<QueryConfig>;
  folder_id?: InputMaybe<Scalars['ID']>;
  search?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Array<DocumentSortConfig>>;
};

export type FoldersResponse = {
  __typename?: 'FoldersResponse';
  count: Scalars['Float'];
  items: Array<Folder>;
};

export type IaparLineItem = {
  __typename?: 'IAPARLineItem';
  description?: Maybe<Scalars['String']>;
  original_amount: Scalars['Float'];
};

export type Invitation = {
  __typename?: 'Invitation';
  /** @deprecated Denormalized data could be out of date */
  company_avatar?: Maybe<Scalars['String']>;
  company_id: Scalars['ID'];
  /** @deprecated Denormalized data could be out of date */
  company_name?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  /** Historically, this could have been nullable, but it should always be present on new invitations. */
  email_address?: Maybe<Scalars['String']>;
  /** @deprecated Unused by new invite flow and should always be blank */
  email_id?: Maybe<Scalars['ID']>;
  first_name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  last_name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  /** The state of the invitation */
  state?: Maybe<States>;
  /** @deprecated Beware: this field doesn't exist in the database */
  updated?: Maybe<Scalars['String']>;
  /** If present, the id of the user who can redeem this invitation  */
  user_id?: Maybe<Scalars['ID']>;
};

export type JiraEpic = {
  __typename?: 'JiraEpic';
  duedate?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  key?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  summary: Scalars['String'];
};

export type Member = {
  __typename?: 'Member';
  avatar?: Maybe<Scalars['String']>;
  company_id: Scalars['ID'];
  created?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  email_id: Scalars['ID'];
  first_name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  last_name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  state?: Maybe<States>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['ID']>;
};

export type MemberInput = {
  company_id: Scalars['ID'];
  email_id: Scalars['ID'];
  role: Scalars['String'];
  state: Scalars['String'];
};

export type MemberResponse = {
  __typename?: 'MemberResponse';
  count: Scalars['Float'];
  items: Array<Member>;
};

export type Merchant = {
  __typename?: 'Merchant';
  companyId: Scalars['ID'];
  id: Scalars['ID'];
  name: Scalars['String'];
  /** TODO: what is this? Seems like an enum */
  type: Scalars['String'];
};

export type MiscellaneousActionItemResponse = {
  __typename?: 'MiscellaneousActionItemResponse';
  action_item_id: Scalars['ID'];
  author_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  long_answer: Scalars['String'];
  response_time: Scalars['String'];
};

export type MiscellaneousActionItemResponseInput = {
  action_item_id?: InputMaybe<Scalars['ID']>;
  author_id?: InputMaybe<Scalars['ID']>;
  long_answer?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Creates a new user or links existing to the company to which they're invited. */
  acceptInvite: AuthResponse;
  batchCreateActionItem: Array<ActionItem>;
  changePassword: ChangePasswordResponse;
  createActionItem: ActionItem;
  createAmortizationActionItemResponse: AmortizationActionItemResponse;
  createAsset: Asset;
  createCategorizationActionItemResponse: CategorizationActionItemResponse;
  createFolder: Folder;
  /** Create a single invitation */
  createInvitation: CreateInvitationResponse;
  /** Create a company membership, or update it if one exists. Unique for a given user and company */
  createMember?: Maybe<Member>;
  createMiscellaneousActionItemResponse: MiscellaneousActionItemResponse;
  createPasswordReset?: Maybe<CreatePasswordResetResponse>;
  /** Create a team membership, or update it if one exists. Unique on role, for a given user and company */
  createTeamMember?: Maybe<Member>;
  deleteActionItem: ActionItem;
  deleteAsset: DeleteAssetResponse;
  /** Delete a folder and its contents recursively */
  deleteFolder?: Maybe<DeleteFolderResponse>;
  /** Delete an invitation so it can no longer be redeemed. */
  deleteInvitation: DeleteInvitationResponse;
  /** Remove the company membership that joins a user and a company */
  deleteMember: DeleteMemberResponse;
  /** Delete a user, their email records, and their company memberships. */
  deleteUser: DeleteUserResponse;
  loginWithGoogle: AuthResponse;
  loginWithPassword: AuthResponse;
  /** Resend an invitation */
  resendInvitation: ResendInvitationResponse;
  resetPassword: ResetPasswordResponse;
  updateActionItemContents: ActionItem;
  updateActionItemState: ActionItem;
  updateAsset: Asset;
  updateCompany: Company;
  updateCompanyProfile: Company;
  updateFolder: Folder;
  /** Update an invitation */
  updateInvitation: Invitation;
  /** Update the role or state of an existing company membership */
  updateMember?: Maybe<Member>;
  /** Update a user */
  updateUser: User;
};


export type MutationAcceptInviteArgs = {
  credential?: InputMaybe<Scalars['String']>;
  invitation_id: Scalars['ID'];
  password?: InputMaybe<Scalars['String']>;
  password_confirmation?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['ID']>;
  username?: InputMaybe<Scalars['String']>;
};


export type MutationBatchCreateActionItemArgs = {
  input: Array<CreateActionItemInput>;
};


export type MutationChangePasswordArgs = {
  id: Scalars['ID'];
  new_password: Scalars['String'];
  password: Scalars['String'];
  password_confirmation: Scalars['String'];
};


export type MutationCreateActionItemArgs = {
  data: CreateActionItemInput;
};


export type MutationCreateAmortizationActionItemResponseArgs = {
  data: AmortizationActionItemResponseInput;
};


export type MutationCreateAssetArgs = {
  input: CreateAssetInput;
};


export type MutationCreateCategorizationActionItemResponseArgs = {
  data: CategorizationActionItemResponseInput;
};


export type MutationCreateFolderArgs = {
  input: CreateFolderInput;
};


export type MutationCreateInvitationArgs = {
  input: CreateInvitationInput;
};


export type MutationCreateMemberArgs = {
  input: MemberInput;
};


export type MutationCreateMiscellaneousActionItemResponseArgs = {
  data: MiscellaneousActionItemResponseInput;
};


export type MutationCreatePasswordResetArgs = {
  address: Scalars['String'];
  baseUrl: Scalars['String'];
};


export type MutationCreateTeamMemberArgs = {
  input: MemberInput;
};


export type MutationDeleteActionItemArgs = {
  data: DeleteActionItemInput;
};


export type MutationDeleteAssetArgs = {
  company_id: Scalars['ID'];
  id: Scalars['ID'];
};


export type MutationDeleteFolderArgs = {
  company_id?: InputMaybe<Scalars['ID']>;
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationDeleteInvitationArgs = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationDeleteMemberArgs = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};


export type MutationLoginWithGoogleArgs = {
  credential: Scalars['String'];
};


export type MutationLoginWithPasswordArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};


export type MutationResendInvitationArgs = {
  input: ResendInvitationInput;
};


export type MutationResetPasswordArgs = {
  new_password: Scalars['String'];
  password_confirmation: Scalars['String'];
  user_jwt: Scalars['String'];
};


export type MutationUpdateActionItemContentsArgs = {
  data: UpdateActionItemContentsInput;
};


export type MutationUpdateActionItemStateArgs = {
  data: UpdateActionItemStateInput;
};


export type MutationUpdateAssetArgs = {
  company_id: Scalars['ID'];
  id: Scalars['ID'];
  input: UpdateAssetInput;
};


export type MutationUpdateCompanyArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input: UpdateCompanyInput;
};


export type MutationUpdateCompanyProfileArgs = {
  company: UpdateCompanyProfile;
  company_id?: InputMaybe<Scalars['ID']>;
  config?: InputMaybe<QueryConfig>;
  user_id?: InputMaybe<Scalars['ID']>;
};


export type MutationUpdateFolderArgs = {
  company_id?: InputMaybe<Scalars['ID']>;
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  input: UpdateFolderInput;
};


export type MutationUpdateInvitationArgs = {
  input: UpdateInvitationInput;
};


export type MutationUpdateMemberArgs = {
  input: UpdateMemberInput;
};


export type MutationUpdateUserArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input: UpdateUserInput;
};

/** The submitted password doesn't match the one in the database. */
export type PasswordIncorrect = {
  __typename?: 'PasswordIncorrect';
  message: Scalars['String'];
};

/** The new password must be different from the old password. */
export type PasswordNotUnique = {
  __typename?: 'PasswordNotUnique';
  message: Scalars['String'];
};

/** The password reset link has expired. */
export type PasswordResetExpired = {
  __typename?: 'PasswordResetExpired';
  message: Scalars['String'];
};

/** The password reset link is not valid. */
export type PasswordResetInvalid = {
  __typename?: 'PasswordResetInvalid';
  message: Scalars['String'];
};

/** The password confirmation does not match the password. */
export type PasswordUnconfirmed = {
  __typename?: 'PasswordUnconfirmed';
  message: Scalars['String'];
};

export type PlaidCashBalance = {
  __typename?: 'PlaidCashBalance';
  accountId: Scalars['String'];
  accountName: Scalars['String'];
  companyId: Scalars['String'];
  connectionId?: Maybe<Scalars['String']>;
  currentBalance: Scalars['Float'];
  lastUpdated: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  /** A flat list of all of a company's accounts */
  accounts: Array<Account>;
  /** Gets a company's action items */
  actionItems: Array<ActionItem>;
  asset?: Maybe<Asset>;
  assets: Array<Asset>;
  /** One of our sort-of-legacy, denormalized data queries. Returns a list of 'bills' with a remaining balance greater than zero. */
  bills: Array<CardDataBill>;
  /** If this returns items, the company's books are blocked. */
  blockedJiraEpics: Array<JiraEpic>;
  /** Denormalized data extracted from reports for graph purposes. This endpoint returns 'Net Burn' and 'Net Cash' by month. */
  burn: Array<CardDataBalanceSum>;
  /** Denormalized data extracted from reports for graph purposes. This endpoint returns a list of monthly bank account balances (see CardDataBalance) for the last 24 months. See also: revenue and spend, which return 'balances' for non-cash accounts like expense and income */
  cashBalances: Array<CardDataBalance>;
  /** If this returns items, the company's books are ready. */
  closedJiraEpics: Array<JiraEpic>;
  companies: Array<Company>;
  company?: Maybe<Company>;
  documents: DocumentResponse;
  email?: Maybe<Email>;
  folder?: Maybe<Folder>;
  folders?: Maybe<FoldersResponse>;
  getActionItems: Array<ActionItem>;
  getWorkbenchCompanies: WorkbenchCompanies;
  /** If this returns items, the company's books are in progress. */
  inProgressJiraEpics: Array<JiraEpic>;
  /** An invitation. Check the state field for validity; deleted invitations cannot be redeemed. */
  invitation?: Maybe<Invitation>;
  invitations?: Maybe<Array<Invitation>>;
  /** One of our sort-of-legacy, denormalized data queries. Returns all of a company's invoices with a remaining balance above zero. */
  invoices: Array<CardDataInvoice>;
  /** Gets a company's open Jira epics. */
  jiraEpics: Array<JiraEpic>;
  /** An active member of a company */
  member?: Maybe<Member>;
  members: CompanyMemberResponse;
  merchants: Array<Merchant>;
  paginatedAssets?: Maybe<AssetsResponse>;
  /** Returns a list of live cash balances. */
  plaidCashBalances: Array<PlaidCashBalance>;
  /** Denormalized data extracted from reports for graph purposes. This endpoint returns a list of monthly account balances (see CardDataBalance) of type 'income' and 'cost of goods sold' for the last 24 months. See also: spend */
  revenue: Array<CardDataBalance>;
  /** Denormalized data extracted from reports for graph purposes. This endpoint returns a list of monthly account balances (see CardDataBalance) of type 'expense' for the last 24 months. See also: revenue */
  spend: Array<CardDataBalance>;
  /** Returns a list of subscriptions */
  subscriptions: Array<Subscription>;
  /** Flow staff who are members of a given company */
  teamMembers: MemberResponse;
  /** One of our sort-of-legacy, denormalized data queries. Returns a list of 'top vendor expenses': vendor names and the amounts and dates associated with expenses, as extracted from monthly reports. There are no ids, and this data is not guaranteed to correspond to other objects. */
  topVendorExpenses: Array<VendorExpenses>;
  /** If this returns items, the company's books are blocked. */
  unblockedJiraEpics: Array<JiraEpic>;
  user?: Maybe<User>;
};


export type QueryAccountsArgs = {
  account_type?: InputMaybe<Array<AccountType>>;
  company_id: Scalars['ID'];
};


export type QueryActionItemsArgs = {
  company_id: Scalars['ID'];
  config?: InputMaybe<QueryConfig>;
  states?: InputMaybe<Array<ActionItemState>>;
};


export type QueryAssetArgs = {
  company_id: Scalars['ID'];
  id: Scalars['ID'];
};


export type QueryAssetsArgs = {
  company_id: Scalars['ID'];
  config?: InputMaybe<QueryConfig>;
};


export type QueryBillsArgs = {
  company_id: Scalars['ID'];
};


export type QueryBlockedJiraEpicsArgs = {
  company_id: Scalars['ID'];
  config?: InputMaybe<QueryConfig>;
};


export type QueryBurnArgs = {
  company_id: Scalars['ID'];
};


export type QueryCashBalancesArgs = {
  company_id: Scalars['ID'];
};


export type QueryClosedJiraEpicsArgs = {
  company_id: Scalars['ID'];
  config?: InputMaybe<QueryConfig>;
};


export type QueryCompaniesArgs = {
  config?: InputMaybe<QueryConfig>;
  user_id?: InputMaybe<Scalars['ID']>;
};


export type QueryCompanyArgs = {
  company_id?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  user_id?: InputMaybe<Scalars['ID']>;
};


export type QueryDocumentsArgs = {
  company_id: Scalars['ID'];
  config?: InputMaybe<QueryConfig>;
  fixed?: InputMaybe<Scalars['Boolean']>;
  folder_id?: InputMaybe<Scalars['ID']>;
  root?: InputMaybe<Scalars['Boolean']>;
  search?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Array<DocumentSortConfig>>;
};


export type QueryEmailArgs = {
  address?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryFolderArgs = {
  company_id?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryFoldersArgs = {
  company_id: Scalars['ID'];
  config?: InputMaybe<QueryConfig>;
  fixed?: InputMaybe<Scalars['Boolean']>;
  parent_id?: InputMaybe<Scalars['ID']>;
  root?: InputMaybe<Scalars['Boolean']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetActionItemsArgs = {
  company_id?: InputMaybe<Scalars['ID']>;
  config?: InputMaybe<QueryConfig>;
  user_id?: InputMaybe<Scalars['ID']>;
};


export type QueryGetWorkbenchCompaniesArgs = {
  company_id?: InputMaybe<Scalars['ID']>;
  config?: InputMaybe<QueryConfig>;
  user_id?: InputMaybe<Scalars['ID']>;
};


export type QueryInProgressJiraEpicsArgs = {
  company_id: Scalars['ID'];
  config?: InputMaybe<QueryConfig>;
};


export type QueryInvitationArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryInvitationsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryInvoicesArgs = {
  company_id: Scalars['ID'];
};


export type QueryJiraEpicsArgs = {
  company_id: Scalars['ID'];
  config?: InputMaybe<QueryConfig>;
};


export type QueryMemberArgs = {
  company_id?: InputMaybe<Scalars['ID']>;
  user_id?: InputMaybe<Scalars['ID']>;
};


export type QueryMembersArgs = {
  company_id?: InputMaybe<Scalars['ID']>;
  config?: InputMaybe<QueryConfig>;
  substring?: InputMaybe<Scalars['String']>;
  user_id: Scalars['ID'];
};


export type QueryMerchantsArgs = {
  company_id: Scalars['ID'];
};


export type QueryPaginatedAssetsArgs = {
  company_id: Scalars['ID'];
  config?: InputMaybe<QueryConfig>;
  folder_id?: InputMaybe<Scalars['ID']>;
  search?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Array<AssetSortConfig>>;
};


export type QueryPlaidCashBalancesArgs = {
  company_id: Scalars['ID'];
};


export type QueryRevenueArgs = {
  company_id: Scalars['ID'];
};


export type QuerySpendArgs = {
  company_id: Scalars['ID'];
};


export type QuerySubscriptionsArgs = {
  company_id: Scalars['ID'];
};


export type QueryTeamMembersArgs = {
  company_id: Scalars['ID'];
  config?: InputMaybe<QueryConfig>;
  substring?: InputMaybe<Scalars['String']>;
};


export type QueryTopVendorExpensesArgs = {
  company_id: Scalars['ID'];
};


export type QueryUnblockedJiraEpicsArgs = {
  company_id: Scalars['ID'];
  config?: InputMaybe<QueryConfig>;
};


export type QueryUserArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryConfig = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type ResendInvitationInput = {
  baseUrl: Scalars['String'];
  id: Scalars['ID'];
  sender_id: Scalars['ID'];
};

export type ResendInvitationResponse = {
  __typename?: 'ResendInvitationResponse';
  error?: Maybe<Scalars['String']>;
};

export type ResetPasswordResponse = PasswordIncorrect | PasswordNotUnique | PasswordResetExpired | PasswordResetInvalid | PasswordUnconfirmed | User;

export enum Roles {
  ADMIN = 'admin',
  BOOKKEEPER = 'bookkeeper',
  CFO = 'cfo',
  MEMBER = 'member',
  OWNER = 'owner',
  SALES = 'sales'
}

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc'
}

export enum SortableAssetFields {
  CREATED = 'created',
  DISPLAY_NAME = 'display_name',
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name'
}

export enum SortableDocumentFields {
  CREATED_AT = 'created_at',
  /** @deprecated searching on concatenated fields is unpredictable & thus not the greatest of practices */
  CREATED_BY_NAME = 'created_by_name',
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  TITLE = 'title',
  TYPENAME = 'typename'
}

export enum States {
  ACTIVE = 'active',
  DELETED = 'deleted',
  PENDING = 'pending',
  SUSPENDED = 'suspended'
}

/** Info about a subscription, assembled from QBO */
export type Subscription = {
  __typename?: 'Subscription';
  companyId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  merchantId?: Maybe<Scalars['String']>;
  nextExpectedTransactionDate?: Maybe<Scalars['String']>;
  priorTransactions: Array<Transaction>;
};

/** One of a series of transactions associated with a subscription */
export type Transaction = {
  __typename?: 'Transaction';
  amount?: Maybe<Scalars['Float']>;
  categorizedAccountId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  merchantId?: Maybe<Scalars['String']>;
  originatingAccountId?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
};

export type UpdateActionItemContentsInput = {
  action_item_id?: InputMaybe<Scalars['ID']>;
  category?: InputMaybe<Scalars['String']>;
  due_date?: InputMaybe<Scalars['String']>;
  jira_id?: InputMaybe<Scalars['String']>;
  question?: InputMaybe<Scalars['String']>;
};

export type UpdateActionItemStateInput = {
  action_item_id?: InputMaybe<Scalars['ID']>;
  state?: InputMaybe<Scalars['String']>;
};

export type UpdateAssetInput = {
  description?: InputMaybe<Scalars['String']>;
  display_name?: InputMaybe<Scalars['String']>;
  /** The id of the parent folder, if one exists */
  folder_id?: InputMaybe<Scalars['ID']>;
  url?: InputMaybe<Scalars['String']>;
};

export type UpdateCompanyInput = {
  avatar: Scalars['String'];
};

export type UpdateCompanyProfile = {
  books_start?: InputMaybe<Scalars['String']>;
  fiscal_start?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  industry?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  primary_business_activity?: InputMaybe<Scalars['String']>;
  primary_poc?: InputMaybe<Scalars['String']>;
  revenue_model?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UpdateFolderInput = {
  /** True if this folder should be 'fixed' or uneditable */
  fixed?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  /** The id of the parent folder, if one exists */
  parent_id?: InputMaybe<Scalars['ID']>;
  /** The user associated with this action, if one exists */
  user_id?: InputMaybe<Scalars['ID']>;
};

export type UpdateInvitationInput = {
  id: Scalars['ID'];
  role?: InputMaybe<Roles>;
  state?: InputMaybe<States>;
};

export type UpdateMemberInput = {
  id: Scalars['ID'];
  role?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

export type UpdateUserEmail = {
  address: Scalars['String'];
  id: Scalars['ID'];
};

export type UpdateUserInput = {
  email?: InputMaybe<UpdateUserEmail>;
  first_name?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  avatar?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  email_id?: Maybe<Scalars['String']>;
  emails: Array<Email>;
  first_name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  last_name?: Maybe<Scalars['String']>;
  state?: Maybe<States>;
  type?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
};

/** A vendor's name and their expenses for a given period */
export type VendorExpenses = {
  __typename?: 'VendorExpenses';
  expenses: Array<Expense>;
  vendorName: Scalars['String'];
};

export type WorkbenchCompanies = {
  __typename?: 'WorkbenchCompanies';
  all: Array<Company>;
  assigned: Array<Company>;
};
