import React from 'react'
import useCashBalances from '~/src/hooks/use-cash-balances'
import { formatCurrency } from '~/src/utilities/currency'
import { TCardSettings } from '~/src/types/card-settings'
import Classnames from 'classnames'
import { getPrevMonthString } from '~/src/utilities/dates'

const MiniCashBalancesCard = ({
  startDate,
  endDate,
  accountIds,
}: TCardSettings) => {
  const { sumsByMonth, loading } = useCashBalances({
    startDate,
    endDate,
    accountIds,
  })

  const lastBalance = sumsByMonth[0].sum

  const cardClasses = Classnames('animate-value animate-value--out', {
    'animate-value--in': !loading,
  })

  return (
    <>
      <div className={cardClasses}>
        {lastBalance ? (
          <div className="mini-card__value">{formatCurrency(lastBalance)}</div>
        ) : (
          <div>No balances to display</div>
        )}
      </div>
    </>
  )
}

const MiniCashBalancesHeader = () => (
  <div className="mini-card__info-line">as of {getPrevMonthString()}</div>
)

export default MiniCashBalancesCard

export { MiniCashBalancesHeader }
