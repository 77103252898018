import { getBucketEndpointPlugin } from "@aws-sdk/middleware-bucket-endpoint";
import { getThrow200ExceptionsPlugin } from "@aws-sdk/middleware-sdk-s3";
import { getSerdePlugin } from "@aws-sdk/middleware-serde";
import { getSsecPlugin } from "@aws-sdk/middleware-ssec";
import { Command as $Command } from "@aws-sdk/smithy-client";
import { CompleteMultipartUploadOutputFilterSensitiveLog, CompleteMultipartUploadRequestFilterSensitiveLog, } from "../models/models_0";
import { deserializeAws_restXmlCompleteMultipartUploadCommand, serializeAws_restXmlCompleteMultipartUploadCommand, } from "../protocols/Aws_restXml";
export class CompleteMultipartUploadCommand extends $Command {
    constructor(input) {
        super();
        this.input = input;
    }
    resolveMiddleware(clientStack, configuration, options) {
        this.middlewareStack.use(getSerdePlugin(configuration, this.serialize, this.deserialize));
        this.middlewareStack.use(getThrow200ExceptionsPlugin(configuration));
        this.middlewareStack.use(getSsecPlugin(configuration));
        this.middlewareStack.use(getBucketEndpointPlugin(configuration));
        const stack = clientStack.concat(this.middlewareStack);
        const { logger } = configuration;
        const clientName = "S3Client";
        const commandName = "CompleteMultipartUploadCommand";
        const handlerExecutionContext = {
            logger,
            clientName,
            commandName,
            inputFilterSensitiveLog: CompleteMultipartUploadRequestFilterSensitiveLog,
            outputFilterSensitiveLog: CompleteMultipartUploadOutputFilterSensitiveLog,
        };
        const { requestHandler } = configuration;
        return stack.resolve((request) => requestHandler.handle(request.request, options || {}), handlerExecutionContext);
    }
    serialize(input, context) {
        return serializeAws_restXmlCompleteMultipartUploadCommand(input, context);
    }
    deserialize(output, context) {
        return deserializeAws_restXmlCompleteMultipartUploadCommand(output, context);
    }
}
