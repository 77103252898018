import React from 'react'
import Button from '~/src/components/button'
import { FlowIcons } from '~/src/assets/flow-icons'
import { IBannerProps } from '~/src/types/bookkeeping-banner'
import BooksReadyImg from '~/src/assets/images/books-ready.svg'
import { useNavigate } from 'react-router-dom'
import ROUTES from '~/src/constants/routes'
import useResponsive from '~/src/hooks/use-responsive'

const BooksReady = ({ bookMonth }: IBannerProps): JSX.Element => {
  const { isDesktop } = useResponsive()
  const navigate = useNavigate()

  return (
    <>
      {isDesktop && (
        <img
          className="mr-4"
          src={BooksReadyImg}
          alt="A green checkmark shown over a file tray."
        />
      )}
      <div className="tablet:pr-20 flex flex-col items-start">
        <h2 className="bookkeeping-banner__title">Your books are ready!</h2>
        <p className="bookkeeping-banner__text">
          We have finished your books for <strong>&nbsp;{bookMonth}</strong>.
          {isDesktop && <br />} Please click here to download your financial
          reports
        </p>
      </div>
      <Button
        className="mr-auto my-auto tablet:ml-auto tablet:mr-0"
        theme="primary"
        label="Download Report"
        size="medium"
        iconPosition="left"
        icon={FlowIcons.FileDownload}
        iconColor="white"
        onClick={() => navigate(ROUTES.documents.path)}
      />
    </>
  )
}

export default BooksReady
