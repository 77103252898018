import ActionItems from "./action-items.svg";
import AlertFill from "./alert-fill.svg";
import AlertOutline from "./alert-outline.svg";
import ArrowDown from "./arrow-down.svg";
import ArrowLeft from "./arrow-left.svg";
import ArrowRight from "./arrow-right.svg";
import ArrowUp from "./arrow-up.svg";
import BellNotification from "./bell-notification.svg";
import Bell from "./bell.svg";
import Building from "./building.svg";
import BurgerMenu from "./burger-menu.svg";
import BurnRate from "./burn-rate.svg";
import Calendar from "./calendar.svg";
import Categorization from "./categorization.svg";
import CheckFill from "./check-fill.svg";
import CheckOutline from "./check-outline.svg";
import Check from "./check.svg";
import ChevronDown from "./chevron-down.svg";
import ChevronLeft from "./chevron-left.svg";
import ChevronRight from "./chevron-right.svg";
import ChevronUp from "./chevron-up.svg";
import ClockFill from "./clock-fill.svg";
import Code from "./code.svg";
import Company from "./company.svg";
import Copy from "./copy.svg";
import Dashboard from "./dashboard.svg";
import Documents from "./documents.svg";
import Dots from "./dots.svg";
import Download from "./download.svg";
import Email from "./email.svg";
import EyeSlash from "./eye-slash.svg";
import Eye from "./eye.svg";
import FileDownload from "./file-download.svg";
import FileInvoice from "./file-invoice.svg";
import FileText from "./file-text.svg";
import Folder from "./folder.svg";
import Link from "./link.svg";
import ListNum from "./list-num.svg";
import List from "./list.svg";
import Miscellaneous from "./miscellaneous.svg";
import Pen from "./pen.svg";
import Plus from "./plus.svg";
import QuestionOutline from "./question-outline.svg";
import Reply from "./reply.svg";
import Search from "./search.svg";
import Settings from "./settings.svg";
import Share from "./share.svg";
import Snapshot from "./snapshot.svg";
import SystemFolder from "./system-folder.svg";
import Team from "./team.svg";
import TextBold from "./text-bold.svg";
import TextItalic from "./text-italic.svg";
import TextStrikethrough from "./text-strikethrough.svg";
import Trash from "./trash.svg";
import Undo from "./undo.svg";
import Upload from "./upload.svg";
import XFill from "./x-fill.svg";
import X from "./x.svg";
import ZeroCash from "./zero-cash.svg";

export const FlowIcons = {
  ActionItems,
  AlertFill,
  AlertOutline,
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  BellNotification,
  Bell,
  Building,
  BurgerMenu,
  BurnRate,
  Calendar,
  Categorization,
  CheckFill,
  CheckOutline,
  Check,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  ClockFill,
  Code,
  Company,
  Copy,
  Dashboard,
  Documents,
  Dots,
  Download,
  Email,
  EyeSlash,
  Eye,
  FileDownload,
  FileInvoice,
  FileText,
  Folder,
  Link,
  ListNum,
  List,
  Miscellaneous,
  Pen,
  Plus,
  QuestionOutline,
  Reply,
  Search,
  Settings,
  Share,
  Snapshot,
  SystemFolder,
  Team,
  TextBold,
  TextItalic,
  TextStrikethrough,
  Trash,
  Undo,
  Upload,
  XFill,
  X,
  ZeroCash,
};
