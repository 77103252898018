import Text from '~/src/components/form-fields/components/text'
import Password from '~/src/components/form-fields/components/password'
import Select from '~/src/components/form-fields/components/select'
import Checkbox from '~/src/components/form-fields/components/checkbox'
import Radio from '~/src/components/form-fields/components/radio'
import Textarea from '~/src/components/form-fields/components/textarea'
import Toggle from '~/src/components/form-fields/components/toggle'
import RichText from '~/src/components/form-fields/components/richtext'
import DatePicker from '~/src/components/form-fields/components/datepicker'
import SelectWithFilter from '~/src/components/form-fields/components/select-with-filter'
import './form-fields.css'

export const FormFields = {
  Text,
  Password,
  Select,
  SelectWithFilter,
  Checkbox,
  Radio,
  Textarea,
  Toggle,
  RichText,
  DatePicker,
}
