import { createContext, useContext } from 'react'

export type TNavBarContext = {
  isMenuOpen: boolean
  setMenuOpen?: (isOpen: boolean) => void
}

export const NavBarContext = createContext<TNavBarContext>({
  isMenuOpen: false,
})

export const useNavBarContext = () => useContext(NavBarContext)
