import { accounts as IGoogleAccounts, CredentialResponse } from 'google-one-tap'
import { useState, useEffect } from 'react'
import loadScript from '~/src/utilities/load-script'
import removeScript from '~/src/utilities/remove-script'

const url = 'https://accounts.google.com/gsi/client'

type TUseGoogleLoginArgs = {
  buttonId: string
  callback: (response: CredentialResponse) => void
}

const useGoogleLogin = ({ buttonId, callback }: TUseGoogleLoginArgs) => {
  const [loaded, setLoaded] = useState(false)
  const [initialized, setInitialized] = useState(false)

  // Load Google client API script if not already loaded.
  useEffect(() => {
    if (loaded) {
      return
    }

    loadScript(document, 'google-login', url, () => setLoaded(true))

    return () => removeScript(document, 'google-login')
  }, [loaded])

  // Runs once, then is initialized.
  useEffect(() => {
    if (!loaded || initialized) {
      return
    }

    const accounts: IGoogleAccounts = google.accounts

    accounts.id.initialize({
      client_id: process.env.GOOGLE_CLIENT_ID || '',
      callback,
    })

    const buttonElement = document.getElementById(buttonId)

    if (buttonElement) {
      accounts.id.renderButton(buttonElement, {
        theme: 'outline',
        size: 'large',
        width: 350,
        type: 'standard',
        shape: 'rectangular',
        text: 'continue_with',
        logo_alignment: 'center',
      })
    }

    setInitialized(true)
  }, [loaded, initialized, buttonId, callback])

  // Reinitialize the button if the callback changes.
  useEffect(() => {
    if (!initialized) {
      return
    }
    const accounts: IGoogleAccounts = google.accounts
    accounts.id.initialize({
      client_id: process.env.GOOGLE_CLIENT_ID || '',
      callback,
    })
  }, [callback, initialized])

  return {
    loaded,
  }
}

export default useGoogleLogin
