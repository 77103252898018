import axios from 'axios'
import { IChartImage } from '~/src/types/graphs'

const api = axios.create({
  baseURL: `${process.env.EXPORTS_API}/exports`,
  headers: {
    'Content-Type': 'application/json',
  },
})

export const exportImages = async (
  userId: string,
  companyId: string,
  companyName: string,
  dateStamp: string,
  images: IChartImage[]
) => {
  const payload = JSON.stringify({
    userId,
    companyId,
    companyName,
    dateStamp,
    images,
  })

  return (await api.post('/images', payload)).data
}

export const exportReports = async (
  userId: string,
  companyId: string,
  companyName: string,
  connectionId: string,
  start: string,
  stop: string,
  reports: string[]
) => {
  const payload = JSON.stringify({
    userId,
    companyId,
    companyName,
    connectionId,
    start,
    stop,
    reports,
  })

  return (await api.post('/reports', payload)).data
}

export const exportSubscriptions = async (
  companyId: string,
  companyName: string
) => {
  const payload = JSON.stringify({
    companyId,
    companyName,
  })

  return (await api.post('/subscriptions', payload)).data
}
