import React from 'react'
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form'
import ScrollContainer from '~/src/components/scroll-container'
import Button from '~/src/components/button'
import NestedForm, {
  INestedFormFieldStates,
  NestedFormItem,
} from '~/src/components/nested-form'
import { FormFields } from '~/src/components/form-fields'
import useFolderTree from '~/src/hooks/use-folder-tree'
import { FlowIcons } from '~/src/assets/flow-icons'
import SVGHandler from '~/src/components/svg-handler'
import { FORMS } from '~/src/constants/forms'

interface IFields extends FieldValues {
  folder_id: string
  existing_folder_id: string
}

interface IWithChildren {
  id: string
  label: string
  children: IChildren
  fixed?: boolean
}

interface IChildren extends Array<IWithChildren> {}

interface IMoveDocumentForm<T extends FieldValues = IFields, K = IWithChildren>
  extends IBaseMoveDocumentForm<T> {
  tree: K
}
interface IBaseMoveDocumentForm<T extends FieldValues>
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onSubmit'> {
  defaultValues: T
  onSubmit: SubmitHandler<T>
  id: string
}

const searchMatch = <K extends NestedFormItem>(search: string, item: K) => {
  if (!search) return true

  return item.label.toLocaleLowerCase().includes(search.toLocaleLowerCase())
}

const matchSelfOrChildren = <K extends NestedFormItem>(
  item: K,
  matchers: ((item: K) => boolean)[],
  accum: boolean[] = []
): boolean[] => {
  if (!matchers.length) {
    return [true]
  }

  const selfMatches = matchers.some(matchFn => matchFn(item))

  if (!item.children?.length) return [selfMatches]
  return item.children.reduce((accum, child) => {
    return [
      ...accum,
      selfMatches,
      ...matchSelfOrChildren(child, matchers, accum),
    ]
  }, accum)
}

const BaseMoveDocumentForm = <T extends FieldValues>(
  props: IMoveDocumentForm<T>
) => {
  const { className, defaultValues, onSubmit } = props

  const {
    register,
    handleSubmit,
    watch,
    formState: { isValid, isSubmitting },
  } = useForm<IFields>({
    mode: 'onChange',
    defaultValues,
  })

  const { folder_id, title, existing_folder_id, search } = watch()

  type TItem = typeof props.tree

  const isItemDisabled = (item: TItem) => item.id === existing_folder_id

  const isItemSelected = (item: TItem) => item.id === folder_id

  const isItemHidden = (item: TItem) => {
    const matches = matchSelfOrChildren(item, [
      item => searchMatch(search, item),
      item => item.id === folder_id,
    ])
    return !matches.some(result => !!result)
  }

  const label = (
    item: TItem,
    { disabled, selected }: Partial<INestedFormFieldStates>
  ) => {
    const iconColor = item.fixed ? undefined : selected ? 'primary' : 'gray'

    return (
      <>
        <SVGHandler
          icon={item.fixed ? FlowIcons.SystemFolder : FlowIcons.Folder}
          size="medium"
          color={disabled ? 'lightgray' : iconColor}
        />
        <span>{item.label}</span>
      </>
    )
  }
  return (
    <>
      <h3 className="mb-5 font-bold w-full text-ellipsis whitespace-nowrap overflow-hidden">
        Move "{title}"
      </h3>

      <div className={className}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormFields.Text
            {...register('search')}
            id="search"
            label=""
            placeholder="Search folders"
          />
          <ScrollContainer className="form-tree-wrap">
            <ul className="form-tree">
              <NestedForm
                tree={props.tree}
                disabled={isItemDisabled}
                selected={isItemSelected}
                hidden={isItemHidden}
                label={label}
                registerProps={register('folder_id', {
                  required: FORMS.VALIDATION.REQUIRED_FIELD,
                })}
              />
            </ul>
          </ScrollContainer>
          <Button
            className="w-full"
            type="submit"
            label="Move"
            disabled={!isValid || isSubmitting}
          />
        </form>
      </div>
    </>
  )
}

const MoveDocumentForm = <T extends IFields = IFields>(
  props: IBaseMoveDocumentForm<T>
) => {
  const { tree } = useFolderTree()

  if (!tree) {
    return null
  }

  return <BaseMoveDocumentForm {...props} tree={tree} />
}

export default MoveDocumentForm
