import React from 'react'
import './action-items.css'
import ActionItemGroup from './components/action-item-group'
import Loader from '~/src/components/loader'
import useAccountOptions from '~/src/hooks/use-account-options'
import useActionItems from '~/src/hooks/use-action-items'
import useMerchantOptions from '~/src/hooks/use-merchant-options'
import { ActionItemsZeroState } from './components/action-items-zero-state'
import PageHeader from '~/src/components/page-header'

const ActionItemsPage = (): JSX.Element => {
  // Preload the merchants and accounts.
  useMerchantOptions()
  useAccountOptions()

  const {
    actionItems: { late, completed, todo, loading, all },
  } = useActionItems()

  return (
    <>
      <PageHeader title="Action Items" />
      <section className="pt-4">
        {loading ? (
          <Loader size="lg" fullScreen />
        ) : all.length ? (
          <>
            {!!late.length && <ActionItemGroup items={late} label="Overdue" />}
            <ActionItemGroup items={todo} label="To Do" />
            <ActionItemGroup items={completed} label="Pending" />
          </>
        ) : (
          <ActionItemsZeroState />
        )}
      </section>
    </>
  )
}

export default ActionItemsPage
