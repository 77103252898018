import React, { FunctionComponent, useState, createRef } from 'react'
import Dropzone, { DropzoneRef } from 'react-dropzone'
import { notify } from '~/src/utilities/notify'
import { ICompany } from '~/src/types/company'
import './avatar-upload-form.css'
import Button from '~/src/components/button'
import { useMutation, gql } from '@apollo/client'
import UPDATE_COMPANY_MUTATION from '~/src/graphql/mutations/update-company.graphql'
import { useForm, Controller } from 'react-hook-form'
import { FORMS } from '~/src/constants/forms'
import { upload } from '~/src/utilities/s3'

interface IAvatarUploadFormProps {
  company: ICompany
  setModalOpen: (arg: boolean) => void
}

interface FormData {
  image: File
}

const convertImageFileToDataString = (
  image: File,
  callback: (imageString: string) => void
): void => {
  const reader = new FileReader()
  reader.onloadend = () => {
    callback(reader.result as string)
  }
  reader.readAsDataURL(image)
}

export const AvatarUploadForm: FunctionComponent<IAvatarUploadFormProps> = ({
  company,
  setModalOpen,
}: IAvatarUploadFormProps) => {
  // Form configuration
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({ mode: 'onSubmit' })

  const [updateCompany] = useMutation(gql(UPDATE_COMPANY_MUTATION), {
    refetchQueries: ['CompanyQuery'],
  })

  const [getImageSrc, setImageSrc] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)

  const dropzoneRef = createRef<DropzoneRef>()

  const onSubmit = async (data: { image: File }) => {
    const { image } = data
    try {
      // Assemble S3 information.
      const key = `${company.id}/${image.name}`
      const avatarUrl = await upload(key, image, { ACL: 'public-read' })

      // We have to manage our own loading state because we're outside
      // the Apollo ecosystem.
      setLoading(true)

      // update the company with the image url
      await updateCompany({
        variables: {
          id: company.id,
          input: {
            avatar: avatarUrl,
          },
        },
      })

      setLoading(false)
      notify('Company avatar successfully updated', { type: 'success' })
      setModalOpen(false)
    } catch (e) {
      setLoading(false)
      notify('Unable to update avatar', {
        type: 'error',
      })
    }
  }

  return (
    <form
      className="avatar-upload-form"
      action="Upload Image"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        control={control}
        name="image"
        rules={{
          ...FORMS.VALIDATION.AVATAR,
        }}
        render={({ field: { onChange } }) => (
          <Dropzone
            ref={dropzoneRef}
            multiple={false}
            onDrop={(files: File[]) => {
              onChange(files[0])
              convertImageFileToDataString(files[0], setImageSrc)
            }}
            noClick
          >
            {({ getRootProps, getInputProps }) => (
              <>
                {getImageSrc && (
                  <div
                    className="avatar-upload-form__preview"
                    {...getRootProps()}
                    data-cy="dropzone"
                  >
                    {/* The old dash uses a component react-avatar-editor I removed from here
                    because we're not actually editing and I didn't understand what it was doing.
                    If it's actually fixing the aspect ratio or something, we might need it back */}
                    <img src={getImageSrc} />
                  </div>
                )}
                <div
                  className="avatar-upload-form__dropzone"
                  {...getRootProps()}
                  data-cy="dropzone"
                >
                  <input {...getInputProps()} />

                  <p>
                    Drop here or{' '}
                    <button
                      type="button"
                      onClick={e => {
                        e.stopPropagation()
                        dropzoneRef.current?.open()
                      }}
                    >
                      browse files
                    </button>
                  </p>
                  {/* <p>Maximum file size 5MB</p> */}
                  {/* ^^ Is this real? Let us substantiate whether we actually care,
                  what the number is, and what would happen if we exceeded it. */}
                </div>
              </>
            )}
          </Dropzone>
        )}
      />
      <Button type="submit" label={loading ? 'Loading...' : 'Confirm'} />
      <div>{errors?.image?.message}</div>
    </form>
  )
}
