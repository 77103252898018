import React from 'react'
import NavBarLink from './navbar-link'
import ROUTES from '~/src/constants/routes'
import { FlowIcons } from '~/src/assets/flow-icons'

export interface INavBarItems {
  collapsed: boolean
  badgeProps: { [key: string]: number }
  onClick?: () => void
}

const NavBarItems = ({
  collapsed,
  badgeProps,
  onClick,
}: INavBarItems): JSX.Element => {
  return (
    <ul className="navigation-items">
      <NavBarLink
        path={ROUTES.dashboard.path}
        label={ROUTES.dashboard.name}
        icon={FlowIcons.Dashboard}
        collapsed={collapsed}
        onClick={onClick}
      />
      <NavBarLink
        path={ROUTES.documents.path}
        label={ROUTES.documents.name}
        icon={FlowIcons.Documents}
        collapsed={collapsed}
        onClick={onClick}
      />
      <NavBarLink
        path={ROUTES.actionItems.path}
        label={ROUTES.actionItems.name}
        icon={FlowIcons.ActionItems}
        collapsed={collapsed}
        notificationCount={badgeProps['actionItemCount']}
        onClick={onClick}
      />
      <NavBarLink
        path={ROUTES.team.path}
        label={ROUTES.team.name}
        icon={FlowIcons.Team}
        collapsed={collapsed}
        onClick={onClick}
      />
      <NavBarLink
        path={ROUTES.company.path}
        label={ROUTES.company.name}
        icon={FlowIcons.Company}
        collapsed={collapsed}
        onClick={onClick}
      />
    </ul>
  )
}

export default NavBarItems
