import React from 'react'
import DocumentsContextProvider from './components/documents-context-provider'
import { EditFolder } from './components/edit-folder'
import { EditAsset } from './components/edit-asset'
import { Outlet } from 'react-router-dom'
import './documents.css'
import AssetsPage from './components/assets'
import { useFeatureFlag } from '~/src/hooks/use-feature-flag'
import { MoveAsset } from './components/move-asset'
import { MoveFolder } from './components/move-folder'
import DeleteAsset from './components/delete-asset'
import DeleteFolder from './components/delete-folder'

const DocumentsPage = (): JSX.Element => {
  const showFolders = useFeatureFlag('showFolders')

  return (
    <DocumentsContextProvider>
      <EditFolder />
      <EditAsset />
      <MoveFolder />
      <MoveAsset />
      <DeleteAsset />
      <DeleteFolder />

      {showFolders ? <Outlet /> : <AssetsPage />}
    </DocumentsContextProvider>
  )
}

export default DocumentsPage
