import React, { useEffect } from 'react'
import useRevenue from '~/src/hooks/use-revenue'
import { formatCurrency } from '~/src/utilities/currency'
import Classnames from 'classnames'
import PercentChange from '~/src/components/percent-change'
import { percentChanged } from '~/src/utilities/numbers'
import { getPrevMonthString } from '~/src/utilities/dates'
import { TCardSettings } from '~/src/types/card-settings'
import ReactTooltip from 'react-tooltip'
import { v4 } from 'uuid'

const MiniRevenueCard = ({ startDate, endDate, accountIds }: TCardSettings) => {
  const { netSumsByMonth: sumsByMonth, loading } = useRevenue({
    startDate,
    endDate,
    accountIds,
  })

  const amount = sumsByMonth?.at(-1)?.sum
  const changedFrom = sumsByMonth?.at(-2)?.sum

  const canShowRevenue = !!amount
  const canShowChange = !!(amount && changedFrom)

  const cardClasses = Classnames('animate-value animate-value--out', {
    'animate-value--in': !loading,
  })

  const percentChange =
    amount && changedFrom ? percentChanged(amount, changedFrom) : 0

  const tipId = v4()
  const changeWord = Math.sign(percentChange) === -1 ? 'decreased' : 'increased'
  const tipMsg = `Your revenue ${changeWord} by ${percentChange}%.`

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [canShowRevenue])

  return (
    <>
      <div className={cardClasses}>
        {canShowRevenue ? (
          <div className="mini-card__value">
            {formatCurrency(amount)}
            {canShowChange && (
              <div data-for={tipId} data-tip={tipMsg}>
                <PercentChange value={percentChange} />
              </div>
            )}
          </div>
        ) : (
          <div>No revenue to display.</div>
        )}
      </div>
      <ReactTooltip
        className="panopticon-tooltip"
        id={tipId}
        place="bottom"
        effect="solid"
      />
    </>
  )
}

export const MiniRevenueCardHeader = () => (
  <div className="mini-card__info-line">as of {getPrevMonthString()}</div>
)

export default MiniRevenueCard
