import { onError } from '@apollo/client/link/error'
import * as Sentry from '@sentry/react'

const errorLink = onError(({ graphQLErrors, networkError }) => {
  try {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) =>
        Sentry.captureException(
          new Error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        )
      )
    }

    if (networkError) {
      Sentry.captureException(new Error(`[Network error]: ${networkError}`))
    }
  } catch {
    // Really grasping at straws here. I'm wondering if this errorLink and the Sentry call
    // are actually the SOURCE of the unhandled error sinking Cypress.
  }
})

export default errorLink
