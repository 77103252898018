import React from 'react'
import Button from '~/src/components/button'
import { IMember } from '~/src/types/member'
import { useMutation, gql } from '@apollo/client'
import { CustomerRoles } from '~/src/types/roles'
import DELETE_MEMBER from '~/src/graphql/mutations/delete-member.graphql'
import UPDATE_MEMBER_MUTATION from '~/src/graphql/mutations/update-member.graphql'
import { notify } from '~/src/utilities/notify'
import './delete-form.css'
import useTeam from '~/src/hooks/use-team'

interface DeleteTeamRoleFormProps {
  member: IMember
  onClose: () => void
}

const mutationOptions = {
  refetchQueries: ['TeamMemberQuery', 'MemberQuery'],
}

export const DeleteTeamRoleForm = ({
  member,
  onClose,
}: DeleteTeamRoleFormProps) => {
  const { teamMembers } = useTeam()
  const [deleteMember] = useMutation(gql(DELETE_MEMBER), mutationOptions)
  const [updateMember] = useMutation(
    gql(UPDATE_MEMBER_MUTATION),
    mutationOptions
  )

  // Hack alert: We use an "admin" role as a sort of placeholder role to park team memberships.
  // Does this user already have a membership with an admin role?
  const adminRole = teamMembers.find(
    ({ user_id, role }) =>
      user_id === member.user_id && role === CustomerRoles.admin
  )

  // If so, we'll delete the non-admin role in question. The user will
  // continue to be a member of the company and be available to be assigned to roles.
  // If not, reassign the role to "admin".
  const action = adminRole
    ? () =>
        deleteMember({
          variables: { id: member.id },
        })
    : () =>
        updateMember({
          variables: {
            input: {
              id: member.id,
              role: CustomerRoles.admin,
            },
          },
        })

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    const { data } = await action()
    if (data?.deleteMember?.id || data?.updateMember?.id) {
      notify('Role removed.', { type: 'success' })
      onClose()
    }
  }

  return (
    <form className="delete-team-role-form">
      <div>
        {`Are you sure you want to remove the role of ${member.role} from your company?`}
      </div>
      <Button
        type="submit"
        label="Yes, Remove"
        theme="primary"
        size="medium"
        className="delete-team-role-form__submit"
        onClick={onSubmit}
      />
    </form>
  )
}

export default DeleteTeamRoleForm
