import React, { useMemo, useState } from 'react'
import { FieldValues, Path, UseFormRegister } from 'react-hook-form'
import { FlowIcons } from '~/src/assets/flow-icons'
import Button from '~/src/components/button'
import { FormFields } from '~/src/components/form-fields'
import { IAccountWithChildren } from '~/src/types/account'
import Classnames from 'classnames'

interface IAccountFieldProps<T extends FieldValues> {
  accountsWithChildren: IAccountWithChildren[]
  isIndeterminate: (id: string) => boolean
  account: IAccountWithChildren
  register: UseFormRegister<T>
  path: string
  onChange: (id: string, value: boolean) => void
  search: string
}

/**
 *
 * This is really a checkbox tree; it renders the account checkbox and its child checkboxes.
 * Fun with recursion and generic types!
 */
const AccountField = <T extends FieldValues>({
  accountsWithChildren,
  isIndeterminate,
  account,
  register,
  onChange: parentOnChange,
  search,
}: IAccountFieldProps<T>) => {
  const [childrenShown, toggleChildren] = useState<boolean>(true)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e?.target?.checked
    parentOnChange(account.id, value)
  }

  const getSearchMatches = (
    search: string,
    account: IAccountWithChildren,
    matches: boolean[] = []
  ): boolean[] => {
    if (!search) return [true]

    const thisAccountMatches = [account.name, account.accountNumber].some(
      string => string?.toLocaleLowerCase().includes(search.toLocaleLowerCase())
    )

    if (!account.children?.length) return [thisAccountMatches]
    return account.children.reduce((matches, child) => {
      return [
        ...matches,
        thisAccountMatches,
        ...getSearchMatches(search, child, matches),
      ]
    }, matches)
  }

  const searchMatches = getSearchMatches(search, account)
  const shouldShowAccount = searchMatches.some(result => !!result)

  const accountClasses = Classnames('account-item', {
    'account-item--has-children': !!account.children?.length,
    'account-item--hidden': !shouldShowAccount,
  })

  const childClasses = Classnames('account-tree', {
    'account-tree--hidden': !childrenShown,
  })

  // Find this account's first children (its collection of children is ALL children).
  // Retrieve them from our list of IAccountsWithChildren.
  const children = useMemo(
    () =>
      account.children
        ?.filter(child => child.parentId === account.id)
        .reduce((acc: IAccountWithChildren[], child) => {
          const childWithChildren = accountsWithChildren.find(
            ({ id }) => id === child.id
          )
          return childWithChildren ? [...acc, childWithChildren] : acc
        }, []) || [],
    [accountsWithChildren, account.children, account.id]
  )

  return (
    <li className={accountClasses}>
      <div className="flex flex-row items-center gap-1">
        {!!children.length && (
          <Button
            label="toggle child accounts"
            className="!h-5 !w-5 px-0"
            type="button"
            onClick={() => toggleChildren(!childrenShown)}
            icon={
              childrenShown ? FlowIcons.ChevronDown : FlowIcons.ChevronRight
            }
            iconColor="black"
            iconOnly
            theme="text"
          />
        )}
        <FormFields.Checkbox
          indeterminate={isIndeterminate(account.id)}
          {...register(`accountIds.${account.id}` as Path<T>)}
          onChange={onChange}
          label={account.name || ''}
          labelRight
        />
      </div>
      {children && (
        <ul className={childClasses}>
          {children.map(child => (
            <AccountField
              accountsWithChildren={accountsWithChildren}
              isIndeterminate={isIndeterminate}
              key={`${child.id} ${child.name}`}
              account={child}
              onChange={parentOnChange}
              register={register}
              search={search}
            />
          ))}
        </ul>
      )}
    </li>
  )
}

export default AccountField
