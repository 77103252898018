import React from 'react'
import Classnames from 'classnames'
import { TextareaProps } from '~/src/types/forms'

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (props, ref) => {
    const inputClasses = Classnames('form-field', {
      'form-field--invalid': props.error,
      'form-field--disabled': props.disabled,
    })

    const { testId, ...rest } = props

    return (
      <div className={inputClasses}>
        <label htmlFor={props.id} className="form-field__label">
          {props.label}
        </label>
        <textarea
          className="form-field__input"
          ref={ref}
          data-test={testId}
          {...rest}
        />
        <p className="form-field__error">{props.error}</p>
      </div>
    )
  }
)

export default Textarea
