import { useState, useMemo } from 'react'
import { SortOrder } from '../types/tables'
import { TSortableColumn } from '../components/sortable/components/sortable-column'
import { compareSortableFields } from '../utilities/sorting'
import { searchFilter } from '../utilities/filtering'
import { TSortableDatum } from '../components/sortable'

export const defaultOnSort = <
  T extends TSortableDatum,
  K extends keyof T = keyof T,
>(
  data: T[],
  sortField: K,
  sortOrder: SortOrder
) => [...data].sort(compareSortableFields<T>({ sortField, sortOrder }))

interface IUseSortableGrid<T extends TSortableDatum, K = keyof T> {
  columns: TSortableColumn<T, K>[]
  data?: T[]
  searchTerm?: string
  filterFields?: Array<keyof T>
  // Optional. If you provide an onSort function, it must be safe to call it with data
  onSort?: K extends keyof T
    ? (data: T[], sortField: K, sortOrder: SortOrder) => T[]
    : never
}

const useSortableGrid = <T extends TSortableDatum, K = keyof T>({
  columns,
  data,
  onSort,
  filterFields,
  searchTerm,
}: IUseSortableGrid<T, K>) => {
  const { accessor: initialSortField, initialSortOrder } =
    columns.find(column => column.default) || {}

  if (!initialSortField) {
    throw new Error('Must provide default sort field.')
  }

  if (data && !(onSort || filterFields)) {
    throw new Error(
      'Both `data` and `onSort` are required to use client-side sorting!'
    )
  }

  const [sortField, setSortField] = useState<K>(initialSortField)
  const [sortOrder, setSortOrder] = useState<SortOrder>(
    initialSortOrder || SortOrder.ASC
  )

  // Sets the sort field and direction
  const handleSortingChange = (accessor: K) => {
    const newOrder =
      accessor === sortField && sortOrder === SortOrder.ASC
        ? SortOrder.DESC
        : SortOrder.ASC

    setSortField(accessor)
    setSortOrder(newOrder)
  }

  // If you provided filter keys, and a search term: filter.
  const filteredAndSortedData = useMemo(() => {
    if (!data) return []

    // If you provided data and a sorting function, sort.
    const sorted = onSort ? onSort(data, sortField, sortOrder) : data

    if (!(filterFields?.length && searchTerm)) return sorted || []

    return sorted.filter(item => searchFilter(searchTerm, filterFields, item))
  }, [data, sortField, sortOrder, onSort, filterFields, searchTerm])

  return {
    handleSortingChange,
    sortField,
    sortOrder,
    data: filteredAndSortedData,
  }
}

export default useSortableGrid
