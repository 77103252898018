import React from 'react'
import SVGHandler from '~/src/components/svg-handler'
import { FlowIcons } from '~/src/assets/flow-icons'
import Classnames from 'classnames'

interface IActionItemNotification {
  type: 'overdue' | 'submitted'
}

const ActionItemNotification = ({
  type,
}: IActionItemNotification): JSX.Element => {
  const notificationClasses = Classnames('action-item__notification', {
    [`action-item__notification--${type}`]: type,
  })

  const icon = type === 'submitted' ? FlowIcons.CheckFill : FlowIcons.AlertFill
  const message =
    type === 'submitted'
      ? 'Answer is submitted and awaiting review by your accountant'
      : 'Immediate action is required!'

  const color = type === 'submitted' ? 'success' : 'warning'

  return (
    <div className={notificationClasses}>
      <SVGHandler
        className="flex-shrink-0"
        size="small"
        icon={icon}
        color={color}
      />
      <span>{message}</span>
    </div>
  )
}

export default ActionItemNotification
