import React, { useEffect } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import ROUTES from '~/src/constants/routes'
import useCurrentUser from '~/src/hooks/use-current-user'
import useInvitation from '~/src/hooks/use-invitation'

const ProtectedRoute = ({
  isAllowed,
  redirectPath,
  conditionName,
}: {
  isAllowed: boolean
  redirectPath: string
  // Unused in code. This is just nice to have if you have a crisis of faith
  // and need to log something.
  conditionName: string
}) => {
  const location = useLocation()
  const { logOutCurrentUser } = useCurrentUser()
  const fromPath = (location.state as any)?.from?.pathname ?? ''
  const [, invitationId] = location.pathname.match(/\/invitation\/(.*)/) || []
  const { invitation, loading } = useInvitation({ invitationId })

  if (fromPath.includes(ROUTES.login.subroutes.forgotPassword.path)) {
    logOutCurrentUser()
    return <Navigate to={fromPath} state={{ from: location }} replace />
  }

  if (isAllowed) {
    return <Outlet key={`${conditionName}${isAllowed}`} />
  }

  if (!invitationId) {
    return <Navigate to={redirectPath} state={{ from: location }} replace />
  }

  // Load the invitation to decide how to route.
  if (loading || !invitation) {
    return <div>Loading ...</div>
  }

  // Invitation belongs to an existing user. Send to login
  if (invitation.user_id) {
    return (
      <Navigate to={ROUTES.login.path} state={{ from: location }} replace />
    )
  }

  // Invitation email isn't registered with us. Send to registration
  return (
    <Navigate
      to={`/register/${invitationId}`}
      state={{ from: location }}
      replace
    />
  )
}

export default ProtectedRoute

export const AuthRequired = () => {
  const { loggedIn } = useCurrentUser()
  return (
    <ProtectedRoute
      conditionName="logged in"
      isAllowed={loggedIn}
      redirectPath={'/login'}
    />
  )
}

// Restricted to users who aren't logged in.
export const Unauthenticated = () => {
  const { loggedIn } = useCurrentUser()
  return (
    <ProtectedRoute
      isAllowed={!loggedIn}
      redirectPath={ROUTES.dashboard.path}
      conditionName="not logged in"
    />
  )
}

export const CurrentCompany = () => {
  const { currentCompany, loading } = useCurrentUser()

  if (loading) {
    return null
  }

  return (
    <ProtectedRoute
      isAllowed={!!currentCompany}
      redirectPath={ROUTES.companyPicker.path}
      conditionName="has current company"
    />
  )
}

export const NoCurrentCompany = () => {
  const {
    currentCompany,
    loading,
    companiesDataLoading,
    companies,
    setCompany,
  } = useCurrentUser()

  useEffect(() => {
    // We've loaded your current company and you don't have one.
    // But, you do have access to a single company, so you don't need to choose one.
    if (currentCompany === null && companies.length === 1) {
      setCompany(companies[0].id)
    }
  }, [currentCompany, loading, companies, setCompany])

  // We need to load your company to be sure you don't have one.
  // We also need your companies to be loaded to decide how to further route you.
  // If you only have one, we're going to set it (above, in useEffect) instead of
  // passing you through to render the picker.
  if (loading || companiesDataLoading) {
    return null
  }

  return (
    <ProtectedRoute
      isAllowed={!currentCompany}
      redirectPath={ROUTES.dashboard.path}
      conditionName="doesn't have current company"
    />
  )
}

export const DevelopersOnly = () => {
  const { isDeveloper } = useCurrentUser()
  return (
    <ProtectedRoute
      conditionName="user is a developer"
      isAllowed={isDeveloper}
      redirectPath={ROUTES.dashboard.path}
    />
  )
}
