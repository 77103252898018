import React from 'react'
import { eachMonthOfInterval, format } from 'date-fns'
import LineChart from '~/src/components/graphs/line'
import Loader from '~/src/components/loader'
import useSpending from '~/src/hooks/use-spending'
import { formatCurrency } from '~/src/utilities/currency'
import { GRAPH_DATE_FORMAT } from '../../config'
import { TCardSettings } from '~/src/types/card-settings'

const Spending = ({ startDate, endDate, accountIds }: TCardSettings) => {
  const { sortedExpenses, loading } = useSpending({
    startDate,
    endDate,
    accountIds,
  })

  const series = sortedExpenses.map(({ name, data }) => ({
    name: name || '',
    data: data.map(({ value }) => value),
  }))

  const labels = eachMonthOfInterval({
    start: startDate,
    end: endDate,
  }).map(date => format(date, GRAPH_DATE_FORMAT))

  return loading ? (
    <Loader size="lg" centered />
  ) : (
    <LineChart
      id="spending"
      series={series}
      labels={labels}
      yFormatter={(number: number) =>
        formatCurrency(number, {
          notation: 'compact',
        })
      }
    />
  )
}

export default Spending
