import { format, sub } from 'date-fns'
import { sortBy } from 'lodash'
import React from 'react'
import Banner from '~/src/components/banner'
import BookkeepingBanner from '~/src/components/bookkeeping-banner'
import Loader from '~/src/components/loader'
import PageHeader from '~/src/components/page-header'
import useJira from '~/src/hooks/use-jira'
import usePlaidBalances from '~/src/hooks/use-plaid-balances'
import { IChartRefs } from '~/src/types/graphs'
import DashboardCard from './components/dashboard-card'
import { SETTINGS } from './config'
import './dashboard.css'

interface IDashboardPage {
  graphRefs: IChartRefs
}

const DashboardPage = ({ graphRefs }: IDashboardPage): JSX.Element => {
  // Check to see which balance card we want to display.
  const { liveBalance, loading: liveBalancesLoading } = usePlaidBalances()

  // Check for what status banner to display, if any
  const { bookStatus, calendarRange, dueDate, loading: jiraLoading } = useJira()

  // If liveBalance is truthy, exclude QBO balances
  const excludedBalanceCard = liveBalance
    ? 'mini-cash-balances'
    : 'mini-live-cash-balance'

  const filteredCards = SETTINGS.filter(card => card.id !== excludedBalanceCard)

  const orderedCards = sortBy(filteredCards, 'order')

  // For accounts that aren't migrated to Jira yet, we display a simple banner
  // indicating their dashboard may be off while we work on books.
  const dayOfMonth = new Date().getDate()
  const lastMonth = format(sub(new Date(), { months: 1 }), 'MMMM yyyy')
  const showBackupBanner = dayOfMonth < 16 && !jiraLoading && !bookStatus

  const message = `Balances shown on your Dashboard may change while we're finalizing ${lastMonth}.`

  return (
    <>
      {showBackupBanner ? <Banner message={message} theme="primary" /> : null}
      <PageHeader title="Dashboard" showSnapshotButton={true} />
      {liveBalancesLoading ? (
        <Loader size="lg" centered altMask />
      ) : (
        <section className="pt-4">
          <div className="card-grid">
            {!jiraLoading && bookStatus ? (
              <BookkeepingBanner
                calendarRange={calendarRange}
                dueDate={dueDate}
                status={bookStatus}
              />
            ) : null}
            {orderedCards.map(config => {
              const refProps: {
                ref?: React.Ref<HTMLDivElement>
              } = {}
              if ('refKey' in config && !!config.refKey) {
                refProps.ref = graphRefs[config.refKey]
              }

              return (
                <DashboardCard key={config.id} id={config.id} {...refProps} />
              )
            })}
          </div>
        </section>
      )}
    </>
  )
}

export default DashboardPage
