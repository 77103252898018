import React from 'react'
import Button from '~/src/components/button'
import { FlowIcons } from '~/src/assets/flow-icons'
import { useDocumentsContext } from '../documents.context'
import useModal from '~/src/hooks/use-modal'
import { useFeatureFlag } from '~/src/hooks/use-feature-flag'
import { debounce } from 'debounce'

interface IDocumentsControls {
  searchPlaceholder?: string
}

const DocumentsControls = ({
  searchPlaceholder = 'Search',
}: IDocumentsControls) => {
  const showFolders = useFeatureFlag('showFolders')

  const { setSearchTerm, setUploadModalOpen, uploading } = useDocumentsContext()

  const { setModalOpen: setNewFolderModalOpen } = useModal({
    name: 'createFolder',
  })

  const handleSearch = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm?.(e.target.value)
  }, 200)

  return (
    <div className="flex flex-row gap-2 items-center mb-4">
      <input
        className="!w-1/4"
        type="text"
        data-test="documents-controls-search-input"
        placeholder={searchPlaceholder}
        onChange={handleSearch}
      />

      {showFolders && (
        <Button
          className="ml-auto"
          onClick={() => setNewFolderModalOpen()}
          theme="text"
          label="Add Folder"
          size="medium"
          icon={FlowIcons.Plus}
        />
      )}

      <Button
        className="ml-auto"
        onClick={() => setUploadModalOpen?.(true)}
        theme="text"
        testId="upload-file-button"
        label={uploading ? 'Upload in progress' : 'Upload'}
        size="medium"
        icon={FlowIcons.Upload}
        iconColor={uploading ? 'lightgray' : 'primary'}
        disabled={uploading}
      />
    </div>
  )
}

export default DocumentsControls
