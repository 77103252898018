import { gql, useQuery } from '@apollo/client'
import { eachMonthOfInterval, format, isWithinInterval, parse } from 'date-fns'
import { groupBy, sortBy, sumBy } from 'lodash'
import GET_REVENUE from '~/src/graphql/queries/revenue.graphql'
import useCurrentUser from '~/src/hooks/use-current-user'
import { Balance } from '~/src/types/balance'
import { QBO_DATE_FORMAT } from '../pages/dashboard/config'
import { TCardSettings } from '../types/card-settings'

const NO_DATA_VALUE = 0

const dateFromQBOFormat = (start: string) =>
  parse(start, QBO_DATE_FORMAT, new Date())
const monthAndYear = 'MM-yyyy'

const useRevenue = ({ startDate, endDate, accountIds }: TCardSettings) => {
  const { currentCompanyId } = useCurrentUser()
  const { data: data, loading } = useQuery(gql(GET_REVENUE), {
    variables: {
      company_id: currentCompanyId,
    },
  })

  const balances: Balance[] = data?.revenue ?? []

  const interval = {
    start: startDate,
    end: endDate,
  }

  const monthsInRange = eachMonthOfInterval(interval)
  const balancesInRange = balances?.filter(
    ({ start, accountId }) =>
      isWithinInterval(dateFromQBOFormat(start), interval) &&
      accountId &&
      accountIds?.includes(accountId)
  )

  const groupedByMonth = groupBy(balancesInRange || [], ({ start }) =>
    format(dateFromQBOFormat(start), monthAndYear)
  )

  const netSumsByMonth = monthsInRange.map((month: Date) => {
    const balancesInMonth = groupedByMonth[format(month, monthAndYear)]
    return {
      date: month,
      sum: balancesInMonth ? sumBy(balancesInMonth, 'value') : NO_DATA_VALUE,
    }
  })

  const revenueSumsByMonth = monthsInRange.map((month: Date) => {
    const balancesInMonth = groupedByMonth[format(month, monthAndYear)]
    return {
      date: month,
      sum: balancesInMonth
        ? sumBy(
            balancesInMonth.filter(b => b.value >= 0),
            'value'
          )
        : NO_DATA_VALUE,
    }
  })

  return {
    balances,
    netSumsByMonth: sortBy(netSumsByMonth, 'date'),
    revenueSumsByMonth: sortBy(revenueSumsByMonth, 'date'),
    loading,
  }
}

export default useRevenue
