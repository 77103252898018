import { useMemo } from 'react'
import useAccounts from './use-accounts'
import useExpenses from './use-expenses'
import { rollup } from '~/src/utilities/rollup'
import { eachMonthOfInterval, format, isWithinInterval, parse } from 'date-fns'
import { groupBy, sortBy, sumBy } from 'lodash'
import { Balance } from '~/src/types/balance'
import { QBO_DATE_FORMAT } from '~/src/pages/dashboard/config'

const dateFromQBOFormat = (start: string) =>
  parse(start, QBO_DATE_FORMAT, new Date())
const monthAndYear = 'MM-yyyy'

const useSpending = ({
  startDate,
  endDate,
  accountIds,
}: {
  startDate: Date
  endDate: Date
  accountIds?: string[]
}) => {
  const { data, loading: expensesLoading } = useExpenses()
  const { accounts, loading: accountsLoading } = useAccounts()
  const loading = accountsLoading || expensesLoading

  const interval = {
    start: startDate,
    end: endDate,
  }

  const balances: Balance[] = data?.spend ?? []
  const monthsInRange = eachMonthOfInterval(interval)
  const balancesInRange = balances?.filter(
    ({ start, accountId }) =>
      isWithinInterval(dateFromQBOFormat(start), interval) &&
      accountId &&
      accountIds?.includes(accountId)
  )

  const groupedByMonth = groupBy(balancesInRange || [], ({ start }) =>
    format(dateFromQBOFormat(start), monthAndYear)
  )

  const sortedExpenses = useMemo(() => {
    return rollup({
      accounts,
      accountIds,
      balances: data?.spend,
      start: startDate,
      end: endDate,
    })
  }, [accounts, accountIds, data?.spend, startDate, endDate])

  const sumsByMonth = monthsInRange.map((month: Date) => {
    const balancesInMonth = groupedByMonth[format(month, monthAndYear)]
    return {
      date: month,
      sum: balancesInMonth ? sumBy(balancesInMonth, 'value') : 0,
    }
  })

  return {
    sumsByMonth: sortBy(sumsByMonth, 'date'),
    sortedExpenses,
    loading,
  }
}

export default useSpending
