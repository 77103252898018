import React from 'react'
import Classnames from 'classnames'
import './user-icon.css'

type UserIconSizes = 'sm' | 'md' | 'lg' | 'xl'

// Any user-like type with these properties
interface IIconUser {
  first_name?: string | null
  last_name?: string | null
  avatar?: string | null
}

interface IUserIconProps {
  user?: IIconUser
  size: UserIconSizes
  color?: string
  className?: string
}

const getInitials = ({
  first_name,
  last_name,
}: {
  first_name: string
  last_name: string
}): string => {
  return `${first_name.charAt(0)}${last_name.charAt(0)}`
}

const UserIcon = ({
  size,
  user,
  color,
  className,
}: IUserIconProps): JSX.Element => {
  const { avatar, first_name, last_name } = user || {}

  const userClasses = Classnames('user-icon', {
    'user-icon--sm': size === 'sm',
    'user-icon--md': size === 'md',
    'user-icon--lg': size === 'lg',
    'user-icon--xl': size === 'xl',
    ...(className ? { [className]: className } : {}),
  })

  return (
    <div
      className={userClasses}
      style={color ? { backgroundColor: color } : {}}
    >
      {avatar ? (
        <img
          className="user-icon__avatar"
          src={avatar}
          alt={`${first_name} ${last_name}'s user avatar`}
        />
      ) : (
        <span>
          {first_name && last_name
            ? getInitials({ first_name, last_name })
            : undefined}
        </span>
      )}
    </div>
  )
}

export default UserIcon
