import { useMemo } from 'react'
import { useQuery, gql } from '@apollo/client'
import GET_TOP_VENDOR_EXPENSES_QUERY from '~/src/graphql/queries/top-paid-vendors.graphql'
import useCurrentUser from './use-current-user'
import { sortBy, sumBy } from 'lodash'
import { isWithinInterval, parse } from 'date-fns'
import { QBO_DATE_FORMAT } from '../pages/dashboard/config'

interface IVendorExpense {
  vendorName: string
  expenses: {
    date: string
    amount: number
  }[]
}

const useTopVendorExpenses = ({
  startDate,
  endDate,
}: {
  startDate: Date
  endDate: Date
}) => {
  const { currentCompanyId } = useCurrentUser()
  const { data, loading } = useQuery(gql(GET_TOP_VENDOR_EXPENSES_QUERY), {
    variables: { company_id: currentCompanyId },
    skip: !currentCompanyId,
  })

  const memoized = useMemo(() => {
    // List of vendor names that should be filtered out of the data.
    const forbiddenVendors = ['--Undefined--', 'Not Specified']

    const topPaidVendors: IVendorExpense[] = data?.topVendorExpenses || []

    // Vendors must have a name.
    const filtered = topPaidVendors.filter(
      ({ vendorName }) => vendorName && !forbiddenVendors.includes(vendorName)
    )

    const withSums = filtered.map(({ vendorName, expenses }) => {
      const expensesInDateRange = expenses.filter(({ date }) =>
        isWithinInterval(parse(date, QBO_DATE_FORMAT, new Date()), {
          start: startDate,
          end: endDate,
        })
      )

      const sum = sumBy(expensesInDateRange, ({ amount }) => amount)

      return {
        vendorName,
        totalAmountPaid: Math.round(sum),
      }
    })

    const nonZeroVendors = withSums.filter(
      v => v.totalAmountPaid > 0 && !forbiddenVendors.includes(v.vendorName)
    )

    return sortBy(nonZeroVendors, ['totalAmountPaid'])
  }, [endDate, startDate, data?.topVendorExpenses])

  return {
    topPaidVendors: memoized,
    loading,
  }
}

export default useTopVendorExpenses
