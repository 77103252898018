import React from 'react'
import AssetPreview from './asset-preview'
import DocumentUpload from './asset-upload'
import Pagination from '~/src/components/pagination'
import DocumentGridRow from './document-grid-row'
import Loader from '~/src/components/loader'
import { IAsset } from '~/src/types/asset'
import SortableGrid from '~/src/components/sortable'
import useSortableTable from '~/src/hooks/use-sortable-grid'
import usePagination from '~/src/hooks/use-pagination'
import { useDocumentsContext } from '../documents.context'
import { TSortableColumn } from '~/src/components/sortable/components/sortable-column'
import { SortOrder } from '~/src/types/tables'
import { SortableAssetFields } from '~/src/types/generated'
import { usePaginatedAssetQuery } from '~/src/graphql/queries/generated/paginated-assets'
import useCurrentUser from '~/src/hooks/use-current-user'

type ISortableAsset = IAsset & { last_name?: string | null }

const columns: TSortableColumn<ISortableAsset, SortableAssetFields>[] = [
  {
    label: 'Title',
    accessor: SortableAssetFields.DISPLAY_NAME,
    sortable: true,
  },
  {
    label: 'Description',
  },
  {
    label: 'Date uploaded',
    accessor: SortableAssetFields.CREATED,
    initialSortOrder: SortOrder.DESC,
    default: true,
    sortable: true,
    default: true,
  },
  {
    label: 'Uploaded by',
    accessor: SortableAssetFields.LAST_NAME,
    sortable: true,
  },
]

const AssetGrid = () => {
  const { searchTerm, setUploadModalOpen, isUploadModalOpen } =
    useDocumentsContext()

  const { currentCompanyId } = useCurrentUser()

  // Sortable/searchable table state.
  const { handleSortingChange, sortOrder, sortField } = useSortableTable({
    columns,
  })

  const { data, loading, fetchMore } = usePaginatedAssetQuery({
    variables: {
      company_id: currentCompanyId || '',
      search: searchTerm,
      sort_by: [{ sort_field: sortField, direction: sortOrder, priority: 1 }],
      config: {
        offset: 0,
        limit: 1000,
      },
    },
    skip: !currentCompanyId,
  })

  const { items, count } = data?.paginatedAssets || {}

  const { pagination, windowedItems } = usePagination<IAsset>({
    items,
    count,
    fetchMore,
  })

  return (
    <div className="p-4 rounded-lg border bg-white border-grayscale-300">
      {loading ? (
        <div className="m-10 flex items-center justify-center">
          <Loader size="lg" />
        </div>
      ) : (
        <div>
          <SortableGrid
            className="documents-table"
            columns={columns}
            handleSortingChange={handleSortingChange}
            sortOrder={sortOrder}
            sortField={sortField}
          >
            {windowedItems.map(document => {
              return <DocumentGridRow key={document.id} document={document} />
            })}
          </SortableGrid>
          {searchTerm && !count && (
            <div data-test="zero-state" className="my-4 text-center">
              No documents match your query.
            </div>
          )}
          <Pagination {...pagination} />
          <AssetPreview />
          <DocumentUpload
            show={isUploadModalOpen}
            onClose={() => setUploadModalOpen?.(false)}
          />
        </div>
      )}
    </div>
  )
}

export default AssetGrid
