import React from 'react'
import { useParams } from 'react-router-dom'
import EntryForm from '~/src/components/entry-form'
import useAuth from '~/src/hooks/use-auth'
import useInvitation from '~/src/hooks/use-invitation'
import Loader from '~/src/components/loader'
import { Title } from 'react-head'

const Register = () => {
  const { invitationId } = useParams()
  const { acceptInvitationWithPassword, acceptInvitationWithGoogleAuth } =
    useAuth({
      invitationId: invitationId,
    })

  const { invitation } = useInvitation({ invitationId })

  if (!invitation) {
    return <Loader fullScreen size="lg" />
  }

  return (
    <>
      <Title>FlowFi App | Register</Title>
      <EntryForm
        description={`Use your ${invitation.email_address} Google account or choose a password to get started.`}
        email={invitation.email_address}
        submitText="Register"
        showDisclaimer={true}
        showPasswordConfirm={true}
        onGoogleAuthSuccess={acceptInvitationWithGoogleAuth}
        onSubmit={acceptInvitationWithPassword}
      />
    </>
  )
}

export default Register
