import React, { ForwardedRef, useMemo } from 'react'
import Classnames from 'classnames'
import useDashboardSettings from '~/src/hooks/use-dashboard-settings'
import { IInitialDashboardSettings, SETTINGS } from '../../config'
import DateRangePicker, {
  useDateRangeModal,
} from '../card-settings/date-range-picker'
import AccountPicker, { useAccountPicker } from '../account-picker'
import BillsInvoicesSettings, {
  useBillsInvoicesSettings,
} from '../bills-invoices-settings'
import { TDaysOverdue } from '~/src/types/card-settings'
import useAccounts from '~/src/hooks/use-accounts'
import useTopExpenseAccounts from '~/src/hooks/use-top-expense-accounts'
import { endDateFromRange, startDateFromRange } from '~/src/utilities/dates'

// Don't pull this into the component where it will be instantiated on every render;
// it gets fed into a long chain of memoized functions.
const topExpenseDateRange = {
  startDate: startDateFromRange(6),
  endDate: endDateFromRange(),
}

interface IDashboardCardProps {
  id: (typeof SETTINGS)[number]['id']
}

const DashboardCard = (
  props: IDashboardCardProps,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const { id } = props

  const {
    range,
    showAccountPicker,
    showDateRangePicker,
    maxAccounts,
    startDate,
    title,
    showBillsSettings,
    settingsTitle,
    minHeight,
    minBalance,
    minDaysOverdue,
    accountTypes,
    className,
    component,
    getDefaultAccountIds,
    endDate,
    headerComponent = () => null,
  } = SETTINGS.find(
    ({ id: cardId }) => cardId === id
  ) as IInitialDashboardSettings

  // If this card needs account data for its account select, fetch it.
  const { accounts } = useAccounts({
    accountTypes,
    skip: !showAccountPicker,
  })

  // The Spending card needs this.
  const { sumsByAccount } = useTopExpenseAccounts(topExpenseDateRange)

  const initialSettings = useMemo(() => {
    const topExpenseIds = sumsByAccount.map(({ id }) => id)
    // Pure function of accounts. Should return no data if no accounts.
    const defaultSelectedIds = getDefaultAccountIds?.(accounts, topExpenseIds)

    const loading = showAccountPicker ? !defaultSelectedIds : false

    if (loading) return
    return {
      range,
      startDate,
      endDate,
      minBalance,
      minDaysOverdue,
      accountIds: defaultSelectedIds,
    }
  }, [
    range,
    startDate,
    endDate,
    minBalance,
    minDaysOverdue,
    getDefaultAccountIds,
    showAccountPicker,
    accounts,
    sumsByAccount,
  ])

  // Provides a getter and setter for dashboard card state
  // that gets synced to/retrieved from localStorage.
  const { settings, updateSettings } = useDashboardSettings({
    id,
    initialSettings,
  })

  const billsSettingsData = {
    minBalance: settings?.minBalance
      ? parseInt(settings.minBalance.toString())
      : 0,
    minDaysOverdue: settings?.minDaysOverdue || (0 as TDaysOverdue),
  }

  const accountModalState = useAccountPicker()
  const dateRangeModalState = useDateRangeModal()
  const billsSettingsModalState = useBillsInvoicesSettings()
  const cardClasses = Classnames('dashboard-card', {
    ...(className
      ? {
          [className]: className,
        }
      : {}),
  })

  const Content = component
  const Header = headerComponent

  const loading = !settings

  return (
    <>
      <div id={props.id} className={cardClasses} ref={ref}>
        <div className="dashboard-card__header">
          <h3 className="dashboard-card__name">{title}</h3>
          {!loading && (
            <>
              {showDateRangePicker && (
                <DateRangePicker
                  cardSettings={settings}
                  setCardSettings={updateSettings}
                  {...dateRangeModalState}
                />
              )}
              <Header {...settings} />
              {showAccountPicker && accounts && (
                <AccountPicker
                  title={title}
                  accounts={accounts}
                  accountIds={settings.accountIds}
                  {...accountModalState}
                  setCardSettings={updateSettings}
                  maxAccounts={maxAccounts}
                />
              )}
              {showBillsSettings && (
                <BillsInvoicesSettings
                  title={settingsTitle || 'Settings'}
                  defaultValues={billsSettingsData}
                  setCardSettings={updateSettings}
                  {...billsSettingsModalState}
                />
              )}
            </>
          )}
        </div>
        {minHeight ? (
          <div style={{ minHeight }}>
            {settings && <Content {...settings} />}
          </div>
        ) : (
          settings && <Content {...settings} />
        )}
      </div>
    </>
  )
}

const WrappedDashboardCard = React.forwardRef<
  HTMLDivElement,
  IDashboardCardProps
>(DashboardCard)

export default WrappedDashboardCard
