import { Combobox, Transition } from '@headlessui/react'
import Classnames from 'classnames'
import React, { useState } from 'react'
import { FieldValues, useController } from 'react-hook-form'
import { FlowIcons } from '~/src/assets/flow-icons'
import SVGHandler from '~/src/components/svg-handler'
import { SelectProps, TStringOrNumber } from '~/src/types/forms'

type SelectWithFilterProps<T extends FieldValues> = SelectProps<T> & {
  // unique id and action text if the user should be allowed to select an option to create new
  create?: { id: TStringOrNumber; actionText: string }
}

export const SelectWithFilter = <T extends FieldValues>(
  props: SelectWithFilterProps<T>
): JSX.Element => {
  const selectClasses = Classnames('form-field', {
    'form-field--invalid': props.error,
    'form-field--disabled': props.disabled,
  })

  const {
    field: { value, onChange },
  } = useController(props)

  const [filter, setFilter] = useState('')

  const filteredOptions = props.options.filter(item => {
    return item.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
  })

  if (props.create) {
    const createOption = { id: props.create.id, name: filter }
    filteredOptions.push(createOption)
  }

  return (
    <Combobox
      as="div"
      className={props.className || ''}
      value={value}
      name={props.name}
      disabled={props.disabled}
      onChange={onChange}
    >
      {({ open }) => (
        <div className={selectClasses}>
          <Combobox.Label className="form-field__label">
            {props.label}
          </Combobox.Label>
          <div className="relative">
            <Combobox.Input
              onChange={event => setFilter(event.target.value)}
              displayValue={(value: T) => value?.name}
            />
            <Combobox.Button className="combobox__trigger">
              <SVGHandler
                size="small"
                icon={open ? FlowIcons.ChevronUp : FlowIcons.ChevronDown}
                color={open ? 'black' : 'gray'}
              />
            </Combobox.Button>

            <Transition
              show={open}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setFilter('')}
            >
              <Combobox.Options static className="select__popover">
                {filteredOptions.map((option, index) => (
                  <Combobox.Option
                    key={`${option.name}-${index}`}
                    value={option}
                  >
                    {({ selected, active }) => (
                      <div
                        className={`${
                          active ? 'select__popover-item--active' : ''
                        } select__popover-item`}
                      >
                        <span
                          className={`${
                            selected ? 'select__popover-item-text--active' : ''
                          } select__popover-item-text`}
                        >
                          {option.id === props.create?.id
                            ? `${props.create.actionText} "${option.name}"`
                            : option.name}
                        </span>
                      </div>
                    )}
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            </Transition>
          </div>
          <p className="form-field__error">{props.error}</p>
        </div>
      )}
    </Combobox>
  )
}

export default SelectWithFilter
