import { InMemoryCacheConfig, makeVar } from '@apollo/client'
import { getFromLocalStorage } from '../utilities/local-storage'
import { merge } from './pagination-cache-policy'

// Reactive Vars
export const navCollapsed = makeVar(false)
export const subscriptionsTextFilter = makeVar('')
export const snapshotModalOpen = makeVar(false)

export const modalItemId = makeVar('')
export const modalKey = makeVar('')

// Localstorage values are present or null. In the interest
// of not introducing a third state, we fall back to undefined
// if not set.
const savedUserId = localStorage.getItem('loggedInUserId')

export const loggedInUserId = makeVar<string | undefined>(
  savedUserId || undefined
)

const getSelectedCompanyId = () => {
  if (!savedUserId) {
    return
  }

  return getFromLocalStorage({ key: savedUserId, path: 'selectedCompanyId' })
}

export const selectedCompanyId = makeVar<string | undefined>(
  getSelectedCompanyId()
)

// Cache Configuration
export const cacheOptions: InMemoryCacheConfig = {
  typePolicies: {
    CardDataBalance: { keyFields: false },
    Query: {
      fields: {
        documents: {
          keyArgs: [
            'company_id',
            'folder_id',
            'search',
            'fixed',
            'sort_by',
            ['priority', 'direction', 'sort_field'],
          ],
          merge,
        },
        paginatedAssets: {
          keyArgs: ['company_id', 'sort_by', 'search'],
          merge,
        },
        members: {
          keyArgs: ['company_id', 'user_id', 'substring'],
          merge,
        },
      },
    },
  },
  possibleTypes: {
    Document: ['Folder', 'Asset'],
  },
}
