import { resolveEndpointsConfig, resolveRegionConfig, } from "@aws-sdk/config-resolver";
import { resolveEventStreamSerdeConfig, } from "@aws-sdk/eventstream-serde-config-resolver";
import { resolveBucketEndpointConfig, } from "@aws-sdk/middleware-bucket-endpoint";
import { getContentLengthPlugin } from "@aws-sdk/middleware-content-length";
import { getAddExpectContinuePlugin } from "@aws-sdk/middleware-expect-continue";
import { getHostHeaderPlugin, resolveHostHeaderConfig, } from "@aws-sdk/middleware-host-header";
import { getLoggerPlugin } from "@aws-sdk/middleware-logger";
import { getRecursionDetectionPlugin } from "@aws-sdk/middleware-recursion-detection";
import { getRetryPlugin, resolveRetryConfig } from "@aws-sdk/middleware-retry";
import { getValidateBucketNamePlugin } from "@aws-sdk/middleware-sdk-s3";
import { getAwsAuthPlugin, resolveAwsAuthConfig, } from "@aws-sdk/middleware-signing";
import { getUserAgentPlugin, resolveUserAgentConfig, } from "@aws-sdk/middleware-user-agent";
import { Client as __Client, } from "@aws-sdk/smithy-client";
import { getRuntimeConfig as __getRuntimeConfig } from "./runtimeConfig";
export class S3Client extends __Client {
    constructor(configuration) {
        const _config_0 = __getRuntimeConfig(configuration);
        const _config_1 = resolveRegionConfig(_config_0);
        const _config_2 = resolveEndpointsConfig(_config_1);
        const _config_3 = resolveRetryConfig(_config_2);
        const _config_4 = resolveHostHeaderConfig(_config_3);
        const _config_5 = resolveAwsAuthConfig(_config_4);
        const _config_6 = resolveBucketEndpointConfig(_config_5);
        const _config_7 = resolveUserAgentConfig(_config_6);
        const _config_8 = resolveEventStreamSerdeConfig(_config_7);
        super(_config_8);
        this.config = _config_8;
        this.middlewareStack.use(getRetryPlugin(this.config));
        this.middlewareStack.use(getContentLengthPlugin(this.config));
        this.middlewareStack.use(getHostHeaderPlugin(this.config));
        this.middlewareStack.use(getLoggerPlugin(this.config));
        this.middlewareStack.use(getRecursionDetectionPlugin(this.config));
        this.middlewareStack.use(getAwsAuthPlugin(this.config));
        this.middlewareStack.use(getValidateBucketNamePlugin(this.config));
        this.middlewareStack.use(getAddExpectContinuePlugin(this.config));
        this.middlewareStack.use(getUserAgentPlugin(this.config));
    }
    destroy() {
        super.destroy();
    }
}
