import { useLocation } from 'react-router'
import ROUTES from '~/src/constants/routes'
import { find } from 'lodash'

const useRoute = () => {
  const location = useLocation()

  /**
   * This gets the name of the currently active top-level route.
   *
   * Subroutes are visually respresented by tabs/folder structure, so we
   * don't need to take this further (until reality contradicts this).
   */
  const getRoute = (pathname: string) => {
    // Strip the route params off and just return the base path
    const basePath = `/${pathname.split('/')[1]}`

    // Match to our base routes
    return find(ROUTES, el => el.path === basePath)
  }

  const currentRoute = getRoute(location.pathname)

  return {
    currentRoute,
  }
}

export default useRoute
