import React from 'react'
import Button from '~/src/components/button'
import { FlowIcons } from '~/src/assets/flow-icons'
import { useNavigate } from 'react-router-dom'
import ROUTES from '~/src/constants/routes'
import { IBannerProps } from '~/src/types/bookkeeping-banner'

const BooksNeedResponse = ({ bookMonth }: IBannerProps): JSX.Element => {
  const navigate = useNavigate()

  return (
    <>
      <div className="tablet:pr-20 flex flex-col items-start">
        <h2 className="bookkeeping-banner__title">
          We need your help to close your books!
        </h2>
        <p className="bookkeeping-banner__text">
          We have some final questions before we can finish your books for{' '}
          <strong>&nbsp;{bookMonth}</strong>. Please click here to respond to
          your Action Items. Once we have answers from you, we will resolve and
          close your books.
        </p>
      </div>
      <Button
        className="mr-auto tablet:ml-auto my-auto"
        theme="primary"
        label="Go to Action Items"
        size="medium"
        iconPosition="right"
        icon={FlowIcons.ArrowRight}
        iconColor="white"
        onClick={() => navigate(ROUTES.actionItems.path)}
      />
    </>
  )
}

export default BooksNeedResponse
