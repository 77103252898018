import { AccountFragment } from '~/src/graphql/fragments/generated/account'
export type IAccount = AccountFragment

export enum AccountType {
  ACCOUNTS_PAYABLE = 'ACCOUNTS_PAYABLE',
  ACCOUNTS_RECEIVABLE = 'ACCOUNTS_RECEIVABLE',
  BANK = 'BANK',
  COGS = 'COGS',
  CREDIT_CARDS = 'CREDIT_CARDS',
  EQUITY = 'EQUITY',
  EXPENSE = 'EXPENSE',
  FIXED_ASSETS = 'FIXED_ASSETS',
  INCOME = 'INCOME',
  LONG_TERM_LIABILITIES = 'LONG_TERM_LIABILITIES',
  OTHER_ASSETS = 'OTHER_ASSETS',
  OTHER_CURRENT_ASSETS = 'OTHER_CURRENT_ASSETS',
  OTHER_CURRENT_LIABILITIES = 'OTHER_CURRENT_LIABILITIES',
  OTHER_EXPENSES = 'OTHER_EXPENSES',
  OTHER_INCOME = 'OTHER_INCOME',
}

export interface IAccountWithChildren extends IAccount {
  id: string
  children?: IAccountWithChildren[]
  parentIds?: string[]
}
