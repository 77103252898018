import { useQuery, gql } from '@apollo/client'
import GET_BILLS_QUERY from '~/src/graphql/queries/bills.graphql'
import useCurrentUser from './use-current-user'
import { IBill } from '../types/bills'
import { differenceInDays, parse } from 'date-fns'
import { QBO_DATE_FORMAT } from '~/src/pages/dashboard/config'

interface IUseBillsResponse {
  bills: IBill[]
  loading: boolean
}

const useBills = ({
  minBalance,
  minDaysOverdue,
}: {
  minBalance: number
  minDaysOverdue: number
}): IUseBillsResponse => {
  const { currentCompanyId } = useCurrentUser()
  const { data, loading } = useQuery(gql(GET_BILLS_QUERY), {
    variables: { company_id: currentCompanyId },
    skip: !currentCompanyId,
  })

  const daysOverdue = (bill: IBill) => {
    const startDate = parse(bill.due_date, QBO_DATE_FORMAT, new Date())

    const interval = differenceInDays(startDate, new Date())

    return Math.abs(interval)
  }

  const inRange = (days: number | undefined): boolean => {
    // considers a bill to be within range if minDays is 0 ("all") or if we couldn't figure
    // out a value for days_overdue (don't wanna lose random bills)
    if (minDaysOverdue === 0 || !days) return true

    return days >= minDaysOverdue
  }

  const bills: IBill[] = data?.bills.map((bill: IBill) => ({
    ...bill,
    days_overdue: daysOverdue(bill),
  }))

  const filteredBills = bills?.filter(bill => {
    return inRange(bill.days_overdue) && bill.remaining_balance >= minBalance
  })

  return {
    bills: filteredBills || [],
    loading,
  }
}

export default useBills
