import React from 'react'
import {
  AuthRequired,
  CurrentCompany,
  NoCurrentCompany,
  Unauthenticated,
} from './components/protected-route'
import ROUTES from './constants/routes'
import ActionItemsPage from './pages/action-items'
import CompanyPage from './pages/company'
import CompanyPickerPage from './pages/company-picker'
import DirectCompanyPickerPage from './pages/company-picker/direct'
import CompanyGeneral from './pages/company/components/general'
import CompanyUsers from './pages/company/components/users'
import DashboardPage from './pages/dashboard'
import DocumentsPage from './pages/documents'
import File, { fileLoader } from './pages/documents/components/file'
import Document, { folderLoader } from './pages/documents/components/document'
import Documents from './pages/documents/components/documents'
import InvitationPage from './pages/invitation'
import LoginPage from './pages/login'
import ProfilePage from './pages/profile'
import ProfileGeneral from './pages/profile/components/general'
import ProfileNotifications from './pages/profile/components/notifications'
import ProfilePreferences from './pages/profile/components/preferences'
import RegisterPage from './pages/register'
import TeamPage from './pages/team'
import { ResetPasswordPage } from './pages/reset-password-page'
import { GRAPH_REFS } from './components/snapshot-modal'
import {
  createHashRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom'
import { NavContainer } from './components/nav-container'

export const router = createHashRouter(
  createRoutesFromElements(
    <Route element={<NavContainer />}>
      <Route element={<AuthRequired />}>
        <Route element={<CurrentCompany />}>
          <Route
            path={ROUTES.dashboard.path}
            element={<DashboardPage graphRefs={GRAPH_REFS} />}
          />
          <Route path={ROUTES.actionItems.path} element={<ActionItemsPage />} />
          <Route path={ROUTES.documents.path} element={<DocumentsPage />}>
            <Route
              path={ROUTES.documents.subroutes.folder.path}
              loader={folderLoader}
              element={<Document />}
            />
            <Route
              path={ROUTES.documents.subroutes.file.path}
              loader={fileLoader}
              element={<File />}
            />
            <Route index element={<Documents />} />
          </Route>
          <Route path={ROUTES.team.path} element={<TeamPage />} />
          <Route path={ROUTES.company.path} element={<CompanyPage />}>
            <Route
              path={ROUTES.company.subroutes.general.path}
              element={<CompanyGeneral />}
            />
            <Route
              path={ROUTES.company.subroutes.users.path}
              element={<CompanyUsers />}
            />
          </Route>
          <Route path={ROUTES.profile.path} element={<ProfilePage />}>
            <Route
              path={ROUTES.profile.subroutes.general.path}
              element={<ProfileGeneral />}
            />
            <Route
              path={ROUTES.profile.subroutes.preferences.path}
              element={<ProfilePreferences />}
            />
            <Route
              path={ROUTES.profile.subroutes.notifications.path}
              element={<ProfileNotifications />}
            />
          </Route>
          <Route
            path={ROUTES.companyPicker.subroutes.direct.path}
            element={<DirectCompanyPickerPage />}
          />
        </Route>
        <Route element={<NoCurrentCompany />}>
          <Route
            path={ROUTES.companyPicker.path}
            element={<CompanyPickerPage />}
          />
        </Route>

        <Route path={ROUTES.invitation.path} element={<InvitationPage />} />
      </Route>

      <Route element={<Unauthenticated />}>
        <Route path={ROUTES.login.path} element={<LoginPage />} />
        <Route
          path={ROUTES.login.subroutes.forgotPassword.subroutes.reset.path}
          element={<ResetPasswordPage />}
        />
        <Route path={ROUTES.register.path} element={<RegisterPage />} />
      </Route>
    </Route>
  )
)
