# import UserFragment from "../fragments/user.graphql"

mutation AcceptInvitation(
  $username: String
  $password: String
  $password_confirmation: String
  $invitation_id: ID!
  $credential: String
  $user_id: ID
) {
  acceptInvite(
    username: $username
    password: $password
    password_confirmation: $password_confirmation
    invitation_id: $invitation_id
    credential: $credential
    user_id: $user_id
  ) {
    user {
      ...UserFragment
      id
    }
  }
}
