import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'
import GRAPH_COLORS from '~/src/constants/graph-colors'

interface IStackedBarChart {
  labelFormat?: { x?: string; y?: string }
  yFormatter?: (number: number) => string
  xFormatter?: (number: number) => string
  series: ApexAxisChartSeries
  labels?: string[]
  id: string
}

const StackedBarChart = ({
  labelFormat,
  yFormatter,
  xFormatter,
  labels,
  series,
  id,
}: IStackedBarChart) => {
  const chart = {
    options: {
      chart: {
        toolbar: {
          show: false,
        },
        id: id,
        fontFamily: 'Manrope',
        stacked: true,
      },
      tooltip: {
        enabled: true,
        custom: ({
          series,
          dataPointIndex,
          w,
        }: {
          series: ApexAxisChartSeries[]
          seriesIndex: number
          dataPointIndex: number
          w: any
        }) => {
          const sharedTip: number[] = series.map(serie =>
            parseInt(serie[dataPointIndex] as unknown as string)
          )

          let markup = ''

          for (let i = 0; i < sharedTip.length; i++) {
            const value = yFormatter?.(sharedTip[i])

            markup += `<div class="graph__tooltip-item">
              <div class="graph__marker" style="background-color: ${
                w.config.fill.colors[i]
              }">
              </div> 
              <div class="graph__tooltip-label">${
                w.globals.seriesNames[i]
              }:</div>
              <div class="graph__tooltip-value">${
                !isNaN(sharedTip[i]) ? value : 'N/A'
              }</div>
            </div>`
          }

          return `<div class="graph__tooltip">${markup}</div>`
        },
      },
      legend: {
        showForSingleSeries: true,
        fontSize: '12px',
        markers: {
          width: 10,
          height: 10,
          fillColors: GRAPH_COLORS,
          radius: 4,
        },
        labels: {
          colors: '#718096',
        },
        onItemClick: {
          toggleDataSeries: false,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        colors: GRAPH_COLORS,
        opacity: 1,
      },
      states: {
        hover: {
          filter: 'none',
        },
      },
      grid: {
        position: 'back',
        borderColor: '#EDF2F7',
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      xaxis: {
        categories: labels || ['One', 'Two', 'Three', 'Four', 'Five'],
        labels: {
          style: {
            colors: '#718096',
            fontSize: '10px',
          },
          format: labelFormat?.x,
          formatter: xFormatter,
        },
        axisBorder: {
          color: '#EDF2F7',
        },
        axisTicks: {
          color: '#EDF2F7',
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: '#718096',
            fontSize: '10px',
          },
          formatter: yFormatter,
        },
        axisBorder: {
          color: '#EDF2F7',
        },
        axisTicks: {
          color: '#EDF2F7',
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
        },
      },
    },
    series,
    noData: {
      text: 'Loading...',
    },
  }

  return (
    <ReactApexChart
      id={id}
      options={chart.options as ApexOptions}
      series={chart.series}
      type="bar"
      width="100%"
      height={350}
    />
  )
}

export default StackedBarChart
