import React, { useEffect, useState } from 'react'
import {
  Outlet,
  useNavigate,
  useLocation,
  useOutletContext,
} from 'react-router-dom'
import TabSet from '~/src/components/tabset'
import CompanyIcon from '~/src/components/company-icon'
import ROUTES from '~/src/constants/routes'
import useCurrentUser from '~/src/hooks/use-current-user'
import Modal from '~/src/components/modal'
import SVGHandler from '~/src/components/svg-handler'
import { FlowIcons } from '~/src/assets/flow-icons'
import PageHeader from '~/src/components/page-header'
import { AvatarUploadForm } from '~/src/components/avatar-upload-form'
import { IUser } from '~/src/types/user'
import { IMember } from '~/src/types/member'
import { ICompany } from '~/src/types/company'
import useResponsive from '~/src/hooks/use-responsive'

type CompanyContext = {
  currentCompany: ICompany
  currentUser: IUser
  currentMember: IMember
}
export const useCompanyContext = () => {
  return useOutletContext<CompanyContext>()
}

const CompanyPage = (): JSX.Element => {
  const { isDesktop, isTablet } = useResponsive()
  const { currentCompany, currentUser, currentMember } = useCurrentUser()
  const location = useLocation()
  const navigate = useNavigate()
  const [modalOpen, setModalOpen] = useState(false)

  // redirect to the general tab if the user navigates directly to /company
  useEffect(() => {
    if (location.pathname === ROUTES.company.path) {
      navigate(ROUTES.company.subroutes.general.path, { replace: true })
    }
  }, [location, navigate])

  return (
    <>
      <PageHeader title="Company">
        <div className="flex flex-row items-center gap-4">
          <button
            aria-label="change company icon"
            className="company-icon__button"
            onClick={() => setModalOpen(true)}
          >
            <div className="company-icon__overlay">
              <SVGHandler
                icon={FlowIcons.Snapshot}
                size="xlarge"
                color="white"
              />
            </div>
            <CompanyIcon size="xl" company={currentCompany} />
          </button>
          <Modal
            title="Update logo"
            open={modalOpen}
            onClose={() => setModalOpen(false)}
          >
            {currentCompany && (
              <AvatarUploadForm
                company={currentCompany}
                setModalOpen={setModalOpen}
              />
            )}
          </Modal>

          <h2>{currentCompany?.name}</h2>
        </div>
      </PageHeader>
      {!isDesktop && !isTablet && (
        <header className="flex items-center gap-4 pt-4">
          <CompanyIcon size="xl" company={currentCompany} />
          <h2>{currentCompany?.name}</h2>
        </header>
      )}
      <section>
        <TabSet routes={ROUTES.company.subroutes} />

        {currentCompany && currentUser && currentMember !== undefined && (
          <Outlet context={{ currentCompany, currentUser, currentMember }} />
        )}
      </section>
    </>
  )
}

export default CompanyPage
