import * as Types from '../../../types/generated/index';

import { gql } from '@apollo/client';
import { FolderShallowFragmentDoc } from './folder-shallow';
import { AssetFragmentDoc } from './asset';
export type DocumentShallow_Asset_Fragment = { __typename: 'Asset', id: string, name?: string | null, display_name?: string | null, description?: string | null, created?: string | null, updated?: string | null, url?: string | null, company_id: string, preview_url?: string | null, thumb_url?: string | null, folder_id?: string | null, user?: { __typename?: 'User', id: string, first_name?: string | null, last_name?: string | null } | null };

export type DocumentShallow_Folder_Fragment = { __typename: 'Folder', id: string, company_id: string, created_at: string, fixed: boolean, parent_id?: string | null, title: string, parent?: { __typename: 'Folder', id: string, company_id: string, created_at: string, fixed: boolean, title: string } | null, user?: { __typename: 'User', id: string, first_name?: string | null, last_name?: string | null, avatar?: string | null } | null };

export type DocumentShallowFragment = DocumentShallow_Asset_Fragment | DocumentShallow_Folder_Fragment;

export const DocumentShallowFragmentDoc = gql`
    fragment DocumentShallow on Document {
  ... on Folder {
    ...FolderShallow
  }
  ... on Asset {
    ...AssetFragment
  }
}
    `;