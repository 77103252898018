import { useQuery, gql } from '@apollo/client'
import GET_CASH_BALANCES from '~/src/graphql/queries/cash-balances.graphql'
import useCurrentUser from '~/src/hooks/use-current-user'
import { Balance } from '~/src/types/balance'

const useBalances = () => {
  const { currentCompanyId } = useCurrentUser()
  const { data: queryData, loading } = useQuery(gql(GET_CASH_BALANCES), {
    variables: {
      company_id: currentCompanyId,
    },
  })

  const balances: Balance[] | undefined = queryData?.cashBalances

  return { balances, loading }
}

export default useBalances
